import React from "react";
import {ManagementV1App} from "../../../../../gen/model/managementV1App";
import { SectionProps } from "../../../../components/Drawer/ItemDrawer";
import Select from "../../../../components/Select/Select";
import {arr} from "../../../../lib/helpers/renderhelper";
import {ResultError, Return} from "../../../../lib/result";
import Section from "../../../../components/Drawer/Section/Section";
import Description from "../../../../components/Description/Description";
import Label from "../../../../components/Label/Label";
import {AppstoreAddOutlined, AppstoreOutlined, PlusOutlined, UserOutlined} from "@ant-design/icons";
import Input from "../../../../components/Input/Input";
import styles from "./AppInfo.module.scss";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import AppCard from "../../../Clusters/Details/Overview/AppsList/AppCard";
import {Tooltip} from "../../../../components/Tooltip/Tooltip";
import SectionExpander from "../../../../components/Drawer/SectionExpander/SectionExpander";
import YAMLEditor from "../../../../components/YAMLEditor/YAMLEditor";
const { Option } = Select;

interface AppInfoState {
    icon: string | undefined;
    iconChanged: boolean;
    
    recommendedApp: string[];
    recommendedAppChanged: boolean;

    readme: string | undefined;
}

interface AppInfoProps extends SectionProps {
    app?: ManagementV1App;
}

export default class AppInfo extends React.PureComponent<AppInfoProps, AppInfoState> {
    state: AppInfoState = {
        icon: this.props.app?.spec?.icon,
        iconChanged: false,

        recommendedApp: arr(this.props.app?.spec?.recommendedApp),
        recommendedAppChanged: false,
        
        readme: this.props.app?.spec?.readme,
    };

    create = async (app: ManagementV1App): Promise<ResultError> => {
        if (!app.spec) {
            app.spec = {};
        }
        
        app.spec.icon = this.state.icon;
        app.spec.recommendedApp = this.state.recommendedApp;
        if (!this.state.readme) {
            delete(app.spec.readme);
        } else {
            app.spec.readme = this.state.readme;
        }

        return Return.Ok();
    };

    update = async (app: ManagementV1App): Promise<ResultError> => {
        if (!app.spec) {
            app.spec = {};
        }
        
        if (this.state.iconChanged) {
            app.spec.icon = this.state.icon;
        }
        if (this.state.recommendedAppChanged) {
            app.spec.recommendedApp = this.state.recommendedApp;
        }
        if (!this.state.readme) {
            delete(app.spec.readme);
        } else {
            app.spec.readme = this.state.readme;
        }
        return Return.Ok();
    };

    render() {
        if (this.props.mode === "batch") {
            return null;
        }
 
        return <Section title={`Display Options`} defaultFolded={true} foldable={true}>
            <div className={styles["row"]}>
                <div>
                    <Label>App Icon</Label>
                    <Input resetable={this.props.mode === "update"} placeholder={"https://path.to.my/image.png"} onChangedStatus={changed => this.setState({iconChanged: changed})} value={this.state.icon} onChange={(e) => this.setState({icon: e.target.value})} />
                    <Description>This is the icon that should be used to display the app.</Description>
                    <Tooltip placement={"topLeft"} title={"This is a preview of how the app would look like"}>
                        <div>
                            <AppCard transparent={true} status={"okay"} title={"My App"} icon={this.state.icon ? <img className={styles["app-icon"]} src={this.state.icon} /> : <AppstoreOutlined className={styles["app-icon-not-found"]} />} />
                        </div>
                    </Tooltip>
                </div>
                <div>
                    <Label>Recommend App</Label>
                    <Select resetable={this.props.mode === "update"}
                            onChangedStatus={changed => this.setState({recommendedAppChanged: changed})}
                            mode={"multiple"}
                            value={this.state.recommendedApp}
                            style={{width: "100%"}}
                            placeholder={"None"}
                            onChange={(e) => this.setState({recommendedApp: e})}>
                        <Option key={"space"} value={"space"}>
                            For Spaces
                        </Option>
                        <Option key={"cluster"} value={"cluster"}>
                            For Clusters
                        </Option>
                        <Option key={"virtualcluster"} value={"virtualcluster"}>
                            For Virtual Clusters
                        </Option>
                    </Select>
                    <Description>Specify if this should be a recommended app and displayed in one of the apps views.</Description>
                </div>
            </div>
            <SectionExpander name={"Readme"}>
                <Label>App Readme</Label>
                <YAMLEditor value={this.state.readme}
                            onChange={(value) => this.setState({readme: value})}
                            minLines={20}
                            maxLines={100}
                            language={"markdown"}
                            placeholder={`# h1 Heading 8-)
## h2 Heading
### h3 Heading
#### h4 Heading

and other markdown elements...`} />
                <Description>Optional app readme that will override the helm chart readme if specified. If no readme is given, Loft will try to read the original helm chart readme.</Description>
            </SectionExpander>
        </Section>
    }
}