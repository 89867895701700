import React, {useRef, useState} from "react";
import Metadata from "../../../components/Drawer/Sections/Metadata/Metadata";
import ItemDrawer, {SectionProps} from "../../../components/Drawer/ItemDrawer";
import {NewResource, Resources} from "../../../lib/resources";
import client from "../../../lib/client";
import {useDrawerDispatcher} from "../../../contexts/drawer/DrawerContext";
import Query from "../../../components/Query/Query";
import {ErrorMessage} from "../../../components/ErrorMessage/ErrorMessage";
import Loading from "../../../components/Loading/Loading";
import QuotaOwner from "./Sections/QuotaOwner";
import QuotaSpec from "./Sections/QuotaSpec";
import QuotaStatus from "./Sections/QuotaStatus";
import ClientMessage from "../../../lib/Message/ClientMessage";
import {ErrorTypeNotFound, ResultError, Return} from "../../../lib/result";
import {StorageV1ClusterQuota} from "../../../../gen/model/agentstorageV1ClusterQuota";

export interface QuotaDrawerProps extends SectionProps {
    cluster: string;

    quota?: StorageV1ClusterQuota;
    quotas?: StorageV1ClusterQuota[];

    allQuotas: Array<StorageV1ClusterQuota>;
    refetch: () => Promise<void>;
}

type ChangeFunctionProps = Omit<QuotaDrawerProps, "mode"|"refetch"|"allQuotas"> & {quotaOwnerRef: QuotaOwner, metadataRef: Metadata, quotaSpecRef: QuotaSpec};

async function onCreate({cluster, quotaOwnerRef, metadataRef, quotaSpecRef}: ChangeFunctionProps): Promise<ResultError> {
    // make sure we have an object
    let quota = NewResource(Resources.StorageV1ClusterQuota, undefined, {});

    // apply account quota owner
    const applyOwnerResult = await quotaOwnerRef.create(quota);
    if (applyOwnerResult.err) {
        return applyOwnerResult;
    }

    // apply account quota metadata
    const applyMetadataResult = await metadataRef.create(quota,async metadata => {
        // check if there is an account quota with the owner name already
        const ownerName = quota.spec!.user || quota.spec!.team;
        const result = await client.cluster(cluster, Resources.StorageV1ClusterQuota).Get(ownerName!);
        if (result.err) {
            if (result.val.type !== ErrorTypeNotFound) {
                return result;
            }

            metadata.name = ownerName;
        } else {
            metadata.generateName = ownerName + "-";
        }

        return Return.Ok();
    });
    if (applyMetadataResult.err) {
        return applyMetadataResult;
    }

    // apply quota spec
    const applyQuotaSpec = quotaSpecRef.create(quota);
    if (applyQuotaSpec.err) {
        return applyQuotaSpec;
    }

    // create quota
    const createResult = await client.cluster(cluster, Resources.StorageV1ClusterQuota).Create(quota);
    if (createResult.err) {
        return createResult;
    }

    return Return.Ok();
}

async function onUpdate({quota: originalQuota, cluster, metadataRef, quotaSpecRef}: ChangeFunctionProps): Promise<ResultError> {
    if (!originalQuota) {
        return Return.Ok();
    }

    // make sure the object is up to date
    const getAccountResult = await client.cluster(cluster, Resources.StorageV1ClusterQuota).Get(originalQuota.metadata?.name!);
    if (getAccountResult.err) {
        return getAccountResult;
    }

    // Set account quota
    let quota = getAccountResult.val;

    // apply quota metadata
    const applyMetadataResult = await metadataRef.update(quota);
    if (applyMetadataResult.err) {
        return applyMetadataResult;
    }

    // apply quota spec
    const applyQuotaSpec = quotaSpecRef.update(quota);
    if (applyQuotaSpec.err) {
        return applyQuotaSpec;
    }

    // update quota
    const updateResult = await client.cluster(cluster, Resources.StorageV1ClusterQuota).Update(quota.metadata?.name!, quota!);
    if (updateResult.err) {
        return updateResult;
    }

    return Return.Ok();
}

async function onBatch({quotas: originalQuotas, cluster, metadataRef, quotaSpecRef}: ChangeFunctionProps): Promise<ResultError> {
    if (!originalQuotas) {
        return Return.Ok();
    }

    // we refresh the objects here, otherwise it can be possible that we get a conflict error if the team has changed meanwhile
    const listResult = await client.cluster(cluster, Resources.StorageV1ClusterQuota).List();
    if (listResult.err) {
        return listResult;
    }

    // assign the new objects
    const quotas = listResult.val.items.filter(quota => originalQuotas!.find(oldQuota => oldQuota.metadata?.name === quota.metadata?.name));

    // apply account quota metadata
    const applyMetadataResult = await metadataRef.batch(quotas);
    if (applyMetadataResult.err) {
        return applyMetadataResult;
    }

    // apply account quota spec
    const applySpecResult = quotaSpecRef.batch(quotas);
    if (applySpecResult.err) {
        return applySpecResult;
    }

    // update quotas
    for (let i = 0; i < quotas.length; i++) {
        const quota = quotas[i];
        const updateResult = await client.cluster(cluster, Resources.StorageV1ClusterQuota).Update(quota.metadata?.name!, quota!);
        if (updateResult.err) {
            return updateResult;
        }

        quotas[i] = updateResult.val;
    }

    return Return.Ok();
}

export default function QuotaDrawer(props: QuotaDrawerProps) {
    const drawer = useDrawerDispatcher();
    const quotaOwnerRef = useRef<QuotaOwner>(null);
    const metadataRef = useRef<Metadata>(null);
    const quotaSpecRef = useRef<QuotaSpec>(null);

    return <Query query={async () => await client.cluster(props.cluster!, Resources.StorageV1ClusterQuota).Name(props.quota?.metadata?.name!).CanI("update")} skip={props.mode !== "update"}>
            {
                updateResult => {
                    if (updateResult.error) {
                        return <ErrorMessage error={updateResult.error} />
                    } else if (props.mode === "update") {
                        if (updateResult.loading) {
                            return <Loading />
                        }
                    }

                    const canUpdate = props.mode !== "update" || updateResult.data;
                    return <ItemDrawer hideButtons={!canUpdate} okButtonText={props.mode === "create" ? "Create" : "Update"} onOkAsync={async () => {
                        const message = ClientMessage.Loading(props.cluster);

                        // execute the create / update / batch logic
                        let result: ResultError | undefined = undefined;
                        if (props.mode === "create") {
                            result = await onCreate({cluster: props.cluster, quotaOwnerRef: quotaOwnerRef.current!, metadataRef: metadataRef.current!, quotaSpecRef: quotaSpecRef.current!});
                        } else if (props.mode === "update") {
                            result = await onUpdate({quota: props.quota, cluster: props.cluster, quotaOwnerRef: quotaOwnerRef.current!, metadataRef: metadataRef.current!, quotaSpecRef: quotaSpecRef.current!});
                        } else if (props.mode === "batch") {
                            result = await onBatch({quotas: props.quotas, cluster: props.cluster, quotaOwnerRef: quotaOwnerRef.current!, metadataRef: metadataRef.current!, quotaSpecRef: quotaSpecRef.current!});
                        }

                        // check if there was an error
                        if (result?.err) {
                            message.ErrorCluster(result, props.cluster);
                            return;
                        }

                        // refetch
                        await props.refetch();

                        message.DoneCluster(props.cluster);

                        // close drawer
                        drawer({});
                    }}>
                        <Query query={async () => await client.management(Resources.ManagementV1ClusterMembers).Get(props.cluster)}>
                            {
                                clusterMembersResult => {
                                    if (clusterMembersResult.error) {
                                        return <ErrorMessage error={clusterMembersResult.error} />;
                                    } else if (clusterMembersResult.loading) {
                                        return <Loading />
                                    }

                                    const clusterMembers = clusterMembersResult.data;
                                    return <React.Fragment>
                                        {canUpdate && <React.Fragment>
                                            <QuotaOwner mode={props.mode} cluster={props.cluster} clusterMembers={clusterMembers} ref={quotaOwnerRef} />
                                            <Metadata mode={props.mode} type={"Quota"} obj={props.quota} noMargin={props.mode !== "create"} ref={metadataRef} foldable={true} defaultFolded={true} />
                                            <QuotaSpec cluster={props.cluster} allQuotas={props.allQuotas} quota={props.quota} mode={props.mode} ref={quotaSpecRef} />
                                        </React.Fragment>}
                                        <QuotaStatus quota={props.quota!} mode={props.mode} noMargin={!canUpdate} />
                                    </React.Fragment>
                                }
                            }
                        </Query>
                    </ItemDrawer>
                }
            }
        </Query>
}