import React, {useState} from "react";
import AdminHeader from "../AdminHeader/AdminHeader";
import Label from "../../../components/Label/Label";
import Button from "../../../components/Button/Button";
import client from "../../../lib/client";
import {Resources} from "../../../lib/resources";
import Loading from "../../../components/Loading/Loading";
import {ErrorMessage} from "../../../components/ErrorMessage/ErrorMessage";
import useQuery, {QueryResult} from "../../../lib/Query/Query";
import {ManagementV1Config} from "../../../../gen/model/managementV1Config";
import YAMLEditor from "../../../components/YAMLEditor/YAMLEditor";
import Description from "../../../components/Description/Description";
import jsyaml, {load, safeDump} from "js-yaml";
import {Space} from "antd";
import {ErrorTypeNotFound} from "../../../lib/result";
import styles from "./Config.module.scss";
import ConfigApplyPopup from "./ConfigApplyPopup";

function renderConfig(result: QueryResult<ManagementV1Config>, value: string | undefined, setValue: (val: string) => void) {
    if (result.error) {
        if (result.error.val?.type === ErrorTypeNotFound) {
            return <div className={"color-warning"}>
                loft config API is disabled. Please configure loft through the corresponding config secret or file directly.
            </div>
        }
        
        return <ErrorMessage error={result.error} />;
    } else if (result.loading) {
        return <Loading />;
    }
    
    return <Space size={10} direction={"vertical"} className={styles["config-wrapper"]}>
        <div className={styles["config"]}>
            <div className={styles["config-editor"]}>
                <Label.Bold>Loft Configuration</Label.Bold>
                <YAMLEditor value={value}
                            onChange={setValue}
                            minLines={10}
                            maxLines={100}
                            placeholder={`# Example Configuration for OIDC Authentication
auth:
  oidc:
    issuerUrl: http://my-dex-issuer.com   
    clientId: "$CLIENTID"                          
    clientSecret: "$CLIENTSECRET"     
`}
                            width="400px" />
                <Description>Additional options such as authentication methods and OIDC settings can be configured here</Description>
            </div>
            <div className={styles["reference-wrapper"]}>
                <Label.Bold>Configuration Reference</Label.Bold>
                <YAMLEditor readOnly={true}
                            minLines={10}
                            className={styles["reference"]}
                            maxLines={50}
                            width={"550px"}
                            value={`# The domain Loft is running under
loftHost: ${window.location.host}
# Authentication Options for loft
auth:
  # Tell loft to allow OIDC for authentication
  oidc:
    # IssuerURL is the URL the provider signs ID Tokens as.
    issuerUrl: https://accounts.google.com 
    # ClientID the JWT must be issued for, the "sub" field.
    clientId: my-client
    # ClientSecret to issue tokens from the OIDC provider
    clientSecret: my-client-secret
    # Callback URL in the form of https://your-loft-domain/auth/oidc/callback
    redirectURI: https://loft.my.domain/auth/oidc/callback
    # (Optional) Path to a PEM encoded root certificate of the provider. 
    caFile: /tmp/ca-file.crt
    # (Optional) Specify whether to communicate without validating SSL certificates
    insecureCa: false
    # (Optional) UsernameClaim is the JWT field to use as the user's username. 
    # If not set defaults to email.
    usernameClaim: email
    # (Optional) If specified, causes claims mapping to username to be prefix with
    # the provided value.  
    usernamePrefix: my-prefix-
    # (Optional) If specified, causes the OIDCAuthenticator to try to populate the user's
    # groups with an ID Token field.
    groupsClaim: groups
    # (Optional) If specified, causes claims mapping to group names to be prefixed with the
    # value.
    groupsPrefix: group-prefix-
    # (Optional) If groups is non empty, access is denied if the user is not part of at least one 
    # of the specified groups.
    groups: ["my-oidc-group"]
    # (Optional) If specified, tells the OIDCAuthenticator to try to populate the user's
    # information from the UserInfo. This might be necessary for slim tokens such as used
    # by Okta
    getUserInfo: false
  # Tell loft to use github authentication
  github:
    # ClientID of the github application
    clientId: my-client
    # ClientSecret of the github application
    clientSecret: my-client-secret
    # Callback URL in the form of https://your-loft-domain/auth/github/callback
    redirectURI: https://loft.my.domain/auth/github/callback
    # (Optional) Loft queries the following organizations for group information. 
    # Group claims are formatted as "(org):(team)".
    # For example if a user is part of the "engineering" team of the "coreos"
    # org, the group claim would include "coreos:engineering".
    #
    # If orgs are specified in the config then user MUST be a member of at least one
    # of the specified orgs to authenticate with loft.
    orgs:
      # Organization name in github (not slug, full name)
    - name: My Organization
      # (Optional) Names of teams in a github organization. A user will be able to
      # authenticate if they are members of at least one of these teams.
      teams:
      - myteam
    # (Optional) Required ONLY for GitHub Enterprise.
    # This is the Hostname of the GitHub Enterprise account listed on the
    # management console.
    hostName: my-github.org
    # (Optional) Required ONLY for GitHub Enterprise.
    # Used to support self-signed or untrusted CA root certificates.
    rootCA: /certs/github.ca
  # Tell loft to use gitlab authentication
  gitlab:
    # ClientID for the gitlab authentication
    clientId: my-client
    # ClientSecret for the gitlab authentication
    clientSecret: my-client-secret
    # Callback URL in the form of https://your-loft-domain/auth/gitlab/callback
    redirectURI: https://loft.my.domain/auth/gitlab/callback
    # (Optional) BaseURL of the gitlab instance, 
    # default = https://gitlab.com
    baseURL: https://my-gitlab-instance.com
    # (Optional) Optional groups whitelist, communicated through the "groups" scope.
    # If groups is omitted, all of the user's GitLab groups are returned.
    # If groups is provided, this acts as a whitelist - only the user's GitLab 
    # groups that are in the configured groups below will go into the groups claim. 
    # Conversely, if the user is not in any of the configured groups, the user will
    # not be authenticated.
    groups:
    - my-group
  # Tell loft to use google authentication
  google:
    # ClientID for the google authentication
    clientId: my-client
    # ClientSecret for the google authentication
    clientSecret: my-client-secret
    # Callback URL in the form of https://your-loft-domain/auth/google/callback
    redirectURI: https://loft.my.domain/auth/google/callback
    # (Optional) defaults to "profile" and "email"
    scopes: ["profile", "email"]
    # (Optional) list of whitelisted domains. If this field is nonempty, 
    # only users from a listed domain will be allowed to log in
    hostedDomains: []
    # (Optional) list of whitelisted groups. If this field is nonempty, 
    # only users from a listed group will be allowed to log in
    groups: []
    # (Optional) path to service account json. If nonempty, 
    # and groups claim is made, will use authentication from file to
    # check groups with the admin directory api
    serviceAccountFilePath: /path/to/service/account.json
    # (Optional) Required if serviceAccountFilePath is set. The email of
    # a GSuite super user which the service account will impersonate
    # when listing groups
    adminEmail: myuser@mydomain.com
  # Tell loft to use microsoft authentication
  microsoft:
    # ClientID of the microsoft application. 
    clientId: my-client
    # ClientSecret of the microsoft application
    clientSecret: my-client-secret
    # Callback URL in the form of https://your-loft-domain/auth/microsoft/callback
    redirectURI: https://loft.my.domain/auth/microsoft/callback
    # (Optional) tenant configuration parameter controls what kinds of accounts 
    # may be authenticated in loft. By default, all types of Microsoft 
    # accounts (consumers and organizations) can authenticate in loft via Microsoft. 
    # To change this, set the tenant parameter to one of the following:
    # common - both personal and business accounts can authenticate via Microsoft (default)
    # consumers - only personal accounts can authenticate in loft
    # organizations - only business/school accounts can authenticate in loft
    # 'tenant uuid' or 'tenant name' - only accounts belonging to specific tenant 
    # identified by either 'tenant uuid' or 'tenant name' can authenticate in loft
    tenant: common
    # (Optional) It is possible to require a user to be a member of a particular 
    # group in order to be successfully authenticated in loft.
    groups: []
    # (Optional) Configuration option restricts the list to include only security groups. 
    # By default all groups (security, Office 365, mailing lists) are included.
    onlySecurityGroups: false
    # (Optional) Restrict the groups claims to include only the user’s groups
    # that are in the configured groups
    useGroupsAsWhitelist: false
  # Tell loft to use saml authentication
  saml:
    # SSO URL used for POST value.
    ssoURL: https://saml.example.com/sso
    # CA to use when validating the signature of the SAML response.
    ca: /path/to/ca.pem
    # CA's can also be provided inline as a base64'd blob.
    #
    # caData: ( RAW base64'd PEM encoded CA )
    # Callback URL in the form of https://your-loft-domain/auth/saml/callback
    redirectURI: https://loft.my.domain/auth/saml/callback
    # Name of attributes in the returned assertions to map to ID token claims.
    usernameAttr: name
    emailAttr: email
    groupsAttr: groups # optional
    # (Optional) List of groups to filter access based on membership
    allowedGroups: ["Admins"]
    # (Optional) To skip signature validation, uncomment the following field. This should
    # only be used during testing and may be removed in the future
    insecureSkipSignatureValidation: true
    # (Optional) Manually specify loft's Issuer value.
    # When provided loft will include this as the Issuer value during AuthnRequest.
    # It will also override the redirectURI as the required audience when evaluating
    # AudienceRestriction elements in the response.
    entityIssuer: https://loft.my.domain/auth/saml/callback
    # (Optional) Issuer value expected in the SAML response.
    ssoIssuer: https://saml.example.com/sso
    # (Optional) Delimiter for splitting groups returned as a single string.
    #
    # By default, multiple groups are assumed to be represented as multiple
    # attributes with the same name.
    #
    # If "groupsDelim" is provided groups are assumed to be represented as a
    # single attribute and the delimiter is used to split the attribute's value
    # into multiple groups.
    groupsDelim: ", "
    # (Optional) Requested format of the NameID.
    #
    # The NameID value is is mapped to the user ID of the user. This can be an
    # abbreviated form of the full URI with just the last component. For example,
    # if this value is set to "emailAddress" the format will resolve to:
    #
    #     urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress
    #
    # If no value is specified, this value defaults to:
    #
    #     urn:oasis:names:tc:SAML:2.0:nameid-format:persistent
    #
    nameIDPolicyFormat: persistent
  # Tell loft to disable password authentication
  password:
    # Whether password authentication should be disabled
    disabled: false
# 
# Use loft as an OIDC Provider. If enabled you can use loft to login to services
# that allow logging in through an OIDC provider (e.g. Harbor or Kubernetes)
#
oidc: 
  # If this feature should be enabled
  enabled: false
  # The OIDC clients that are allowed to request tokens from loft
  clients:
    # Name of the client
  - name: my-client
    # The id of the client
    clientId: my-client-id
    # The secret of the client
    clientSecret: my-client-secret
    # When loft is presented with a redirect URL, the url must match one of these
    redirectURIs:
    - https://my-site/oidc/redirect
# 
# Specify additional app repositories and custom predefined apps. The predefined apps
# are displayed in Spaces > Apps. After you change something here, loft will recrawl
# all repositories and show them in the Apps views. Please be patient, this can take
# some time.
#
apps:
  # If this option is true, loft will not parse any default repositories
  noDefault: false
  # Additional repositories that should be parsed. Not affected by the noDefault option
  repositories:
      # The name of the repository. Charts will be shown as name/chart in the UI
    - name: repo
      # The url to the repository. The index.yaml will be appended automatically
      url: http://url-to-repo.com
      # The username of the repository. If prefixed with $ indicates an environment variable
      username: $USERNAME
      # The password of the repository. If prefixed with $ indicates an environment variable
      password: $PASSWORD
      # If the repository TLS should be verified
      insecure: false
# 
# Loft auditing provides a security-relevant, chronological set of records documenting the sequence
# of actions in a cluster. Every action targeting the loft management API, that is forwarded to 
# a connected kubernetes cluster or targets a virtual cluster is audited and recorded. 
# Loft audits the activities generated by users and by applications that use loft kubernetes contexts.
#
audit:
  # Whether auditing should be enabled
  enabled: true
  # The policy log level to use. Can be a number between 0 and 4. Please either use level or policy, but not
  # both options together. If audit is enabled but no level or policy is defined, level 1 will be used.
  level: 0
  # The audit policy to use and log requests. By default loft will not log anything. The policy structure 
  # is the same as a kubernetes audit policy. 
  # See also: https://kubernetes.io/docs/tasks/debug-application-cluster/audit/#audit-policy
  policy:
    # rules specify the audit Level a request should be recorded at.
    # A request may match multiple rules, in which case the FIRST matching rule is used.
    # The default audit level is None, but can be overridden by a catch-all rule at the end of the list.
    # PolicyRules are strictly ordered.
    rules: ...
    # omitStages is a list of stages for which no events are created. Note that this can also
    # be specified per rule in which case the union of both are omitted.
    omitStages: ["RequestReceived", "ResponseComplete", "Panic"]
  # (Optional) DataStoreEndpoint is the database endpoint to use to store audit logs.
  # Sqlite or mysql endpoints are supported. If empty sqlite is used.
  dataStoreEndpoint: mysql://username:password@tcp(hostname:3306)/database-name
  # (Optional) DataStoreTTL is the time to live of objects within the data store. Defaults to 30 days
  dataStoreTTL: 720
  # (Optional) MaxAge is the maximum number of days to retain old log files based on the timestamp 
  # encoded in their filename.
  maxAge: 0
  # (Optional) MaxBackups is the maximum number of old log files to retain.
  maxBackups: 0
  # (Optional) MaxSize is the maximum size in megabytes of the log file before it gets rotated. 
  # It defaults to 100 megabytes.
  maxSize: 0
`} />
            </div>
        </div>
        
        <div style={{marginTop: "10px"}}>
            <Button style={{marginRight: "10px"}} onClick={() => setValue(jsyaml.safeDump(result.data!.spec!))}>Reset</Button>
            <ConfigApplyPopup refetch={result.refetch} config={value!} />
        </div>
    </Space>
}

export default function Config() {
    const [value, setValue] = useState<string | undefined>(undefined);
    const result = useQuery<ManagementV1Config>(async () => {
        const r = await client.management(Resources.ManagementV1Config).Get("loft-config");
        if (r.err) {
            return r;
        }

        let config = r.val.spec?.raw;
        if (config) {
            try {
                config = atob(config);

                // unfortunately we have to do a little migration here
                // since otherwise some configs might get displayed as {..} json
                // between < 1.10.0 versions and > 1.10.0 versions
                try {
                    if (config.length > 0 && config[0] === "{") {
                        const newConfig = safeDump(load(config))
                        if (newConfig) {
                            config = newConfig;
                        }
                    }
                } catch(e) {
                    // just ignore the error
                }
            } catch(e) {
                config = undefined;
            }
        }
        
        setValue(config);
        return r;
    });

    return <div className={styles["admin-wrapper"]}>
        <AdminHeader />
        {renderConfig(result, value, setValue)}
    </div>
}
