import React, {useContext, useMemo, useState} from "react";
import {DrawerDispatch, useItemDrawer} from "../../../../contexts/drawer/DrawerContext";
import {UserContextState, useUser} from "../../../../contexts/UserContext/UserContext";
import styles from "./VClusterTable.module.scss";
import {deepCopy, displayName, formatURIComponent, readableTime, Wait} from "../../../../lib/helper";
import DynamicTime from "../../../../components/DynamicTime/DynamicTime";
import Table, {TableActions} from "../../../../components/Table/Table";
import {Tooltip} from "../../../../components/Tooltip/Tooltip";
import {alert, confirm} from "../../../../lib/Modal/Modal";
import ClientMessage from "../../../../lib/Message/ClientMessage";
import client, {getApiHost} from "../../../../lib/client";
import {NewResource, Resources} from "../../../../lib/resources";
import {ApiOutlined, ReloadOutlined, SettingOutlined, WarningOutlined} from "@ant-design/icons/lib";
import {arr, removeDuplicateFilters} from "../../../../lib/helpers/renderhelper";
import {Button, Space} from "antd";
import {Link, useHistory} from "react-router-dom";
import {ResultError, Return} from "../../../../lib/result";
import {ColumnType} from "antd/lib/table";
import {creationTimestampSorter, nameSorter, numberSorter, stringSorter} from "../../../../lib/helpers/sorthelper";
import ShowYamlPopup from "../../../../components/ShowYamlPopup/ShowYamlPopup";
import {StorageV1VirtualCluster} from "../../../../../gen/model/agentstorageV1VirtualCluster";
import Icon, {FileOutlined, RetweetOutlined} from "@ant-design/icons";
import {ToggleColumn, ToggleColumnContent} from "../../../../contexts/ToggleColumn/ToggleColumn";
import Progress from "../../../../components/Progress/Progress";
import {
    aggregateCores,
    aggregateMemory,
    SpaceTableEntry, wakeUpSpace
} from "../../../Spaces/Spaces/SpaceTable/SpaceTable";
import VClusterDrawer from "../VClusterDrawer/VClusterDrawer";
import Code from "../../../../components/Code/Code";
import Query from "../../../../components/Query/Query";
import {getPodStatus, isPodReady, mapToLabelSelector} from "../../../../lib/helpers/kubehelper";
import copy from "copy-to-clipboard";
import VClusterBatchDeletePopup from "../VClusterBatchDeletePopup/VClusterBatchDeletePopup";
import VClusterDeletePopup from "../VClusterDeletePopup/VClusterDeletePopup";
import {ColumnFilterItem} from "antd/es/table/interface";
import FixedText from "../../../../components/FixedText/FixedText";
import {ValuePopup} from "../../../Clusters/Details/Policies/Policies";
import useQuery from "../../../../lib/Query/Query";
import {V1Beta1PodMetrics, VersionV1Version} from "../../../../lib/types";
import Warning from "../../../../components/Warning/Warning";
import {ManagementV1Cluster} from "../../../../../gen/model/managementV1Cluster";
import {ClusterObject} from "../../../Spaces/Spaces/Spaces";
import {ClusterV1VirtualCluster} from "../../../../../gen/model/clusterV1VirtualCluster";
import constants from "../../../../constants/constants";
import {ManagementV1VirtualClusterTemplate} from "../../../../../gen/model/managementV1VirtualClusterTemplate";
import {SleepPopover} from "../../../Spaces/Spaces/SpaceTable/SleepPopover";
import {ErrorMessage} from "../../../../components/ErrorMessage/ErrorMessage";
import {DockContext, DockTabActions, DockTabKind} from "../../../../components/Dock/DockContext";
import WakeUpIcon from "../../../../images/wakeup-icon.svg";
import SleepingIcon from "../../../../images/sleeping-icon.svg";
import SleepPopup from "../../../Spaces/Spaces/SpaceTable/SleepPopup";
import Description from "../../../../components/Description/Description";

export interface VClusterTableEntry extends ClusterObject<ClusterV1VirtualCluster> {
    metrics?: V1Beta1PodMetrics[];
}

function getSelectedVClusters(allVClusters: Array<ClusterObject<ClusterV1VirtualCluster>> | undefined, selectedRowKeys: React.ReactText[]): Array<ClusterObject<ClusterV1VirtualCluster>> {
    if (!allVClusters) {
        return [];
    }

    return allVClusters!.filter(vCluster => selectedRowKeys.some(selectedRowKey => {
        const vClusterName = selectedRowKey.toString().split("/");
        return vCluster.cluster === vClusterName[0] && vCluster.object?.metadata?.namespace === vClusterName[1] && vCluster.object?.metadata?.name === vClusterName[2];
    }));
}

interface NameColumnProps {
    isDisplayName?: boolean;

    vCluster: ClusterObject<ClusterV1VirtualCluster>;
}

function NameColumn(props: NameColumnProps) {
    const {vCluster} = props;
    const name = props.isDisplayName && vCluster.object?.metadata?.annotations?.[constants.LoftDisplayNameAnnotation] ? vCluster.object?.metadata?.annotations?.[constants.LoftDisplayNameAnnotation] : vCluster.object?.metadata?.name;
    const history = useHistory();
    if (!vCluster.object?.status?.syncerPod) {
        return <FixedText text={name}/>;
    }
    if (!isPodReady(vCluster.object?.status?.syncerPod as any)) {
        return <FixedText text={name}/>;
    }

    return <FixedText className={styles["clickable"]}
                      onClick={() => history.push(`/vclusters/${vCluster.cluster}/${vCluster.object?.metadata?.namespace}/${vCluster.object?.metadata?.name}/namespaces`)}
                      text={name}/>;
}

interface VersionColumnProps {
    vCluster: ClusterObject<ClusterV1VirtualCluster>;
}

function VersionColumn(props: VersionColumnProps) {
    const {vCluster} = props;
    if (!vCluster.object?.status?.syncerPod) {
        return <FixedText text={"Unknown"}/>;
    }

    let vcluster = arr(vCluster.object?.status?.syncerPod?.spec?.containers?.find(container => container.name === "vcluster")?.image?.split(":"));
    if (!vcluster[vcluster.length - 1] && vCluster.object?.status?.clusterPod) {
        vcluster = arr(vCluster.object?.status?.clusterPod?.spec?.containers?.find(container => container.name === "kube-apiserver")?.image?.split(":"));
    }
    
    return <FixedText text={vcluster[vcluster.length - 1] || "Unknown"}/>;
}

interface LogsPopupProps {
    vCluster: ClusterObject<ClusterV1VirtualCluster>;
    
    className?: string;
    text: React.ReactNode;
}

function LogsPopup(props: LogsPopupProps) {
    const { dispatch } = useContext(DockContext);
    const onClick = async () => {
        dispatch({
            type: DockTabActions.OPEN,
            payload: {
                kind: DockTabKind.LOGS,
                cluster: props.vCluster.cluster,
                namespace: props.vCluster.object?.metadata?.namespace!,
                pod: props.vCluster.object?.status?.syncerPod?.metadata?.name!,
            }
        })
    }
    
    return <React.Fragment>
        <Tooltip title="Show Logs"><span className={props.className} onClick={onClick}>{props.text}</span></Tooltip>
    </React.Fragment>;
}


function vClusterTemplate(vcluster: ClusterObject<ClusterV1VirtualCluster>, templates: Array<ManagementV1VirtualClusterTemplate> | undefined) {
    const template = vcluster.object?.metadata?.annotations?.[constants.LoftVirtualClusterTemplate];
    if (template) {
        return templates?.find(t => t.metadata?.name === template);
    }

    return undefined;
}

async function reapplyTemplate(vCluster: ClusterObject<ClusterV1VirtualCluster>, templates: Array<ManagementV1VirtualClusterTemplate> | undefined) {
    // find virtual cluster template
    const vClusterTemplateObj = vClusterTemplate(vCluster, templates);
    if (!vClusterTemplateObj) {
        return;
    }

    // update virtual cluster
    const oldVCluster = deepCopy(vCluster.object);
    vCluster.object!.metadata!.annotations = vClusterTemplateObj.spec?.template?.metadata?.annotations;
    vCluster.object!.metadata!.labels = vClusterTemplateObj.spec?.template?.metadata?.labels;
    vCluster.object!.spec!.access = vClusterTemplateObj.spec?.template?.access;
    vCluster.object!.spec!.helmRelease = vClusterTemplateObj.spec?.template?.helmRelease;

    // preserve old helm chart & repo
    if (!vCluster.object!.spec!.helmRelease) {
        vCluster.object!.spec!.helmRelease = {};
    }
    if (!vCluster.object!.spec!.helmRelease.chart) {
        vCluster.object!.spec!.helmRelease.chart = {};
    }
    vCluster.object!.spec!.helmRelease.chart.repo = oldVCluster?.spec?.helmRelease?.chart?.repo;
    vCluster.object!.spec!.helmRelease.chart.name = oldVCluster?.spec?.helmRelease?.chart?.name;

    // preserve old template annotation
    if (!vCluster.object!.metadata!.annotations) {
        vCluster.object!.metadata!.annotations = {}
    }
    vCluster.object!.metadata!.annotations[constants.LoftVirtualClusterTemplate] = vClusterTemplateObj.metadata!.name!;

    const patchResult = await client.cluster(vCluster.cluster!, Resources.StorageV1VirtualCluster).Namespace(vCluster.object?.metadata?.namespace).PatchObject(oldVCluster!, vCluster.object!);
    if (patchResult.err) {
        ClientMessage.Error(patchResult);
    }

    return;
}

interface StatusColumnProps {
    vCluster: ClusterObject<ClusterV1VirtualCluster>;
    refetch: () => Promise<void>;
}

function StatusColumn(props: StatusColumnProps) {
    const {vCluster} = props;
    if (vCluster.object?.metadata?.deletionTimestamp) {
        return <span className={"color-warning"}>Deleting</span>;
    }

    if (vCluster.object?.status?.sleepModeConfig?.status?.sleepingSince) {
        return <SleepPopover sleepModeConfig={vCluster.object?.status?.sleepModeConfig} cluster={vCluster.cluster} spaceName={vCluster.object?.metadata?.namespace} spacePhase={"Sleeping"} refetch={props.refetch} />;
    } else if (vCluster.object?.status?.phase === "Failed") {
        return <span className={styles["cluster-error"]} onClick={() => confirm({
                title: "Error",
                content: <ValuePopup value={vCluster.object?.status?.message + ""} />,
                okText: "Close",
                width: 900,
                hideCancel: true,
                onOkAsync: async _ => undefined
            })}><WarningOutlined />Error</span>;
    }

    if (!vCluster.object?.status?.syncerPod) {
        return <Link to={`/spaces/${vCluster.cluster!}/${vCluster.object?.metadata?.namespace!}/events`} className={"color-warning"}>Starting</Link>;
    }

    const status = getPodStatus(vCluster.object?.status?.syncerPod as any);
    if (status !== "Running") {
        return <Tooltip title={"more information"}>
            <Link className={"color-warning clickable-link"} to={`/spaces/${vCluster.cluster!}/${vCluster.object?.metadata?.namespace!}#search=${vCluster.object.status.syncerPod.metadata?.name!}`}><FixedText text={status} /></Link>
        </Tooltip>
    }

    if (!isPodReady(vCluster.object?.status?.syncerPod as any)) {
        return <LogsPopup className={styles["cluster"]} text={"Running (Not Ready)"} vCluster={vCluster} />;
    }

    return <LogsPopup className={styles["cluster"]} text={"Running"} vCluster={vCluster} />
}

function getTableColumns(refetch: () => Promise<void>, 
                         drawerDispatcher: DrawerDispatch, 
                         user: UserContextState, 
                         isClusterView: boolean, 
                         metricsAPIAvailable: boolean, 
                         vClusters: ClusterObject<ClusterV1VirtualCluster>[] | undefined,
                         clusters: Array<ManagementV1Cluster> | undefined,
                         templates: Array<ManagementV1VirtualClusterTemplate> | undefined): Array<ColumnType<ClusterObject<ClusterV1VirtualCluster>>> {
    const editVCluster = (vCluster: ClusterObject<ClusterV1VirtualCluster>) => {
        drawerDispatcher({
            title: "Edit Virtual Cluster: " + vCluster.object?.metadata?.name!,
            content: <VClusterDrawer mode={"update"} cluster={vCluster.cluster!} vCluster={vCluster.object} refetch={refetch} />
        })
    };

    const nameColumn: ColumnType<ClusterObject<ClusterV1VirtualCluster>> = {
        title: <ToggleColumn id={"displayname"} columns={['Display Name', 'Kubernetes Name (ID)']}/>,
        sorter: (a, b) => nameSorter(a.object, b.object),
        render: (vCluster: ClusterObject<ClusterV1VirtualCluster>) => <ToggleColumnContent id={"displayname"} columns={[
            () => {
                return <NameColumn vCluster={vCluster} isDisplayName={true} />;
            },
            () => {
                return <NameColumn vCluster={vCluster} />;
            },
        ]}/>,
    };
    const statusColumn: ColumnType<ClusterObject<ClusterV1VirtualCluster>> = {
        title: "Status",
        render: (vCluster: ClusterObject<ClusterV1VirtualCluster>) => <StatusColumn refetch={refetch} vCluster={vCluster} />,
    };
    const descriptionColumn: ColumnType<ClusterObject<ClusterV1VirtualCluster>> = {
        title: "Description",
        sorter: (a, b) => stringSorter(a.object?.metadata?.annotations?.[constants.LoftDescriptionAnnotation] || "", b.object?.metadata?.annotations?.[constants.LoftDescriptionAnnotation] || ""),
        render: (vCluster: ClusterObject<ClusterV1VirtualCluster>) => {
            return <Description.Column style={{minWidth: "100px"}}>
                {vCluster.object?.metadata?.annotations?.[constants.LoftDescriptionAnnotation] || ""}
            </Description.Column>;
        },
    };
    const versionColumn: ColumnType<ClusterObject<ClusterV1VirtualCluster>> = {
        title: "Kubernetes Version",
        render: (vCluster: ClusterObject<ClusterV1VirtualCluster>) => <VersionColumn vCluster={vCluster} />,
    };
    const templateColumn: ColumnType<ClusterObject<ClusterV1VirtualCluster>> = {
        title: <Tooltip title={"The Virtual Cluster Template that was used to create this virtual cluster"}>Template</Tooltip>,
        sorter: (a: ClusterObject<ClusterV1VirtualCluster>, b: ClusterObject<ClusterV1VirtualCluster>) => stringSorter(displayName(vClusterTemplate(a, templates)), displayName(vClusterTemplate(b, templates))),
        filters: removeDuplicateFilters(arr(vClusters).map(vCluster => {
            const name = displayName(vClusterTemplate(vCluster, templates));
            return {
                text: name,
                value: name + ""
            }
        })),
        onFilter: (value, record: ClusterObject<ClusterV1VirtualCluster>) => displayName(vClusterTemplate(record, templates)) === value,
        render: (vCluster: ClusterObject<ClusterV1VirtualCluster>) => {
            const template = vClusterTemplate(vCluster, templates);
            if (!template) {
                return <span>None</span>;
            }

            return <Link to={`/vclustertemplates#search=${formatURIComponent(displayName(template))}`} className={styles["cluster"]}><FixedText text={displayName(template)} /></Link>;
        }
    };
    const spaceColumn: ColumnType<ClusterObject<ClusterV1VirtualCluster>> = {
        title: "Space",
        sorter: (a, b) => stringSorter(a.object?.metadata?.namespace, b.object?.metadata?.namespace),
        render: (vCluster: ClusterObject<ClusterV1VirtualCluster>) => {
            return <Link to={`/spaces/${vCluster.cluster!}/${vCluster.object?.metadata?.namespace!}`} className={styles["cluster"]}><FixedText text={vCluster.object?.metadata?.namespace} /></Link>;
        }
    };
    const displayNameCluster = (space: ClusterObject<ClusterV1VirtualCluster>) => {
        const cluster = arr(clusters).find(cluster => cluster.metadata?.name === space.cluster);
        return cluster ? displayName(cluster) : space.cluster;
    }
    const clusterColumn: ColumnType<ClusterObject<ClusterV1VirtualCluster>> = {
        title: 'Parent Cluster',
        sorter: (a, b) => stringSorter(displayNameCluster(a), displayNameCluster(b)),
        filters: !isClusterView ? arr(vClusters).map(vCluster => {
            const cluster = displayNameCluster(vCluster);
            return {
                text: cluster,
                value: cluster + ""
            }
        }).reduce((self: any, c) => {
            if (arr(self as ColumnFilterItem[]).find(s => s.value === c.value)) {
                return self;
            }

            return [...self, c];
        }, []) : undefined,
        onFilter: !isClusterView ? (value, record: ClusterObject<ClusterV1VirtualCluster>) => displayNameCluster(record) === value : undefined,
        render: (vCluster: ClusterObject<ClusterV1VirtualCluster>) => {
            return <Link to={`/clusters/details/${vCluster.cluster!}`} className={styles["cluster"]}><FixedText text={displayNameCluster(vCluster)} /></Link>;
        }
    };
    const timeSleepingColumn = {
        title: <ToggleColumn id={"time-sleeping"} columns={['Time Sleeping (last 30 days)', 'Time Sleeping (last 7 days)']}/>,
        width: "280px",
        render: (vCluster: ClusterObject<ClusterV1VirtualCluster>) => {
            return <ToggleColumnContent id={"time-sleeping"} columns={[
                () => {
                    if (vCluster.object?.status?.sleepModeConfig && vCluster.object?.status?.sleepModeConfig.status) {
                        return <SleepPopover sleepModeConfig={vCluster.object?.status?.sleepModeConfig} cluster={vCluster.cluster} spaceName={vCluster.object?.metadata?.namespace} refetch={refetch}>
                            <Progress className={styles["progress"]} status={"normal"} percent={Math.round(vCluster.object?.status?.sleepModeConfig?.status?.sleptLastThirtyDays! * 100)} />
                        </SleepPopover>
                    }

                    return <SleepPopover sleepModeConfig={vCluster.object?.status?.sleepModeConfig} cluster={vCluster.cluster} spaceName={vCluster.object?.metadata?.namespace} refetch={refetch}>
                        <Progress className={styles["progress"]} percent={0} />
                    </SleepPopover>
                },
                () => {
                    if (vCluster.object?.status?.sleepModeConfig && vCluster.object?.status?.sleepModeConfig.status) {
                        return <SleepPopover sleepModeConfig={vCluster.object?.status?.sleepModeConfig} cluster={vCluster.cluster} spaceName={vCluster.object?.metadata?.namespace} refetch={refetch}>
                            <Progress className={styles["progress"]} status={"normal"} percent={Math.round(vCluster.object?.status?.sleepModeConfig?.status?.sleptLastSevenDays! * 100)} />
                        </SleepPopover>
                    }

                    return <SleepPopover sleepModeConfig={vCluster.object?.status?.sleepModeConfig} cluster={vCluster.cluster} spaceName={vCluster.object?.metadata?.namespace} refetch={refetch}>
                        <Progress className={styles["progress"]} percent={0} />
                    </SleepPopover>;
                }
            ]} />
        }
    };
    const createdColumn: ColumnType<ClusterObject<ClusterV1VirtualCluster>> = {
        title: 'Created',
        width: "180px",
        sorter: (a, b) => creationTimestampSorter(a.object, b.object),
        render: (vCluster: ClusterObject<ClusterV1VirtualCluster>) => {
            return <DynamicTime timestamp={vCluster.object?.metadata?.creationTimestamp} useTooltip={true}/>
        }
    };
    const actionsColumn: ColumnType<ClusterObject<ClusterV1VirtualCluster>> = {
        title: 'Actions',
        width: "230px",
        render: (vCluster: ClusterObject<ClusterV1VirtualCluster>) => {
            const isSleeping = !!vCluster.object?.status?.sleepModeConfig?.status?.sleepingSince;
            const vClusterTemplateObject = vClusterTemplate(vCluster, templates);
            return <TableActions className={styles["actions"]}>
                <Tooltip title={"connect"}>
                    <ApiOutlined className={styles["wakeup"]} onClick={() => {
                        const copyText = `loft use vcluster ${vCluster.object?.metadata?.name!} --cluster ${vCluster.cluster} --space ${vCluster.object?.metadata?.namespace}`;

                        alert({
                            title: `Access the vCluster via kubectl`,
                            okText: "Copy & Close",
                            onOkAsync: async () => copy(copyText),
                            content: <Space direction={"vertical"} size={10}>
                                <div>
                                    Make sure you have <a className={"text-bold color-primary"} href={"https://loft.sh/install-cli"} target={"_blank"}>loft CLI</a> installed and copy the following command to configure your local kubernetes context to use this vCluster:
                                </div>
                                <Code text={copyText} />
                            </Space>
                        })
                    }} />
                </Tooltip>
                {vClusterTemplateObject && <Tooltip title={"reapply template"}>
                    <RetweetOutlined className={styles["wakeup"]} onClick={async () =>
                    {
                        confirm({
                            title: `Re-apply Template`,
                            width: 700,
                            content: <div>
                                Are you sure you want to reapply the template on the selected virtual cluster?
                                This might override existing settings.
                            </div>,
                            okText: "Apply",
                            onOkAsync: async () => {
                                const message = ClientMessage.Loading();
                                await reapplyTemplate(vCluster, templates!);
                                message.DoneManagement();
                                await refetch();
                            }
                        })
                    }} />
                </Tooltip>}
                {
                    isSleeping ? <Tooltip title={isSleeping ? "wakeup" : "sleep"}>
                        <Icon className={styles["wakeup"]} component={isSleeping ? WakeUpIcon as any : SleepingIcon as any} onClick={() => {
                            confirm({
                                title: `WakeUp Space: ${vCluster.object?.metadata?.namespace}`,
                                content: `Are you sure you want to wake up the vcluster space ${vCluster.object?.metadata?.namespace}?`,
                                onOkAsync: () => wakeUpSpace(vCluster.object?.status?.sleepModeConfig, vCluster.cluster, vCluster.object?.metadata?.namespace, refetch),
                            });
                        }} />
                    </Tooltip> : <SleepPopup className={styles["wakeup"]}
                                             config={vCluster.object?.status?.sleepModeConfig!}
                                             spaceName={vCluster.object?.metadata?.namespace}
                                             cluster={vCluster.cluster!}
                                             refetch={refetch} />
                }
                <LogsPopup vCluster={vCluster} text={<FileOutlined className={styles["setting"]} />} />
                <Tooltip title="restart">
                    <ReloadOutlined className={styles["setting"]} onClick={() => {
                        confirm({
                            title: `Restart Virtual Cluster: ${vCluster.object?.metadata?.name}`,
                            content: `Are you sure you want to restart the Virtual Cluster ${vCluster.object?.metadata?.name}?`,
                            onOkAsync: async () => {
                                const message = ClientMessage.Loading(vCluster.cluster);
                                if (!vCluster.object?.status?.syncerPod) {
                                    message.ErrorCluster(Return.Failed("no pod found for virtual cluster"), vCluster.cluster!);
                                    return;
                                }

                                const result = await client.cluster(vCluster.cluster!, Resources.V1Pod).Namespace(vCluster.object?.metadata?.namespace).Delete(vCluster.object?.status?.syncerPod?.metadata?.name!);
                                message.Result(result);
                                await refetch();
                            },
                        });
                    }} />
                </Tooltip>
                <Tooltip title="edit">
                    <SettingOutlined className={styles["setting"]} onClick={() => editVCluster(vCluster)} />
                </Tooltip>
                <ShowYamlPopup className={styles["setting"]} object={vCluster.object} cluster={vCluster.cluster!} resource={Resources.StorageV1VirtualCluster} name={vCluster.object?.metadata?.name!} namespace={vCluster.object?.metadata?.namespace!} refetch={refetch} />
                <VClusterDeletePopup vCluster={vCluster} refetch={refetch} />
            </TableActions>;
        }
    };

    if (isClusterView) {
        const metricsColumns = [];
        if (metricsAPIAvailable) {
            metricsColumns.push({
                    title: 'Pods',
                    sorter: (a: VClusterTableEntry, b: VClusterTableEntry) => {
                        return numberSorter(arr(a.metrics).length, arr(b.metrics).length);
                    },
                    render: (vCluster: VClusterTableEntry) => {
                        return <Link to={`/spaces/${vCluster.cluster!}/${vCluster.object?.metadata?.namespace!}/metrics`} className={styles["cluster"]}><FixedText text={arr(vCluster.metrics).length + ""} /></Link>;
                    }
                },
                {
                    title: 'CPU',
                    sorter: (a: VClusterTableEntry, b: VClusterTableEntry) => {
                        return numberSorter(aggregateCores(a as SpaceTableEntry), aggregateCores(b as SpaceTableEntry));
                    },
                    render: (vCluster: VClusterTableEntry) => {
                        const cores = aggregateCores(vCluster as SpaceTableEntry);
                        if (cores > 1.5) {
                            return <Link to={`/spaces/${vCluster.cluster!}/${vCluster.object?.metadata?.namespace!}/metrics`}><Warning tooltip={"High CPU Usage"} text={cores.toFixed(2) + " Cores"} /></Link>;
                        }
                        
                        return <Link to={`/spaces/${vCluster.cluster!}/${vCluster.object?.metadata?.namespace!}/metrics`} className={styles["cluster"]}><FixedText text={cores.toFixed(2) + " Cores"} /></Link>;
                    }
                },
                {
                    title: 'Memory',
                    sorter: (a: VClusterTableEntry, b: VClusterTableEntry) => {
                        return numberSorter(aggregateMemory(a as SpaceTableEntry), aggregateMemory(b as SpaceTableEntry));
                    },
                    render: (vCluster: VClusterTableEntry) => {
                        const memory = aggregateMemory(vCluster as SpaceTableEntry) / 1024 / 1024 / 1024;
                        if (memory > 2) {
                            return <Link to={`/spaces/${vCluster.cluster!}/${vCluster.object?.metadata?.namespace!}/metrics`}><Warning tooltip={"High Memory Usage"} text={memory.toFixed(1) + " GB"} /></Link>;
                        }
                        
                        return <Link to={`/spaces/${vCluster.cluster!}/${vCluster.object?.metadata?.namespace!}/metrics`} className={styles["cluster"]}><FixedText text={memory.toFixed(1) + " GB"} /></Link>;
                    }
                });
        }
        
        return [
            nameColumn,
            statusColumn,
            descriptionColumn,
            versionColumn,
            spaceColumn,
            templateColumn,
            ...metricsColumns,
            createdColumn,
            timeSleepingColumn,
            actionsColumn,
        ];
    }

    return [
        nameColumn,
        statusColumn,
        descriptionColumn,
        versionColumn,
        spaceColumn,
        templateColumn,
        clusterColumn,
        createdColumn,
        timeSleepingColumn,
        actionsColumn,
    ];
}

function filter(item: ClusterObject<ClusterV1VirtualCluster>, value: string) {
    return (item.object?.metadata?.annotations?.[constants.LoftDisplayNameAnnotation]?.includes(value) || item.object?.metadata?.annotations?.[constants.LoftDescriptionAnnotation]?.includes(value) || item.object?.metadata?.name?.includes(value) || item.object?.metadata?.namespace?.includes(value) || item.cluster?.includes(value));
}

export interface VClustersTableProps {
    cluster?: string;
    left?: React.ReactNode;
    top?: React.ReactNode;

    error?: ResultError;
    loading: boolean;
    vClusters: Array<ClusterObject<ClusterV1VirtualCluster>> | undefined;
    clusters?: Array<ManagementV1Cluster> | undefined;
    refetch: () => Promise<void>;
}

export function VClusterTable(props: VClustersTableProps) {
    const userContextState = useUser();
    const drawerDispatcher = useItemDrawer();
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
    const {error: templateError, data: templateData} = useQuery(async () => await client.management(Resources.ManagementV1VirtualClusterTemplate).List())
    const {error, data, refetch: metricsRefetch} = useQuery(async () => await client.cluster(props.cluster!, Resources.V1Beta1PodMetrics).List(), {skip: !props.cluster});
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedKeys: any) => {
            setSelectedRowKeys(selectedKeys);
        },
    };

    const refetch = () => {
        metricsRefetch();
        return props.refetch();
    };

    let tableData = props.vClusters ? arr(props.vClusters).map(vCluster => { return {...vCluster, key: vCluster.cluster+"/"+vCluster.object!.metadata!.namespace!+"/"+vCluster.object!.metadata!.name!}}) : undefined;
    if (tableData && data) {
        tableData = arr(tableData).map(vCluster => ({...vCluster, metrics: data.items.filter(metric => {
            if (metric.metadata?.namespace !== vCluster.object?.metadata?.namespace) {
                return false;
            }
            // managed by vcluster
            if (metric.metadata?.labels?.["vcluster.loft.sh/managed-by"] === vCluster.object?.metadata?.name) {
                return true;
            }
            // vcluster itself
            if (metric.metadata?.labels?.["app"] === "vcluster" && metric.metadata?.labels?.["release"] === vCluster.object?.metadata?.name) {
                return true;
            }
            
            return false;
        })}));
    }

    const metricsAPIAvailable = !!data;
    const isClusterView = !!props.cluster;
    const columns = getTableColumns(refetch, drawerDispatcher, userContextState, isClusterView, metricsAPIAvailable, props.vClusters, props.clusters, templateData?.items);
    return <div>
        <Table className={styles["table"]} 
               loading={!props.vClusters && props.loading} 
               columns={columns} 
               dataSource={tableData} 
               error={props.error || templateError} 
               rowSelection={rowSelection} 
               filter={filter as any} 
               refetch={refetch} 
               header={{
            top: props.top,
            left: props.left ? props.left : isClusterView && !metricsAPIAvailable ? <Tooltip placement="topLeft" title={<span>Please make sure you have the <a href={"https://github.com/kubernetes-sigs/metrics-server"} target={"_blank"}>metrics server</a> installed to view all available columns. <br /><br /> Error retrieving metrics: {error?.val?.message}</span>}><span className={styles["no-metrics-api"]}><WarningOutlined /></span></Tooltip> : undefined,
            right: <Query query={async () => {
                const result = await client.management(Resources.ManagementV1Cluster).List();
                if (result.err) {
                    return result;
                }

                return Return.Value(arr(result.val.items).length > 0)
            }} skip={!!props.cluster}>
                {
                    result => {
                        if (!result.skipped) {
                            if (result.error) {
                                return <ErrorMessage error={result.error} />
                            } else if (result.loading || !result.data) {
                                return null;
                            }
                        }

                        return <Button type={"primary"} onClick={() => {
                            drawerDispatcher({
                                title: "Create Virtual Cluster",
                                content: <VClusterDrawer mode={"create"} cluster={props.cluster} refetch={props.refetch} />
                            })
                        }}>Create Virtual Cluster</Button>
                    }
                }
            </Query>,
            selectedActions: <React.Fragment>
                <Tooltip title={"edit"}>
                    <SettingOutlined className={styles["setting-batch"]} onClick={() =>
                    {
                        let cluster = "";
                        const vClusters: StorageV1VirtualCluster[] = [];
                        for (let i = 0; i < selectedRowKeys.length; i++) {
                            const vClusterName = selectedRowKeys[i].toString().split("/");
                            const vCluster = props.vClusters!.find(vCluster => vCluster.cluster === vClusterName[0] && vCluster.object?.metadata?.namespace === vClusterName[1] && vCluster.object?.metadata?.name === vClusterName[2]);
                            if (!vCluster) {
                                continue;
                            } else if (!cluster) {
                                cluster = vCluster.cluster!;
                            } else if (cluster !== vCluster.cluster) {
                                alert({
                                    title: "Alert",
                                    content: "Please only select virtual clusters that are in the same parent cluster!"
                                });

                                return;
                            }

                            vClusters.push(vCluster.object!);
                        }

                        if (vClusters.length === 0) {
                            return;
                        }

                        if (vClusters.length === 1) {
                            const vCluster = vClusters[0];
                            drawerDispatcher({
                                title: "Edit Virtual Cluster: " + vCluster?.metadata?.name!,
                                content: <VClusterDrawer mode={"update"} cluster={cluster} vCluster={vCluster} refetch={props.refetch} />
                            })
                        } else {
                            drawerDispatcher({
                                title: "Bulk Edit Selected Virtual Clusters",
                                content: <VClusterDrawer mode={"batch"} cluster={cluster} vClusters={vClusters} refetch={props.refetch} />
                            })
                        }

                        setSelectedRowKeys([]);
                    }} />
                </Tooltip>
                <VClusterBatchDeletePopup vClusters={getSelectedVClusters(props.vClusters, selectedRowKeys)} refetch={async () => {
                    setSelectedRowKeys([]);
                    await refetch();
                }} />
                <Tooltip title={"reapply templates"}>
                    <RetweetOutlined className={styles["setting-batch"]} onClick={async () =>
                    {
                        confirm({
                            title: `Re-apply Templates`,
                            width: 700,
                            content: <div>
                                Are you sure you want to reapply all the templates on the selected virtual clusters? This might override existing settings. Virtual Clusters without template are not effected.
                            </div>,
                            okText: "Apply",
                            onOkAsync: async () => {
                                const message = ClientMessage.Loading();

                                // get the templates
                                const templatesResult = await client.management(Resources.ManagementV1VirtualClusterTemplate).List();
                                if (templatesResult.err) {
                                    message.Error(templatesResult);
                                    return;
                                }

                                for (let i = 0; i < selectedRowKeys.length; i++) {
                                    const vClusterName = selectedRowKeys[i].toString().split("/");
                                    const vCluster = props.vClusters!.find(vCluster => vCluster.cluster === vClusterName[0] && vCluster.object?.metadata?.namespace === vClusterName[1] && vCluster.object?.metadata?.name === vClusterName[2]);
                                    if (!vCluster) {
                                        continue;
                                    }

                                    await reapplyTemplate(vCluster, templatesResult.val.items);
                                }

                                message.DoneManagement();
                                setSelectedRowKeys([]);
                                await refetch();
                            },
                        });
                    }} />
                </Tooltip>
            </React.Fragment>
        }} />
    </div>
}