import React, {useEffect, useState} from "react";
import styles from "./ResourceTable.module.scss";
import {ColumnType} from "antd/lib/table";
import client, {GroupVersionResource, List, RequestOptionsVCluster} from "../../lib/client";
import useQuery from "../../lib/Query/Query";
import {Unstructured, V1Table, V1TableColumnDefinition, V1TableRow} from "../../lib/types";
import {arr} from "../../lib/helpers/renderhelper";
import Table, {TableActions} from "../Table/Table";
import {Tooltip} from "../Tooltip/Tooltip";
import {ContainerOutlined, DeleteOutlined} from "@ant-design/icons/lib";
import {ShowYamlModal} from "../ShowYamlPopup/ShowYamlPopup";
import {alert, deleteConfirm} from "../../lib/Modal/Modal";
import ClientMessage from "../../lib/Message/ClientMessage";
import {readableTime} from "../../lib/helper";
import {useItemDrawer} from "../../contexts/drawer/DrawerContext";
import {Button} from "antd";
import ResourcesDrawer from "../../views/Clusters/Details/ResourcesDrawer/ResourcesDrawer";
import {stringSorter} from "../../lib/helpers/sorthelper";
import FixedText from "../FixedText/FixedText";

interface ShowYamlPopupProps<T> {
    className?: string;
    onClose: () => void;

    cluster?: string;
    vCluster?: RequestOptionsVCluster;

    canUpdate?: boolean;

    name: string;
    resource: GroupVersionResource<T>;

    namespace?: string;
    refetch?: () => Promise<void>;
}

function ShowYamlPopup<T>(props: Omit<ShowYamlPopupProps<T>, "onClose">) {
    const [visible, setVisible] = useState<boolean>(false);
    const [object, setObject] = useState<any>(undefined);

    return <React.Fragment>
        <Tooltip title={"show yaml"}>
            <ContainerOutlined className={props.className} onClick={async () => {
                // get the object and set it
                const result = await client.auto(props.cluster, props.vCluster, props.resource).Namespace(props.namespace).Get(props.name);
                if (result.err) {
                    alert({
                        title: `Error retrieving ${props.name}`,
                        content: <span>
                            {result.val.message}
                        </span>
                    })
                    return;
                }

                setObject(result.val);
                setVisible(true);
            }} />
        </Tooltip>
        {visible && <ShowYamlModal {...props} onClose={() => setVisible(false)} object={object} />}
    </React.Fragment>
}

function getTableColumns(
    refetch: () => Promise<void>,
    cluster?: string,
    vCluster?: RequestOptionsVCluster,
    resource?: GroupVersionResource<any>,
    data?: V1Table,
    extraActions?: (tableRow: V1TableRow) => JSX.Element[] | undefined,
    customRender?: (row: V1TableRow, index: number) => React.ReactElement,
): Array<ColumnType<V1TableRow>> {
    if (!data || !resource) {
        return [
            {
                title: "Name",
                render: (row: V1TableRow) => {
                    return <span>Unknown</span>;
                }
            } as ColumnType<V1TableRow>,
            {
                title: "Actions",
                width: "100px",
                render: (row: V1TableRow) => {
                    return <span>Unknown</span>;
                }
            } as ColumnType<V1TableRow>
        ];
    }

    const columns = arr(data.columnDefinitions).filter(definition => definition.type === "string" || definition.type === "number" || definition.type === "integer" || definition.type === "date").map((definition: V1TableColumnDefinition, index: number) => {
        return {
            title: definition.name,
            sorter: (a: V1TableRow, b: V1TableRow) => stringSorter(arr(a.cells)[index] + "", arr(b.cells)[index] + ""),
            render: (row: V1TableRow) => {
                if (customRender){
                  return customRender(row, index);
                }
                return <FixedText maxWidth={450} text={arr(row.cells)[index] + ""} />;
            }
        } as ColumnType<V1TableRow>
    })

    const actionsColumn: ColumnType<V1TableRow> = {
        title: 'Actions',
        width: extraActions ? "140px" : "100px",
        render: (tableRow: V1TableRow) => {
            if (!tableRow.object?.metadata) {
                return <span />
            }

            return <TableActions className={styles["actions"]}>
                {extraActions?.(tableRow)}
                <ShowYamlPopup className={styles["setting"]} vCluster={vCluster} cluster={cluster} resource={resource} name={tableRow.object?.metadata?.name!} namespace={tableRow.object?.metadata?.namespace!} refetch={refetch} />
                <Tooltip title="delete">
                    <DeleteOutlined className={styles["delete"]} onClick={() => {
                        deleteConfirm({
                            title: `Delete ${tableRow.object?.metadata?.name!}`,
                            content: `Are you sure you want to delete the ${resource.kind} ${tableRow.object?.metadata?.name!}?`,
                            onOkAsync: async () => {
                                const message = ClientMessage.Loading(cluster, vCluster);
                                const result = await client.auto(cluster, vCluster, resource).Namespace(tableRow.object?.metadata?.namespace).Delete(tableRow.object?.metadata?.name!);
                                message.ResultAuto(result, cluster, vCluster);
                                await refetch();
                            },
                        });
                    }} />
                </Tooltip>
            </TableActions>;
        }
    };

    return [
        ...columns,
        actionsColumn,
    ];
}

export interface ResourceTableProps {
    cluster?: string;
    vCluster?: RequestOptionsVCluster;

    namespace?: string;

    addNamespaceColumn?: boolean;
    top?: React.ReactNode;
    resource?: GroupVersionResource<any>;
    
    interval?: number;

    extraActions?: (tableRow: V1TableRow) => JSX.Element[] | undefined;
    customRender?: (row: V1TableRow, index: number) => React.ReactElement;
}

function filter(item: V1TableRow, value: string) {
    return !!arr(item.cells).find(v => v.toString().includes(value));
}

function injectNamespaceColumn(data?: V1Table): V1Table | undefined {
    if (!data) {
        return data;
    } else if (!arr(data.columnDefinitions).length || arr(data.columnDefinitions)[0].name === "Namespace") {
        return data;
    }

    data.columnDefinitions = [{
        name: "Namespace",
        type: "string"
    }, ...arr(data.columnDefinitions)];

    for (let i = 0; i < arr(data.rows).length; i++) {
        if (!data.rows?.[i]) {
            continue;
        }

        data.rows[i].cells = [data.rows[i].object?.metadata?.namespace || "", ...arr(data.rows[i].cells)];
    }

    // sort by namespace
    if (data.rows && data.rows.length) {
        data.rows.sort((a, b) => {
           return stringSorter(arr(a.cells)[0] + "", arr(b.cells)[0] + "");
        });
    }

    return data;
}

function convertData(data?: V1Table | List<any>, addNamespaceColumn?: boolean): V1Table | undefined {
    if (!data) {
        return data;
    }

    const dataTable = data as V1Table;
    if (dataTable.kind === "Table" && dataTable.columnDefinitions) {
        return addNamespaceColumn ? injectNamespaceColumn(dataTable) : dataTable;
    }

    const dataList = data as List<Unstructured>;
    if (dataList.items) {
        const returnTable = {
            kind: "Table",
            apiVersion: "meta.k8s.io/v1",
            metadata: {},
            columnDefinitions: [
                {"name":"Name","type":"string","format":"name"},
                {"name":"Age","type":"string","format":""}
            ],
            rows: dataList.items.map(item => {
                return {
                    cells: [item.metadata?.name, readableTime(item.metadata?.creationTimestamp)],
                    object: {
                        kind: "PartialObjectMetadata",
                        apiVersion: "meta.k8s.io/v1beta1",
                        metadata: item.metadata
                    }
                }
            })
        };

        return addNamespaceColumn ? injectNamespaceColumn(returnTable) : returnTable;
    }

    return undefined;
}

export function ResourceTable(props: ResourceTableProps) {
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedKeys: any) => {
            setSelectedRowKeys(selectedKeys);
        },
    };
    const drawerDispatcher = useItemDrawer();
    const {error, loading, data, refetch} = useQuery(async () => await client.auto(props.cluster, props.vCluster, props.resource!).Namespace(props.namespace).ListTable(), {
        skip: !props.resource,
        refetch: [props.resource],
    });
    const convertedData = convertData(data, props.addNamespaceColumn);
    const transformedData = convertedData ? arr(convertedData.rows).filter(row => !!row.object).map(row => { return {...row, key: row.object?.metadata?.namespace ? row.object?.metadata?.namespace + "/" + row.object?.metadata?.name : row.object?.metadata?.name}}) : undefined
    const columns = getTableColumns(refetch, props.cluster, props.vCluster, props.resource, convertedData, props.extraActions, props.customRender);
    useEffect(() => {
        if (!props.interval) {
            return;
        } 
        
        const timeout = window.setTimeout(() => refetch(), props.interval);
        return () => {
            window.clearTimeout(timeout);
        }
    }, [refetch]);
    
    return <Table className={styles["table"]} loading={props.interval ? (!transformedData && loading) : loading} columns={columns} filter={filter} dataSource={transformedData} error={error} rowSelection={rowSelection} refetch={refetch} header={{
        top: props.top,
        right: props.resource && <Button type={"primary"} onClick={() => {
            drawerDispatcher({
                title: "Create a new " + props.resource?.kind,
                content: <ResourcesDrawer selectedResource={props.resource} cluster={props.cluster} vCluster={props.vCluster} placeholder={`apiVersion: ${props.resource?.group ? props.resource.group + "/" + props.resource.version : props.resource?.version}
kind: ${props.resource?.kind}
metadata:
  name: example
...`} namespace={props.namespace} mode={"create"} refetch={refetch} />
            })
        }}>Create {props.resource ? props.resource.kind : "Resource"}</Button>,
        selectedActions: <React.Fragment>
            <Tooltip title={"delete"}>
                <DeleteOutlined className={styles["delete-batch"]} onClick={() =>
                {
                    deleteConfirm({
                        title: `Delete Resources`,
                        content: `Are you sure you want to delete the resources ${selectedRowKeys.join(", ")}?`,
                        onOkAsync: async () => {
                            const message = ClientMessage.Loading(props.cluster, props.vCluster);
                            for (let i = 0; i < selectedRowKeys.length; i++) {
                                const splitted = (selectedRowKeys[i] + "").split("/");
                                const row = transformedData?.find(row => splitted.length === 2 ? row.object?.metadata?.namespace === splitted[0] && row.object?.metadata?.name === splitted[1] : row.object?.metadata?.name === splitted[0]);
                                if (row) {
                                    const result = await client.auto(props.cluster, props.vCluster, props.resource!).Namespace(props.namespace ? props.namespace : splitted.length === 2 ? splitted[0] : undefined).Delete(row.object?.metadata?.name!);
                                    if (result.err) {
                                        message.ErrorAuto(result, props.cluster, props.vCluster);
                                        return;
                                    }
                                }
                            }

                            message?.DoneAuto(props.cluster, props.vCluster);
                            await refetch();
                            setSelectedRowKeys([]);
                        }
                    });
                }} />
            </Tooltip>
        </React.Fragment>
    }} />
}
