import React, {useEffect} from "react";
import client from "../../../../lib/client";
import {Resources} from "../../../../lib/resources";
import useQuery from "../../../../lib/Query/Query";
import {useRouteMatch} from "react-router-dom";
import ClusterHeader from "../ClusterHeader/ClusterHeader";
import {VClusterTable} from "../../../VClusters/VClusters/VClusterTable/VClusterTable";
import {ClusterObject} from "../../../Spaces/Spaces/Spaces";
import {ClusterV1VirtualCluster} from "../../../../../gen/model/clusterV1VirtualCluster";

function buildClusterVCluster(cluster: string, virtualClusters: Array<ClusterV1VirtualCluster>): Array<ClusterObject<ClusterV1VirtualCluster>> {
    return virtualClusters.map(vCluster => {
        return {
            cluster,
            object: vCluster,
        }
    });
}

export default function VClusters() {
    const match = useRouteMatch();
    const {cluster} = match.params as any;
    const {refetch, error, loading, data} = useQuery(async () => await client.cluster(cluster, Resources.ClusterV1VirtualCluster).List());
    useEffect(() => {
        const timeout = window.setTimeout(() => refetch(), 3000);
        return () => {
            window.clearTimeout(timeout);
        }
    }, [refetch]);
    return <VClusterTable cluster={cluster}
                           top={<ClusterHeader />}
                           error={error}
                           loading={loading}
                           vClusters={data ? buildClusterVCluster(cluster, data.items) : undefined}
                           refetch={refetch} />
}