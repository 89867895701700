import React from "react";
import {ResultError, Return} from "../../../../lib/result";
import {SectionProps} from "../../../../components/Drawer/ItemDrawer";
import Section from "../../../../components/Drawer/Section/Section";
import {arr, selectDefaultFilter} from "../../../../lib/helpers/renderhelper";
import Query from "../../../../components/Query/Query";
import client from "../../../../lib/client";
import {Resources} from "../../../../lib/resources";
import {ErrorMessage} from "../../../../components/ErrorMessage/ErrorMessage";
import Loading from "../../../../components/Loading/Loading";
import Label from "../../../../components/Label/Label";
import Select from "../../../../components/Select/Select";
import styles from "./UserTeamMemberships.module.scss";
import {StorageV1KindSecretRef} from "../../../../../gen/model/storageV1KindSecretRef";
import Description from "../../../../components/Description/Description";
import {LoftSchemeGroup, LoftSchemeGroupStorage} from "../../../../lib/types";
import {ManagementV1SharedSecret} from "../../../../../gen/model/managementV1SharedSecret";
import SectionExpander from "../../../../components/Drawer/SectionExpander/SectionExpander";
const { Option } = Select;

interface ImagePullSecretsObject {
    /**
     * ImagePullSecrets holds secret references to image pull secrets the user has access to.
     */
    'imagePullSecrets'?: Array<StorageV1KindSecretRef>;
}

interface ImagePullSecretsState {
    imagePullSecretsChanged: boolean;
    imagePullSecrets: Array<StorageV1KindSecretRef>;
}

interface ImagePullSecretsProps extends SectionProps {
    imagePullSecrets: Array<StorageV1KindSecretRef> | undefined;
}

export default class ImagePullSecrets extends React.PureComponent<ImagePullSecretsProps, ImagePullSecretsState> {
    state: ImagePullSecretsState = {
        imagePullSecretsChanged: false,
        imagePullSecrets: arr(this.props.imagePullSecrets) 
    };

    create = (imagePullSecretsObject: ImagePullSecretsObject): ResultError => {
        imagePullSecretsObject.imagePullSecrets = [...this.state.imagePullSecrets];
        return Return.Ok();
    };

    update = (imagePullSecretsObject: ImagePullSecretsObject): ResultError => {
        if (!this.state.imagePullSecretsChanged) {
            return Return.Ok();
        }

        return this.create(imagePullSecretsObject);
    };

    batch = (imagePullSecretsObjects: Array<ImagePullSecretsObject>): ResultError => {
        for (let i = 0; i < imagePullSecretsObjects.length; i++) {
            const result = this.update(imagePullSecretsObjects[i]);
            if (result.err) {
                return result;
            }
        }

        return Return.Ok();
    };

    renderOptions(secrets?: ManagementV1SharedSecret[]) {
        const options: JSX.Element[] = [];

        // add secrets
        secrets?.forEach(secret => {
            if (!secret.spec?.data) {
                return;
            }
            
            const keys = arr(Object.keys(secret.spec?.data));
            if (!keys.length) {
                return;
            } else if (keys.length === 1) {
                const key = secret.metadata?.namespace + "/" + secret.metadata?.name + "." + keys[0];
                options.push(<Option key={key} value={key}>
                    {secret.metadata?.namespace !== "loft" ? secret.metadata?.namespace + "/"  + secret.metadata?.name : secret.metadata?.name}
                </Option>)
            } else {
                for (let i = 0; i < keys.length; i++) {
                    const key = secret.metadata?.namespace + "/" + secret.metadata?.name + "." + keys[i];
                    options.push(<Option key={key} value={key}>
                        {secret.metadata?.namespace !== "loft" ? key : secret.metadata?.name + "." + keys[i]}
                    </Option>)
                }
            }
        });

        return options;
    }
    
    getValueFromImagePullSecrets() {
        return arr(this.state.imagePullSecrets).filter(imagePullSecret => (imagePullSecret.apiGroup === LoftSchemeGroup || imagePullSecret.apiGroup === LoftSchemeGroupStorage) && imagePullSecret.kind === Resources.ManagementV1SharedSecret.kind)
            .map(imagePullSecret => imagePullSecret.secretNamespace + "/" + imagePullSecret.secretName + "." + imagePullSecret.key)
    }
    
    setImagePullSecretsFromValue(value: string[]) {
        this.setState({
            imagePullSecrets: arr(value).map(v => {
                const splitted = v.split("/");
                const namespace = splitted[0];
                splitted.splice(0, 1);
                const nameSplitted = splitted.join("/").split(".");
                const name = nameSplitted[0];
                nameSplitted.splice(0, 1);
                return {
                    apiGroup: LoftSchemeGroupStorage,
                    kind: Resources.ManagementV1SharedSecret.kind,
                    secretNamespace: namespace, 
                    secretName: name,
                    key: nameSplitted.join(".")
                }
            })
        })
    }

    render() {
        return <SectionExpander name={`Image Pull Secrets`}>
            <Query query={async () => await client.management(Resources.ManagementV1SharedSecret).List()}>
                {
                    result => {
                        if (result.error) {
                            return <ErrorMessage error={result.error} />
                        } else if (result.loading) {
                            return <Loading />
                        }

                        return <div>
                            <Label>Assigned Image Pull Shared Secrets</Label>
                            <Select
                                resetable={this.props.mode !== "create"}
                                onChangedStatus={changed => this.setState({imagePullSecretsChanged: changed})}
                                className={styles["select"]}
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Please select image pull secrets"
                                value={this.getValueFromImagePullSecrets()}
                                onChange={value => this.setImagePullSecretsFromValue(value)}
                                showSearch
                                optionFilterProp="children"
                                filterOption={selectDefaultFilter}
                            >
                                {this.renderOptions(result.data?.items)}
                            </Select>
                            <Description>Image pull secrets are a way to automatically log in users into container image registries as soon as they login via the loft CLI. Make sure the user or team can access the shared secret specified here</Description>
                        </div>
                    }
                }
            </Query>
        </SectionExpander>
    }
}