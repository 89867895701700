import React from "react";
import {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import {Result, ResultError, Return} from "../../../../../lib/result";
import Section from "../../../../../components/Drawer/Section/Section";
import Label from "../../../../../components/Label/Label";
import Query from "../../../../../components/Query/Query";
import Loading from "../../../../../components/Loading/Loading";
import {ErrorMessage} from "../../../../../components/ErrorMessage/ErrorMessage";
import Select from "../../../../../components/Select/Select";
import {arr, selectDefaultFilter} from "../../../../../lib/helpers/renderhelper";
import {displayName} from "../../../../../lib/helper";
import Description from "../../../../../components/Description/Description";
import {StorageV1UserOrTeam} from "../../../../../../gen/model/agentstorageV1UserOrTeam";
import Tabs from "../../../../../components/Tabs/Tabs";
import client from "../../../../../lib/client";
import {Resources} from "../../../../../lib/resources";
const { TabPane } = Tabs;
const {Option} = Select;

interface ClusterAccessUserTeamSelectorState {
    users: Array<string>;
    usersChanged: boolean;
    
    usersInTeams: Array<string>;
    usersInTeamsChanged: boolean;
    
    teams: Array<string>;
    teamsChanged: boolean;
}

interface UsersTeamsObject {
    spec?: {
        teams?: Array<string>;
        users?: Array<StorageV1UserOrTeam>;
    }
}

interface ClusterAccessUserTeamSelectorProps extends SectionProps {
    obj?: UsersTeamsObject;
}

export default class UserTeamSelector extends React.PureComponent<ClusterAccessUserTeamSelectorProps, ClusterAccessUserTeamSelectorState> {
    state: ClusterAccessUserTeamSelectorState = {
        users: this.props.obj?.spec?.users?.filter(user => !!user.user).map(user => user.user!) || [],
        usersChanged: false,

        usersInTeams: this.props.obj?.spec?.users?.filter(user => !!user.team).map(user => user.team!) || [],
        usersInTeamsChanged: false,

        teams: this.props.obj?.spec?.teams || [],
        teamsChanged: false
    };

    create = (obj: UsersTeamsObject): ResultError => {
        if (!this.state.users.length && !this.state.usersInTeams.length && !this.state.teams.length) {
            return Return.Failed("Please select at least one user or team");
        }
        
        if (!obj.spec) {
            obj.spec = {};
        }

        obj.spec.users = [...this.state.users.map(user => ({user})), ...this.state.usersInTeams.map(team => ({team}))];
        obj.spec.teams = this.state.teams;
        return Return.Ok();
    };

    update = async (obj: UsersTeamsObject): Promise<ResultError> => {
        if (!obj.spec) {
            obj.spec = {};
        }
        if (this.state.usersChanged || this.state.usersInTeamsChanged) {
            obj.spec.users = [...this.state.users.map(user => ({user})), ...this.state.usersInTeams.map(team => ({team}))];
        }
        if (this.state.teamsChanged) {
            obj.spec.teams = this.state.teams;
        } 
        return Return.Ok();
    };
    
    getDefaultActiveKey() {
        if (!this.props.obj || this.state.users.length > 0) {
            return "1";
        } else if (this.state.usersInTeams.length > 0) {
            return "2";
        } else if (this.state.teams.length > 0) {
            return "3";
        }
        
        return "1";
    }

    render() {
        if (this.props.mode === "batch") {
            return null;
        }

        return <Section title={`Users & Teams`}>
            <Query query={async () => {
                const userResult = await client.management(Resources.ManagementV1User).List();
                if (userResult.err) {
                    return userResult;
                }

                const teamResult = await client.management(Resources.ManagementV1Team).List();
                if (teamResult.err) {
                    return teamResult;
                }
                
                return Return.Value({
                    users: arr(userResult.val.items),
                    teams: arr(teamResult.val.items)
                })
            }}>
                {
                    result => {
                        if (result.loading) {
                            return <Loading />
                        } else if (result.error) {
                            return <ErrorMessage error={result.error} />
                        }
                        
                        return <Tabs defaultActiveKey={this.getDefaultActiveKey()} type="card" size={"small"}>
                            <TabPane tab="Users" key="1">
                                <Label>Cluster Access For Individual Users</Label>
                                <Select
                                    resetable={this.props.mode !== "create"}
                                    mode={"multiple"}
                                    style={{ width: '100%' }}
                                    placeholder="Select Users"
                                    value={this.state.users}
                                    onChangedStatus={changed => this.setState({usersChanged: changed})}
                                    onChange={(value) => {
                                        const allBefore = !!this.state.users.find(c => c === "*");
                                        const allAfter = !!value.find(c => c === "*");

                                        if (allBefore) {
                                            this.setState({users: value.filter(c => c !== "*")});
                                        } else if (allAfter) {
                                            this.setState({users: ["*"]});
                                        } else {
                                            this.setState({users: value})
                                        }
                                    }}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={selectDefaultFilter}
                                >
                                    <Option key={"*"} value={"*"}>All Users</Option>
                                    {arr(result.data?.users).map(user => <Option key={user.metadata?.name} value={user.metadata?.name!}>
                                        {displayName(user)}
                                    </Option>)}
                                </Select>
                                <Description>Use this option if you want individual users to get this cluster access. Spaces are owned by the user creating them and Quotas apply for each user individually.</Description>
                            </TabPane>
                            <TabPane tab="Users in Teams" key="2">
                                <Label>Cluster Access For All Users Who Are Members Of These Teams</Label>
                                <Select
                                    resetable={this.props.mode !== "create"}
                                    mode={"multiple"}
                                    style={{ width: '100%' }}
                                    placeholder="Select Teams"
                                    value={this.state.usersInTeams}
                                    onChangedStatus={changed => this.setState({usersInTeamsChanged: changed})}
                                    onChange={(value) => this.setState({usersInTeams: value})}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={selectDefaultFilter}
                                >
                                    {arr(result.data?.teams).map(team => <Option key={team.metadata?.name} value={team.metadata?.name!}>
                                        {displayName(team)}
                                    </Option>)}
                                </Select>
                                <Description>Use this option if you want each user of the teams listed above to get this cluster access. Choosing this option means that spaces will be owned by the individual user who creates them and Quotas apply for each user individually. Only the section of these users is based on the team level to make it easier to grant automated access based on team membership rather than having to create and manage a separate cluster access for each user.</Description>
                            </TabPane>
                            <TabPane tab="Teams" key="3">
                                <Label>Cluster Access For These Teams</Label>
                                <Select
                                    resetable={this.props.mode !== "create"}
                                    mode={"multiple"}
                                    style={{ width: '100%' }}
                                    placeholder="Select Teams"
                                    value={this.state.teams}
                                    onChangedStatus={changed => this.setState({teamsChanged: changed})}
                                    onChange={(value) => this.setState({teams: value})}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={selectDefaultFilter}
                                >
                                    {arr(result.data?.teams).map(team => <Option key={team.metadata?.name} value={team.metadata?.name!}>
                                        {displayName(team)}
                                    </Option>)}
                                </Select>
                                <Description>Use this option if you want the spaces to be owned by the team and not by the individual team member who creates it. Besides ownership, this also means that Quotas, for example, are shared and apply for the entire team, not for each team member individually.</Description>
                            </TabPane>
                        </Tabs>
                    }
                }
            </Query>
        </Section>
    }
}