import {V1ObjectMeta} from "../../../gen/model/v1ObjectMeta";

const SleepModeForceAnnotation = "sleepmode.loft.sh/force"
const SleepModeForceDurationAnnotation = "sleepmode.loft.sh/force-duration"
const SleepModeDeleteAllPodsAnnotation = "sleepmode.loft.sh/delete-all-pods"
const SleepModeDeleteAfterAnnotation = "sleepmode.loft.sh/delete-after"
const SleepModeSleepAfterAnnotation = "sleepmode.loft.sh/sleep-after"
export const SleepModeSleepSchedule = "sleepmode.loft.sh/sleep-schedule"
export const SleepModeWakeupSchedule = "sleepmode.loft.sh/wakeup-schedule"
export const SleepModeTimezone = "sleepmode.loft.sh/timezone"
const SleepModeLastActivityAnnotation = "sleepmode.loft.sh/last-activity"
const SleepModeSleepingSinceAnnotation = "sleepmode.loft.sh/sleeping-since"
const SleepModeCurrentEpochStartAnnotation = "sleepmode.loft.sh/current-epoch-start"
const SleepModeCurrentEpochSleptAnnotation = "sleepmode.loft.sh/current-epoch-slept"
const SleepModeLastEpochStartAnnotation = "sleepmode.loft.sh/last-epoch-start"
const SleepModeLastEpochSleptAnnotation = "sleepmode.loft.sh/last-epoch-slept"

export function getSleepAfter(metadata?: V1ObjectMeta) {
    if (!metadata || !metadata.annotations) {
        return undefined;
    }

    const annotations = metadata.annotations;
    const sleepAfter = parseInt(annotations[SleepModeSleepAfterAnnotation])
    if (sleepAfter) {
        return sleepAfter;
    }
    
    return undefined;
}

export function setSleepAfter(metadata?: V1ObjectMeta, sleepAfter?: number) {
    if (!metadata) {
        return;
    }
    if (!metadata.annotations) {
        metadata.annotations = {};
    }

    if (sleepAfter) {
        metadata.annotations[SleepModeSleepAfterAnnotation] = sleepAfter+""
    } else {
        delete metadata.annotations[SleepModeSleepAfterAnnotation]
    }
    return;
}

export function getDeleteAfter(metadata?: V1ObjectMeta) {
    if (!metadata || !metadata.annotations) {
        return undefined;
    }

    const annotations = metadata.annotations;
    const sleepAfter = parseInt(annotations[SleepModeDeleteAfterAnnotation])
    if (sleepAfter) {
        return sleepAfter;
    }

    return undefined;
}

export function setDeleteAfter(metadata?: V1ObjectMeta, deleteAfter?: number) {
    if (!metadata) {
        return;
    }
    if (!metadata.annotations) {
        metadata.annotations = {};
    }

    if (deleteAfter) {
        metadata.annotations[SleepModeDeleteAfterAnnotation] = deleteAfter+""
    } else {
        delete metadata.annotations[SleepModeDeleteAfterAnnotation]
    }
    return;
}

export function convertMinutesToSeconds(minutes: number | undefined): number | undefined {
    if (!minutes) {
        return undefined;
    }

    return minutes * 60;
}

export function convertSecondsToMinutes(seconds: number | undefined): string | undefined {
    if (!seconds) {
        return undefined;
    }

    return (seconds / 60).toString();
}
