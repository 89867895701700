import React, {useState} from "react";
import AceEditor from "react-ace";
import styles from "./YAMLEditor.module.scss";

import "ace-builds/src-noconflict/mode-sh";
import "ace-builds/src-noconflict/mode-yaml";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-markdown";
import "ace-builds/src-noconflict/theme-eclipse";
import {randomString} from "../../lib/helper";
import {Ace} from "ace-builds";

export interface YAMLEditorProps {
    value: string | undefined;
    onChange?: (value: string) => void;
    onLoad?: (editor: Ace.Editor) => void;
    
    className?: string;

    placeholder?: string;
    readOnly?: boolean;
    
    language?: "yaml"|"javascript"|"sh"|"markdown";
    
    height?: string;
    width?: string;
    
    minLines?: number;
    maxLines?: number;
}

export default function YAMLEditor(props: YAMLEditorProps) {
    const [name, _] = useState(randomString(16));
    return <AceEditor value={props.value || ""}
                      name={name}
                      className={props.className ? styles["editor"] + " " + props.className : styles["editor"]}
                      width={props.width || "100%"}
                      height={props.height || "600px"}
                      placeholder={props.placeholder}
                      readOnly={props.readOnly}
                      onChange={props.onChange}
                      onLoad={props.onLoad}
                      mode={props.language || "yaml"}
                      setOptions={{
                          showPrintMargin: false,
                          highlightActiveLine: !props.readOnly,
                          fontSize: "12px",
                          fontFamily: "'Source Code Pro', monospace",
                          tabSize: 2,
                      }}
                      minLines={props.minLines}
                      maxLines={props.maxLines}
                      theme={"eclipse"} />
};