import React from "react";
import client from "../../../lib/client";
import {Resources} from "../../../lib/resources";
import {DrawerDispatch, useItemDrawer} from "../../../contexts/drawer/DrawerContext";
import DynamicTime from "../../../components/DynamicTime/DynamicTime";
import Table, {TableActions} from "../../../components/Table/Table";
import {Tooltip} from "../../../components/Tooltip/Tooltip";
import {DeleteOutlined, SettingOutlined} from "@ant-design/icons/lib";
import {deleteConfirm} from "../../../lib/Modal/Modal";
import styles from "./AccessKeysTable.module.scss";
import {Button} from "antd";
import {arr} from "../../../lib/helpers/renderhelper";
import AccessKeyDrawer from "./AccessKeyDrawer/AccessKeyDrawer";
import ClientMessage from "../../../lib/Message/ClientMessage";
import {ManagementV1OwnedAccessKey} from "../../../../gen/model/managementV1OwnedAccessKey";
import {ToggleColumn, ToggleColumnContent} from "../../../contexts/ToggleColumn/ToggleColumn";
import ShowYamlPopup from "../../../components/ShowYamlPopup/ShowYamlPopup";
import FixedText from "../../../components/FixedText/FixedText";
import {stringSorter, timeSorter} from "../../../lib/helpers/sorthelper";
import {ResultError} from "../../../lib/result";
import {ManagementV1User} from "../../../../gen/model/managementV1User";
import UserDrawer from "../../Users/UserDrawer/UserDrawer";

function getTableColumns(refetch: () => Promise<void>, drawerDispatcher: DrawerDispatch) {
    const editAccessKey = (accessKey: ManagementV1OwnedAccessKey) => {
        drawerDispatcher({
            title: "Edit Access Key: " + accessKey.metadata?.name!,
            content: <AccessKeyDrawer mode={"update"} accessKey={accessKey} refetch={refetch} />
        })
    };
    
    return [
        {
            title: <ToggleColumn id={"displayname"} columns={['Display Name', 'Kubernetes Name']}/>,
            sorter: (a: ManagementV1OwnedAccessKey, b: ManagementV1OwnedAccessKey) => stringSorter(a.spec?.displayName || a.metadata?.name!, b.spec?.displayName || b.metadata?.name!),
            render: (key: ManagementV1OwnedAccessKey) => {
                return <ToggleColumnContent id={"displayname"} columns={[
                    () => {
                        return <FixedText className={"clickable-link"} onClick={() => editAccessKey(key)} maxWidth={350} text={key.spec?.displayName || key.metadata?.name!} />
                    },
                    () => {
                        return <FixedText className={"clickable-link"} onClick={() => editAccessKey(key)} maxWidth={250} text={key.metadata?.name!} />
                    },
                ]}/>
            },
        },
        {
            title: 'TTL',
            render: (key: ManagementV1OwnedAccessKey) => {
                return <FixedText text={!key.spec?.ttl ? "forever" : key.spec.ttlAfterLastActivity ? (key.spec?.ttl / 3600) + " hour(s) from last activity" : (key.spec?.ttl / 3600) + " hour(s)"} />;
            }
        },
        {
            title: 'Disabled',
            render: (key: ManagementV1OwnedAccessKey) => {
                return !!key.spec?.disabled + "";
            }
        },
        {
            title: 'Last Activity',
            sorter: (a: ManagementV1OwnedAccessKey, b: ManagementV1OwnedAccessKey) => {
                const lastActivityA = a.status?.lastActivity || a.metadata?.creationTimestamp;
                const lastActivityB = b.status?.lastActivity || b.metadata?.creationTimestamp;
                return timeSorter(lastActivityA, lastActivityB);
            },
            render: (key: ManagementV1OwnedAccessKey) => {
                return <DynamicTime timestamp={key.status?.lastActivity ? key.status?.lastActivity : key.metadata?.creationTimestamp} useTooltip={true}/>
            }
        },
        {
            title: 'Created',
            width: "180px",
            render: (key: ManagementV1OwnedAccessKey) => {
                return <DynamicTime timestamp={key.metadata?.creationTimestamp} useTooltip={true}/>
            }
        },
        {
            title: 'Actions',
            width: "180px",
            render: (key: ManagementV1OwnedAccessKey) => {
                return <TableActions className={styles["actions"]}>
                    <Tooltip title="edit">
                        <SettingOutlined className={styles["setting"]} onClick={() => editAccessKey(key)} />
                    </Tooltip>
                    <ShowYamlPopup className={styles["setting"]} object={key} resource={Resources.ManagementV1OwnedAccessKey} name={key.metadata?.name!} refetch={refetch} />
                    <Tooltip title="delete">
                        <DeleteOutlined className={styles["delete"]} onClick={() => {
                            deleteConfirm({
                                title: `Delete Access Key: ${key.metadata?.name}`,
                                content: `Are you sure you want to delete the access key ${key.metadata?.name}? This action CANNOT be reverted!`,
                                onOkAsync: async () => {
                                    const message = ClientMessage.Loading();
                                    const deleteResult = await client.management(Resources.ManagementV1OwnedAccessKey).Delete(key.metadata?.name!);
                                    if (deleteResult.err) {
                                        message.ErrorManagement(deleteResult);
                                        return;
                                    }

                                    // refetch
                                    await refetch();
                                    message.DoneManagement();
                                },
                            });
                        }} />
                    </Tooltip>
                </TableActions>;
            }
        },
    ];
}

function filter(key: ManagementV1OwnedAccessKey, value: string) {
    return !!(key.metadata?.name?.includes(value))
}

interface AccessKeysTableProps {
    user?: string;
    team?: string;
    top: React.ReactNode;
    
    error?: ResultError;
    loading: boolean;
    accessKeys: Array<ManagementV1OwnedAccessKey> | undefined;
    refetch: () => Promise<void>;
}

export default function AccessKeysTable(props: AccessKeysTableProps) {
    const drawerDispatcher = useItemDrawer();
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedKeys: any) => {
            setSelectedRowKeys(selectedKeys);
        },
    };

    return <div>
        <Table loading={props.loading} columns={getTableColumns(props.refetch, drawerDispatcher)} dataSource={props.accessKeys ? arr(props.accessKeys).map(key => { return {...key, key: key.metadata?.name}}) : undefined} error={props.error} rowSelection={rowSelection} filter={filter} refetch={props.refetch} header={{
            top: props.top,
            right: <Button type={"primary"} onClick={() => {
                drawerDispatcher({
                    title: "Create Access Key",
                    content: <AccessKeyDrawer mode={"create"} user={props.user} team={props.team} refetch={props.refetch} />
                })
            }}>Create Access Key</Button>,
            selectedActions: <React.Fragment>
                <Tooltip title={"delete"}>
                    <DeleteOutlined className={styles["delete-batch"]} onClick={() =>
                    {
                        deleteConfirm({
                            title: `Delete Access Keys`,
                            content: `Are you sure you want to delete the access keys ${selectedRowKeys.join(", ")}?`,
                            onOkAsync: async () => {
                                const message = ClientMessage.Loading();
                                for (let i = 0; i < selectedRowKeys.length; i++) {
                                    const accessKeyName = selectedRowKeys[i];
                                    const result = await client.management(Resources.ManagementV1OwnedAccessKey).Delete(accessKeyName as string);
                                    if (result.err) {
                                        message.Error(result);
                                        return;
                                    }
                                }

                                // refetch
                                await props.refetch();
                                message.DoneManagement();
                                setSelectedRowKeys([]);
                            }
                        });
                    }} />
                </Tooltip>
            </React.Fragment>
        }} />
    </div>
};