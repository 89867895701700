import React, {useState} from "react";
import {Tooltip} from "../../../../components/Tooltip/Tooltip";
import {DeleteOutlined} from "@ant-design/icons/lib";
import styles from "./VClusterBatchDeletePopup.module.scss";
import {DeleteModal, Modal} from "../../../../lib/Modal/Modal";
import client from "../../../../lib/client";
import {Resources} from "../../../../lib/resources";
import ClientMessage from "../../../../../src/lib/Message/ClientMessage";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import {ClusterV1VirtualCluster} from "../../../../../gen/model/clusterV1VirtualCluster";
import {ClusterObject} from "../../../Spaces/Spaces/Spaces";

interface Props {
    vClusters: ClusterObject<ClusterV1VirtualCluster>[];
    refetch: () => Promise<void>;
}

export default function VClusterBatchDeletePopup(props: Props) {
    const [visible, setVisible] = useState<boolean>(false);
    const [deleteNamespace, setDeleteNamespace] = useState<boolean>(true);
    const vClusters = props.vClusters

    return <React.Fragment>
        <Tooltip title={"delete"}>
            <DeleteOutlined className={styles["delete-batch"]} onClick={() => setVisible(true)} />
        </Tooltip>
        <DeleteModal title={`Delete vClusters: ${vClusters.map(vCluster => vCluster.object?.metadata?.name).join(", ")}`} onCancel={() => setVisible(false)} visible={visible} onOkAsync={async () => {
          let message: ClientMessage | undefined = undefined;
          let lastCluster: string = "";

          for (const vCluster of vClusters) {
            if (!message) {
                message = ClientMessage.Loading(vCluster.cluster);
            } else {
                message.Loading(vCluster.cluster);
            }

            const result = await client.cluster(vCluster.cluster!, Resources.StorageV1VirtualCluster).Namespace(vCluster.object?.metadata?.namespace).Delete(vCluster.object?.metadata?.name!);
            if (result.err) {
              message.Error(result);
              return;
            }
              
            if (deleteNamespace && result.ok && vCluster.object?.metadata?.namespace) {
              const namespaceResult = await client.cluster(vCluster.cluster!, Resources.V1Namespace).Delete(vCluster.object?.metadata?.namespace);
              if (namespaceResult.err) {
                message.Error(namespaceResult);
                return;
              }
            }

            lastCluster = vCluster.cluster!;
          }

          message?.DoneCluster(lastCluster);
          setVisible(false);
          await props.refetch();
        }}>
            <div>
              Are you sure you want to delete the vClusters {vClusters.map(vCluster => vCluster.object?.metadata?.name).join(", ")}?
            </div>
            <div className={styles["checkbox"]}>
                <Checkbox onChange={e => setDeleteNamespace(e.target.checked)} checked={deleteNamespace}>Delete namespaces as well</Checkbox>
            </div>
        </DeleteModal>
    </React.Fragment>
}