import React, {RefObject} from "react";
import {SectionProps} from "../../../../../../components/Drawer/ItemDrawer";
import {ClusterV1ChartInfo} from "../../../../../../../gen/model/clusterV1ChartInfo";
import styles from "./AppReadme.module.scss";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm';
import {ManagementV1App} from "../../../../../../../gen/model/managementV1App";
import Loading from "../../../../../../components/Loading/Loading";
import {AppstoreOutlined} from "@ant-design/icons";
import {displayName} from "../../../../../../lib/helper";
import FixedText from "../../../../../../components/FixedText/FixedText";

interface AppReadmeProps extends SectionProps {
    selectedApp: ManagementV1App | undefined;
    selectedChart: ClusterV1ChartInfo | undefined;
    
    loading: boolean | undefined;
}

export default function AppReadme(props: AppReadmeProps) {
    if (props.loading) {
        return <div className={styles["readme-wrapper"] + " " + styles["loading"]}>
            <Loading />
        </div>
    }
    
    const icon = props.selectedApp?.spec?.icon || props.selectedChart?.status?.metadata?.icon;
    const readme = props.selectedApp?.spec?.readme || props.selectedChart?.status?.readme || "";
    const appType = props.selectedApp?.spec?.streamContainer?.container ? "Bash" : props.selectedApp?.spec?.config?.manifests ? "Manifests" : "Helm";
    const title = props.selectedApp ? "App: "+displayName(props.selectedApp) : "Chart: "+props.selectedChart?.spec?.chart?.name;
    return <div className={styles["readme-wrapper"]}>
        <div className={styles["title"]}>{title}</div>
        <div className={styles["icon-header"]}>
            <div className={styles["icon"]}>
                {icon ? <img className={styles["app-icon"]} src={icon} /> : <AppstoreOutlined className={styles["app-icon-not-found"]} />}
            </div>
            <div className={styles["description-wrapper"]}>
                <div className={styles["description"]}>{props.selectedApp?.spec?.description || props.selectedChart?.status?.metadata?.description || props.selectedApp?.spec?.displayName || props.selectedApp?.metadata?.name || props.selectedChart?.spec?.chart?.name}</div>
                <div className={styles["type"]}>
                    <div>Type</div>
                    <div>{appType}</div>
                </div>
                <div className={styles["home"]}>
                    <div>Home</div>
                    <div>{props.selectedChart?.status?.metadata?.home && <a href={props.selectedChart?.status?.metadata?.home} target={"_blank"}><FixedText maxWidth={400} text={props.selectedChart?.status?.metadata?.home} /></a>}</div>
                </div>
            </div>
        </div>
        <div className={styles["markdown"]}>
            <ReactMarkdown children={readme}
                           linkTarget="_blank"
                           remarkPlugins={[remarkGfm]} />
        </div>
    </div>
}
