import React from "react";
import styles from "./ClusterConnectStep3.module.scss";
import {Button} from "antd";
import {ResultError, Return} from "../../../lib/result";
import {ErrorMessage} from "../../../components/ErrorMessage/ErrorMessage";
import {Resources} from "../../../lib/resources";
import client from "../../../lib/client";
import {Wait} from "../../../lib/helper";
import useQuery from "../../../lib/Query/Query";
import ClientMessage from "../../../lib/Message/ClientMessage";
import {useUser} from "../../../contexts/UserContext/UserContext";
import {ManagementV1User} from "../../../../gen/model/managementV1User";

interface Props {
    clusterName: string;
    setCurrentStep: (step: number) => void;

    refetch: () => Promise<void>;
}

async function checkStatus(props: Props, user: ManagementV1User): Promise<ResultError> {
    // pretend we are doing something :)
    await Wait(4000);

    // check that the cluster could be initialized
    while(true) {
        // first verify the cluster is initialized
        const response = await client.management(Resources.ManagementV1Cluster).Get(props.clusterName);
        if (response.err) {
            ClientMessage.Error(response);
            return response;
        }

        // check the status
        if (response.val && response.val.status) {
            if (response.val.status.phase === "Failed") {
                // return failed status
                return Return.Failed(response.val.status.message!, response.val.status.reason!);
            } else if (response.val.status.phase === "Initialized") {
                break;
            }
        }

        // wait so that we don't spam the api server
        await Wait(500);
    }

    // check our user that we have access to the cluster
    for (let i = 0; i < 120; i++) {
        // first verify the cluster is initialized
        const response = await client.cluster(props.clusterName, Resources.StorageV1LocalUser).Get(user.metadata?.name!);
        if (response.err) {
            // wait so that we don't spam the api server
            await Wait(2000);

            // we timed out
            if (i + 1 >= 50) {
                return Return.Failed(`Timed out for user ${user.metadata?.name} to get access to cluster ${props.clusterName}`);
            }
        } else {
            break;
        }
    }

    // refetch the table
    await props.refetch();

    // next step
    props.setCurrentStep(3);
    return Return.Ok();
}

export default function ClusterConnectStep3(props: Props) {
    const user = useUser();
    const {loading, error} = useQuery(async () => {
        const result = await checkStatus(props, user!);
        if (result.err) {
            // try to delete the cluster
            await client.management(Resources.ManagementV1ClusterConnect).Delete(props.clusterName);
            return result;
        }

        return Return.Ok();
    });
    return <div className={styles["step-3"]}>
        {loading && <React.Fragment>
            <div className={styles["please-wait"]}>
                <div>Please wait until loft has installed or upgraded the following services:</div>
                <div>- <span className={styles["bold"]}>loft-agent</span></div>
            </div>
            <div className={styles["blue-text"]}>This process may take a while. Please be patient.</div>
        </React.Fragment>}
        {error && <React.Fragment>
            <ErrorMessage error={error}/>
            <div>
                <Button type="primary" onClick={() => {
                    props.setCurrentStep(1);
                }}>Retry</Button>
            </div>
        </React.Fragment>}
    </div>
}