import React, {RefObject} from "react";
import {ResultError, Return} from "../../../../../../lib/result";
import {SectionProps} from "../../../../../../components/Drawer/ItemDrawer";
import {ClusterV1HelmRelease} from "../../../../../../../gen/model/clusterV1HelmRelease";
import Section from "../../../../../../components/Drawer/Section/Section";
import {HelmRelease} from "../../AppsTable/AppsTable";
import {ManagementV1App} from "../../../../../../../gen/model/managementV1App";
import AppParameters from "../../../../../../components/Drawer/AppParameters/AppParameters";
import {StorageV1AppParameter} from "../../../../../../../gen/model/storageV1AppParameter";
import {arr} from "../../../../../../lib/helpers/renderhelper";

interface AppParametersPromptState {
    appName: string | undefined;
    parameters: StorageV1AppParameter[] | undefined;
}

interface AppParametersPromptProps extends SectionProps {
    release?: HelmRelease;
    selectedApp: ManagementV1App | undefined;
}


export default class AppParametersPrompt extends React.PureComponent<AppParametersPromptProps, AppParametersPromptState> {
    parametersRef?: RefObject<AppParameters>;
    state: AppParametersPromptState = {
        appName: this.props.release?.app?.metadata?.name || this.props.selectedApp?.metadata?.name,
        parameters: this.props.release?.app?.spec?.parameters || this.props.selectedApp?.spec?.parameters,
    };

    constructor(props: AppParametersPromptProps) {
        super(props);

        this.parametersRef = React.createRef<AppParameters>();
    }

    create = (obj: ClusterV1HelmRelease): ResultError => {
        if (!this.state.parameters) {
            return Return.Ok();
        }
        if (!obj.spec) {
            obj.spec = {};
        }
        
        const parametersResult = this.parametersRef?.current?.getParameters();
        if (parametersResult?.err) {
            return parametersResult;
        }
        
        obj.spec.parameters = parametersResult?.val;
        return Return.Ok();
    };

    update = (obj: ClusterV1HelmRelease): ResultError => {
        return this.create(obj);
    };
    
    selectApp = (app: ManagementV1App | undefined) => {
        if (!app) {
            this.setState({
                appName: undefined,
                parameters: undefined,
            });

            return;
        }

        this.setState({
            appName: app.metadata?.name,
            parameters: app.spec?.parameters,
        });
    }
    
    render() {
        if (this.props.mode === "batch" || arr(this.state.parameters).length === 0 || !this.state.appName) {
            return null;
        }

        return <Section title={"Parameters"} noLastLine={!!this.state.parameters?.find(p => p.section)}>
            <AppParameters key={this.state.appName} parameters={this.props.release?.release.spec?.parameters} parameterDefinitions={this.state.parameters} mode={this.props.mode} ref={this.parametersRef} />
        </Section>
    }
}