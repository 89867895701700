import React from "react";
import {SectionProps} from "../../../../components/Drawer/ItemDrawer";
import {ResultError, Return} from "../../../../lib/result";
import {selectOwnerFilter} from "../../../../lib/helpers/renderhelper";
import Label from "../../../../components/Label/Label";
import Description from "../../../../components/Description/Description";
import Select from "../../../../components/Select/Select";
import {ManagementV1ClusterMembers} from "../../../../../gen/model/managementV1ClusterMembers";
import Owner from "../../../../components/Owner/Owner";
import {StorageV1ClusterQuota} from "../../../../../gen/model/agentstorageV1ClusterQuota";
import {GetKey, GetUserTeamFromKey} from "../../../Spaces/Spaces/SpaceDrawer/Sections/SpaceAccess";
const { Option } = Select;

interface QuotaOwnerState {
    selectedOwner: string | undefined;
    selectedOwnerChanged: boolean;
}

interface QuotaOwnerProps extends SectionProps {
    clusterMembers: ManagementV1ClusterMembers | undefined;

    // The cluster the account is in
    cluster: string;
}

export default class QuotaOwner extends React.PureComponent<QuotaOwnerProps, QuotaOwnerState> {
    state: QuotaOwnerState = {
        selectedOwner: undefined,
        selectedOwnerChanged: false,
    };

    create = async (obj: StorageV1ClusterQuota): Promise<ResultError> => {
        if (!this.state.selectedOwner) {
            return Return.Failed("Please select an owner for the account quota");
        }
        if (!obj.metadata) {
            obj.metadata = {}
        }

        const userTeam = GetUserTeamFromKey(this.state.selectedOwner);
        obj.spec = {
            user: userTeam.user,
            team: userTeam.team,
        };

        return Return.Ok();
    };

    renderOptions = (data: ManagementV1ClusterMembers) => {
        const options: JSX.Element[] = [];

        // add users
        data.users?.forEach(user => {
            options.push(<Option key={GetKey(user?.info?.name)} value={GetKey(user?.info?.name!)!}>
                <Owner displayName={user.info?.displayName} username={user.info?.username} kubeName={user.info?.name} type={"small"} />
            </Option>)
        });

        // add teams
        data.teams?.forEach(team => {
            options.push(<Option key={GetKey(undefined, team?.info?.name)} value={GetKey(undefined, team?.info?.name)!}>
                <Owner isTeam={true} displayName={team.info?.displayName} username={team.info?.username} kubeName={team.info?.name} type={"small"} />
            </Option>)
        });

        return options;
    };

    renderSelectOwner(clusterMembers: ManagementV1ClusterMembers) {
        return <Select
            allowClear
            showSearch
            style={{ width: "100%" }}
            placeholder="Select an owner"
            optionFilterProp="children"
            value={this.state.selectedOwner}
            resetable={this.props.mode !== "create"}
            onChangedStatus={(changed) => this.setState({selectedOwnerChanged: changed})}
            onChange={(value) => this.setState({selectedOwner: value})}
            filterOption={selectOwnerFilter}
        >
            {this.renderOptions(clusterMembers)}
        </Select>;
    }

    render() {
        if (this.props.mode !== "create") {
            return null;
        }

        return <React.Fragment>
        {
            this.props.cluster && this.props.clusterMembers && <React.Fragment>
                <Label>Owner</Label>
                {this.renderSelectOwner(this.props.clusterMembers)}
                <Description>Choose the owner for this account quota.</Description>
            </React.Fragment>
        }
        </React.Fragment>
    }
}