import React from 'react';
import client from "../../lib/client";
import {NewResource, Resources} from "../../lib/resources";
import {ErrorTypeForbidden, Result, Return} from "../../lib/result";
import useQuery, {usePeriodicQuery} from "../../lib/Query/Query";
import {ReLogin} from "../../components/ReLogin/ReLogin";
import {arr} from "../../lib/helpers/renderhelper";
import {ManagementV1License} from "../../../gen/model/managementV1License";
import {ManagementV1LicenseToken} from "../../../gen/model/managementV1LicenseToken";
import {ManagementV1Feature} from "../../../gen/model/managementV1Feature";
import {Wait} from "../../lib/helper";

type Refetch = () => Promise<void>

export interface LicenseState {
    license?: ManagementV1License,
    token?: ManagementV1LicenseToken
}

const LicenseRefreshContext = React.createContext<Refetch | undefined>(undefined);
const LicenseContext = React.createContext<LicenseState | undefined>(undefined);
const FeaturesContext = React.createContext<Array<ManagementV1Feature> | undefined>([]);
const AnnouncementContext = React.createContext<string | undefined>(undefined);

export function useLicenseRefresh() {
    return React.useContext(LicenseRefreshContext);
}

export function useLicense(): LicenseState | undefined {
    return React.useContext(LicenseContext);
}

export function useFeatures(): Array<ManagementV1Feature> {
    return arr(React.useContext(FeaturesContext));
}

export function useAnnouncement() {
    return React.useContext(AnnouncementContext);
}

export default function LicenseProvider(props: React.PropsWithChildren<{}>) {
    const {error: featuresError, data: features, refetch: featuresRefetch} = useQuery(async () => await client.management(Resources.ManagementV1Feature).List());
    const {error: announcementError, data: announcements, refetch: announcementsRefetch} = usePeriodicQuery(async () => await client.management(Resources.ManagementV1Announcement).List(), 60000);
    const {error, data, refetch} = usePeriodicQuery<LicenseState>(async () => {
        // get the license
        const licenseResult = await client.management(Resources.ManagementV1License).List();
        if (licenseResult.err) {
            if (licenseResult.val.type === ErrorTypeForbidden) {
                return Return.Value<LicenseState>({});
            }

            return licenseResult;
        } else if (arr(licenseResult.val.items).length !== 1) {
            return Return.Failed("unexpected amount of licenses");
        }

        // get a valid license token
        const licenseTokenResult = await client.management(Resources.ManagementV1LicenseToken).Create(NewResource(Resources.ManagementV1LicenseToken));
        if (licenseTokenResult.err) {
            if (licenseTokenResult.val.type === ErrorTypeForbidden) {
                return Return.Value<LicenseState>({});
            }

            return licenseTokenResult;
        }

        return Return.Value<LicenseState>({
            license: licenseResult.val.items[0],
            token: licenseTokenResult.val
        })
    }, 1800000);

    const completeRefetch = async () => {
        // completely refetch license
        await client.management(Resources.ManagementV1License).Get("license");
        await Wait(2000);
        await featuresRefetch();
        await announcementsRefetch();
        await refetch();
    };
    if (error || featuresError || announcementError) {
        return <ReLogin refetch={completeRefetch} error={(error || featuresError || announcementError)!} />
    }

    const announcement = announcements?.items.length === 1 ? announcements?.items[0].status : undefined;
    if (announcement?.analyticsToken) {
        if (!(window as any).loft) {
            (window as any).loft = {}
        }

        (window as any).loft.hmacToken = announcement.analyticsToken;
    }
    
    return (
        <LicenseContext.Provider value={data!}>
            <LicenseRefreshContext.Provider value={completeRefetch}>
                <FeaturesContext.Provider value={arr(features?.items)}>
                    <AnnouncementContext.Provider value={announcement?.announcement}>
                        {props.children}
                    </AnnouncementContext.Provider>
                </FeaturesContext.Provider>
            </LicenseRefreshContext.Provider>
        </LicenseContext.Provider>
    )
};