import React from "react";
import {displayName, formatURIComponent} from "../../../lib/helper";
import {ManagementV1ClusterAccess} from "../../../../gen/model/managementV1ClusterAccess";
import {DrawerDispatch, useItemDrawer} from "../../../contexts/drawer/DrawerContext";
import * as History from "history";
import {ToggleColumn, ToggleColumnContent} from "../../../contexts/ToggleColumn/ToggleColumn";
import FixedText from "../../../components/FixedText/FixedText";
import {Link, useHistory} from "react-router-dom";
import useQuery from "../../../lib/Query/Query";
import client from "../../../lib/client";
import {Resources} from "../../../lib/resources";
import Table, {TableActions} from "../../../components/Table/Table";
import ClustersHeader from "../ClustersHeader/ClustersHeader";
import Button from "../../../components/Button/Button";
import {Tooltip} from "../../../components/Tooltip/Tooltip";
import {DeleteOutlined, SettingOutlined} from "@ant-design/icons";
import {deleteConfirm} from "../../../lib/Modal/Modal";
import ClientMessage from "../../../lib/Message/ClientMessage";
import {
    arr,
    Filter,
    getDisplayNameFromEntity,
    removeDuplicateFilters
} from "../../../lib/helpers/renderhelper";
import {creationTimestampSorter, displayNameSorter, numberSorter, stringSorter} from "../../../lib/helpers/sorthelper";
import DynamicTime from "../../../components/DynamicTime/DynamicTime";
import {MultiItem} from "../../../components/MultiItem/MultiItem";
import ShowYamlPopup from "../../../components/ShowYamlPopup/ShowYamlPopup";
import Owner from "../../../components/Owner/Owner";
import styles from "./ClusterAccess.module.scss";
import ClusterAccessDrawer from "./ClusterAccessDrawer/ClusterAccessDrawer";
import Description from "../../../components/Description/Description";
import Query from "../../../components/Query/Query";

interface Props {}

function getTableColumns(refetch: () => Promise<void>, drawerDispatcher: DrawerDispatch, history: History.History<any>, allGlobalClusterAccesses: ManagementV1ClusterAccess[]) {
    const editGlobalClusterAccess = (clusterAccess: ManagementV1ClusterAccess) => {
        drawerDispatcher({
            title: "Edit Cluster Access: " + displayName(clusterAccess),
            content: <ClusterAccessDrawer mode={"update"} clusterAccess={clusterAccess} refetch={refetch} />
        })
    };

    return [
        {
            title: <ToggleColumn id={"displayname"} columns={['Display Name', 'Kubernetes Name (ID)']}/>,
            sorter: (a: ManagementV1ClusterAccess, b: ManagementV1ClusterAccess) => stringSorter(displayName(a), displayName(b)),
            render: (clusterAccess: ManagementV1ClusterAccess) => {
                return <ToggleColumnContent id={"displayname"} columns={[
                    () => {
                        return <FixedText className={"clickable-link"} onClick={() => editGlobalClusterAccess(clusterAccess)} text={displayName(clusterAccess)} />;
                    },
                    () => {
                        return <FixedText className={"clickable-link"} onClick={() => editGlobalClusterAccess(clusterAccess)} text={clusterAccess.metadata?.name} />;
                    },
                ]}/>
            }
        },
        {
            title: "Description",
            render: (clusterAccess: ManagementV1ClusterAccess) => {
                return <Description.Column>
                    {clusterAccess.spec?.description || ""}
                </Description.Column>;
            }
        },
        {
            title: 'Clusters',
            filters: removeDuplicateFilters(arr(allGlobalClusterAccesses).reduce((previous: Filter[], clusterAccess): Filter[] => {
                const clusters = clusterAccess.status?.clusters?.map(c => ({
                    text: getDisplayNameFromEntity(c),
                    value: getDisplayNameFromEntity(c) + ""
                }))
                return [...previous, ...arr(clusters)];
            }, [])),
            onFilter: (value: string | number | boolean, cluster: ManagementV1ClusterAccess) => {
                return !!cluster.status?.clusters?.find(c => getDisplayNameFromEntity(c) === value);
            },
            render: (cluster: ManagementV1ClusterAccess) => {
                const clusters = arr(cluster.status?.clusters);
                return <MultiItem items={clusters.map(c => ({key: c.name, children: getDisplayNameFromEntity(c)}))} maxItems={5} />;
            }
        },
        {
            title: 'Users',
            filters: removeDuplicateFilters(arr(allGlobalClusterAccesses).reduce((previous: Filter[], clusterAccess): Filter[] => {
                const clusters = clusterAccess.status?.users?.map(c => {
                    if (c.user) {
                        return ({
                            text: getDisplayNameFromEntity(c.user),
                            value: getDisplayNameFromEntity(c.user) + ""
                        })
                    } else {
                        return ({
                            text: getDisplayNameFromEntity(c.team),
                            value: getDisplayNameFromEntity(c.team) + ""
                        })
                    }
                })
                return [...previous, ...arr(clusters)];
            }, [])),
            onFilter: (value: string | number | boolean, cluster: ManagementV1ClusterAccess) => {
                return !!cluster.status?.users?.find(c => {
                    if (c.user) {
                        return getDisplayNameFromEntity(c.user) === value
                    } else {
                        return getDisplayNameFromEntity(c.team) === value
                    }
                });
            },
            render: (cluster: ManagementV1ClusterAccess) => {
                const users = arr(cluster.status?.users).map(u => {
                    if (u.user) {
                        return {
                            key: u.user.name,
                            children: <Owner displayName={u.user.displayName}
                                             username={u.user.username}
                                             kubeName={u.user.name}
                                             isTeam={false}
                                             className={styles["owner"]} />
                        }
                    } else if (u.team) {
                        return {
                            key: u.team.name,
                            children: <Owner displayName={u.team.displayName}
                                             username={u.team.username}
                                             kubeName={u.team.name}
                                             isTeam={true}
                                             className={styles["owner"]} />
                        }
                    }

                    return {};
                });
                return <MultiItem items={users} maxItems={5} />;
            }
        },
        {
            title: 'Teams',
            filters: removeDuplicateFilters(arr(allGlobalClusterAccesses).reduce((previous: Filter[], clusterAccess): Filter[] => {
                const clusters = clusterAccess.status?.teams?.map(c => ({
                    text: getDisplayNameFromEntity(c),
                    value: getDisplayNameFromEntity(c) + ""
                }))
                return [...previous, ...arr(clusters)];
            }, [])),
            onFilter: (value: string | number | boolean, cluster: ManagementV1ClusterAccess) => {
                return !!cluster.status?.teams?.find(c => getDisplayNameFromEntity(c) === value);
            },
            showSorterTooltip: false,
            render: (cluster: ManagementV1ClusterAccess) => {
                const teams = arr(cluster.status?.teams);
                return <MultiItem items={teams.map(c => ({key: c.name, children: <Owner displayName={c.displayName}
                                                                                        username={c.username}
                                                                                        kubeName={c.name}
                                                                                        isTeam={true}
                                                                                        className={styles["owner"]} />}))} maxItems={5} />;
            }
        },
        {
            title: <Tooltip title={"Cluster access rules with higher priority will override rules with lower priority"}>Priority</Tooltip>,
            sorter: (a: ManagementV1ClusterAccess, b: ManagementV1ClusterAccess) => numberSorter(a.spec?.localClusterAccessTemplate?.spec?.priority, b.spec?.localClusterAccessTemplate?.spec?.priority),
            render: (cluster: ManagementV1ClusterAccess) => {
                return cluster.spec?.localClusterAccessTemplate?.spec?.priority || "None"
            }
        },
        {
            title: 'Space Constraints',
            sorter: (a: ManagementV1ClusterAccess, b: ManagementV1ClusterAccess) => stringSorter(a.spec?.localClusterAccessTemplate?.spec?.spaceConstraintsRef, b.spec?.localClusterAccessTemplate?.spec?.spaceConstraintsRef),
            render: (cluster: ManagementV1ClusterAccess) => {
                if (!cluster.status?.spaceConstraint) {
                    return <span>None</span>;
                }
                
                return <Link to={`/clusters/spaceconstraints#search=${formatURIComponent(getDisplayNameFromEntity(cluster.status?.spaceConstraint))}`} className={"hover-link"}>{getDisplayNameFromEntity(cluster.status?.spaceConstraint)}</Link>
            }
        },
        {
            title: 'Created',
            sorter: (a: ManagementV1ClusterAccess, b: ManagementV1ClusterAccess) => creationTimestampSorter(a, b),
            render: (cluster: ManagementV1ClusterAccess) => {
                return <DynamicTime timestamp={cluster.metadata?.creationTimestamp} useTooltip={true}/>
            }
        },
        {
            title: 'Actions',
            width: "210px",
            render: (cluster: ManagementV1ClusterAccess) => {
                return <TableActions>
                    <Tooltip title="edit">
                        <SettingOutlined className={"blue-btn"} onClick={() => editGlobalClusterAccess(cluster)} />
                    </Tooltip>
                    <ShowYamlPopup className={"blue-btn"} object={cluster} resource={Resources.ManagementV1ClusterAccess} name={cluster.metadata?.name!} refetch={refetch} />
                    <Tooltip title="delete">
                        <DeleteOutlined className={"actions-delete-btn"} onClick={() => {
                            deleteConfirm({
                                title: `Delete Cluster Access: ${cluster?.metadata?.name}`,
                                content: `Are you sure you want to delete the cluster access ${cluster?.metadata?.name}? This action CANNOT be reverted!`,
                                onOkAsync: async () => {
                                    const message = ClientMessage.Loading();
                                    const result = await client.management(Resources.ManagementV1ClusterAccess).Delete(cluster?.metadata?.name as string);
                                    message.Result(result);
                                    await refetch();
                                },
                            });
                        }} />
                    </Tooltip>
                </TableActions>;
            }
        },
    ];
}

function filter(item: ManagementV1ClusterAccess, value: string) {
    return item.metadata?.name?.includes(value) || item.spec?.displayName?.includes(value) || item.spec?.description?.includes(value) || !!item.status?.clusters?.find(cluster => cluster.name?.includes(value) || cluster.displayName?.includes(value));
}

function ClusterAccess(props: Props) {
    const history = useHistory();
    const drawerDispatcher = useItemDrawer({width: 900});
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
    const {loading, error, data, refetch} = useQuery(async () => await client.management(Resources.ManagementV1ClusterAccess).List());
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedKeys: any) => {
            setSelectedRowKeys(selectedKeys);
        },
    };

    const allGlobalClusterAccesses = arr(data?.items).map(cluster => { return {...cluster, key: cluster.metadata!.name!}}).sort((a, b) => displayNameSorter(a, b));
    return <div>
        <Table showSorterTooltip={false} loading={loading} columns={getTableColumns(refetch, drawerDispatcher, history, allGlobalClusterAccesses)} dataSource={arr(allGlobalClusterAccesses)} error={error} rowSelection={rowSelection} filter={filter} refetch={refetch} header={{
            top: <ClustersHeader>
                <Description.Table>Globally defined cluster access. You can allow users or teams to access certain clusters here and define their cluster roles as well as cluster limits and space constraints in those clusters.</Description.Table>
            </ClustersHeader>,
            right: <Query query={async () => await client.management(Resources.ManagementV1ClusterAccess).CanI("create")}>
                {
                    result => {
                        if (result.loading || !result.data) {
                            return null;
                        }

                        return <Button type={"primary"} onClick={() => {
                            drawerDispatcher({
                                title: "Create Cluster Access",
                                content: <ClusterAccessDrawer mode={"create"} refetch={refetch} />
                            });
                        }}>Create Cluster Access</Button>
                    }
                }
            </Query>,
            selectedActions: <React.Fragment>
                <Tooltip title={"delete"}>
                    <DeleteOutlined className={"actions-delete-btn-batch"} onClick={() =>
                    {
                        deleteConfirm({
                            title: `Delete Cluster Access`,
                            content: `Are you sure you want to delete the cluster access ${selectedRowKeys.join(", ")}?`,
                            onOkAsync: async () => {
                                const message = ClientMessage.Loading();
                                for (let i = 0; i < selectedRowKeys.length; i++) {
                                    const clusterName = selectedRowKeys[i];
                                    const result = await client.management(Resources.ManagementV1ClusterAccess).Delete(clusterName as string);
                                    if (result.err) {
                                        message.Error(result);
                                        return;
                                    }
                                }

                                message?.DoneManagement();
                                await refetch();
                                setSelectedRowKeys([]);
                            }
                        });
                    }} />
                </Tooltip>
            </React.Fragment>
        }} />
    </div>
}

export default ClusterAccess;
