import React, {RefObject} from "react";
import {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import Section from "../../../../../components/Drawer/Section/Section";
import {ResultError, Return} from "../../../../../lib/result";
import {ManagementV1ClusterAccountTemplate} from "../../../../../../gen/model/managementV1ClusterAccountTemplate";
import AccountQuota from "./AccountQuota";
import ClusterRoleBindings from "./ClusterRoleBindings";
import OtherResources from "./OtherResources";

interface AccountResourcesState {

}

interface AccountResourcesProps extends SectionProps {
    clusterAccountTemplate?: ManagementV1ClusterAccountTemplate;
}

export default class AccountResources extends React.PureComponent<AccountResourcesProps, AccountResourcesState> {
    accountQuotaRef?: RefObject<AccountQuota>;
    clusterRoleBindingsRef?: RefObject<ClusterRoleBindings>;
    otherResourcesRef?: RefObject<OtherResources>;
    state: AccountResourcesState = {};

    constructor(props: AccountResourcesProps) {
        super(props);

        this.accountQuotaRef = React.createRef<AccountQuota>();
        this.clusterRoleBindingsRef = React.createRef<ClusterRoleBindings>();
        this.otherResourcesRef = React.createRef<OtherResources>();
    }

    create = async (clusterAccountTemplate: ManagementV1ClusterAccountTemplate): Promise<ResultError> => {
        let result = await this.accountQuotaRef?.current?.create(clusterAccountTemplate);
        if (result?.err) {
            return result;
        }

        result = await this.clusterRoleBindingsRef?.current?.create(clusterAccountTemplate);
        if (result?.err) {
            return result;
        }

        result = await this.otherResourcesRef?.current?.create(clusterAccountTemplate);
        if (result?.err) {
            return result;
        }

        return Return.Ok();
    };

    update = async (clusterAccountTemplate: ManagementV1ClusterAccountTemplate): Promise<ResultError> => {
        let result = await this.accountQuotaRef?.current?.update(clusterAccountTemplate);
        if (result?.err) {
            return result;
        }

        result = await this.clusterRoleBindingsRef?.current?.update(clusterAccountTemplate);
        if (result?.err) {
            return result;
        }

        result = await this.otherResourcesRef?.current?.update(clusterAccountTemplate);
        if (result?.err) {
            return result;
        }

        return Return.Ok();
    };

    render() {
        if (this.props.mode === "batch") {
            return null;
        }

        return <Section title={"3. Create Account Resources in Clusters"} foldable={true} defaultFolded={true}>
            <AccountQuota {...this.props} ref={this.accountQuotaRef} />
            <ClusterRoleBindings {...this.props} ref={this.clusterRoleBindingsRef} />
            <OtherResources {...this.props} ref={this.otherResourcesRef} />
        </Section>
    }
}