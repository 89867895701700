import React from "react";
import {arr, selectDefaultFilter} from "../../../../../lib/helpers/renderhelper";
import Select from "../../../../../components/Select/Select";
import {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import {Result, Return} from "../../../../../lib/result";
import client from "../../../../../lib/client";
import {Resources} from "../../../../../lib/resources";
import Query from "../../../../../components/Query/Query";
import {ErrorMessage} from "../../../../../components/ErrorMessage/ErrorMessage";
import Description from "../../../../../components/Description/Description";
import Label from "../../../../../components/Label/Label";
import Section from "../../../../../components/Drawer/Section/Section";
import {ManagementV1App} from "../../../../../../gen/model/managementV1App";
import {ManagementV1SpaceTemplate} from "../../../../../../gen/model/managementV1SpaceTemplate";
import {displayName} from "../../../../../lib/helper";
const { Option } = Select;

interface SpaceAppsState {
    apps?: string[];
}

interface SpaceAppsProps extends SectionProps {
    
}

export default class SpaceApps extends React.PureComponent<SpaceAppsProps, SpaceAppsState> {
    private availableApps: Array<ManagementV1App> = [];
    
    state: SpaceAppsState = {
        apps: [],
    };
    
    selectTemplate(spaceTemplate: ManagementV1SpaceTemplate | undefined) {
        if (!spaceTemplate) {
            return;
        }
        
        this.setState({
            apps: arr(spaceTemplate.spec?.template?.apps?.map(app => app.name!)),
        })
    }
    
    getApps(): Result<Array<ManagementV1App>> {
        const deployApps: Array<ManagementV1App> = [];
        const apps = arr(this.state.apps);
        for (let i = 0; i < apps.length; i++) {
            const app = this.availableApps.find(app => app.metadata?.name === apps[i]);
            if (!app) {
                return Return.Failed(`cannot find app ${apps[i]}, the user has either no access to this app or it does not exist`)
            }
            
            deployApps.push(app);
        }
        
        return Return.Value(deployApps);
    }

    render() {
        if (this.props.mode !== "create") {
            return null;
        }

        return <Section title={"Apps"} foldable={true} defaultFolded={true}>
            <Query query={async () => {
                let apps: ManagementV1App[] = [];
                const appsResult = await client.management(Resources.ManagementV1App).List();
                if (appsResult.err) {
                    console.error(appsResult.val)
                } else {
                    apps = arr(appsResult.val.items);
                }

                return Return.Value({
                    apps
                });
            }}>
                {
                    result => {
                        if (result.error) {
                            return <ErrorMessage error={result.error} />;
                        }

                        this.availableApps = arr(result.data?.apps);
                        return <React.Fragment>
                            <Label>Which apps should get deployed within the space?</Label>
                            <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Please select apps that should be deployed in the space"
                                value={this.state.apps}
                                onChange={(value) => this.setState({apps: value})}
                                showSearch
                                optionFilterProp="children"
                                filterOption={selectDefaultFilter}
                            >
                                {arr(result.data?.apps).map(app => <Option key={app.metadata?.name} value={app.metadata?.name!}>
                                    {displayName(app)}
                                </Option>)}
                            </Select>
                            <Description>Each app will be deployed into the created space</Description>
                        </React.Fragment>
                    }
                }
            </Query>
        </Section>
    }
}