import React from "react";
import {StorageV1UserClusterAccountTemplate} from "../../../../../gen/model/storageV1UserClusterAccountTemplate";
import {SectionProps} from "../../../../components/Drawer/ItemDrawer";
import {arr, selectDefaultFilter} from "../../../../lib/helpers/renderhelper";
import {ResultError, Return} from "../../../../lib/result";
import Label from "../../../../components/Label/Label";
import Query from "../../../../components/Query/Query";
import client from "../../../../lib/client";
import {Resources} from "../../../../lib/resources";
import constants from "../../../../constants/constants";
import Section from "../../../../components/Drawer/Section/Section";
import {ErrorMessage} from "../../../../components/ErrorMessage/ErrorMessage";
import Select from "../../../../components/Select/Select";
import Description from "../../../../components/Description/Description";
import styles from "./ClusterAccountTemplates.module.scss";
import {Link} from "react-router-dom";
import {Alert} from "antd";
const {Option} = Select;

interface ClusterAccountTemplatesSpec {
    'clusterAccountTemplates'?: Array<StorageV1UserClusterAccountTemplate>;
    [a: string]: any;
}

interface ClusterAccountTemplatesProps extends SectionProps {
    clusterAccountTemplatesSpecs?: ClusterAccountTemplatesSpec[];
    clusterAccountTemplatesSpec?: ClusterAccountTemplatesSpec;
}

interface ClusterAccountTemplatesState {
    clusterAccountTemplates: string[];
    clusterAccountTemplatesChanged: boolean;
}

function wereClusterAccountTemplatesDeleted(oldTemplates: string[] | undefined, newTemplates: string[] | undefined) {
    if (!oldTemplates) {
        return false;
    }

    for (let i = 0; i < oldTemplates.length; i++) {
        if (!newTemplates) {
            return true;
        }

        let found = false;
        for (let j = 0; j < newTemplates.length; j++) {
            if (newTemplates[j] === oldTemplates[i]) {
                found = true;

            }
        }
        if (!found) {
            return true;
        }
    }

    return false;
}

export default class ClusterAccountTemplates extends React.PureComponent<ClusterAccountTemplatesProps, ClusterAccountTemplatesState> {
    state: ClusterAccountTemplatesState = {
        clusterAccountTemplates: this.props.clusterAccountTemplatesSpec ? arr(this.props.clusterAccountTemplatesSpec?.clusterAccountTemplates).map((template: StorageV1UserClusterAccountTemplate) => template.name!) : [],
        clusterAccountTemplatesChanged: false,
    };

    create(clusterAccountTemplatesSpec: ClusterAccountTemplatesSpec): ResultError {
        clusterAccountTemplatesSpec.clusterAccountTemplates = arr(this.state.clusterAccountTemplates).map((template: string) => ({
            name: template,
            sync: true,
        }));
        return Return.Ok();
    }

    update(clusterAccountTemplatesSpec: ClusterAccountTemplatesSpec): ResultError {
        if (this.state.clusterAccountTemplatesChanged) {
            this.create(clusterAccountTemplatesSpec);
        }

        return Return.Ok();
    }

    batch(clusterAccountTemplatesSpecs: ClusterAccountTemplatesSpec[]): ResultError {
        for (let i = 0; i < clusterAccountTemplatesSpecs.length; i++) {
            const result = this.update(clusterAccountTemplatesSpecs[i]);
            if (result.err) {
                return result;
            }
        }

        return Return.Ok();
    }

    render() {
        return <Query query={async () => {
            const result = await client.management(Resources.ManagementV1ClusterAccountTemplate).List();
            if (result.err) {
                return result;
            }

            if (this.props.mode === "create") {
                const defaultTemplates = result.val.items.filter(t => t.metadata?.labels?.[constants.LoftManagementDefaultClusterAccountTemplateLabel] === "true");
                if (defaultTemplates.length > 0) {
                    this.setState({
                        clusterAccountTemplates: defaultTemplates.map(t => t.metadata!.name!)
                    })
                }
            }

            return result;
        }}>
            {
                result => {
                    if (result.error) {
                        return <ErrorMessage error={result.error} />;
                    } else if (result.loading || arr(result.data?.items).length === 0) {
                        return null;
                    }

                    return <Section title={"Account Templates"}>
                        <Label>Cluster Account Templates</Label>
                        <Select
                            resetable={this.props.mode !== "create"}
                            value={this.state.clusterAccountTemplates}
                            loading={result.loading}
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="Please select cluster account templates"
                            onChangedStatus={status => this.setState({clusterAccountTemplatesChanged: status})}
                            onChange={(value) => {
                                this.setState({
                                    clusterAccountTemplates: value
                                });
                            }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={selectDefaultFilter}
                        >
                            {result.data ? result.data.items.map(template => <Option key={template.metadata?.name!} value={template.metadata?.name!}>{template.metadata?.name}</Option>) : undefined}
                        </Select>
                        <Description>For each of these <Link to={"/users/accounttemplates"}>cluster account templates</Link> an account for the selected clusters will be created.</Description>
                        {
                            this.props.clusterAccountTemplatesSpec && this.state.clusterAccountTemplatesChanged && wereClusterAccountTemplatesDeleted(arr(this.props.clusterAccountTemplatesSpec?.clusterAccountTemplates).map((template: StorageV1UserClusterAccountTemplate) => template.name!), this.state.clusterAccountTemplates) ?
                                <Alert className={styles["alert"]} message="Deleting cluster account templates for a user or a team will NOT delete already created cluster accounts and other account-related resources in the clusters." type="error" /> : undefined
                        }
                    </Section>
                }
            }
        </Query>
    }
}