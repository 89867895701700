import React from "react";
import client from "../../../../lib/client";
import {Resources} from "../../../../lib/resources";
import {DrawerDispatch, useItemDrawer} from "../../../../contexts/drawer/DrawerContext";
import DynamicTime from "../../../../components/DynamicTime/DynamicTime";
import Table, {TableActions} from "../../../../components/Table/Table";
import {Tooltip} from "../../../../components/Tooltip/Tooltip";
import {DeleteOutlined, SettingOutlined, WarningOutlined} from "@ant-design/icons/lib";
import {deleteConfirm} from "../../../../lib/Modal/Modal";
import ClientMessage from "../../../../lib/Message/ClientMessage";
import {ToggleColumn, ToggleColumnContent} from "../../../../contexts/ToggleColumn/ToggleColumn";
import useQuery from "../../../../lib/Query/Query";
import {arr} from "../../../../lib/helpers/renderhelper";
import styles from "./Accounts.module.scss";
import { useRouteMatch } from "react-router-dom";
import {ManagementV1ClusterMember} from "../../../../../gen/model/managementV1ClusterMember";
import {ManagementV1ClusterMembers} from "../../../../../gen/model/managementV1ClusterMembers";
import {getDisplayName} from "../../../../lib/helpers/renderhelper";
import Owner from "../../../../components/Owner/Owner";
import {Button} from "antd";
import ClusterHeader from "../ClusterHeader/ClusterHeader";
import AccountDrawer from "./AccountDrawer/AccountDrawer";
import {creationTimestampSorter, stringSorter} from "../../../../lib/helpers/sorthelper";
import ShowYamlPopup from "../../../../components/ShowYamlPopup/ShowYamlPopup";
import {Item} from "../../../../components/Item/Item";
import FixedText from "../../../../components/FixedText/FixedText";
import AccountDeletePopup from "./AccountDeletePopup";
import {ConfigV1alpha1Account} from "../../../../../gen/model/configV1alpha1Account";
import {Return} from "../../../../lib/result";
import constants from "../../../../constants/constants";
import {getSleepAfter} from "../../../../lib/helpers/sleepmodehelper";
import {MultiItem} from "../../../../components/MultiItem/MultiItem";

export interface ClusterAccount {
    member: ManagementV1ClusterMember;
    account: ConfigV1alpha1Account;
    isTeam: boolean;
}

function getTableColumns(allAccounts: ClusterAccount[], refetch: () => Promise<void>, drawerDispatcher: DrawerDispatch, cluster: string) {
    const editAccount = (account: ClusterAccount) => {
        drawerDispatcher({
            title: "Edit Account: " + account.account?.metadata?.name!,
            content: <AccountDrawer mode={"update"} cluster={cluster} account={account} allAccounts={allAccounts} refetch={refetch} />
        })
    };

    return [
        {
            title: <ToggleColumn id={"owner"} columns={['Owner', 'Account Name']}/>,
            showSorterTooltip: false,
            sorter: (a: ClusterAccount, b: ClusterAccount) => {
                const aValue = a.member?.info ? getDisplayName(a.member?.info.displayName, a.member?.info.username, a.member?.info.name) : "none";
                const bValue = b.member?.info ? getDisplayName(b.member?.info.displayName, b.member?.info.username, b.member?.info.name) : "none";
                return stringSorter(aValue, bValue);
            },
            render: (account: ClusterAccount) => {
                return <ToggleColumnContent id={"owner"} columns={[
                () => {
                    return <FixedText className={styles["clickable"]} onClick={() => editAccount(account)} text={getDisplayName(account.member.info?.displayName, account.member.info?.username, account.member.info?.name)} />
                },
                () => {
                    return <FixedText className={styles["clickable"]} onClick={() => editAccount(account)} text={account.account?.metadata?.name!} />
                }]} />
            },
        },
        {
            title: 'Type',
            render: (account: ClusterAccount) => {
                return <Owner displayName={account.isTeam ? "Team" : "User"} isTeam={account.isTeam} type={account.isTeam ? "dark" : undefined} />
            }
        },
        {
            title: 'Spaces',
            render: (account: ClusterAccount) => {
                const spaceLimit = account.account?.spec?.space?.limit;
                const spaceNum = arr(account.account?.status?.namespaces).length;
                if (spaceLimit !== undefined) {
                    if (spaceLimit <= spaceNum) {
                        return <span className={"color-warning"}><WarningOutlined />&nbsp;{spaceNum}&nbsp;/&nbsp;{spaceLimit}</span>
                    }

                    return <span>{spaceNum}&nbsp;/&nbsp;{spaceLimit}</span>
                }

                return <span>{spaceNum}</span>;
            }
        },
        {
            title: 'Start Sleep Mode',
            render: (account: ClusterAccount) => {
                const sleepAfter = getSleepAfter(account.account?.spec?.space?.spaceTemplate?.metadata);
                if (sleepAfter) {
                    const minutes = Number((sleepAfter / 60).toPrecision(2));
                    return <span>after {minutes} minute{minutes === 1 ? "" : "s"}</span>
                }

                return <span>manual</span>
            }
        },
        {
            title: 'Enforce Templates',
            render: (account: ClusterAccount) => {
                const templateInstances = account.account?.spec?.space?.templateInstances;
                return <MultiItem items={templateInstances ? templateInstances.map(instance => ({key: instance.metadata?.name, children: instance.spec?.template})) : []} />
            }
        },
        {
            title: 'Created',
            width: "180px",
            sorter: (a: ClusterAccount, b: ClusterAccount) => creationTimestampSorter(a.account, b.account),
            render: (account: ClusterAccount) => {
                return <DynamicTime timestamp={account.account?.metadata?.creationTimestamp} useTooltip={true}/>
            }
        },
        {
            title: 'Actions',
            width: "180px",
            render: (account: ClusterAccount) => {
                return <TableActions className={styles["actions"]}>
                    <Tooltip title="edit">
                        <SettingOutlined className={styles["setting"]} onClick={() => editAccount(account)} />
                    </Tooltip>
                    <ShowYamlPopup className={styles["setting"]} object={account.account} cluster={cluster} resource={Resources.ConfigV1alpha1Account} name={account.account?.metadata?.name!} refetch={refetch} />
                    <AccountDeletePopup account={account.account} cluster={cluster} refetch={refetch} />
                </TableActions>;
            }
        },
    ];
}

function filter(item: ClusterAccount, value: string) {
    return !!(item.member.info?.name?.includes(value) || item.member.info?.username?.includes(value) || item.member.info?.displayName?.includes(value) || item.account?.metadata?.name?.includes(value))
}

function sort(accounts: ClusterAccount[]) {
    return accounts.sort((a, b) => {
        const aName = getDisplayName(a.member.info?.displayName, a.member.info?.username, a.member.info?.name) as any;
        const bName = getDisplayName(b.member.info?.displayName, b.member.info?.username, b.member.info?.name) as any;
        if (aName < bName) {
            return -1;
        } else if (aName > bName) {
            return 1;
        }

        return 0;
    })
}

function convertClusterMembers(members?: ManagementV1ClusterMembers, accounts?: ConfigV1alpha1Account[]): ClusterAccount[] {
    if (!members) {
        return [];
    }
    
    const mappedUsers = [...arr(members.users).map((user: ManagementV1ClusterMember) => {
        const account = accounts?.find(account => account.metadata?.labels?.[constants.UserLabel] === user.info?.name)
        return {isTeam: false, member: user, account}
    })];
    const mappedTeams = [...arr(members.teams).map((team: ManagementV1ClusterMember) => {
        const account = accounts?.find(account => account.metadata?.labels?.[constants.TeamLabel] === team.info?.name)
        return {isTeam: true, member: team, account}
    })];
    const combined = [...mappedUsers, ...mappedTeams].filter(clusterMember => !!clusterMember.account);
    return sort(combined.map(clusterMember => {return {...clusterMember, key: clusterMember.account!.metadata!.name!, account: clusterMember.account!}}));
}

export default function Accounts() {
    const match = useRouteMatch();
    const {cluster} = match.params as any;
    const drawerDispatcher = useItemDrawer();
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedKeys: any) => {
            setSelectedRowKeys(selectedKeys);
        },
    };

    // get accounts
    const {loading, error, data: allAccounts, refetch} = useQuery(async () => {
        const clusterMembersResult = await client.management(Resources.ManagementV1ClusterMembers).Get(cluster);
        if (clusterMembersResult.err) {
            return clusterMembersResult;
        }
        
        const accountResult = await client.cluster(cluster, Resources.ConfigV1alpha1Account).List();
        if (accountResult.err) {
            return accountResult;
        }
        
        return Return.Value(convertClusterMembers(clusterMembersResult.val, accountResult.val.items));
    });
    return <div>
        <Table loading={loading} columns={getTableColumns(allAccounts!, refetch, drawerDispatcher, cluster)} dataSource={allAccounts} error={error} rowSelection={rowSelection} filter={filter} refetch={refetch} header={{
            top: <ClusterHeader />,
            right: <Button type={"primary"} onClick={() => {
                drawerDispatcher({
                    title: "Create Account",
                    content: <AccountDrawer mode={"create"} cluster={cluster} allAccounts={allAccounts!} refetch={refetch} />
                })
            }}>Create Account</Button>,
            selectedActions: <React.Fragment>
                <Tooltip title={"edit"}>
                    <SettingOutlined className={styles["setting-batch"]} onClick={() =>
                    {
                        const accounts: ClusterAccount[] = [];
                        for (let i = 0; i < selectedRowKeys.length; i++) {
                            const accountName = selectedRowKeys[i];
                            const account = allAccounts!.find(account => account.account?.metadata?.name === accountName);
                            if (!account) {
                                continue;
                            }

                            accounts.push(account);
                        }

                        if (accounts.length === 0) {
                            return;
                        }

                        if (accounts.length === 1) {
                            const account = accounts[0];
                            drawerDispatcher({
                                title: "Edit Account: " + account?.account?.metadata?.name!,
                                content: <AccountDrawer mode={"update"} account={account} allAccounts={allAccounts!} cluster={cluster} refetch={refetch} />
                            })
                        } else {
                            drawerDispatcher({
                                title: "Bulk Edit Selected Accounts",
                                content: <AccountDrawer mode={"batch"} accounts={accounts} allAccounts={allAccounts!} cluster={cluster} refetch={refetch} />
                            })
                        }

                        setSelectedRowKeys([]);
                    }} />
                </Tooltip>
                {
                    selectedRowKeys.length === 1 ? <AccountDeletePopup className={styles["delete-batch"]} 
                                                                       account={allAccounts!.find(acc => acc.account?.metadata?.name === selectedRowKeys[0])?.account} 
                                                                       cluster={cluster} 
                                                                       refetch={async () => {
                                                                            await refetch();
                                                                            setSelectedRowKeys([]);
                                                                        }} /> : 
                    <Tooltip title={"delete"}>
                        <DeleteOutlined className={styles["delete-batch"]} onClick={() =>
                        {
                            deleteConfirm({
                                title: `Delete ${selectedRowKeys.length} Accounts`,
                                content: <div>
                                    <div>Are you sure you want to delete the accounts {selectedRowKeys.join(", ")}?</div>
                                    <div className={"color-error"} style={{marginTop: "10px"}}>Deleting an account will also delete all spaces owned by these accounts.</div>
                                </div>,
                                onOkAsync: async () => {
                                    const message = ClientMessage.Loading(cluster);
                                    for (let i = 0; i < selectedRowKeys.length; i++) {
                                        const accountName = selectedRowKeys[i];
                                        const result = await client.cluster(cluster, Resources.ConfigV1alpha1Account).Delete(accountName as string);
                                        if (result.err) {
                                            message.Error(result);
                                            return;
                                        }
                                    }

                                    message?.DoneManagement();
                                    await refetch();
                                    setSelectedRowKeys([]);
                                }
                            });
                        }} />
                    </Tooltip>
                }
            </React.Fragment>
        }} />
    </div>
};