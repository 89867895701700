import React from "react";
import Label from "../../../../../components/Label/Label";
import {ResultError, Return} from "../../../../../lib/result";
import {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import {ManagementV1OwnedAccessKey} from "../../../../../../gen/model/managementV1OwnedAccessKey";
import {StorageV1AccessKeyScopeRule} from "../../../../../../gen/model/storageV1AccessKeyScopeRule";
import {arr, selectDefaultFilter} from "../../../../../lib/helpers/renderhelper";
import styles from "./AccessKeyScope.module.scss";
import Select from "../../../../../components/Select/Select";
import Query from "../../../../../components/Query/Query";
import client from "../../../../../lib/client";
import {Resources} from "../../../../../lib/resources";
import {ErrorMessage} from "../../../../../components/ErrorMessage/ErrorMessage";
import Loading from "../../../../../components/Loading/Loading";
import Section from "../../../../../components/Drawer/Section/Section";
import Description from "../../../../../components/Description/Description";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import {alert} from "../../../../../lib/Modal/Modal";
import {SharedCache} from "../../../../../lib/sharedcache";
import Input from "../../../../../components/Input/Input";
import FixedText from "../../../../../components/FixedText/FixedText";
import {displayName} from "../../../../../lib/helper";
import {ManagementV1Cluster} from "../../../../../../gen/model/managementV1Cluster";
const {Option} = Select;

const defaultRules = [
    {
        requestTargets: ["Management"],
        verbs: ["create"],
        resources: [
            {
                resources: ["tasks", "selves", "selfsubjectaccessreviews", "directclusterendpointtokens"],
                group: Resources.ManagementV1Self.group,
            }
        ]
    }, 
    {
        requestTargets: ["Management"],
        verbs: ["get", "list"]
    },
    {
        requestTargets: ["Cluster", "VirtualCluster"],
        nonResourceURLs: ["*"]
    },
    {
        requestTargets: ["Cluster"],
        verbs: ["get", "list"],
        resources: [
            {
                resources: [Resources.StorageV1VirtualCluster.resource],
                group: Resources.StorageV1VirtualCluster.group,
            },
            {
                resources: [Resources.ClusterV1Space.resource],
                group: Resources.ClusterV1Space.group,
            }
        ]
    },
]

interface AccessKeyScopeState {
    rules: Array<Rule>;
    selectedCluster: string | undefined;
}

interface AccessKeyScopeProps extends SectionProps {
    accessKey?: ManagementV1OwnedAccessKey;
}

interface RuleRowProps {
    clusters: Array<ManagementV1Cluster>
    
    rule: Rule;
    sharedCache: SharedCache;

    onChangeNamespace: (namespace: string | undefined) => void;
    onChangeVCluster: (vcluster: string | undefined) => void;
    onDelete: () => void;
}

function RuleRow(props: RuleRowProps) {
    const cluster = props.clusters.find(c => c.metadata?.name === props.rule.cluster);
    const clusterName = cluster ? displayName(cluster) : props.rule.cluster;
    return <div className={styles["rule-row"]}>
        <div className={styles["cluster"]}>
            <FixedText text={clusterName} maxWidth={190} />
        </div>
        <div className={styles["namespace"]}>
            <Query query={async () => props.sharedCache.load(props.rule.cluster,  async () => await client.cluster(props.rule.cluster, Resources.ClusterV1Space).List())}
                   refetch={[props.rule.cluster]}>
                {
                    result => {
                        if (result.error) {
                            return <ErrorMessage error={result.error} />;
                        }
    
                        return <Select showSearch
                                       allowClear
                                       className={styles["select"]}
                                       filterOption={selectDefaultFilter}
                                       placeholder={!props.rule.namespace ? "All namespaces" : "Select namespace"}
                                       value={props.rule.namespace}
                                       onChange={value => props.onChangeNamespace(value)}>
                            {result.data?.items.map(space => <Option key={space.metadata?.name} value={space.metadata?.name!}>{space.metadata?.name}</Option>)}
                        </Select>
                    }
                }
            </Query>
        </div>
        <div>
           <Query query={async () => props.sharedCache.load(props.rule.cluster+"/"+props.rule.namespace,  async () => await client.cluster(props.rule.cluster, Resources.ClusterV1VirtualCluster).Namespace(props.rule.namespace).List())}
                  skip={!props.rule.namespace}
                  refetch={[props.rule.cluster, props.rule.namespace]}>
                {
                    result => {
                        if (result.error) {
                            return <ErrorMessage error={result.error} />;
                        }
                        if (!props.rule.namespace) {
                            return <Input readOnly placeholder={"All virtual clusters"} />
                        }
    
                        return <Select showSearch
                                       allowClear
                                       className={styles["select"]}
                                       filterOption={selectDefaultFilter}
                                       placeholder={props.rule.vcluster ? "All virtual clusters" : "Select virtual cluster"}
                                       value={props.rule.vcluster}
                                       onChange={value => props.onChangeVCluster(value)}>
                            <Option key={"*"} value={"*"}>*</Option>
                            {result.data?.items.map(vCluster => <Option key={vCluster.metadata?.name!} value={vCluster.metadata?.name!}>{vCluster.metadata?.name!}</Option>)}
                        </Select>
                    }
                }
            </Query>         
        </div>
        <div>
            <DeleteOutlined className={styles["delete-btn"]} onClick={() => props.onDelete()} />
        </div>
    </div>
}

interface Rule {
    cluster: string;
    namespace: string | undefined;
    vcluster: string | undefined;
}

function convertRules(rules: Array<Rule>): Array<StorageV1AccessKeyScopeRule> {
    const retRules: Array<StorageV1AccessKeyScopeRule> = [];
    for (let i = 0; i < rules.length; i++) {
        const rule = rules[i];
        if (!rule.cluster) {
            continue
        }
        
        const retRule: StorageV1AccessKeyScopeRule = {
            cluster: rule.cluster
        }
        if (rule.vcluster) {
            retRule.requestTargets = ["VirtualCluster"]
            retRule.virtualClusters = [{
              name:      rule.vcluster,
              namespace: rule.namespace  
            }];
        } else if (rule.namespace) {
            retRule.requestTargets = ["Cluster"];
            retRule.namespaces = [rule.namespace];
        } else {
            retRule.requestTargets = ["VirtualCluster", "Cluster"]
        }
        
        retRules.push(retRule)
    }
    return retRules;
}

function getRulesFromAccessKey(accessKey: ManagementV1OwnedAccessKey | undefined): {rules: Array<Rule>, nonParsable: Array<StorageV1AccessKeyScopeRule>} {
    if (!accessKey) {
        return {
            rules: [],
            nonParsable: []
        }
    }
    
    const accessKeyRules = arr(accessKey.spec?.scope?.rules);
    const rules: Array<Rule> = [];
    const nonParsable: Array<StorageV1AccessKeyScopeRule> = [];
    for (let i = 0; i < accessKeyRules.length; i++) {
        const rule = accessKeyRules[i];
        if (arr(rule.namespaces).length > 1) {
            nonParsable.push(rule);
            continue;
        } else if (!rule.cluster) {
            nonParsable.push(rule);
            continue;
        } else if (arr(rule.resources).length > 0) {
            nonParsable.push(rule);
            continue;
        } else if (arr(rule.verbs).length > 0) {
            nonParsable.push(rule);
            continue;
        } else if (arr(rule.nonResourceURLs).length > 0) {
            nonParsable.push(rule);
            continue;
        } else if (arr(rule.virtualClusters).length > 1) {
            nonParsable.push(rule);
            continue;
        } else if (arr(rule.virtualClusters).length === 1 && arr(rule.namespaces).length === 1) {
            nonParsable.push(rule);
            continue;
        }

        rules.push({
            cluster: rule.cluster!,
            namespace: arr(rule.namespaces).length === 1 ? rule.namespaces![0] : arr(rule.virtualClusters).length === 1 ? rule.virtualClusters![0].namespace : undefined,
            vcluster: arr(rule.virtualClusters).length === 1 ? rule.virtualClusters![0].name : undefined,
        })
    }
    
    return {
        rules,
        nonParsable
    }
}

export default class AccessKeyScope extends React.PureComponent<AccessKeyScopeProps, AccessKeyScopeState> {
    private sharedCache: SharedCache = new SharedCache();
    
    state: AccessKeyScopeState = {
        rules: getRulesFromAccessKey(this.props.accessKey).rules,
        selectedCluster: undefined
    };

    create = (obj: ManagementV1OwnedAccessKey): ResultError => {
        if (!this.state.rules.length) {
            return Return.Ok();
        }

        const retRules = convertRules(this.state.rules);
        if (!obj.spec) {
            obj.spec = {};
        }
        if (!obj.spec.scope) {
            obj.spec.scope = {};
        }
        obj.spec.scope.rules = [...retRules, ...defaultRules];
        return Return.Ok();
    };
    
    update = (obj: ManagementV1OwnedAccessKey): ResultError => {
        const rules = getRulesFromAccessKey(obj);
        const retRules = convertRules(this.state.rules);
        if (!obj.spec) {
            obj.spec = {};
        }
        if (!obj.spec.scope) {
            obj.spec.scope = {};
        }
        if (retRules.length === 0 && rules.rules.length > 0) {
            obj.spec.scope.rules = [];
        } else {
            obj.spec.scope.rules = [...rules.nonParsable, ...retRules];
            if (rules.nonParsable.length === 0) {
                obj.spec.scope.rules.push(...defaultRules);
            }
        }
        return Return.Ok();
    }

    render() {
        if (this.props.mode === "batch") {
            return null;
        }

        return <Section title={"Limit Access Key Scope"} foldable={this.props.mode === "create"} defaultFolded={this.props.mode === "create"} noMargin={this.props.mode !== "create"}>
            <Query query={async () => await client.management(Resources.ManagementV1Cluster).List()}>
                {
                    result => {
                        if (result.error) {
                            return <ErrorMessage error={result.error} />
                        } else if (result.loading) {
                            return <Loading />
                        }
                        
                        return <div>
                            <Label>Restrict Access to Cluster:</Label>
                            <div className={styles["cluster-select-wrapper"]}>
                                <Select showSearch
                                        allowClear
                                        className={styles["cluster-select"]}
                                        filterOption={selectDefaultFilter}
                                        placeholder={"Select cluster"}
                                        value={this.state.selectedCluster}
                                        onChange={value => this.setState({selectedCluster: value})}>
                                    {result.data?.items.map(cluster => <Option key={cluster.metadata?.name!} value={cluster.metadata?.name!}>{displayName(cluster)}</Option>)}
                                </Select>
                                <PlusOutlined className={styles["plus-btn"]} onClick={async () => {
                                    if (!this.state.selectedCluster) {
                                        alert({
                                            title: `Please select a Cluster`,
                                            content: `Please select a Cluster to add a new rule`,
                                        });
                                        return;
                                    }
                                    
                                    this.setState({
                                        rules: [...this.state.rules, {
                                            cluster: this.state.selectedCluster,
                                            namespace: undefined,
                                            vcluster: undefined
                                        }]
                                    })
                                }} />
                            </div>
                            <Description>As soon as you select at least one cluster, the access key will only be able to access the specified cluster, namespaces and virtual clusters listed below.</Description>
                            {this.state.rules.length > 0 && <React.Fragment>
                                <div className={styles["rules-header"]}>
                                    <div>Cluster</div>
                                    <div>Namespace</div>
                                    <div>Virtual Cluster</div>
                                    <div>&nbsp;</div>
                                </div>
                                <div className={styles["rules-wrapper"]}>
                                    {this.state.rules.map((rule, index) => <RuleRow key={index}
                                                                                    rule={rule}
                                                                                    clusters={arr(result.data?.items)}
                                                                                    sharedCache={this.sharedCache}
                                                                                    onDelete={() => {
                                                                                        const newRules = [...this.state.rules];
                                                                                        newRules.splice(index, 1);
                                                                                        this.setState({rules: newRules});
                                                                                    }}
                                                                                    onChangeNamespace={async (namespace) => {
                                                                                        const newRules = [...this.state.rules];
                                                                                        newRules[index] = {...newRules[index], namespace: namespace, vcluster: undefined}
                                                                                        this.setState({
                                                                                            rules: newRules
                                                                                        });
                                                                                    }}
                                                                                    onChangeVCluster={vCluster => {
                                                                                        const newRules = [...this.state.rules];
                                                                                        newRules[index] = {...newRules[index], vcluster: vCluster}
                                                                                        this.setState({rules: newRules});
                                                                                    }} />)}
                                </div>
                            </React.Fragment>}
                        </div>
                    }
                }
            </Query>
        </Section>
    }
}