import React, {useState} from "react";
import {Modal} from "../../lib/Modal/Modal";
import client, {GroupVersionResource, RequestOptionsVCluster} from "../../lib/client";
import {ContainerOutlined} from "@ant-design/icons/lib";
import {Tooltip} from "../Tooltip/Tooltip";
import useQuery from "../../lib/Query/Query";
import {ErrorMessage} from "../ErrorMessage/ErrorMessage";
import * as yaml from "js-yaml";
import {arr, without} from "../../lib/helpers/renderhelper";
import ClientMessage from "../../lib/Message/ClientMessage";
import {Return} from "../../lib/result";
import YAMLEditor from "../YAMLEditor/YAMLEditor";
import jsonmergepatch from "json-merge-patch";
import Button from "../Button/Button";

const toggleFolds = [
    "  managedFields:", 
    "  labels:",
    "  annotations:",
    "  ownerReferences:",
    "status:"
];

export interface ShowYamlPopupProps<T> {
    className?: string;
    onClose: () => void;

    vCluster?: RequestOptionsVCluster;
    cluster?: string;
    canUpdate?: boolean;

    name?: string;
    namespace?: string;
    object: any;
    refetch?: () => Promise<void>;
    resource?: GroupVersionResource<T>;
    
    disableAutoFolds?: boolean;
}

function convertToYaml<T>(obj: any, resource?: GroupVersionResource<T>) {
    if (!resource) {
        return yaml.safeDump({...without(obj, ["key"])})
    }
    
    return yaml.safeDump({"apiVersion": resource.group ? resource.group + "/" + resource.version : resource.version, "kind": resource.kind, ...without(obj, ["key"])})
}

export function ShowYamlModal<T>(props: ShowYamlPopupProps<T>) {
    const [clone, setClone] = useState(false);
    const [objYaml, setObjYaml] = useState<string>(() => convertToYaml(props.object, props.resource));
    const {error, loading, data: canUpdate} = useQuery(async () => {
        if (props.canUpdate === false) {
            return Return.Value(false);
        }
        
        let request = client.auto(props.cluster, props.vCluster, props.resource!);
        if (props.namespace) {
            request = request.Namespace(props.namespace);
        }
        if (props.name) {
            request = request.Name(props.name);
        }

        return await request.CanI("update");
    });
    if (error) {
        return <Modal visible={true} hideCancel={true} onOkAsync={async () => props.onClose()} title={"An Error Occured"}>
            <ErrorMessage error={error} />
        </Modal>;
    } else if (loading) {
        return null;
    }

    return <Modal title={"Show Kubernetes YAML"} visible={true} onCancel={props.onClose} okText={!canUpdate ? "Close" : clone ? "Create": "Update"} hideCancel={!canUpdate} width={"90vw"} leftButton={canUpdate && !clone && <Button onClick={() => {
        let obj: T | undefined;
        try {
            obj = yaml.safeLoad(convertToYaml(props.object, props.resource));
        } catch(err) {
            console.error(err);
            return;
        }
        
        const objAny = obj as any;
        if (objAny?.metadata) {
            const newMeta = {
                name: objAny.metadata.name + "-cloned",
                namespace: objAny.metadata.namespace,
                labels: objAny.metadata.labels,
                annotations: objAny.metadata.annotations
            }
            if (!newMeta.namespace) {
                delete newMeta.namespace;
            }
            if (!newMeta.labels) {
                delete newMeta.labels;
            }
            if (!newMeta.annotations) {
                delete newMeta.annotations;
            }
            objAny.metadata = newMeta
        }
        if (objAny?.status) {
            delete objAny.status;
        }
        
        setObjYaml(convertToYaml(objAny, props.resource));
        setClone(true);
    }}>Clone</Button>} onOkAsync={!canUpdate ? async () => props.onClose() : async () => {
        const message = ClientMessage.Loading(props.cluster, props.vCluster);

        let obj: T | undefined;
        try {
            obj = yaml.safeLoad(objYaml);
        } catch(err) {
            message.ErrorAuto(Return.Failed(err + ""), props.cluster, props.vCluster);
            return;
        }

        let request = client.auto(props.cluster, props.vCluster, props.resource!);
        if ((obj as any).metadata?.namespace || props.namespace) {
            request = request.Namespace((obj as any).metadata?.namespace || props.namespace);
        }
        
        if (!clone) {
            // create a patch
            const patch = jsonmergepatch.generate(props.object, obj!);
            const updateResult = await request.Patch(props.name!, patch);
            if (updateResult.err) {
                message.ErrorAuto(updateResult, props.cluster, props.vCluster);
                return;
            }
        } else {
            // create the object
            const createResult = await request.Create(obj!);
            if (createResult.err) {
                message.ErrorAuto(createResult, props.cluster, props.vCluster);
                return;
            }
        }

        if (props.refetch) {
            await props.refetch();
        }

        message.DoneAuto(props.cluster, props.vCluster);
        props.onClose();
    }}>
        <YAMLEditor readOnly={!canUpdate} 
                    value={objYaml} 
                    minLines={10}
                    maxLines={5000}
                    onLoad={editor => {
                        if (!props.disableAutoFolds) {
                            (editor.session as any).foldAll(undefined, undefined, undefined, (row: any) => {
                                return toggleFolds.includes(editor.session.getDocument().getLine(row));
                            });
                        }
                    }}
                    onChange={v => setObjYaml(v)} />
    </Modal>
}

export default function ShowYamlPopup<T>(props: Omit<ShowYamlPopupProps<T>, "onClose">) {
    const [visible, setVisible] = useState<boolean>(false);

    return <React.Fragment>
        <Tooltip title={"show yaml"}>
            <ContainerOutlined className={props.className} onClick={() => setVisible(true)} />
        </Tooltip>
        {visible && <ShowYamlModal {...props} onClose={() => setVisible(false)} />}
    </React.Fragment>
}
