import React, {useRef} from "react";
import ItemDrawer, {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import {useDrawerDispatcher} from "../../../../../contexts/drawer/DrawerContext";
import ClientMessage from "../../../../../lib/Message/ClientMessage";
import {Resources} from "../../../../../lib/resources";
import {ResultError, Return} from "../../../../../lib/result";
import Metadata from "../../../../../components/Drawer/Sections/Metadata/Metadata";
import {genericOnBatch, genericOnCreate, genericOnUpdate} from "../../../../../lib/helpers/drawerhelper";
import {arr} from "../../../../../lib/helpers/renderhelper";
import client from "../../../../../lib/client";
import {ClusterV1LocalClusterAccess} from "../../../../../../gen/model/clusterV1LocalClusterAccess";
import UserTeamSelector from "../../../ClusterAccess/ClusterAccessDrawer/Sections/UserTeamSelector";
import ClusterAccessConfig
    from "../../../ClusterAccess/ClusterAccessDrawer/Sections/ClusterAccessConfig";
import constants from "../../../../../constants/constants";

export interface ClusterAccessDrawerProps extends SectionProps {
    cluster: string;
    
    clusterAccess?: ClusterV1LocalClusterAccess;
    clusterAccesses?: ClusterV1LocalClusterAccess[];

    refetch: () => Promise<void>;
}

export default function ClusterAccessDrawer(props: ClusterAccessDrawerProps) {
    const drawer = useDrawerDispatcher();
    const metadataRef = useRef<Metadata>(null);
    const userTeamSelectorRef = useRef<UserTeamSelector>(null);
    const clusterAccessConfigRef = useRef<ClusterAccessConfig>(null);

    return <ItemDrawer okButtonText={props.mode === "create" ? "Create" : "Update"} onOkAsync={async () => {
        const message = ClientMessage.Loading();

        // execute the create / update / batch logic
        let result: ResultError | undefined = undefined;
        if (props.mode === "create") {
            result = await genericOnCreate({
                resource: Resources.ClusterV1LocalClusterAccess,
                cluster: props.cluster,
                sections: [
                    metadataRef.current!.create,
                    userTeamSelectorRef.current!.create,
                    clusterAccessConfigRef.current!.create,
                ],
                defaultData: {
                    spec: {}
                }
            });
        } else if (props.mode === "update") {
            result = await genericOnUpdate({
                existing: props.clusterAccess!,
                cluster: props.cluster,
                resource: Resources.ClusterV1LocalClusterAccess,
                sections: [
                    metadataRef.current!.update,
                    userTeamSelectorRef.current!.update,
                    clusterAccessConfigRef.current!.update,
                ],
            });
        } else if (props.mode === "batch") {
            result = await genericOnBatch({
                existings: arr(props.clusterAccesses),
                cluster: props.cluster,
                resource: Resources.ClusterV1LocalClusterAccess,
                sections: [
                    metadataRef.current!.batch,
                ],
            });
        }

        // check if there was an error
        if (result?.err) {
            message.ErrorCluster(result, props.cluster);
            return;
        }

        // refetch
        await props.refetch();
        message.DoneCluster(props.cluster);

        // close drawer
        drawer({});
    }}>
        <Metadata mode={props.mode} type={"Cluster Access"} obj={props.clusterAccess} showDisplayName={true} showDescription={true} noMargin ref={metadataRef} />
        <UserTeamSelector mode={props.mode} obj={props.clusterAccess} ref={userTeamSelectorRef} />
        <ClusterAccessConfig mode={props.mode} 
                             obj={props.clusterAccess}
                             spaceConstraintsQuery={async () => {
                                 const result = await client.cluster(props.cluster, Resources.StorageV1LocalSpaceConstraint).List();
                                 if (result.err) {
                                     return result;
                                 }

                                 return Return.Value(arr(result.val.items));
                             }}
                             clusterRoleQuery={async () => {
                                const result = await client.cluster(props.cluster, Resources.V1ClusterRole).List({labelSelector: constants.LoftClusterRoleCluster+"=true"});
                                if (result.err) {
                                    return result;
                                }
                    
                                return Return.Value(arr(result.val.items));
                             }} 
                             ref={clusterAccessConfigRef} />
    </ItemDrawer>
}