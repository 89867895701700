import React, {useRef} from "react";
import ItemDrawer, {SectionProps} from "../../../../components/Drawer/ItemDrawer";
import {useDrawerDispatcher} from "../../../../contexts/drawer/DrawerContext";
import ClientMessage from "../../../../lib/Message/ClientMessage";
import {Resources} from "../../../../lib/resources";
import {ResultError, Return} from "../../../../lib/result";
import Access from "../../../../components/Drawer/Sections/Access/Access";
import {useUser} from "../../../../contexts/UserContext/UserContext";
import Metadata from "../../../../components/Drawer/Sections/Metadata/Metadata";
import Clusters from "../../../../components/Drawer/Sections/Clusters/Clusters";
import {ManagementV1ClusterAccess} from "../../../../../gen/model/managementV1ClusterAccess";
import {genericOnBatch, genericOnCreate, genericOnUpdate} from "../../../../lib/helpers/drawerhelper";
import UserTeamSelector from "./Sections/UserTeamSelector";
import ClusterAccessConfig from "./Sections/ClusterAccessConfig";
import {arr} from "../../../../lib/helpers/renderhelper";
import client from "../../../../lib/client";

const defaultVerbMappingRoles = [
    {
        key: "*",
        text: "All"
    },
    {
        key: "create",
        text: "create"
    },
    {
        key: "get",
        text: "view"
    },
    {
        key: "update",
        text: "update"
    },
    {
        key: "delete",
        text: "delete"
    },
    {
        key: "bind",
        text: "bind"
    },
]

export interface ClusterAccessDrawerProps extends SectionProps {
    clusterAccess?: ManagementV1ClusterAccess;
    clusterAccesses?: ManagementV1ClusterAccess[];

    refetch: () => Promise<void>;
}

export default function ClusterAccessDrawer(props: ClusterAccessDrawerProps) {
    const drawer = useDrawerDispatcher();
    const user = useUser();
    const metadataRef = useRef<Metadata>(null);
    const accessRef = useRef<Access>(null);
    const clustersRef = useRef<Clusters>(null);
    const userTeamSelectorRef = useRef<UserTeamSelector>(null);
    const clusterAccessConfigRef = useRef<ClusterAccessConfig>(null);

    return <ItemDrawer okButtonText={props.mode === "create" ? "Create" : "Update"} onOkAsync={async () => {
        const message = ClientMessage.Loading();

        // execute the create / update / batch logic
        let result: ResultError | undefined = undefined;
        if (props.mode === "create") {
            result = await genericOnCreate({
                resource: Resources.ManagementV1ClusterAccess,
                sections: [
                    metadataRef.current!.create,
                    accessRef!.current!.create,
                    clustersRef!.current!.create,
                    async (obj) => userTeamSelectorRef.current!.create(obj.spec!.localClusterAccessTemplate!),
                    async (obj) => clusterAccessConfigRef.current!.create(obj.spec!.localClusterAccessTemplate!),
                ],
                defaultData: {
                    spec: {
                        localClusterAccessTemplate: {}
                    }
                }
            });
        } else if (props.mode === "update") {
            result = await genericOnUpdate({
                existing: props.clusterAccess!,
                resource: Resources.ManagementV1ClusterAccess,
                sections: [
                    metadataRef.current!.update,
                    accessRef!.current!.update,
                    clustersRef!.current!.update,
                    async (obj) => userTeamSelectorRef.current!.update(obj.spec!.localClusterAccessTemplate!),
                    async (obj) => clusterAccessConfigRef.current!.update(obj.spec!.localClusterAccessTemplate!),
                ],
            });
        } else if (props.mode === "batch") {
            result = await genericOnBatch({
                existings: arr(props.clusterAccesses),
                resource: Resources.ManagementV1ClusterAccess,
                sections: [
                    metadataRef.current!.batch,
                    accessRef!.current!.batch,
                ],
            });
        }

        // check if there was an error
        if (result?.err) {
            message.ErrorManagement(result);
            return;
        }

        // refetch
        await props.refetch();
        message.DoneManagement();

        // close drawer
        drawer({});
    }}>
        <Metadata mode={props.mode} type={"Cluster Access"} obj={props.clusterAccess} showDisplayName={true} showDescription={true} noMargin ref={metadataRef} />
        <UserTeamSelector mode={props.mode} obj={props.clusterAccess?.spec?.localClusterAccessTemplate} ref={userTeamSelectorRef} />
        <Clusters description={"The clusters that the users or teams selected above should get access to."} obj={props.clusterAccess} mode={props.mode} ref={clustersRef} />
        <ClusterAccessConfig mode={props.mode} 
                             obj={props.clusterAccess?.spec?.localClusterAccessTemplate}
                             spaceConstraintsQuery={async () => {
                                 const result = await client.management(Resources.ManagementV1SpaceConstraint).List();
                                 if (result.err) {
                                     return result;
                                 }

                                 return Return.Value(arr(result.val.items));
                             }}
                             clusterRoleQuery={async () => {
                                const result = await client.management(Resources.ManagementV1ClusterRoleTemplate).List();
                                if (result.err) {
                                    return result;
                                }
                                
                                return Return.Value(arr(result.val.items).filter(clusterRole => !clusterRole.spec?.management));
                             }} 
                             ref={clusterAccessConfigRef} />
        <Access mode={props.mode} kind={"Cluster Access"} user={user} allowedVerbs={defaultVerbMappingRoles} access={props.clusterAccess} ref={accessRef} />
    </ItemDrawer>
}