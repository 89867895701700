import React from "react";
import Label from "../../../../../components/Label/Label";
import {ResultError, Return} from "../../../../../lib/result";
import {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import Input from "../../../../../components/Input/Input";
import {ManagementV1UserProfile} from "../../../../../../gen/model/managementV1UserProfile";

interface EmailAddressState {
    emailAddress: string | undefined;
}

interface EmailAddressProps extends SectionProps {
    emailAddress: string | undefined;
    show: boolean;
}

export default class EmailAddress extends React.PureComponent<EmailAddressProps, EmailAddressState> {
    state: EmailAddressState = {
        emailAddress: this.props.emailAddress
    };

    create = (obj: ManagementV1UserProfile): ResultError => {
        if (!this.props.show) {
            return Return.Ok();
        }

        obj.email = this.state.emailAddress;
        return Return.Ok();
    };

    render() {
        if (this.props.mode !== "create" || !this.props.show) {
            return null;
        }

        return <div>
            <Label>Email Address</Label>
            <Input onChange={e => this.setState({emailAddress: e.target.value})} placeholder={"name@company.tld"} value={this.state.emailAddress} />
        </div>
    }
}