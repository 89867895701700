import React, {useState} from "react";
import {Tooltip} from "../../../../components/Tooltip/Tooltip";
import {DeleteOutlined} from "@ant-design/icons/lib";
import styles from "./VClusterDeletePopup.module.scss";
import {DeleteModal, Modal} from "../../../../lib/Modal/Modal";
import client from "../../../../lib/client";
import {Resources} from "../../../../lib/resources";
import ClientMessage from "../../../../../src/lib/Message/ClientMessage";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import {ClusterV1VirtualCluster} from "../../../../../gen/model/clusterV1VirtualCluster";
import {ClusterObject} from "../../../Spaces/Spaces/Spaces";

interface Props {
    vCluster: ClusterObject<ClusterV1VirtualCluster>;
    refetch: () => Promise<void>;
}

export default function VClusterDeletePopup(props: Props) {
    const [visible, setVisible] = useState<boolean>(false);
    const [deleteNamespace, setDeleteNamespace] = useState<boolean>(true);
    const vCluster = props.vCluster

    return <React.Fragment>
        <Tooltip title="delete">
            <DeleteOutlined className={styles["delete"]} onClick={() => setVisible(true)} />
        </Tooltip>
        <DeleteModal title={`Delete Virtual Cluster: ${vCluster.object?.metadata?.name}`} onCancel={() => setVisible(false)} visible={visible} onOkAsync={async () => {
          const message = ClientMessage.Loading(vCluster.cluster);
          const result = await client.cluster(vCluster.cluster!, Resources.StorageV1VirtualCluster).Namespace(vCluster.object?.metadata?.namespace).Delete(vCluster.object?.metadata?.name!);

          if (deleteNamespace && result.ok && vCluster.object?.metadata?.namespace) {
            const namespaceResult = await client.cluster(vCluster.cluster!, Resources.ClusterV1Space).Delete(vCluster.object?.metadata?.namespace);
            message.Result(namespaceResult);
          } else {
            message.Result(result);
          }
          
          await props.refetch();
          setVisible(false);
        }}>
            <div>
              Are you sure you want to delete the virtual cluster {vCluster.object?.metadata?.name}?
            </div>
            <div className={styles["checkbox"]}>
                <Checkbox onChange={e => setDeleteNamespace(e.target.checked)} checked={deleteNamespace}>Delete namespace {props.vCluster.object?.metadata?.namespace} as well</Checkbox>
            </div>
        </DeleteModal>
    </React.Fragment>
}