import React from "react";
import Label from "../../../../../components/Label/Label";
import {ResultError, Return} from "../../../../../lib/result";
import {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import Input from "../../../../../components/Input/Input";
import {ManagementV1OwnedAccessKey} from "../../../../../../gen/model/managementV1OwnedAccessKey";
import {ManagementV1User} from "../../../../../../gen/model/managementV1User";

interface AccessKeyState {
    name: string | undefined;
}

interface AccessKeyProps extends SectionProps {

}

export default class AccessKeyName extends React.PureComponent<AccessKeyProps, AccessKeyState> {
    state: AccessKeyState = {
        name: undefined,
    };

    create = (obj: ManagementV1OwnedAccessKey, user?: string, team?: string): ResultError => {
        if (!this.state.name) {
            return Return.Failed("No access key name specified");
        }
        
        if (!obj.spec) {
            obj.spec = {}
        }
        obj.spec = {
            user: user!,
            team: team!,
            displayName: this.state.name,
        } 

        return Return.Ok();
    };

    render() {
        if (this.props.mode !== "create") {
            return null;
        }

        return <div>
            <Label>Enter a display name for this access key</Label>
            <Input onChange={e => this.setState({name: e.target.value})} placeholder={"CI/CD Staging"} value={this.state.name} />
        </div>
    }
}