import React from "react";
import RolesTable from "./RolesTable/RolesTable";
import UserHeader from "../UserHeader/UserHeader";
import Description from "../../../components/Description/Description";

interface Props {}

function ManagementRoles(props: Props) {
    return <RolesTable top={<UserHeader>
        <Description.Table><a href={"https://kubernetes.io/docs/reference/access-authn-authz/rbac/#role-and-clusterrole"} target={"_blank"}>Kubernetes RBAC cluster roles</a> that are available in the Loft management instance. These cluster roles can be assigned to users or teams to give them certain permissions to manage objects in the Loft management instance themselves.</Description.Table>
    </UserHeader>} management={true} />
}

export default ManagementRoles;
