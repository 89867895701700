import React from "react";
import {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import {
    convertToMap,
    parseMap
} from "../../../../../components/Drawer/Sections/Metadata/LabelsAnnotationsSection";
import {NewResource, Resources} from "../../../../../lib/resources";
import {arr} from "../../../../../lib/helpers/renderhelper";
import Label from "../../../../../components/Label/Label";
import Description from "../../../../../components/Description/Description";
import TextArea from "../../../../../components/TextArea/TextArea";
import {ResultError, Return} from "../../../../../lib/result";
import {ManagementV1ClusterAccountTemplate} from "../../../../../../gen/model/managementV1ClusterAccountTemplate";
import SectionExpander from "../../../../../components/Drawer/SectionExpander/SectionExpander";

interface AccountQuotaState {
    quotaLimits?: string | undefined;
    quotaLimitsChanged: boolean;
}

interface AccountQuotaProps extends SectionProps {
    clusterAccountTemplate?: ManagementV1ClusterAccountTemplate;
}

export function isAccountQuota(resource?: any): boolean {
    return resource && resource["apiVersion"] === "config.kiosk.sh/v1alpha1" && resource["kind"] === "AccountQuota";
}

function getQuota(clusterAccountTemplate: ManagementV1ClusterAccountTemplate | undefined): any | undefined {
    if (!clusterAccountTemplate) {
        return undefined;
    }

    const resources = arr(clusterAccountTemplate.spec?.owns);
    for (let i = 0; i < resources.length; i++) {
        const resource = resources[i] as {[a: string]: any};
        if (isAccountQuota(resource)) {
            return resource;
        }
    }

    return undefined;
}

function setQuota(clusterAccountTemplate: ManagementV1ClusterAccountTemplate | undefined, accountQuota: object | undefined) {
    if (!clusterAccountTemplate) {
        return;
    }
    if (!accountQuota && !clusterAccountTemplate.spec?.owns) {
        return;
    }
    if (!clusterAccountTemplate.spec) {
        clusterAccountTemplate.spec = {};
    }
    if (!clusterAccountTemplate.spec.owns) {
        clusterAccountTemplate.spec.owns = [];
    }

    for (let i = 0; i < clusterAccountTemplate.spec.owns.length; i++) {
        const resource = clusterAccountTemplate.spec.owns[i] as {[a: string]: any};
        if (isAccountQuota(resource)) {
            if (!accountQuota) {
                clusterAccountTemplate.spec.owns.splice(i, 1);
                i--;
            } else {
                clusterAccountTemplate.spec.owns[i] = accountQuota;
            }

            return;
        }
    }

    if (accountQuota) {
        clusterAccountTemplate.spec.owns.push(accountQuota);
    }
}

export default class AccountQuota extends React.PureComponent<AccountQuotaProps, AccountQuotaState> {
    state: AccountQuotaState = {
        quotaLimits: this.props.clusterAccountTemplate ? parseMap(getQuota(this.props.clusterAccountTemplate)?.spec?.quota?.hard) : "",
        quotaLimitsChanged: false,
    };

    create = async (clusterAccountTemplate: ManagementV1ClusterAccountTemplate): Promise<ResultError> => {
        if (this.state.quotaLimits?.trim()) {
            // convert to map
            const mapResult = convertToMap(this.state.quotaLimits);
            if (mapResult.err) {
                return mapResult;
            }

            // set account as owner
            const accountQuota = {
                "apiVersion": "config.kiosk.sh/v1alpha1",
                "kind": "AccountQuota",
                "metadata": {},
                "spec": {
                    account: "${LOFT_ACCOUNT_NAME}",
                    quota: {
                        hard: mapResult.val
                    }
                }
            }

            // add account quota
            setQuota(clusterAccountTemplate, accountQuota);
        } else {
            setQuota(clusterAccountTemplate, undefined);
        }

        return Return.Ok();
    };

    update = async (clusterAccountTemplate: ManagementV1ClusterAccountTemplate): Promise<ResultError> => {
        if (!this.state.quotaLimitsChanged) {
            return Return.Ok();
        }

        // delete quota if there are no limits
        if (this.state.quotaLimitsChanged && !this.state.quotaLimits?.trim()) {
            setQuota(clusterAccountTemplate, undefined);
            return Return.Ok();
        }

        // update quota
        let existingQuota = getQuota(clusterAccountTemplate);
        if (!existingQuota) {
            existingQuota = {
                "apiVersion": "config.kiosk.sh/v1alpha1",
                "kind": "AccountQuota",
                "metadata": {}
            }
            existingQuota.spec = {
                account: "${LOFT_ACCOUNT_NAME}",
            };
        }
        if (this.state.quotaLimitsChanged) {
            // convert to map
            const mapResult = convertToMap(this.state.quotaLimits);
            if (mapResult.err) {
                return mapResult;
            }

            existingQuota.spec!.quota = {
                hard: mapResult.val
            };
        }

        // update quota
        setQuota(clusterAccountTemplate, existingQuota)
        return Return.Ok();
    };

    render() {
        if (this.props.mode === "batch") {
            return null;
        }

        return <SectionExpander name={"Account Quota"}>
            <div>
                <Label>Account Quota</Label>
                <TextArea resetable={this.props.mode !== "create"}
                          value={this.state.quotaLimits}
                          style={{minHeight: "98px"}}
                          onChangedStatus={(changed) => this.setState({quotaLimitsChanged: changed})}
                          onChange={(e) => this.setState({quotaLimits: e.target.value})}
                          placeholder={`limits.cpu: 2
limits.memory: 4Gi
requests.cpu: 1
requests.memory: 1Gi`}
                />
                <Description>The sum of all resources from all spaces the account owns have to be below the limits defined here.</Description>
            </div>
        </SectionExpander>
    }
}