import React, {useState} from "react";
import useQuery from "../../../../lib/Query/Query";
import client from "../../../../lib/client";
import {Resources} from "../../../../lib/resources";
import Label from "../../../Label/Label";
import Select from "../../../Select/Select";
import {selectDefaultFilter} from "../../../../lib/helpers/renderhelper";
import {displayName} from "../../../../lib/helper";
import Description from "../../../Description/Description";
import {ErrorMessage} from "../../../ErrorMessage/ErrorMessage";
import {useUser} from "../../../../contexts/UserContext/UserContext";
import {StorageV1Target} from "../../../../../gen/model/storageV1Target";
import {StorageV1TargetVirtualCluster} from "../../../../../gen/model/storageV1TargetVirtualCluster";
import {SectionProps} from "../../ItemDrawer";
import {ManagementV1Task} from "../../../../../gen/model/managementV1Task";
import {ResultError, Return} from "../../../../lib/result";
const { Option } = Select;

const managementKey = ":management:";

interface SelectTargetProps {
    onChange: (value: StorageV1Target | undefined) => void;
}

export function SelectTarget(props: SelectTargetProps) {
    const user = useUser();
    const [target, setTarget] = useState<string | undefined>(managementKey);
    const [vCluster, setVCluster] = useState<StorageV1TargetVirtualCluster | undefined>(undefined);
    const {loading, error, data} = useQuery(async () => client.management(Resources.ManagementV1UserClusters).Get(user?.metadata?.name!));
    const {loading: vClusterLoading, error: vClusterError, data: vClusterData} = useQuery(async () => client.cluster(target!, Resources.ClusterV1VirtualCluster).List(), {skip: !target || target === managementKey, refetch: [target]});
    if (error && error.err) {
        return <ErrorMessage error={error} />
    }

    return <React.Fragment>
        <Label>Target</Label>
        <Select
            showSearch
            loading={loading}
            style={{ width: "100%" }}
            placeholder="Select a target"
            value={target}
            onChange={(v) => {
                setTarget(v);
                setVCluster(undefined);
                props.onChange(v === managementKey ? {} : {
                    cluster: {
                        cluster: v,
                    }
                });
            }}
            optionFilterProp="children"
            filterOption={selectDefaultFilter}
        >
            <Option key={managementKey} value={managementKey}>Management API</Option>
            {data ? data.clusters?.map(cluster => <Option key={cluster.cluster?.metadata?.name!} value={cluster.cluster?.metadata?.name!}>{displayName(cluster.cluster)} (Connected Cluster)</Option>) : undefined}
        </Select>
        <Description>Select a target to apply the manifests in.</Description>
        {target && target !== managementKey && <React.Fragment>
            <Label>Optional Virtual Cluster</Label>
            <Select
                showSearch
                loading={vClusterLoading}
                allowClear={true}
                style={{ width: "100%" }}
                placeholder="Optional Virtual Cluster"
                value={vCluster ? vCluster.namespace + "/" + vCluster.name : undefined}
                onChange={(v) => {
                    if (!v) {
                        setVCluster(undefined);
                        props.onChange({
                            cluster: {
                                cluster: target,
                            }
                        });
                    } else {
                        const splitted = v.split("/")
                        setVCluster({
                            name: splitted[1],
                            namespace: splitted[0],
                            cluster: target,
                        })
                        props.onChange({
                            virtualCluster: {
                                name: splitted[1],
                                namespace: splitted[0],
                                cluster: target,
                            }
                        });
                    }
                }}
                optionFilterProp="children"
                filterOption={selectDefaultFilter}
            >
                {vClusterData ? vClusterData.items?.map(vCluster => {
                    const key = vCluster.metadata?.namespace + "/" + vCluster.metadata?.name;
                    return <Option key={key} value={key}>{vCluster.metadata?.name} (Namespace: {vCluster.metadata?.namespace})</Option>;
                }) : undefined}
            </Select>
            <Description>If the manifests should be created in a virtual cluster.</Description>
        </React.Fragment>}
    </React.Fragment>
}

interface TargetSelectorState {
    selectedTarget: StorageV1Target | undefined;
}

interface TargetSelectorProps extends SectionProps {

}

export default class TargetSelector extends React.PureComponent<TargetSelectorProps, TargetSelectorState> {
    state: TargetSelectorState = {
        selectedTarget: {},
    };

    create = (obj: ManagementV1Task): ResultError => {
        if (!obj.spec) {
            obj.spec = {};
        } 
        
        obj.spec.target = this.state.selectedTarget;
        return Return.Ok();
    };

    render() {
        if (this.props.mode !== "create") {
            return null;
        }

        return <div>
            <SelectTarget onChange={target => this.setState({selectedTarget: target})} />
        </div>
    }
}