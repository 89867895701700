import React from "react";
import client from "../../../lib/client";
import {Resources} from "../../../lib/resources";
import DynamicTime from "../../../components/DynamicTime/DynamicTime";
import Table, {TableActions} from "../../../components/Table/Table";
import useQuery from "../../../lib/Query/Query";
import styles from "./ClusterAccountTemplates.module.scss";
import {DeleteOutlined, SettingOutlined} from "@ant-design/icons/lib";
import {deleteConfirm} from "../../../lib/Modal/Modal";
import ClientMessage from "../../../lib/Message/ClientMessage";
import {Button} from "antd";
import {DrawerDispatch, useItemDrawer} from "../../../contexts/drawer/DrawerContext";
import {arr} from "../../../lib/helpers/renderhelper";
import {Tooltip} from "../../../components/Tooltip/Tooltip";
import {creationTimestampSorter} from "../../../lib/helpers/sorthelper";
import ShowYamlPopup from "../../../components/ShowYamlPopup/ShowYamlPopup";
import UserHeader from "../UserHeader/UserHeader";
import {ManagementV1ClusterAccountTemplate} from "../../../../gen/model/managementV1ClusterAccountTemplate";
import ClusterAccountTemplateDrawer from "./ClusterAccountTemplateDrawer/ClusterAccountTemplateDrawer";
import {convertLabelSelectorToString} from "./ClusterAccountTemplateDrawer/Sections/ClusterSelector";
import Query from "../../../components/Query/Query";
import {Item} from "../../../components/Item/Item";
import {ErrorTypeForbidden} from "../../../lib/result";
import FixedText from "../../../components/FixedText/FixedText";

function getTableColumns(refetch: () => Promise<void>, drawerDispatcher: DrawerDispatch) {
    const editClusterAccountTemplate = (clusterAccountTemplate: ManagementV1ClusterAccountTemplate) => {
        drawerDispatcher({
            title: "Edit Cluster Account Template: " + clusterAccountTemplate?.metadata?.name!,
            content: <ClusterAccountTemplateDrawer mode={"update"} clusterAccountTemplate={clusterAccountTemplate} refetch={refetch} />
        })
    };

    return [
        {
            title: "Template Name",
            render: (clusterAccountTemplate: ManagementV1ClusterAccountTemplate) => {
                return <span className={styles["clickable"]} onClick={() => editClusterAccountTemplate(clusterAccountTemplate)}>{clusterAccountTemplate.metadata?.name}</span>
            }
        },
        {
            title: 'Selected Clusters',
            render: (clusterAccountTemplate: ManagementV1ClusterAccountTemplate) => {
                const labelSelector = convertLabelSelectorToString(clusterAccountTemplate.spec?.clusterSelector);
                if (!labelSelector) {
                    if (!clusterAccountTemplate.spec?.clusterSelector) {
                        if (arr(clusterAccountTemplate.spec?.clusters).length === 0) {
                            return <span>None</span>;
                        }
                        
                        return <span>{arr(clusterAccountTemplate.spec?.clusters).map(cluster => <Item key={cluster}><FixedText text={cluster === "*" ? "All" : cluster} /></Item>)}</span> 
                    }
                    
                    return <Item><span>All</span></Item>;
                }

                return <Query query={async () => await client.management(Resources.ManagementV1Cluster).List({labelSelector})}>
                    {
                        result => {
                            if (result.error) {
                                return <span className={"color-warning"}>Error: {result.error.val?.message}</span>;
                            } else if (result.loading) {
                                return null;
                            }
                            if (arr(result.data?.items).length === 0) {
                                return <span>None</span>;
                            }

                            return <span>{arr(result.data?.items).map(cluster => <Item key={cluster.metadata?.name}><FixedText text={cluster.metadata?.name} /></Item>)}</span>
                        }
                    }
                </Query>;
            }
        },
        {
            title: 'Created',
            width: "180px",
            sorter: (a: ManagementV1ClusterAccountTemplate, b: ManagementV1ClusterAccountTemplate) => creationTimestampSorter(a, b),
            render: (clusterAccountTemplate: ManagementV1ClusterAccountTemplate) => {
                return <DynamicTime timestamp={clusterAccountTemplate.metadata?.creationTimestamp} useTooltip={true}/>
            }
        },
        {
            title: 'Actions',
            width: "180px",
            render: (clusterAccountTemplate: ManagementV1ClusterAccountTemplate) => {
                return <TableActions className={styles["actions"]}>
                    <Tooltip title="edit">
                        <SettingOutlined className={styles["setting"]} onClick={() => editClusterAccountTemplate(clusterAccountTemplate)} />
                    </Tooltip>
                    <ShowYamlPopup className={styles["setting"]} object={clusterAccountTemplate} resource={Resources.ManagementV1ClusterAccountTemplate} name={clusterAccountTemplate.metadata?.name!} refetch={refetch} />
                    <Tooltip title="delete">
                        <DeleteOutlined className={styles["delete"]} onClick={() => {
                            deleteConfirm({
                                title: `Delete Cluster Account Template: ${clusterAccountTemplate?.metadata?.name}`,
                                content: `Are you sure you want to delete the cluster account template ${clusterAccountTemplate?.metadata?.name}? This action CANNOT be reverted!`,
                                onOkAsync: async () => {
                                    const message = ClientMessage.Loading();
                                    const result = await client.management(Resources.ManagementV1ClusterAccountTemplate).Delete(clusterAccountTemplate.metadata?.name!);
                                    message.Result(result);
                                    await refetch();
                                },
                            });
                        }} />
                    </Tooltip>
                </TableActions>;
            }
        },
    ];
}

function filter(item: ManagementV1ClusterAccountTemplate, value: string) {
    return !!(item.metadata?.name?.includes(value));
}

function ClusterAccountTemplates() {
    const drawerDispatcher = useItemDrawer();
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
    const {loading, error, data, refetch} = useQuery(async () => await client.management(Resources.ManagementV1ClusterAccountTemplate).List());
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedKeys: any) => {
            setSelectedRowKeys(selectedKeys);
        },
    };
    if (error && error.val?.type === ErrorTypeForbidden) {
        return <div className={styles["admin-wrapper"]}>
            <UserHeader />
            <div>
                Sorry, seems like you have no access to view the cluster account templates.
            </div>
        </div>
    }

    return <div>
        <Table loading={loading} columns={getTableColumns(refetch, drawerDispatcher)} dataSource={data ? arr(data.items).map(clusterAccountTemplate => { return {...clusterAccountTemplate, key: clusterAccountTemplate.metadata!.name!}}) : undefined} error={error} rowSelection={rowSelection} filter={filter} refetch={refetch} header={{
            top: <UserHeader />,
            right: <Button type={"primary"} onClick={() => {
                drawerDispatcher({
                    title: "Add Cluster Account Template",
                    content: <ClusterAccountTemplateDrawer mode={"create"} refetch={refetch} />
                });
            }}>Add Cluster Account Template</Button>,
            selectedActions: <React.Fragment>
                <Tooltip title={"edit"}>
                    <SettingOutlined className={styles["setting-batch"]} onClick={() =>
                    {
                        const clusterAccountTemplates: ManagementV1ClusterAccountTemplate[] = [];
                        for (let i = 0; i < selectedRowKeys.length; i++) {
                            const clusterAccountTemplateName = selectedRowKeys[i];
                            const clusterAccountTemplate = data!.items!.find(clusterAccountTemplate => clusterAccountTemplate.metadata?.name === clusterAccountTemplateName);
                            if (!clusterAccountTemplate) {
                                continue;
                            }

                            clusterAccountTemplates.push(clusterAccountTemplate);
                        }

                        if (clusterAccountTemplates.length === 0) {
                            return;
                        }

                        if (clusterAccountTemplates.length === 1) {
                            const clusterAccountTemplate = clusterAccountTemplates[0];
                            drawerDispatcher({
                                title: "Edit Cluster Account Template: " + clusterAccountTemplate?.metadata?.name!,
                                content: <ClusterAccountTemplateDrawer mode={"update"} clusterAccountTemplate={clusterAccountTemplate} refetch={refetch} />
                            })
                        } else {
                            drawerDispatcher({
                                title: "Bulk Edit Selected Cluster Account Templates",
                                content: <ClusterAccountTemplateDrawer mode={"batch"} clusterAccountTemplates={clusterAccountTemplates} refetch={refetch} />
                            })
                        }

                        setSelectedRowKeys([]);
                    }} />
                </Tooltip>
                <Tooltip title={"delete"}>
                    <DeleteOutlined className={styles["delete-batch"]} onClick={() =>
                    {
                        deleteConfirm({
                            title: `Delete Cluster Account Templates`,
                            content: `Are you sure you want to delete the cluster account templates ${selectedRowKeys.join(", ")}? This action CANNOT be reverted!`,
                            onOkAsync: async () => {
                                const message = ClientMessage.Loading();
                                for (let i = 0; i < selectedRowKeys.length; i++) {
                                    const clusterAccountTemplateName = selectedRowKeys[i];
                                    const result = await client.management(Resources.ManagementV1ClusterAccountTemplate).Delete(clusterAccountTemplateName as string);
                                    if (result.err) {
                                        message.Error(result);
                                        return;
                                    }
                                }

                                message?.DoneManagement();
                                await refetch();
                                setSelectedRowKeys([]);
                            }
                        });
                    }} />
                </Tooltip>
            </React.Fragment>
        }} />
    </div>
}

export default ClusterAccountTemplates;