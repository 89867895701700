import React from "react";
import ActivityTable from "../../../Audit/Activity/ActivityTable";
import {useRouteMatch} from "react-router-dom";
import SpacesHeader from "../SpacesHeader/SpacesHeader";
import Description from "../../../../components/Description/Description";

export default function SpaceActivity() {
    const match: any = useRouteMatch();
    const {cluster, space} = match.params as any;
    return <ActivityTable top={<SpacesHeader>
        <Description.Table>Shows the user and team activity inside this space depending on your audit configuration.</Description.Table>
    </SpacesHeader>} hideTarget={true} target={`cluster:${cluster}`} hideNamespace={true} namespace={space} />
}