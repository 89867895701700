import React from "react";
import {ResultError, Return} from "../../../../lib/result";
import {SectionProps} from "../../../../components/Drawer/ItemDrawer";
import Section from "../../../../components/Drawer/Section/Section";
import {ManagementV1Team} from "../../../../../gen/model/managementV1Team";
import {arr, selectOwnerFilter} from "../../../../lib/helpers/renderhelper";
import Query from "../../../../components/Query/Query";
import client from "../../../../lib/client";
import {Resources} from "../../../../lib/resources";
import {ErrorMessage} from "../../../../components/ErrorMessage/ErrorMessage";
import Loading from "../../../../components/Loading/Loading";
import Label from "../../../../components/Label/Label";
import Select from "../../../../components/Select/Select";
import styles from "./UserTeamMemberships.module.scss";
import Owner from "../../../../components/Owner/Owner";
import {ManagementV1User} from "../../../../../gen/model/managementV1User";
import Description from "../../../../components/Description/Description";
import SectionExpander from "../../../../components/Drawer/SectionExpander/SectionExpander";
const { Option } = Select;

interface UserTeamMembershipsState {
    teams: string[];
    teamsChanged: boolean;
}

interface UserTeamMembershipsProps extends SectionProps {
    user?: ManagementV1User;
}

export default class UserTeamMemberships extends React.PureComponent<UserTeamMembershipsProps, UserTeamMembershipsState> {
    state: UserTeamMembershipsState = {
        teams: [],
        teamsChanged: false,
    };

    create = async (user: ManagementV1User): Promise<ResultError> => {
        // get all teams
        const teamsResult = await client.management(Resources.ManagementV1Team).List();
        if (teamsResult.err) {
            return teamsResult;
        }

        // add and remove user from teams
        const teams = arr(teamsResult.val.items);
        for (let i = 0; i < teams.length; i++) {
            const team = teams[i];
            const foundInTeam = team.spec?.users?.find(u => u === user.metadata?.name);
            const shouldBeInTeam = this.state.teams.find(t => t === team.metadata?.name);

            // delete from team
            if (foundInTeam && !shouldBeInTeam) {
                team.spec!.users = arr(team.spec?.users).filter(u => u !== user.metadata?.name);

                const updateTeamResult = await client.management(Resources.ManagementV1Team).Update(team.metadata?.name!, team);
                if (updateTeamResult.err) {
                    return updateTeamResult;
                }
            }
            // add to team
            else if (!foundInTeam && shouldBeInTeam) {
                team.spec!.users = [...arr(team.spec?.users), user.metadata?.name!];

                const updateTeamResult = await client.management(Resources.ManagementV1Team).Update(team.metadata?.name!, team);
                if (updateTeamResult.err) {
                    return updateTeamResult;
                }
            }
        }

        return Return.Ok();
    };

    update = async (user: ManagementV1User): Promise<ResultError> => {
        if (!this.state.teamsChanged) {
            return Return.Ok();
        }

        return this.create(user);
    };

    renderOptions(teams?: ManagementV1Team[]) {
        const options: JSX.Element[] = [];

        // add teams
        teams?.forEach(team => {
            options.push(<Option key={team.metadata?.name!} value={team?.metadata?.name!}>
                <Owner isTeam={true} displayName={team.spec?.displayName} username={team.spec?.username} kubeName={team?.metadata?.name!} type={"small"} className={styles["option"]} />
            </Option>)
        });

        return options;
    }

    render() {
        if (this.props.mode === "batch") {
            return null;
        }

        return <Section title={`Team Memberships`} foldable={true} defaultFolded={true}>
            <Query query={async () => {
                const result = await client.management(Resources.ManagementV1Team).List();
                if (result.err) {
                    return result;
                }

                await new Promise(resolve => this.setState({
                    teams: this.props.user ? arr(result.val.items).filter(team => arr(team.spec?.users).find(user => user === this.props.user?.metadata?.name)).map(team => team.metadata!.name!) : [],
                }, () => resolve(undefined)));

                return result;
            }}>
                {
                    result => {
                        if (result.error) {
                            return <ErrorMessage error={result.error} />
                        } else if (result.loading) {
                            return <Loading />
                        }

                        return <div>
                            <Label>Teams</Label>
                            <Select
                                resetable={this.props.mode !== "create"}
                                onChangedStatus={changed => this.setState({teamsChanged: changed})}
                                className={styles["select"]}
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Please select teams the user should be part of"
                                value={this.state.teams}
                                onChange={value => this.setState({teams: value})}
                                showSearch
                                optionFilterProp="children"
                                filterOption={selectOwnerFilter}
                            >
                                {this.renderOptions(result.data?.items)}
                            </Select>
                            <Description>The teams the user is part of.</Description>
                        </div>
                    }
                }
            </Query>
        </Section>
    }
}