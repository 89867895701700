import React from "react";
import { SectionProps } from "../../../../components/Drawer/ItemDrawer";
import Select from "../../../../components/Select/Select";
import {ManagementV1Cluster} from "../../../../../gen/model/managementV1Cluster";
import constants from "../../../../constants/constants";
import {ResultError, Return} from "../../../../lib/result";
import Query from "../../../../components/Query/Query";
import client from "../../../../lib/client";
import {Resources} from "../../../../lib/resources";
import {ErrorMessage} from "../../../../components/ErrorMessage/ErrorMessage";
import Loading from "../../../../components/Loading/Loading";
import Description from "../../../../components/Description/Description";
import Section from "../../../../components/Drawer/Section/Section";
import Label from "../../../../components/Label/Label";
import {arr, selectDefaultFilter} from "../../../../lib/helpers/renderhelper";
import {displayName} from "../../../../lib/helper";
const { Option } = Select;

interface DefaultTemplatesState {
    defaultSpaceTemplate: string | undefined;
    defaultVirtualClusterTemplate: string | undefined;
}

interface DefaultTemplatesProps extends SectionProps {
    cluster?: ManagementV1Cluster;
}

export default class DefaultTemplates extends React.PureComponent<DefaultTemplatesProps, DefaultTemplatesState> {
    state: DefaultTemplatesState = {
        defaultSpaceTemplate: this.props.cluster?.metadata?.annotations?.[constants.LoftDefaultSpaceTemplate],
        defaultVirtualClusterTemplate: this.props.cluster?.metadata?.annotations?.[constants.LoftDefaultVirtualClusterTemplate],
    };

    update = (cluster: ManagementV1Cluster): ResultError => {
        if (!cluster.metadata) {
            cluster.metadata = {};
        }
        if (!cluster.metadata.annotations) {
            cluster.metadata.annotations = {};
        }

        if (this.state.defaultSpaceTemplate) {
            cluster.metadata.annotations[constants.LoftDefaultSpaceTemplate] = this.state.defaultSpaceTemplate;
        } else {
            delete cluster.metadata.annotations[constants.LoftDefaultSpaceTemplate];
        }
        if (this.state.defaultVirtualClusterTemplate) {
            cluster.metadata.annotations[constants.LoftDefaultVirtualClusterTemplate] = this.state.defaultVirtualClusterTemplate;
        } else {
            delete cluster.metadata.annotations[constants.LoftDefaultVirtualClusterTemplate];
        }
        return Return.Ok();
    };

    render() {
        if (["batch", "create"].includes(this.props.mode)) {
            return null;
        }

        return <Section title={`Default Templates`} foldable={true} defaultFolded={true}>
            <Query query={async () => await client.management(Resources.ManagementV1SpaceTemplate).List()}>
                {
                    result => {
                        if (result.error) {
                            return <ErrorMessage error={result.error} />
                        } else if (result.loading) {
                            return <Loading />
                        }

                        return <React.Fragment>
                            <Label>Default Space Template</Label>
                            <Select
                                showSearch
                                allowClear
                                placeholder="Select a Template"
                                style={{ width: '100%' }}
                                value={this.state.defaultSpaceTemplate}
                                onChange={value => {
                                    this.setState({
                                        defaultSpaceTemplate: value,
                                    });
                                }}
                                optionFilterProp="children"
                                filterOption={selectDefaultFilter}
                            >
                                {arr(result.data?.items).map(template => {
                                    return <Option key={template.metadata?.name} value={template.metadata?.name!}>
                                        {displayName(template)}
                                    </Option>
                                })}
                            </Select>
                            <Description>The default space template that will be used to create a new space if no other template is specified.</Description>
                        </React.Fragment>
                    }
                }
            </Query>
            <Query query={async () => await client.management(Resources.ManagementV1VirtualClusterTemplate).List()}>
                {
                    result => {
                        if (result.error) {
                            return <ErrorMessage error={result.error} />
                        } else if (result.loading) {
                            return <Loading />
                        }
                        
                        return <React.Fragment>
                            <Label>Default Virtual Cluster Template</Label>
                            <Select
                                showSearch
                                allowClear
                                placeholder="Select a Template"
                                style={{ width: '100%' }}
                                value={this.state.defaultVirtualClusterTemplate}
                                onChange={value => {
                                    this.setState({
                                        defaultVirtualClusterTemplate: value,
                                    });
                                }}
                                optionFilterProp="children"
                                filterOption={selectDefaultFilter}
                            >
                                {arr(result.data?.items).map(template => {
                                    return <Option key={template.metadata?.name} value={template.metadata?.name!}>
                                        {displayName(template)}
                                    </Option>
                                })}
                            </Select>
                            <Description>The default virtual cluster template that will be used to create a new virtual cluster if no other template is specified.</Description>
                        </React.Fragment>
                    }
                }
            </Query>
        </Section>
    }
}