import React from "react";
import {SectionProps} from "../../../../components/Drawer/ItemDrawer";
import Select from "../../../../components/Select/Select";
import {
    convertToMap,
    parseMap
} from "../../../../components/Drawer/Sections/Metadata/LabelsAnnotationsSection";
import Section from "../../../../components/Drawer/Section/Section";
import {selectOwnerFilter} from "../../../../lib/helpers/renderhelper";
import Label from "../../../../components/Label/Label";
import Description from "../../../../components/Description/Description";
import TextArea from "../../../../components/TextArea/TextArea";
import {ResultError, Return} from "../../../../lib/result";
import {StorageV1ClusterQuota} from "../../../../../gen/model/agentstorageV1ClusterQuota";
const { Option } = Select;

interface QuotaSpecState {
    quotaLimits?: string | undefined;
    quotaLimitsChanged: boolean;
    quotaLimitsInitial?: string | undefined;

    // this is a workaround, because the logic of the antd select does not really allow
    // to prevent selection of items except making them disabled (which is not desired in this case)
    // so we will quickly select and deselect
    copyFromClusterQuota: string | undefined;
}

interface QuotaSpecProps extends SectionProps {
    // The cluster the account quota is in
    cluster: string;
    quota?: StorageV1ClusterQuota;

    allQuotas: Array<StorageV1ClusterQuota>;
}

export default class QuotaSpec extends React.PureComponent<QuotaSpecProps, QuotaSpecState> {
    state: QuotaSpecState = {
        quotaLimits: this.props.quota ? parseMap(this.props.quota?.spec?.quota?.hard) : undefined,
        quotaLimitsChanged: false,
        quotaLimitsInitial: this.props.quota ? parseMap(this.props.quota?.spec?.quota?.hard) : undefined,

        copyFromClusterQuota: undefined
    };

    create = (quota: StorageV1ClusterQuota): ResultError => {
        // convert to map
        const mapResult = convertToMap(this.state.quotaLimits);
        if (mapResult.err) {
            return mapResult;
        }

        quota.spec!.quota = {
            hard: mapResult.val
        };

        return Return.Ok();
    };

    update = (quota: StorageV1ClusterQuota): ResultError => {
        if (!this.state.quotaLimitsChanged) {
            return Return.Ok();
        }

        return this.create(quota);
    };

    batch = (quotas: StorageV1ClusterQuota[]): ResultError => {
        for (let i = 0; i < quotas.length; i++) {
            const result = this.update(quotas[i]);
            if (result.err) {
                return result;
            }
        }

        return Return.Ok();
    };

    render() {
        return <Section title={"Quota"}>
            <div>
                <Label>Copy From Existing Quota</Label>
                <Select
                    showSearch
                    allowClear
                    placeholder="Select Quota"
                    style={{ width: '100%' }}
                    value={this.state.copyFromClusterQuota}
                    onChange={value => {
                        const quota = this.props.allQuotas.find(quota => quota.metadata?.name === value);
                        if (quota) {
                            // update state
                            this.setState({
                                quotaLimitsChanged: true,
                                quotaLimits: parseMap(quota.spec?.quota?.hard),

                                copyFromClusterQuota: ""
                            }, () => this.setState({copyFromClusterQuota: undefined}));
                        }
                    }}
                    optionFilterProp="children"
                    filterOption={selectOwnerFilter}
                >
                    {this.props.allQuotas.map(quota => {
                        return <Option key={quota.metadata?.name} value={quota.metadata?.name!}>
                            {quota.metadata?.name}
                        </Option>
                    })}
                </Select>
                <Description>Selecting a quota will copy the values from the other quota into the fields below.</Description>
            </div>
            <div>
                <Label>Quota {this.props.quota ? this.props.quota.metadata?.name! : ""}</Label>
                <TextArea resetable={this.props.mode !== "create"}
                          changed={this.state.quotaLimitsChanged}
                          initial={this.state.quotaLimitsInitial}
                          value={this.state.quotaLimits}
                          style={{minHeight: "98px"}}
                          onChangedStatus={(changed) => this.setState({quotaLimitsChanged: changed})}
                          onChange={(e) => this.setState({quotaLimits: e.target.value})}
                          placeholder={`spaces: 5
limits.cpu: 2
limits.memory: 4Gi
requests.cpu: 1
requests.memory: 1Gi`}
                />
                <Description>The sum of all resources from all spaces the user or team owns have to be below the limits defined here.</Description>
            </div>
        </Section>
    }
}