import {useRouteMatch} from "react-router-dom";
import React, {useState} from "react";
import {GroupVersionResource} from "../../../../lib/client";
import {Unstructured} from "../../../../lib/types";
import {arr} from "../../../../lib/helpers/renderhelper";
import {ResourceTable} from "../../../../components/ResourceTable/ResourceTable";
import SpacesHeader from "../SpacesHeader/SpacesHeader";
import {
    ApiResources,
    useApiResourceClusterQuery
} from "../../../Clusters/Details/Resources/Resources";

export default function Other() {
    const match = useRouteMatch();
    const {cluster, space, resource} = match.params as any;

    // get api resources
    const [selectedResource, setSelectedResource] = useState<GroupVersionResource<Unstructured> | undefined>(undefined);
    const {loading, error, apiResourcesRaw} = useApiResourceClusterQuery({cluster, removeDuplicates: true, includeSubResources: false});
    const apiResources = arr(apiResourcesRaw).filter(r => arr(r.verbs).includes("list") && arr(r.verbs).includes("get") && !!r.namespaced)
    return <div>
        <ResourceTable cluster={cluster}
                       namespace={space}
                       resource={selectedResource}
                       top={<SpacesHeader>
                            <ApiResources selectedResource={selectedResource}
                                          setSelectedResource={setSelectedResource}
                                          apiResources={apiResources}
                                          loading={loading}
                                          error={error}
                                          defaultResource={resource}
                                          baseUrl={`/spaces/${cluster}/${space}/other`} />
                       </SpacesHeader>} />
    </div>
};