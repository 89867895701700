import React, {RefObject} from "react";
import {arr, selectDefaultFilter} from "../../../../../lib/helpers/renderhelper";
import Select from "../../../../../components/Select/Select";
import {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import {ResultError, Return} from "../../../../../lib/result";
import client from "../../../../../lib/client";
import {Resources} from "../../../../../lib/resources";
import Query from "../../../../../components/Query/Query";
import {ErrorMessage} from "../../../../../components/ErrorMessage/ErrorMessage";
import Description from "../../../../../components/Description/Description";
import Label from "../../../../../components/Label/Label";
import Section from "../../../../../components/Drawer/Section/Section";
import {ManagementV1App} from "../../../../../../gen/model/managementV1App";
import LabelsAnnotationsSection from "../../../../../components/Drawer/Sections/Metadata/LabelsAnnotationsSection";
import {ManagementV1SpaceTemplate} from "../../../../../../gen/model/managementV1SpaceTemplate";
import {
    convertMinutesToSeconds, convertSecondsToMinutes, setDeleteAfter, setSleepAfter
} from "../../../../../lib/helpers/sleepmodehelper";
import styles from "./SpaceTemplateConfiguration.module.scss";
import Input from "../../../../../components/Input/Input";
import {displayName} from "../../../../../lib/helper";
import SleepMode from "../../../../../components/Drawer/Sections/SleepMode/SleepMode";
import SectionExpander from "../../../../../components/Drawer/SectionExpander/SectionExpander";
const { Option } = Select;

interface SpaceTemplateConfigurationState {
    apps?: string[];
}

interface SpaceTemplateConfigurationProps extends SectionProps {
    spaceTemplate?: ManagementV1SpaceTemplate;
}

export default class SpaceTemplateConfiguration extends React.PureComponent<SpaceTemplateConfigurationProps, SpaceTemplateConfigurationState> {
    spaceLabelsSectionRef?: RefObject<LabelsAnnotationsSection>;
    sleepModeRef?: RefObject<SleepMode>;
    
    state: SpaceTemplateConfigurationState = {
        apps: this.props.spaceTemplate?.spec?.template?.apps?.map(app => app.name!) || [],
    };

    constructor(props: SpaceTemplateConfigurationProps) {
        super(props);

        this.spaceLabelsSectionRef = React.createRef<LabelsAnnotationsSection>();
        this.sleepModeRef = React.createRef<SleepMode>();
    }

    create = (space: ManagementV1SpaceTemplate): ResultError => {
        if (!space.spec) {
            space.spec = {};
        }
        if (!space.spec.template) {
            space.spec.template = {};
        }
        if (!space.spec.template.apps) {
            space.spec.template.apps = [];
        }
        if (!space.spec.template.metadata) {
            space.spec.template.metadata = {};
        }
        // set virtual cluster annotations
        const annotationsResult = this.spaceLabelsSectionRef!.current!.create(space.spec!.template!.metadata!);
        if (annotationsResult.err) {
            return annotationsResult;
        }
        const sleepModeResult = this.sleepModeRef!.current!.create(space.spec!.template);
        if (sleepModeResult.err) {
            return sleepModeResult;
        }

        const apps = arr(this.state.apps);
        space.spec.template.apps = apps.map(app => ({name: app}));
        return Return.Ok();
    };

    update = (space: ManagementV1SpaceTemplate): ResultError => {
        if (!space.spec) {
            space.spec = {};
        }
        if (!space.spec.template) {
            space.spec.template = {};
        }
        if (!space.spec.template.apps) {
            space.spec.template.apps = [];
        }
        if (!space.spec.template.metadata) {
            space.spec.template.metadata = {};
        }
        // set virtual cluster annotations
        const annotationsResult = this.spaceLabelsSectionRef!.current!.update(space.spec!.template!.metadata!);
        if (annotationsResult.err) {
            return annotationsResult;
        }
        const sleepModeResult = this.sleepModeRef!.current!.update(space.spec!.template);
        if (sleepModeResult.err) {
            return sleepModeResult;
        }

        const apps = arr(this.state.apps);
        space.spec.template.apps = apps.map(app => ({name: app}));
        return Return.Ok()
    };

    render() {
        if (this.props.mode === "batch") {
            return null;
        }

        return <Section title={"Configuration"}>
            <Query query={async () => {
                let apps: ManagementV1App[] = [];
                const appsResult = await client.management(Resources.ManagementV1App).List();
                if (appsResult.err) {
                    console.error(appsResult.val)
                } else {
                    apps = arr(appsResult.val.items);
                }

                return Return.Value({
                    apps
                });
            }}>
                {
                    result => {
                        if (result.error) {
                            return <ErrorMessage error={result.error} />;
                        }

                        return <React.Fragment>
                            <LabelsAnnotationsSection {...this.props} name={"Space Labels & Annotations"} metadata={this.props.spaceTemplate ? this.props.spaceTemplate.spec?.template?.metadata : undefined} ref={this.spaceLabelsSectionRef} />
                            <SleepMode mode={this.props.mode} obj={this.props.spaceTemplate?.spec?.template} ref={this.sleepModeRef} />
                            <SectionExpander name={"Deploy Apps"}>
                                <Label>Which apps should get deployed within the space?</Label>
                                <Select
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    placeholder="Please select apps that should be deployed in the space"
                                    value={this.state.apps}
                                    onChange={(value) => this.setState({apps: value})}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={selectDefaultFilter}
                                >
                                    {arr(result.data?.apps).map(app => <Option key={app.metadata?.name} value={app.metadata?.name!}>
                                        {displayName(app)}
                                    </Option>)}
                                </Select>
                                <Description>Each app will be deployed into the created space</Description>
                            </SectionExpander>
                        </React.Fragment>
                    }
                }
            </Query>
        </Section>
    }
}