import React from "react";
import {PolicyV1beta1JsPolicy} from "../../../../../../gen/model/policyV1beta1JsPolicy";
import {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import Select from "../../../../../components/Select/Select";
import {
    convertLabelSelectorToString,
    convertStringToLabelSelector
} from "../../../../Users/ClusterAccountTemplates/ClusterAccountTemplateDrawer/Sections/ClusterSelector";
import {ResultError, Return} from "../../../../../lib/result";
import Section from "../../../../../components/Drawer/Section/Section";
import styles from './PolicyAdvanced.module.scss'
import Label from "../../../../../components/Label/Label";
import Description from "../../../../../components/Description/Description";
import Input from "../../../../../components/Input/Input";
const { Option } = Select;

interface PolicyAdvancedState {
    namespaceSelector?: string;
    objectSelector?: string;
    violationPolicy?: string;
    timeout?: number;
    auditPolicy?: string;
    auditLogSize?: number;
    dependencies?: string;
}

interface PolicyAdvancedProps extends SectionProps {
    policy?: PolicyV1beta1JsPolicy;
}

export default class PolicyAdvanced extends React.PureComponent<PolicyAdvancedProps, PolicyAdvancedState> {
    state: PolicyAdvancedState = {
        namespaceSelector: this.props.policy?.spec?.namespaceSelector ? convertLabelSelectorToString(this.props.policy?.spec?.namespaceSelector) : undefined,
        objectSelector: this.props.policy?.spec?.objectSelector ? convertLabelSelectorToString(this.props.policy?.spec?.objectSelector) : undefined,
        violationPolicy: this.props.policy?.spec?.violationPolicy,
        auditPolicy: this.props.policy?.spec?.auditPolicy,
        timeout: this.props.policy?.spec?.timeoutSeconds,
        auditLogSize: this.props.policy?.spec?.auditLogSize
    };

    create = (policy: PolicyV1beta1JsPolicy): ResultError => {
        if (!policy.spec) {
            policy.spec = {};
        }
        policy.spec.violationPolicy = this.state.violationPolicy;
        policy.spec.timeoutSeconds = this.state.timeout;
        policy.spec.auditPolicy = this.state.auditPolicy;
        policy.spec.auditLogSize = this.state.auditLogSize;
        
        let result = convertStringToLabelSelector(this.state.namespaceSelector);
        if (result.err) {
            return result;
        }
        policy.spec.namespaceSelector = result.val;
        
        result = convertStringToLabelSelector(this.state.objectSelector);
        if (result.err) {
            return result;
        }
        policy.spec.objectSelector = result.val;

        result = convertStringToLabelSelector(this.state.dependencies);
        if (result.err) {
            return result;
        }
        policy.spec.dependencies = result.val.matchLabels;
        
        return Return.Ok();
    };

    update = (policy: PolicyV1beta1JsPolicy): ResultError => {
        return this.create(policy);
    };

    render() {
        if (this.props.mode === "batch") {
            return null;
        }

        return <Section title={`Advanced Options`} defaultFolded={true} foldable={true}>
            <div className={styles["row"]}>
                <div>
                    <Label>Violation Policy</Label>
                    <Select value={this.state.violationPolicy}
                            style={{width: "100%"}}
                           placeholder={"Deny"}
                           onChange={(e) => this.setState({violationPolicy: e})}>
                        <Option key={"Deny"} value={"Deny"}>
                            Deny
                        </Option>
                        <Option key={"Warn"} value={"Warn"}>
                            Warn
                        </Option>
                        <Option key={"Dry"} value={"Dry"}>
                            Dry
                        </Option>
                    </Select>
                    <Description>What should happen if the javascript payload decides to deny a request.</Description>
                </div>
                <div>
                    <Label>Timeout</Label>
                    <Input type={"number"}
                           value={this.state.timeout}
                           placeholder={"Seconds before interrupting"}
                           onChange={(e) => this.setState({timeout: parseInt(e.target.value)})} />
                    <Description>The timeout after which execution is interrupted in seconds.</Description>
                </div>
            </div>
            <div className={styles["row"]}>
                <div>
                    <Label>Audit Policy</Label>
                    <Select value={this.state.auditPolicy}
                            style={{width: "100%"}}
                            placeholder={"Log"}
                            onChange={(e) => this.setState({auditPolicy: e})}>
                        <Option key={"Deny"} value={"Deny"}>
                            Log
                        </Option>
                        <Option key={"Warn"} value={"Warn"}>
                            Skip
                        </Option>
                    </Select>
                    <Description>Should the request be logged if it was denied?</Description>
                </div>
                <div>
                    <Label>Audit Log Size</Label>
                    <Input type={"number"}
                           value={this.state.auditLogSize}
                           placeholder={"Number of entries to remember"}
                           onChange={(e) => this.setState({auditLogSize: parseInt(e.target.value)})} />
                    <Description>How many denied requests should be logged in the webhook.</Description>
                </div>
            </div>
            <div>
                <Label>NPM Packages used by this policy</Label>
                <Input value={this.state.dependencies}
                       placeholder={"lodash=^4.17.21,other-package=0.4.3"}
                       onChange={(e) => this.setState({dependencies: e.target.value})} />
                <Description>Packages that should be included in your policy</Description>
            </div>
            <div>
                <Label>Select target namespaces by label</Label>
                <Input value={this.state.namespaceSelector}
                       placeholder={"label1=value1,label2=value2 (Empty to select all namespaces)"}
                       onChange={(e) => this.setState({namespaceSelector: e.target.value})} />
                <Description>Select only specific namespaces by label which apply for this webhook. For more information take a look at the <a target="_blank" href="https://kubernetes.io/docs/reference/access-authn-authz/extensible-admission-controllers/#matching-requests-namespaceselector">Kubernetes Documenation</a></Description>
            </div>
            <div>
                <Label>Select target objects by label</Label>
                <Input value={this.state.objectSelector}
                       placeholder={"label1=value1,label2=value2 (Empty to select all objects)"}
                       onChange={(e) => this.setState({objectSelector: e.target.value})} />
                <Description>Select only specific objects by label which apply for this webhook. For more information take a look at the <a target="_blank" href="https://kubernetes.io/docs/reference/access-authn-authz/extensible-admission-controllers/#matching-requests-objectselector">Kubernetes Documenation</a></Description>
            </div>
        </Section>
    }
}
