import React from "react";
import Label from "../../../../../components/Label/Label";
import {ResultError, Return} from "../../../../../lib/result";
import {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import {ManagementV1UserProfile} from "../../../../../../gen/model/managementV1UserProfile";
import Description from "../../../../../components/Description/Description";
import {Input, Space} from "antd";
import client from "../../../../../lib/client";
import {log} from "util";

interface PasswordState {
    currentPassword: string | undefined;
    newPassword: string | undefined;
    newPasswordConfirmed: string | undefined;
}

interface PasswordProps extends SectionProps {
    username: string;
    show: boolean;
}

export default class Password extends React.PureComponent<PasswordProps, PasswordState> {
    state: PasswordState = {
        currentPassword: undefined,
        newPassword: undefined,
        newPasswordConfirmed: undefined,
    };

    create = async (obj: ManagementV1UserProfile): Promise<ResultError> => {
        if (!this.props.show) {
            return Return.Ok();
        }

        if (!this.state.currentPassword) {
            return Return.Failed("Current password is incorrect");
        } else if (!this.state.newPassword) {
            return Return.Failed("No password specified");
        } else if (this.state.newPassword !== this.state.newPasswordConfirmed) {
            return Return.Failed("Passwords must match");
        }

        const loginResult = await client.login(this.props.username, this.state.currentPassword);
        if (loginResult.err) {
            return Return.Failed("wrong current password");
        }

        obj.password = this.state.newPassword;
        return Return.Ok();
    };

    render() {
        if (this.props.mode !== "create" || !this.props.show) {
            return null;
        }

        return <Space size={26} direction={"vertical"} style={{width: "100%"}}>
            <div>
                <Label>Current Password</Label>
                <Input.Password onChange={e => this.setState({currentPassword: e.target.value})} placeholder={"*******************"} value={this.state.currentPassword} />
                <Description>Enter your current password to confirm this operation.</Description>
            </div>
            <div>
                <Label>New Password</Label>
                <Input.Password onChange={e => this.setState({newPassword: e.target.value})} placeholder={"*******************"} value={this.state.newPassword} />
                <Description>Define a new password.</Description>
            </div>
            <div>
                <Label>Repeat New Password</Label>
                <Input.Password onChange={e => this.setState({newPasswordConfirmed: e.target.value})} placeholder={"*******************"} value={this.state.newPasswordConfirmed} />
                <Description>Repeat your new password.</Description>
            </div>
        </Space>
    }
}