import React from "react";
import { SectionProps } from "../../../../components/Drawer/ItemDrawer";
import {ManagementV1Cluster} from "../../../../../gen/model/managementV1Cluster";
import {ResultError, Return} from "../../../../lib/result";
import Description from "../../../../components/Description/Description";
import Section from "../../../../components/Drawer/Section/Section";
import Label from "../../../../components/Label/Label";
import Input from "../../../../components/Input/Input";
import SectionExpander from "../../../../components/Drawer/SectionExpander/SectionExpander";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import TextArea from "../../../../components/TextArea/TextArea";

const DirectClusterEndpointDomain = "loft.sh/direct-cluster-endpoint";
const DirectClusterEndpointCaData = "loft.sh/direct-cluster-endpoint-ca-data";
const DirectClusterEndpointInsecure = "loft.sh/direct-cluster-endpoint-insecure";

interface DirectClusterEndpointState {
    directClusterEndpoint: string | undefined;
    directClusterEndpointChanged: boolean;
    
    directClusterEndpointCaCert: string | undefined;
    directClusterEndpointCaCertChanged: boolean;
    
    directClusterEndpointInsecure: boolean | undefined;
}

interface DirectClusterEndpointProps extends SectionProps {
    cluster: ManagementV1Cluster | undefined;
}

export default class DirectClusterEndpoint extends React.PureComponent<DirectClusterEndpointProps, DirectClusterEndpointState> {
    state: DirectClusterEndpointState = {
        directClusterEndpoint: this.props.cluster?.metadata?.annotations?.[DirectClusterEndpointDomain],
        directClusterEndpointChanged: false,

        directClusterEndpointCaCert: this.props.cluster?.metadata?.annotations?.[DirectClusterEndpointCaData],
        directClusterEndpointCaCertChanged: false,
        
        directClusterEndpointInsecure: this.props.cluster?.metadata?.annotations?.[DirectClusterEndpointInsecure] === "true",
    };

    update = (cluster: ManagementV1Cluster): ResultError => {
        if (!cluster.metadata) {
            cluster.metadata = {};
        }
        if (!cluster.metadata.annotations) {
            cluster.metadata.annotations = {};
        }

        if (this.state.directClusterEndpointChanged) {
            if (this.state.directClusterEndpoint) {
                cluster.metadata.annotations[DirectClusterEndpointDomain] = this.state.directClusterEndpoint;
            } else {
                delete cluster.metadata.annotations[DirectClusterEndpointDomain];
            }
        }
        if (this.state.directClusterEndpointCaCertChanged) {
            if (this.state.directClusterEndpointCaCert) {
                cluster.metadata.annotations[DirectClusterEndpointCaData] = this.state.directClusterEndpointCaCert;
            } else {
                delete cluster.metadata.annotations[DirectClusterEndpointCaData];
            }
        }
        if (this.state.directClusterEndpointInsecure) {
            cluster.metadata.annotations[DirectClusterEndpointInsecure] = this.state.directClusterEndpointInsecure + "";
        } else {
            delete cluster.metadata.annotations[DirectClusterEndpointInsecure];
        }
        return Return.Ok();
    };

    render() {
        if (["batch", "create"].includes(this.props.mode)) {
            return null;
        }

        return <Section title={`Direct Cluster Endpoint`} foldable={true} defaultFolded={true}>
            <Description>
                Direct Cluster Endpoints are a feature to avoid the central Loft instance and directly connect to a connected Loft cluster. By default, all Kubernetes contexts that are created through Loft will route all Kubernetes traffic (such as kubectl get pods etc.) through the central Loft instance. This allows Loft to handle authentication, authorization, auditing, sleep mode activity etc.
                <br /><br />
                However, if you have multiple connected clusters in a Loft instance that are spread across the globe, the traffic redirection through the central Loft gateway can increase request delay. A solution to this are direct cluster endpoints, which avoid rerouting traffic to the central Loft instance and rather handle authentication, authorization etc. directly within the connected cluster through the installed Loft agent.
            </Description>
            <Label>Direct Cluster Endpoint</Label>
            <Input resetable={this.props.mode === "update"}
                   onChangedStatus={changed => this.setState({directClusterEndpointChanged: changed})}
                   placeholder={`my-direct-cluster-endpoint.domain.com`}
                   value={this.state.directClusterEndpoint}
                   onChange={e => this.setState({directClusterEndpoint: e.target.value})} />
            <Description>The domain name that should be used to connect to the cluster directly. As soon as this value is set, the direct cluster endpoint for the connected cluster is turned on and will be preferred in future over the central Loft instance.</Description>
            <SectionExpander name={"Advanced Options"}>
                <Label>Insecure Endpoint</Label>
                <Checkbox onChange={e => this.setState({directClusterEndpointInsecure: e.target.checked})} 
                          checked={this.state.directClusterEndpointInsecure}>Insecure Endpoint</Checkbox>
                <Description>If this is checked, Loft will create Kubernetes contexts with <i>insecure-skip-tls-verify: true</i> for this endpoint</Description>
                <Label>Direct Cluster Endpoint Ca Certificate</Label>
                <TextArea resetable={this.props.mode !== "create"}
                          changed={this.state.directClusterEndpointCaCertChanged}
                          value={this.state.directClusterEndpointCaCert}
                          onChangedStatus={(changed) => this.setState({directClusterEndpointCaCertChanged: changed})}
                          onChange={(e) => this.setState({directClusterEndpointCaCert: e.target.value})}
                          placeholder={`LS0tLS1CRUdJTiBDRVJUSUZJQ0FURS0tLS0tCk1JSUJrVENDQVRlZ0F3SUJBZ0lJVXZnMWMxVEhrRkF3Q2dZSUtvWkl6ajBFQXdJd0l6RWhNQjhHQTFVRUF3d1kKYXpOekxXTnNhV1Z1ZEMxallVQXhOakl4TWpZek1qWTVNQjRYRFRJeE1EVXhOekUwTlRReU9Wb1hEVEl5TURVeApOekUwTlRReU9Wb3dNREVYTUJVR0ExV...`} />
                <Description>Base64 encoded ca cert of the direct cluster endpoint</Description>
            </SectionExpander>
        </Section>
    }
}