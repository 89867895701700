import React from "react";
import {useRouteMatch} from "react-router-dom";
import {ResourceTable} from "../../../../components/ResourceTable/ResourceTable";
import {Resources} from "../../../../lib/resources";
import SpacesHeader from "../SpacesHeader/SpacesHeader";
import useQuery from "../../../../lib/Query/Query";
import client from "../../../../lib/client";
import Loading from "../../../../components/Loading/Loading";
import {ErrorTypeNotFound} from "../../../../lib/result";

export default function Ingresses() {
    const match = useRouteMatch();
    const {cluster, space} = match.params as any;
    const {error, loading} = useQuery(async () => client.cluster(cluster, Resources.NetworkingV1Ingress).Namespace(space).List());
    if (loading) {
        return <Loading />;
    }

    return <ResourceTable cluster={cluster}
                          namespace={space}
                          top={<SpacesHeader />}
                          resource={error && error.val?.type === ErrorTypeNotFound ? Resources.NetworkingV1beta1Ingress : Resources.NetworkingV1Ingress} />
}