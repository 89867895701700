import React, {useState} from "react";
import {Tooltip} from "../../../../components/Tooltip/Tooltip";
import {DeleteOutlined} from "@ant-design/icons/lib";
import styles from "./AccountDeletePopup.module.scss";
import {DeleteModal} from "../../../../lib/Modal/Modal";
import ClientMessage from "../../../../lib/Message/ClientMessage";
import client from "../../../../lib/client";
import {Resources} from "../../../../lib/resources";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import {ConfigV1alpha1Account} from "../../../../../gen/model/configV1alpha1Account";

interface Props {
    cluster: string;
    account: ConfigV1alpha1Account | undefined;
    refetch: () => Promise<void>;
    className?: string;
}

export default function AccountDeletePopup(props: Props) {
    const [visible, setVisible] = useState<boolean>(false);
    const [addToExclude, setAddToExclude] = useState<boolean>(true);
    
    const user = props.account?.metadata?.labels?.["loft.sh/user"];
    const team = props.account?.metadata?.labels?.["loft.sh/team"];
    const clusterAccountTemplate = props.account?.metadata?.labels?.["loft.sh/cluster-account-template"];
    return <React.Fragment>
        <Tooltip title="delete">
            <DeleteOutlined className={props.className ? props.className : styles["delete"]} onClick={() => setVisible(true)} />
        </Tooltip>
        <DeleteModal title={`Delete Account ${props.account?.metadata?.name}`} onCancel={() => setVisible(false)} visible={visible} onOkAsync={async () => {
            const message = ClientMessage.Loading(props.cluster);
            if (addToExclude) {
                const resource = user ? Resources.ManagementV1User : Resources.ManagementV1Team;
                const userResult = await client.management(resource).Get(user ? user! : team!);
                if (userResult.err) {
                    message.Result(userResult);
                    return;
                }
                
                const obj = userResult.val;
                if (!obj.metadata?.annotations) {
                    obj.metadata!.annotations = {};
                }
                
                const excludedClusters = obj.metadata?.annotations["loft.sh/account-templates-ignore-clusters"];
                if (excludedClusters) {
                    obj.metadata!.annotations["loft.sh/account-templates-ignore-clusters"] = excludedClusters + "," + props.cluster;
                } else {
                    obj.metadata!.annotations["loft.sh/account-templates-ignore-clusters"] = props.cluster;
                }

                const userResultUpdate = await client.management(resource).Update(user ? user! : team!, obj);
                if (userResultUpdate.err) {
                    message.Result(userResultUpdate);
                    return;
                }
            }
            
            const result = await client.cluster(props.cluster, Resources.ConfigV1alpha1Account).Delete(props.account?.metadata?.name!);
            message.Result(result);
            await props.refetch();
        }}>
            <div>
                Are you sure you want to delete the account {props.account?.metadata?.name!}?
            </div>
            {clusterAccountTemplate && (user || team) ? <div style={{marginTop: "5px"}}>
                <Checkbox onChange={e => setAddToExclude(e.target.checked)} checked={addToExclude}>Do not recreate this account for {user ? "User" : "Team"} {user || team} with cluster account templates</Checkbox>
            </div> : undefined}
        </DeleteModal>
    </React.Fragment>
}