import React from "react";
import {DrawerDispatch, useItemDrawer} from "../../../../contexts/drawer/DrawerContext";
import {UserContextState, useUser} from "../../../../contexts/UserContext/UserContext";
import SpaceDrawer from "../SpaceDrawer/SpaceDrawer";
import styles from "./SpaceTable.module.scss";
import SleepingIcon from "../../../../images/sleeping-icon.svg";
import {ToggleColumn, ToggleColumnContent} from "../../../../contexts/ToggleColumn/ToggleColumn";
import Owner from "../../../../components/Owner/Owner";
import {deepCopy, displayName, formatURIComponent, readableTime, timeAgo, Wait} from "../../../../lib/helper";
import DynamicTime from "../../../../components/DynamicTime/DynamicTime";
import Progress from "../../../../components/Progress/Progress";
import Table, {TableActions} from "../../../../components/Table/Table";
import {Tooltip} from "../../../../components/Tooltip/Tooltip";
import WakeUpIcon from "../../../../images/wakeup-icon.svg";
import {alert, confirm, deleteConfirm} from "../../../../lib/Modal/Modal";
import ClientMessage from "../../../../lib/Message/ClientMessage";
import client from "../../../../lib/client";
import Icon, {RetweetOutlined, WarningOutlined} from "@ant-design/icons";
import {Resources} from "../../../../lib/resources";
import {ApiOutlined, DeleteOutlined, SettingOutlined} from "@ant-design/icons/lib";
import {
    arr,
    getDisplayName,
    getDisplayNameFromEntity,
    removeDuplicateFilters
} from "../../../../lib/helpers/renderhelper";
import {Button, Space} from "antd";
import {Link} from "react-router-dom";
import {Result, ResultError, Return} from "../../../../lib/result";
import {Popover} from "../../../../components/Popover/Popover";
import {creationTimestampSorter, nameSorter, numberSorter, stringSorter} from "../../../../lib/helpers/sorthelper";
import Query from "../../../../components/Query/Query";
import {ErrorMessage} from "../../../../components/ErrorMessage/ErrorMessage";
import {LicenseState, useFeatures, useLicense} from "../../../../contexts/LicenseContext/LicenseContext";
import {ManagementV1Feature} from "../../../../../gen/model/managementV1Feature";
import constants from "../../../../constants/constants";
import {openLicenseLink} from "../../../Admin/License/License";
import UpgradeLicenseLink from "../../../../components/UpgradeLicenseLink/UpgradeLicenseLink";
import ShowYamlPopup from "../../../../components/ShowYamlPopup/ShowYamlPopup";
import Code from "../../../../components/Code/Code";
import copy from "copy-to-clipboard";
import {ClusterV1SleepModeConfig} from "../../../../../gen/model/clusterV1SleepModeConfig";
import {IntervalRender} from "../../../../components/Interval/Interval";
import SleepPopup from "./SleepPopup";
import FixedText from "../../../../components/FixedText/FixedText";
import useQuery from "../../../../lib/Query/Query";
import {V1Beta1PodMetrics} from "../../../../lib/types";
import {cpuParser, memoryParser} from "../../../../lib/helpers/resourcehelper";
import Warning from "../../../../components/Warning/Warning";
import {ClusterV1Space} from "../../../../../gen/model/clusterV1Space";
import {ClusterV1EntityInfo} from "../../../../../gen/model/clusterV1EntityInfo";
import {ManagementV1Cluster} from "../../../../../gen/model/managementV1Cluster";
import {ClusterObject} from "../Spaces";
import {ManagementV1SpaceTemplate} from "../../../../../gen/model/managementV1SpaceTemplate";
import {SleepPopover} from "./SleepPopover";
import {ColumnType} from "antd/lib/table";
import Description from "../../../../components/Description/Description";

export interface SpaceTableEntry extends ClusterObject<ClusterV1Space> {
    metrics?: V1Beta1PodMetrics[];
}

export function aggregateCores(space: SpaceTableEntry): number {
    let aggregated = 0;
    for (let i = 0; i < arr(space.metrics).length; i++) {
        for (let j = 0; j < arr(space.metrics?.[i].containers).length; j++) {
            const container = space.metrics?.[i].containers?.[j];
            aggregated += cpuParser((container?.usage?.cpu || 0) + "")
        }
    }
    
    return aggregated;
}

export function aggregateMemory(space: SpaceTableEntry): number {
    let aggregated = 0;
    for (let i = 0; i < arr(space.metrics).length; i++) {
        for (let j = 0; j < arr(space.metrics?.[i].containers).length; j++) {
            const container = space.metrics?.[i].containers?.[j];
            aggregated += memoryParser((container?.usage?.memory || 0) + "")
        }
    }

    return aggregated;
}

export function wrapLicenseLink(content: JSX.Element, features: Array<ManagementV1Feature>) {
    if (!features.find(feature => feature.metadata?.name === constants.SleepModeFeature && feature.status?.enabled)) {
        return <div>
            <UpgradeLicenseLink className={styles["upgrade-license-link"]}>Upgrade to enable sleep mode</UpgradeLicenseLink>
            <div className={styles["hide-on-hover"]}>
                {content}
            </div>
        </div>
    }

    return content;
}

export async function wakeUpSpace(config: ClusterV1SleepModeConfig | undefined, cluster: string | undefined, spaceName: string | undefined, refetch?: () => Promise<void>) {
    const message = ClientMessage.Loading(cluster);
    if (config) {
        config.spec!.forceSleep = false;
        delete config.spec!.forceSleepDuration;
        config.status!.lastActivity = Math.round(new Date().getTime() / 1000);
    }

    const result = await client.cluster(cluster!, Resources.ClusterV1SleepModeConfig).Namespace(spaceName).Create(config!);
    if (result.err) {
        message.ErrorCluster(result, cluster!);
        return;
    }
    
    // wait until space is woken up
    for (let i = 0; i < 30; i++) {
        await Wait(200);
        
        const result = await client.cluster(cluster!, Resources.ClusterV1SleepModeConfig).Namespace(spaceName).List();
        if (result.err) {
            message.ErrorCluster(result, cluster!);
            return;
        } else if (arr(result.val.items).length === 0) {
            continue;
        }
        
        if (!result.val.items[0].status?.sleepingSince) {
            break;
        }
    }
    
    message.Result(result);
    if (result.ok && refetch) {
        await refetch();
    }
}

function getSpaceByName(props: SpaceTableProps, cluster: string, spaceName: React.ReactText) {
  return props.spaces?.find(space => space.cluster === cluster && space.object?.metadata?.name === spaceName);
}

export function checkEnabledSleepMode(features : ManagementV1Feature[], license? : LicenseState) : boolean {
    if (!features.find(feature => feature.metadata?.name === constants.SleepModeFeature && feature.status?.enabled)) {
        if (license?.token) {
            confirm({
                title: `Please upgrade loft`,
                content: `Please upgrade loft to let spaces sleep`,
                okText: "Upgrade",
                onOkAsync: async () => {
                    openLicenseLink(license?.license!, "switchPlan", license?.token?.status?.token!)
                },
            });
        } else {
            alert({
                title: `Please upgrade loft`,
                content: `Please upgrade loft to let spaces sleep`,
            });
        }

        return false;
    }
    
    return true;
}

function getSpacesWithSleepingState(shouldSleep: boolean, selectedRowKeys: React.ReactText[], props: SpaceTableProps, features: ManagementV1Feature[], license? : LicenseState) : Result<ClusterObject<ClusterV1Space>[]> {
    const spaces = [];
    for (let i = 0; i < selectedRowKeys.length; i++) {
        const spaceName = selectedRowKeys[i].toString().split("/");
        const space = getSpaceByName(props, spaceName[0], spaceName[1]);
        if (!space) {
            continue;
        }

        if (!checkEnabledSleepMode(features, license)) {
            return Return.Failed("Sleep mode not enabled");
        }

        const isSleeping = !!space.object?.status?.sleepModeConfig?.status?.sleepingSince;
        if (isSleeping == shouldSleep) {
            spaces.push(space)
        }
    }

    return Return.Value(spaces);
}


const spaceTemplate = (space: ClusterObject<ClusterV1Space>, templates: Array<ManagementV1SpaceTemplate> | undefined) => {
    const template = space.object?.metadata?.annotations?.[constants.LoftSpaceTemplate];
    if (template) {
        return templates?.find(t => t.metadata?.name === template);
    }

    return undefined;
}

async function reapplyTemplate(space: ClusterObject<ClusterV1Space>, templates: Array<ManagementV1SpaceTemplate> | undefined) {
    // find virtual cluster template
    const templateObj = spaceTemplate(space, templates);
    if (!templateObj) {
        return;
    }

    // update virtual cluster
    const oldSpace = deepCopy(space.object);
    space.object!.metadata!.annotations = templateObj.spec?.template?.metadata?.annotations;
    space.object!.metadata!.labels = templateObj.spec?.template?.metadata?.labels;

    // preserve old annotations
    if (!space.object!.metadata!.annotations) {
        space.object!.metadata!.annotations = {}
    }
    const annotations = [constants.LoftSpaceTemplate, "loft.sh/space-constraints-status", "sleepmode.loft.sh/last-activity"];
    for (let i = 0; i < annotations.length; i++) {
        if (oldSpace?.metadata?.annotations?.[annotations[i]]) {
            space.object!.metadata!.annotations[annotations[i]] = oldSpace?.metadata?.annotations?.[annotations[i]];
        }
    }

    // preserve old labels
    if (!space.object!.metadata!.labels) {
        space.object!.metadata!.labels = {}
    }
    const labels = ["kubernetes.io/metadata.name", "loft.sh/space-constraints"];
    for (let i = 0; i < labels.length; i++) {
        if (oldSpace?.metadata?.labels?.[labels[i]]) {
            space.object!.metadata!.labels[labels[i]] = oldSpace?.metadata?.labels?.[labels[i]];
        }
    }

    const patchResult = await client.cluster(space.cluster!, Resources.ClusterV1Space).PatchObject(oldSpace!, space.object!);
    if (patchResult.err) {
        ClientMessage.Error(patchResult);
    }

    return;
}

function getTableColumns(refetch: () => Promise<void>,
                         drawerDispatcher: DrawerDispatch,
                         user: UserContextState,
                         isClusterView: boolean,
                         metricsAPIAvailable: boolean,
                         license: LicenseState | undefined,
                         features: Array<ManagementV1Feature>,
                         spaces: Array<SpaceTableEntry> | undefined,
                         clusters: Array<ManagementV1Cluster> | undefined,
                         templates: Array<ManagementV1SpaceTemplate> | undefined) {
    const editSpace = (space: ClusterObject<ClusterV1Space>) => {
        drawerDispatcher({
            title: "Edit Space: " + space.object?.metadata?.name!,
            content: <SpaceDrawer mode={"update"} cluster={space.cluster} space={space.object} refetch={refetch} />
        })
    };
    const nameColumn = {
        title: <ToggleColumn id={"displayname"} columns={['Display Name', 'Kubernetes Name (ID)']}/>,
        sorter: (a: ClusterObject<ClusterV1Space>, b: ClusterObject<ClusterV1Space>) => nameSorter(a.object, b.object),
        render: (space: ClusterObject<ClusterV1Space>) => {
            return <ToggleColumnContent id={"displayname"} columns={[
                () => {
                    const name = space.object?.metadata?.annotations?.[constants.LoftDisplayNameAnnotation] ? space.object?.metadata?.annotations?.[constants.LoftDisplayNameAnnotation] : space.object?.metadata?.name;
                    return <Link to={`/spaces/${space.cluster}/${space.object?.metadata?.name}/pods`} className={styles["clickable"]}><FixedText text={name} /></Link>;
                },
                () => {
                    return <Link to={`/spaces/${space.cluster}/${space.object?.metadata?.name}/pods`} className={styles["clickable"]}><FixedText text={space.object?.metadata?.name} /></Link>;
                },
            ]}/>;
        }
    };
    const descriptionColumn: ColumnType<ClusterObject<ClusterV1Space>> = {
        title: "Description",
        sorter: (a, b) => stringSorter(a.object?.metadata?.annotations?.[constants.LoftDescriptionAnnotation] || "", b.object?.metadata?.annotations?.[constants.LoftDescriptionAnnotation] || ""),
        render: (space: ClusterObject<ClusterV1Space>) => {
            return <Description.Column style={{minWidth: "100px"}}>
                {space.object?.metadata?.annotations?.[constants.LoftDescriptionAnnotation] || ""}
            </Description.Column>;
        },
    };
    const statusColumn = {
        title: 'Status',
        showSorterTooltip: false,
        sorter: (a: ClusterObject<ClusterV1Space>, b: ClusterObject<ClusterV1Space>) => {
            const aValue = a.object?.status?.sleepModeConfig?.status?.sleepingSince ? "Sleeping" : a.object?.status?.phase+"";
            const bValue = b.object?.status?.sleepModeConfig?.status?.sleepingSince ? "Sleeping" : b.object?.status?.phase+"";
            return stringSorter(aValue, bValue);
        },
        filters: removeDuplicateFilters(arr(spaces).map(space => {
            const v = space.object?.status?.sleepModeConfig?.status?.sleepingSince ? "Sleeping" : space.object?.status?.phase+"";
            return {
                text: v,
                value: v + ""
            }
        })),
        onFilter: (value: string, space: ClusterObject<ClusterV1Space>) => {
            const v = space.object?.status?.sleepModeConfig?.status?.sleepingSince ? "Sleeping" : space.object?.status?.phase;
            return v === value;
        },
        render: (space: ClusterObject<ClusterV1Space>) => {
            return <IntervalRender interval={5000}>
                {
                    () => {
                        // is sleeping or terminating?
                        if (space.object?.status?.phase+"" === "Terminating") {
                            return <span className={styles["warning"]}>{space.object?.status?.phase}</span>
                        } else if (space.object?.status?.spaceConstraint && space.object?.status?.spaceConstraintStatus?.phase === "Error") {
                            return <Popover title={<span className={"color-text-dark-90"}>Space Constraint Sync Error</span>} content={<div className={styles["error-popover"]}>
                                <b>Error syncing Space Constraints <i>{getDisplayNameFromEntity(space.object?.status?.spaceConstraint)}</i></b>: {space.object?.status.spaceConstraintStatus.message + " (" + space.object?.status.spaceConstraintStatus.reason + ")"}
                            </div>}>
                                <span className={"color-error"}>
                                    <WarningOutlined />&nbsp;Error
                                </span>
                            </Popover>;
                        } 
                        
                        return <SleepPopover sleepModeConfig={space.object?.status?.sleepModeConfig} cluster={space.cluster} spaceName={space.object?.metadata?.name} spacePhase={space.object?.status?.phase+""} refetch={refetch} />
                    }
                }
            </IntervalRender>
        }
    };

    const templateColumn = {
        title: <Tooltip title={"The Space Template that was used to create this space"}>Template</Tooltip>,
        sorter: (a: ClusterObject<ClusterV1Space>, b: ClusterObject<ClusterV1Space>) => stringSorter(displayName(spaceTemplate(a, templates)), displayName(spaceTemplate(b, templates))),
        filters: removeDuplicateFilters(arr(spaces).map(space => {
            const cluster = displayName(spaceTemplate(space, templates));
            return {
                text: cluster,
                value: cluster + ""
            }
        })),
        onFilter: (value: string, record: ClusterObject<ClusterV1Space>) => displayName(spaceTemplate(record, templates)) === value,
        render: (space: ClusterObject<ClusterV1Space>) => {
            const template =  spaceTemplate(space, templates);
            if (!template) {
                return <span>None</span>;
            }
            
            return <Link to={`/spacetemplates#search=${formatURIComponent(displayName(template))}`} className={styles["cluster"]}><FixedText text={displayName(template)} /></Link>;
        }
    };
    const spaceConstraints = (space: ClusterObject<ClusterV1Space>) => {
        return space.object?.status?.spaceConstraint;
    }
    const spaceConstraintsColumn = {
        title: <Tooltip title={"The Space Constraints that are used to isolate this space"}>Constraints</Tooltip>,
        sorter: (a: ClusterObject<ClusterV1Space>, b: ClusterObject<ClusterV1Space>) => stringSorter(getDisplayNameFromEntity(spaceConstraints(a)), getDisplayNameFromEntity(spaceConstraints(b))),
        filters: removeDuplicateFilters(arr(spaces).map(space => {
            const cluster = getDisplayNameFromEntity(spaceConstraints(space));
            return {
                text: cluster,
                value: cluster + ""
            }
        })),
        onFilter: (value: string, record: ClusterObject<ClusterV1Space>) => getDisplayNameFromEntity(spaceConstraints(record)) === value,
        render: (space: ClusterObject<ClusterV1Space>) => {
            const constraints = spaceConstraints(space);
            if (!constraints) {
                return <span>None</span>;
            }

            return <Link to={`/clusters/spaceconstraints#search=${formatURIComponent(getDisplayNameFromEntity(constraints))}`} className={styles["cluster"]}><FixedText text={getDisplayNameFromEntity(constraints)} /></Link>;
        }
    };
    const displayNameCluster = (space: ClusterObject<ClusterV1Space>) => {
        const cluster = arr(clusters).find(cluster => cluster.metadata?.name === space.cluster);
        return cluster ? displayName(cluster) : space.cluster;
    }
    const clusterColumn = {
        title: 'Cluster',
        showSorterTooltip: false,
        sorter: (a: ClusterObject<ClusterV1Space>, b: ClusterObject<ClusterV1Space>) => stringSorter(displayNameCluster(a), displayNameCluster(b)),
        filters: !isClusterView ? removeDuplicateFilters(arr(spaces).map(space => {
            const cluster = displayNameCluster(space);
            return {
                text: cluster,
                value: cluster + ""
            }
        })) : undefined,
        onFilter: !isClusterView ? (value: string, record: ClusterObject<ClusterV1Space>) => displayNameCluster(record) === value : undefined,
        render: (space: ClusterObject<ClusterV1Space>) => {
            return <Link to={`/clusters/details/${space.cluster!}`} className={styles["cluster"]}><FixedText text={displayNameCluster(space)} /></Link>;
        }
    };
    const getOwnerNameFromSpace = (space: ClusterObject<ClusterV1Space>) => {
        const owner = space.object?.status?.owner;
        return owner?.user ? getDisplayName(owner.user.displayName, owner.user.username, owner.user.name) : owner?.team ? getDisplayName(owner.team.displayName, owner.team.username, owner.team.name) : (space.object?.spec?.user || space.object?.spec?.team) ? "Not Found" : "none";
    }
    const ownerColumn = {
        title: <ToggleColumn id={"owner"} columns={['Owner', 'Owner (Kubernetes)', 'Account (Kubernetes)']}/>,
        showSorterTooltip: false,
        sorter: (a: ClusterObject<ClusterV1Space>, b: ClusterObject<ClusterV1Space>) => {
            return stringSorter(getOwnerNameFromSpace(a), getOwnerNameFromSpace(b));
        },
        filters: removeDuplicateFilters(arr(spaces).map(space => {
            const v = getOwnerNameFromSpace(space);
            return {
                text: v,
                value: v + ""
            }
        })),
        onFilter: (value: string, space: ClusterObject<ClusterV1Space>) => {
            return getOwnerNameFromSpace(space) === value;
        },
        render: (space: ClusterObject<ClusterV1Space>) => {
            return <ToggleColumnContent id={"owner"} columns={[
                () => {
                    let owner = space.object?.status?.owner;
                    if (owner) {
                        if (owner.user) {
                            return <Owner displayName={owner.user.displayName} username={owner.user.username} kubeName={owner.user.name!} type={user?.metadata?.name === owner.user.name ? "dark" : undefined} />;
                        } else if (owner.team) {
                            return <Owner displayName={owner.team.displayName} username={owner.team.username} kubeName={owner.team.name!} isTeam={true} />;
                        }
                    }
                    if (space.object?.spec?.user || space.object?.spec?.team) {
                        return <Tooltip title={`Seems like the Owner of this Space was deleted or has no cluster access anymore. (Owner ${space.object.spec.user ? "User: " + space.object.spec.user : "Team: " + space.object.spec.team})`}>
                            <span className={"color-warning"}>
                                <WarningOutlined />&nbsp;Not Found
                            </span>
                        </Tooltip>
                    }

                    return "None";
                },
                () => {
                    let owner = space.object?.status?.owner;
                    if (owner) {
                        if (owner.user) {
                            return <Owner kubeName={owner.user.name!} type={user?.metadata?.name === owner.user.name ? "dark" : undefined} />;
                        } else if (owner.team) {
                            return <Owner kubeName={owner.team.name!} isTeam={true} />;
                        }
                    }

                    return "None";
                },
                () => {
                    if (space.object?.spec?.user) {
                        return <Owner kubeName={space.object?.spec?.user} type={user?.metadata?.name === space.object?.status?.owner?.user?.name ? "dark" : undefined} />;
                    } else if (space.object?.spec?.team) {
                        return <Owner kubeName={space.object?.spec?.team} isTeam={true} />;
                    }

                    return "None";
                }
            ]}/>
        }
    };
    const createdColumn = {
        title: 'Created',
        sorter: (a: ClusterObject<ClusterV1Space>, b: ClusterObject<ClusterV1Space>) => creationTimestampSorter(a.object, b.object),
        render: (space: ClusterObject<ClusterV1Space>) => {
            return <DynamicTime timestamp={space.object?.metadata?.creationTimestamp} useTooltip={true}/>
        }
    };
    const timeSleepingColumn = {
        title: <ToggleColumn id={"time-sleeping"} columns={['Time Sleeping (last 30 days)', 'Time Sleeping (last 7 days)']}/>,
        width: 240,
        render: (space: ClusterObject<ClusterV1Space>) => {
            return <ToggleColumnContent id={"time-sleeping"} columns={[
                () => {
                    if (space.object?.status?.sleepModeConfig?.status) {
                        return <SleepPopover sleepModeConfig={space.object?.status?.sleepModeConfig} cluster={space.cluster} spaceName={space.object?.metadata?.name} refetch={refetch}>
                            <Progress className={styles["progress"]} status={"normal"} percent={Math.round(space.object?.status?.sleepModeConfig?.status?.sleptLastThirtyDays! * 100)} />
                        </SleepPopover>;
                    }

                    return <SleepPopover sleepModeConfig={space.object?.status?.sleepModeConfig} cluster={space.cluster} spaceName={space.object?.metadata?.name} refetch={refetch}>
                        <Progress percent={0} />
                    </SleepPopover>;
                },
                () => {
                    if (space.object?.status?.sleepModeConfig?.status) {
                        return <SleepPopover sleepModeConfig={space.object?.status?.sleepModeConfig} cluster={space.cluster} spaceName={space.object?.metadata?.name} refetch={refetch}>
                            <Progress className={styles["progress"]} status={"normal"} percent={Math.round(space.object?.status?.sleepModeConfig?.status?.sleptLastSevenDays! * 100)} />
                        </SleepPopover>;
                    }

                    return <SleepPopover sleepModeConfig={space.object?.status?.sleepModeConfig} cluster={space.cluster} spaceName={space.object?.metadata?.name} refetch={refetch}>
                        <Progress className={styles["progress"]} percent={0} />
                    </SleepPopover>;
                }
            ]} />
        }
    };
    const actionsColumn = {
        title: 'Actions',
        width: "210px",
        render: (space: ClusterObject<ClusterV1Space>) => {
            const isSleeping = !!space.object?.status?.sleepModeConfig?.status?.sleepingSince;
            const spaceTemplateObject = spaceTemplate(space, templates);
            return <TableActions className={styles["actions"]}>
                <Tooltip title={"connect"}>
                    <ApiOutlined className={styles["wakeup"]} onClick={() => {
                        const copyText = `loft use space ${space.object?.metadata?.name!} --cluster ${space.cluster}`;
                        alert({
                            title: `Access the space via kubectl`,
                            okText: "Copy & Close",
                            onOkAsync: async () => copy(copyText),
                            content: <Space direction={"vertical"} size={10}>
                                <div>
                                    Make sure you have <a className={"text-bold color-primary"} href={"https://loft.sh/install-cli"} target={"_blank"}>loft CLI</a> installed and copy the following command to configure your local kubernetes context to use this space:
                                </div>
                                <Code text={copyText} />
                            </Space>
                        })
                    }} />
                </Tooltip>
                {spaceTemplateObject && <Tooltip title={"reapply template"}>
                    <RetweetOutlined className={styles["wakeup"]} onClick={async () =>
                    {
                        confirm({
                            title: `Re-apply Template`,
                            width: 700,
                            content: <div>
                                Are you sure you want to reapply the template on the selected virtual cluster?
                                This might override existing settings.
                            </div>,
                            okText: "Apply",
                            onOkAsync: async () => {
                                const message = ClientMessage.Loading();
                                await reapplyTemplate(space, templates!);
                                message.DoneManagement();
                                await refetch();
                            }
                        })
                    }} />
                </Tooltip>}
                {
                    isSleeping ? <Tooltip title={isSleeping ? "wakeup" : "sleep"}>
                        <Icon className={styles["wakeup"]} component={isSleeping ? WakeUpIcon as any : SleepingIcon as any} onClick={() => {
                            // check if sleep mode is enabled
                            if (!checkEnabledSleepMode(features, license)) {
                                return;
                            }

                            confirm({
                                title: `WakeUp Space: ${space.object?.metadata?.name}`,
                                content: `Are you sure you want to wake up the space ${space.object?.metadata?.name}?`,
                                onOkAsync: () => wakeUpSpace(space.object?.status?.sleepModeConfig, space.cluster, space.object?.metadata?.name, refetch),
                            });
                        }} />
                    </Tooltip> : <SleepPopup className={styles["wakeup"]}
                                             config={space.object?.status?.sleepModeConfig!} 
                                             spaceName={space.object?.metadata?.name} 
                                             cluster={space.cluster!} 
                                             refetch={refetch} />
                }
                <Tooltip title="edit">
                    <SettingOutlined className={styles["setting"]} onClick={() => editSpace(space)} />
                </Tooltip>
                <ShowYamlPopup className={styles["setting"]} object={space.object} cluster={space.cluster!} resource={Resources.ClusterV1Space} name={space.object?.metadata?.name!} refetch={refetch} />
                <Tooltip title="delete">
                    <DeleteOutlined className={styles["delete"]} onClick={() => {
                        deleteConfirm({
                            title: `Delete Space: ${space.object?.metadata?.name}`,
                            content: `Are you sure you want to delete the space ${space.object?.metadata?.name}?`,
                            onOkAsync: async () => {
                                const message = ClientMessage.Loading(space.cluster);
                                const result = await client.cluster(space.cluster!, Resources.ClusterV1Space).Delete(space.object?.metadata?.name!);
                                message.Result(result);
                                await refetch();
                            },
                        });
                    }} />
                </Tooltip>
            </TableActions>;
        }
    };

    if (isClusterView) {
        const metricsColumns = [];
        if (metricsAPIAvailable) {
            metricsColumns.push({
                title: 'Pods',
                sorter: (a: SpaceTableEntry, b: SpaceTableEntry) => {
                    return numberSorter(arr(a.metrics).length, arr(b.metrics).length);
                },
                render: (space: SpaceTableEntry) => {
                    return <Link to={`/spaces/${space.cluster!}/${space.object?.metadata?.name!}/metrics`} className={styles["cluster"]}><FixedText text={arr(space.metrics).length + ""} /></Link>;
                }
            },
            {
                title: 'CPU',
                sorter: (a: SpaceTableEntry, b: SpaceTableEntry) => {
                    return numberSorter(aggregateCores(a), aggregateCores(b));
                },
                render: (space: SpaceTableEntry) => {
                    const cores = aggregateCores(space);
                    if (cores > 1.5) {
                        return <Link to={`/spaces/${space.cluster!}/${space.object?.metadata?.name!}/metrics`}><Warning tooltip={"High CPU Usage"} text={cores.toFixed(2) + " Cores"} /></Link>;
                    }
                    
                    return <Link to={`/spaces/${space.cluster!}/${space.object?.metadata?.name!}/metrics`} className={styles["cluster"]}><FixedText text={cores.toFixed(2) + " Cores"} /></Link>;
                }
            },
            {
                title: 'Memory',
                sorter: (a: SpaceTableEntry, b: SpaceTableEntry) => {
                    return numberSorter(aggregateMemory(a), aggregateMemory(b));
                },
                render: (space: SpaceTableEntry) => {
                    const memory = aggregateMemory(space) / 1024 / 1024 / 1024;
                    if (memory > 2) {
                        return <Link to={`/spaces/${space.cluster!}/${space.object?.metadata?.name!}/metrics`}><Warning tooltip={"High Memory Usage"} text={memory.toFixed(1) + " GB"} /></Link>;
                    }
                    
                    return <Link to={`/spaces/${space.cluster!}/${space.object?.metadata?.name!}/metrics`} className={styles["cluster"]}><FixedText text={memory.toFixed(1) + " GB"} /></Link>;
                }
            });
        }
        
        return [
            nameColumn,
            statusColumn,
            descriptionColumn,
            templateColumn,
            ownerColumn,
            ...metricsColumns,
            spaceConstraintsColumn,
            createdColumn,
            timeSleepingColumn,
            actionsColumn,
        ] as any;
    }

    return [
        nameColumn,
        statusColumn,
        descriptionColumn,
        templateColumn,
        clusterColumn,
        ownerColumn,
        spaceConstraintsColumn,
        createdColumn,
        timeSleepingColumn,
        actionsColumn,
    ] as any;
}

function filterOwner(entity: ClusterV1EntityInfo, value: string) {
    return entity.name?.includes(value) || (entity.username && entity.username.includes(value)) || (entity.displayName && entity.displayName.includes(value)) || (entity.email && entity.email.includes(value))
}

function filter(item: ClusterObject<ClusterV1Space>, value: string) {
    return !!(item.object?.metadata?.annotations?.[constants.LoftDisplayNameAnnotation]?.includes(value) || item.object?.metadata?.annotations?.[constants.LoftDescriptionAnnotation]?.includes(value) || item.object?.metadata?.name?.includes(value) || item.cluster?.includes(value) || (item.object?.status?.owner && item.object?.status?.owner.team && filterOwner(item.object?.status?.owner.team, value)) || (item.object?.status?.owner?.user && filterOwner(item.object?.status?.owner.user, value)) || item.object?.spec?.user?.includes(value) || item.object?.spec?.team?.includes(value));
}

export interface SpaceTableProps {
    cluster?: string;
    left?: React.ReactNode;
    top?: React.ReactNode;

    error?: ResultError;
    loading: boolean;
    clusters?: Array<ManagementV1Cluster>;
    spaces: Array<ClusterObject<ClusterV1Space>> | undefined;
    refetch: () => Promise<void>;
}

export function SpaceTable(props: SpaceTableProps) {
    const features = useFeatures();
    const license = useLicense();
    const userContextState = useUser();
    const drawerDispatcher = useItemDrawer();
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
    const {error: templateError, data: templateData} = useQuery(async () => await client.management(Resources.ManagementV1SpaceTemplate).List())
    const {error, data, refetch} = useQuery(async () => await client.cluster(props.cluster!, Resources.V1Beta1PodMetrics).List(), {skip: !props.cluster})
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedKeys: any) => {
            setSelectedRowKeys(selectedKeys);
        },
    };
    
    let newRefetch = props.refetch;
    if (props.cluster) {
        newRefetch = async () => {
            await props.refetch();
            refetch();
        }
    }
    
    let tableData = props.spaces ? arr(props.spaces).map(space => { return {...space, key: space.cluster+"/"+space.object!.metadata!.name!}}) : undefined;
    if (tableData && data) {
        tableData = arr(tableData).map(space => ({...space, metrics: data.items.filter(metric => metric.metadata?.namespace === space.object?.metadata?.name)}));
    }

    const metricsAPIAvailable = !!data;
    const isClusterView = !!props.cluster;
    return <Table className={styles["table"]} 
                  loading={!props.spaces && props.loading} 
                  columns={getTableColumns(newRefetch, drawerDispatcher, userContextState, isClusterView, metricsAPIAvailable, license, features, props.spaces, props.clusters, templateData?.items)} 
                  dataSource={tableData} 
                  error={props.error || templateError} 
                  rowSelection={rowSelection} 
                  filter={filter} 
                  refetch={newRefetch} 
                  header={{
        top: props.top,
        left: props.left ? props.left : isClusterView && !metricsAPIAvailable ? <Tooltip placement="topLeft" title={<span>Please make sure you have the <a href={"https://github.com/kubernetes-sigs/metrics-server"} target={"_blank"}>metrics server</a> installed to view all available columns. <br /><br /> Error retrieving metrics: {error?.val?.message}</span>}><span className={styles["no-metrics-api"]}><WarningOutlined /></span></Tooltip> : undefined,
        right: <Query query={async () => {
            const result = await client.management(Resources.ManagementV1Cluster).List();
            if (result.err) {
                return result;
            }

            return Return.Value(arr(result.val.items).length > 0)
        }} skip={!!props.cluster}>
            {
                result => {
                    if (!result.skipped) {
                        if (result.error) {
                            return <ErrorMessage error={result.error} />
                        } else if (result.loading || !result.data) {
                            return null;
                        }
                    }

                    return <Button type={"primary"} onClick={() => {
                        drawerDispatcher({
                            title: "Create Space",
                            content: <SpaceDrawer mode={"create"} cluster={props.cluster} refetch={newRefetch} />
                        })
                    }}>Create Space</Button>
                }
            }
        </Query>,
        selectedActions: <React.Fragment>
        <Tooltip title={"wakeup"}>
          <Icon className={styles["wakeup-batch"]} component={WakeUpIcon as any} onClick={() => {
            const spacesResult = getSpacesWithSleepingState(true, selectedRowKeys, props, features, license);
            if (spacesResult.err){
              return
            }

            const spacesToWakeUp = spacesResult.val;
            if (spacesToWakeUp.length) {
              const spaceNames = spacesToWakeUp.map(space => space.object?.metadata?.name).reduce((name1, name2) => name1 + ", " + name2);
              confirm({
                title: `WakeUp Spaces: ${spaceNames}`,
                content: `Are you sure you want to wake up the space(s) ${spaceNames}?`,
                onOkAsync: async() => {
                  for (let space of spacesToWakeUp) {
                    await wakeUpSpace(space.object?.status?.sleepModeConfig, space.cluster, space.object?.metadata?.name);
                  }
                  await newRefetch();
                  setSelectedRowKeys([]);
                }
              });
            } else {
              confirm({
                title: `No sleeping spaces selected`,
                content: `Please select at least one sleeping space`,
                hideCancel: true
              })
            }
          }}/>
         </Tooltip>
           <Tooltip title={"sleep"}>
            <Icon className={styles["wakeup-batch"]} component={SleepingIcon as any} onClick={() => {
              const spacesResult = getSpacesWithSleepingState(false, selectedRowKeys, props, features, license);
              if (spacesResult.err){
                return
              }

              const spacesforSleep = spacesResult.val;
              if(spacesforSleep.length){
                const spaceNames = spacesforSleep.map(space => space.object?.metadata?.name).reduce((name1, name2) => name1 + " " + name2);

                confirm({
                  title: `Put Spaces ${spaceNames} to Sleep`,
                  content: `Are you sure you want to put the space(s) ${spaceNames} to sleep? This will scale down all deployments, replicasets and statefulsets to zero.`,
                  onOkAsync: async () => {
                    for(let space of spacesforSleep){
                      const message = ClientMessage.Loading(space.cluster);
                      const config = space.object?.status?.sleepModeConfig;
                      if (config?.spec) {
                        config.spec.forceSleep = true;
                      }

                      const result = await client.cluster(space.cluster!, Resources.ClusterV1SleepModeConfig).Namespace(space.object?.metadata?.name!).Create(config!);
                      message.Result(result);
                      setSelectedRowKeys([]);
                    }
                    await newRefetch();
                  }
                });
              } else {
                confirm({
                  title: `No non-sleeping spaces selected`,
                  content: `Please select at least one non-sleeping space`,
                  hideCancel: true
                })
              }
            }}/>
            </Tooltip>
            <Tooltip title={"edit"}>
                <SettingOutlined className={styles["setting-batch"]} onClick={() =>
                {
                    let cluster = "";
                    const spaces: ClusterV1Space[] = [];
                    for (let i = 0; i < selectedRowKeys.length; i++) {
                        const spaceName = selectedRowKeys[i].toString().split("/");
                        const space = getSpaceByName(props, spaceName[0], spaceName[1]);
                        if (!space) {
                            continue;
                        } else if (!cluster) {
                            cluster = space.cluster!;
                        } else if (cluster !== space.cluster) {
                            alert({
                                title: "Alert",
                                content: "Please only select spaces that are in the same cluster!"
                            });

                            return;
                        }

                        spaces.push(space.object!);
                    }

                    if (spaces.length === 0) {
                        return;
                    }

                    if (spaces.length === 1) {
                        const space = spaces[0];
                        drawerDispatcher({
                            title: "Edit Space: " + space?.metadata?.name!,
                            content: <SpaceDrawer mode={"update"} cluster={cluster} space={space} refetch={newRefetch} />
                        })
                    } else {
                        drawerDispatcher({
                            title: "Bulk Edit Selected Spaces",
                            content: <SpaceDrawer mode={"batch"} cluster={cluster} spaces={spaces} refetch={newRefetch} />
                        })
                    }

                    setSelectedRowKeys([]);
                }} />
            </Tooltip>
            <Tooltip title={"delete"}>
                <DeleteOutlined className={styles["delete-batch"]} onClick={() =>
                {
                    deleteConfirm({
                        title: `Delete Spaces`,
                        content: `Are you sure you want to delete the spaces ${selectedRowKeys.join(", ")}?`,
                        onOkAsync: async () => {
                            let message: ClientMessage | undefined = undefined;
                            let lastCluster: string = "";

                            for (let i = 0; i < selectedRowKeys.length; i++) {
                                const spaceName = selectedRowKeys[i].toString().split("/");
                                const space = getSpaceByName(props, spaceName[0], spaceName[1]);
                                if (!space) {
                                    continue;
                                } else if (!message) {
                                    message = ClientMessage.Loading(space.cluster);
                                } else {
                                    message.Loading(space.cluster);
                                }

                                const result = await client.cluster(space.cluster!, Resources.ClusterV1Space).Delete(space.object?.metadata?.name!);
                                if (result.err) {
                                    message.Error(result);
                                    return;
                                }

                                lastCluster = space.cluster!;
                            }

                            message?.DoneCluster(lastCluster);
                            await newRefetch();
                            setSelectedRowKeys([]);
                        },
                    });
                }} />
            </Tooltip>
        </React.Fragment>
    }} />
}
