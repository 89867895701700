import React from "react";
import {useRouteMatch} from "react-router-dom";
import SpacesHeader from "../SpacesHeader/SpacesHeader";
import PodsTable from "./PodsTable/PodsTable";

export default function Pods() {
    const match = useRouteMatch();
    const {cluster, space} = match.params as any;

    return <React.Fragment>
        <PodsTable cluster={cluster}
                   namespace={space}
                   top={<SpacesHeader />} />
    </React.Fragment>
}