import React, {useRef} from "react";
import ItemDrawer, {SectionProps} from "../../../../components/Drawer/ItemDrawer";
import Metadata from "../../../../components/Drawer/Sections/Metadata/Metadata";
import {ResultError, Return} from "../../../../lib/result";
import {NewResource, Resources} from "../../../../lib/resources";
import client from "../../../../lib/client";
import {useItemDrawer} from "../../../../contexts/drawer/DrawerContext";
import ClientMessage from "../../../../lib/Message/ClientMessage";
import {ManagementV1VirtualClusterTemplate} from "../../../../../gen/model/managementV1VirtualClusterTemplate";
import Access from "../../../../components/Drawer/Sections/Access/Access";
import {useUser} from "../../../../contexts/UserContext/UserContext";
import VClusterTemplateAdvancedOptions from "./Sections/VClusterTemplateAdvancedOptions";
import {ErrorMessage} from "../../../../components/ErrorMessage/ErrorMessage";
import Query from "../../../../components/Query/Query";
import SpaceTemplateSection from "./Sections/SpaceTemplateSection";
import VClusterTemplateAccess from "./Sections/VClusterTemplateAccess";

export interface VClusterTemplateDrawerProps extends SectionProps {
    virtualClusterTemplate?: ManagementV1VirtualClusterTemplate;
    allVirtualClusterTemplates: ManagementV1VirtualClusterTemplate[];
    
    refetch: () => Promise<void>;
}

type ChangeFunctionProps = Omit<VClusterTemplateDrawerProps, "mode"|"allVirtualClusterTemplates"|"refetch"> & {
    spaceTemplateSectionRef: SpaceTemplateSection | null,
    vClusterTemplateAccess: VClusterTemplateAccess,
    vClusterTemplateAdvancedOptionsRef: VClusterTemplateAdvancedOptions,
    accessRef: Access | null,
    metadataRef: Metadata
};

async function onCreate({vClusterTemplateAdvancedOptionsRef, metadataRef, vClusterTemplateAccess, spaceTemplateSectionRef, accessRef}: ChangeFunctionProps): Promise<ResultError> {
    // make sure we have an object
    let virtualClusterTemplate = NewResource(Resources.ManagementV1VirtualClusterTemplate, undefined, {spec: {}});

    // apply metadata
    const applyMetadataResult = await metadataRef.create(virtualClusterTemplate, async metadata => {
        metadata.generateName = "vcluster-template-";
        return Return.Ok();
    });
    if (applyMetadataResult.err) {
        return applyMetadataResult;
    }

    // apply access
    const accessResult = await accessRef?.create(virtualClusterTemplate);
    if (accessResult?.err) {
        return accessResult;
    }
    
    // apply advanced options
    const optionsResult = await vClusterTemplateAdvancedOptionsRef?.create(virtualClusterTemplate);
    if (optionsResult?.err) {
        return optionsResult;
    }

    // apply access
    const templateAccessResult = await vClusterTemplateAccess?.create(virtualClusterTemplate);
    if (templateAccessResult?.err) {
        return templateAccessResult;
    }

    // apply space template
    const spaceTemplateResult = spaceTemplateSectionRef?.create(virtualClusterTemplate);
    if (spaceTemplateResult?.err) {
        return spaceTemplateResult;
    }

    // create
    const createResult = await client.management(Resources.ManagementV1VirtualClusterTemplate).Create(virtualClusterTemplate);
    if (createResult.err) {
        return createResult;
    }

    return Return.Ok();
}

async function onUpdate({virtualClusterTemplate: originalVirtualClusterTemplate, vClusterTemplateAccess, spaceTemplateSectionRef, vClusterTemplateAdvancedOptionsRef, accessRef, metadataRef}: ChangeFunctionProps): Promise<ResultError> {
    if (!originalVirtualClusterTemplate) {
        return Return.Ok();
    }

    // make sure the object is up-to-date
    const getResult = await client.management(Resources.ManagementV1VirtualClusterTemplate).Get(originalVirtualClusterTemplate.metadata?.name!);
    if (getResult.err) {
        return getResult;
    }

    // set object
    let virtualClusterTemplate = getResult.val;

    // apply metadata
    const applyMetadataResult = await metadataRef.update(virtualClusterTemplate);
    if (applyMetadataResult.err) {
        return applyMetadataResult;
    }

    // apply access
    const accessResult = await accessRef?.update(virtualClusterTemplate);
    if (accessResult?.err) {
        return accessResult;
    }

    // apply access
    const templateAccessResult = await vClusterTemplateAccess?.create(virtualClusterTemplate);
    if (templateAccessResult?.err) {
        return templateAccessResult;
    }

    // apply advanced options
    const optionsResult = await vClusterTemplateAdvancedOptionsRef?.update(virtualClusterTemplate);
    if (optionsResult?.err) {
        return optionsResult;
    }

    // apply space template
    const spaceTemplateResult = spaceTemplateSectionRef?.update(virtualClusterTemplate);
    if (spaceTemplateResult?.err) {
        return spaceTemplateResult;
    }

    // update
    const updateResult = await client.management(Resources.ManagementV1VirtualClusterTemplate).Update(virtualClusterTemplate.metadata?.name!, virtualClusterTemplate!);
    if (updateResult.err) {
        return updateResult;
    }

    return Return.Ok();
}

export default function VClusterTemplateDrawer(props: VClusterTemplateDrawerProps) {
    const drawer = useItemDrawer();
    const user = useUser();
    const metadataRef = useRef<Metadata>(null);
    const accessRef = useRef<Access>(null);
    const vClusterTemplateAccess = useRef<VClusterTemplateAccess>(null);
    const vClusterTemplateAdvancedOptionsRef = useRef<VClusterTemplateAdvancedOptions>(null);
    const spaceTemplateSectionRef = useRef<SpaceTemplateSection>(null);

    return <ItemDrawer okButtonText={props.mode === "create" ? "Create" : "Update"} onOkAsync={async () => {
        const message = ClientMessage.Loading();

        // execute the create / update / batch logic
        let result: ResultError | undefined = undefined;
        if (props.mode === "create") {
            result = await onCreate({vClusterTemplateAccess: vClusterTemplateAccess.current!, spaceTemplateSectionRef: spaceTemplateSectionRef.current!, vClusterTemplateAdvancedOptionsRef: vClusterTemplateAdvancedOptionsRef.current!, accessRef: accessRef.current!, metadataRef: metadataRef.current!});
        } else if (props.mode === "update") {
            result = await onUpdate({vClusterTemplateAccess: vClusterTemplateAccess.current!, spaceTemplateSectionRef: spaceTemplateSectionRef.current!, vClusterTemplateAdvancedOptionsRef: vClusterTemplateAdvancedOptionsRef.current!, accessRef: accessRef.current!, virtualClusterTemplate: props.virtualClusterTemplate, metadataRef: metadataRef.current!});
        }

        // check if there was an error
        if (result?.err) {
            message.Error(result);
            return;
        }

        // refetch
        props.refetch();

        message.DoneManagement();

        // close drawer
        drawer({});
    }}>
        <Metadata mode={props.mode} type={"Virtual Cluster Template"} obj={props.virtualClusterTemplate} showDisplayName={true} showDescription={true} noMargin ref={metadataRef} />
        <VClusterTemplateAdvancedOptions mode={props.mode} vClusterTemplate={props.virtualClusterTemplate} ref={vClusterTemplateAdvancedOptionsRef} />
        <SpaceTemplateSection mode={props.mode} virtualClusterTemplate={props.virtualClusterTemplate} ref={spaceTemplateSectionRef} />
        <VClusterTemplateAccess vClusterTemplate={props.virtualClusterTemplate} mode={props.mode} ref={vClusterTemplateAccess} />
        <Query query={async () => await client.management(Resources.ManagementV1User).CanI("bind")} skip={!!props.virtualClusterTemplate}>
            {
                result => {
                    if (result.error) {
                        return <ErrorMessage error={result.error} />
                    } else if (result.loading) {
                        return null;
                    }

                    return <Access mode={props.mode} kind={"Virtual Cluster Template"} user={user} defaultRules={result.data ? [
                        {
                            users: [user?.metadata?.name!],
                            subresources: ["*"],
                            verbs: ["*"]
                        },
                        {
                            users: ["*"],
                            subresources: ["*"],
                            verbs: ["get"]
                        }
                    ] : undefined} access={props.virtualClusterTemplate} ref={accessRef} />
                }
            }
        </Query>
    </ItemDrawer>
}
