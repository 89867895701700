import React from "react";
import {V1ObjectMeta} from "@kubernetes/client-node";
import Label from "../../../Label/Label";
import SectionExpander from "../../SectionExpander/SectionExpander";
import TextArea from "../../../TextArea/TextArea";
import {Result, ResultError, Return} from "../../../../lib/result";
import {SectionProps} from "../../ItemDrawer";
import {arr, filterObj} from "../../../../lib/helpers/renderhelper";
import jsyaml from "js-yaml";

type Map = {
    [key: string]: string;
};

export function parseMap(map?: Map, filterKeys?: string[]) {
    if (!map) {
        return "";
    }

    const newMap: Map = {};
    for(let key in map) {
        if (filterKeys && filterKeys.indexOf(key) !== -1)
            continue;

        newMap[key] = map[key];
    }

    return Object.keys(newMap).length ? jsyaml.dump(newMap) : "";
}

export function convertToMap(str?: string, filterKeys?: string[]): Result<Map> {
    if (!str) {
        return Return.Value({});
    }

    try {
        const map: Map = jsyaml.load(str);
        const newMap: Map = {};
        for(let key in map) {
            if (filterKeys && filterKeys.indexOf(key) !== -1)
                continue;

            newMap[key] = (map[key] + "").trim();
        }

        return Return.Value(newMap);
    } catch(err) {
        return Return.Failed(err + "")
    }
}

interface MetadataState {
    labels: string | undefined;
    labelsChanged: boolean;
    labelsInitial: string | undefined;

    annotations: string | undefined;
    annotationsChanged: boolean;
    annotationsInitial: string | undefined;
}

export interface LabelsAnnotationsSectionProps extends SectionProps {
    filterLabels?: string[];
    filterAnnotations?: string[];

    metadata?: V1ObjectMeta;
    name?: string;
}

export default class LabelsAnnotationsSection extends React.PureComponent<LabelsAnnotationsSectionProps, MetadataState> {
    state: MetadataState = {
        labels: this.props.metadata && this.props.metadata?.labels ? parseMap(this.props.metadata?.labels, this.props.filterLabels) : "",
        labelsChanged: false,
        labelsInitial: this.props.metadata && this.props.metadata?.labels ? parseMap(this.props.metadata?.labels, this.props.filterLabels) : "",

        annotations: this.props.metadata && this.props.metadata?.annotations ? parseMap(this.props.metadata?.annotations, this.props.filterAnnotations) : "",
        annotationsChanged: false,
        annotationsInitial: this.props.metadata && this.props.metadata?.annotations ? parseMap(this.props.metadata?.annotations, this.props.filterAnnotations) : "",
    };

    create = (metadata: V1ObjectMeta): ResultError => {
        return this.update(metadata);
    };

    update = (metadata: V1ObjectMeta): ResultError => {
        if (this.props.mode === "create" || this.state.annotationsChanged) {
            if (this.state.annotations !== undefined) {
                const parsed = convertToMap(this.state.annotations, this.props.filterAnnotations);
                if (parsed.err) {
                    return parsed;
                }
                if (!metadata.annotations) {
                    metadata.annotations = {}
                }

                metadata.annotations = {...filterObj(metadata.annotations, (_, key) => (this.props.filterAnnotations && this.props.filterAnnotations.indexOf(key) !== -1)), ...parsed.val}
            }
        }

        if (this.props.mode === "create" || this.state.labelsChanged) {
            if (this.state.labels !== undefined) {
                const parsed = convertToMap(this.state.labels, this.props.filterLabels);
                if (parsed.err) {
                    return parsed;
                }
                if (!metadata.labels) {
                    metadata.labels = {}
                }

                metadata.labels = {...filterObj(metadata.labels, (_, key) => (this.props.filterLabels && this.props.filterLabels.indexOf(key) !== -1)), ...parsed.val}
            }
        }

        return Return.Ok();
    };

    batch = (metadatas: V1ObjectMeta[]): ResultError => {
        for(let i = 0; i < metadatas.length; i++) {
            const result = this.update(metadatas[i]);
            if (result.err) {
                return result;
            }
        }

        return Return.Ok();
    };

    changed() {
        return this.state.labelsChanged || this.state.annotationsChanged;
    }

    setMetadata(metadata?: V1ObjectMeta) {
        this.setState({
            annotationsChanged: true,
            annotations: metadata?.annotations ? parseMap(metadata?.annotations, this.props.filterAnnotations) : "",

            labelsChanged: true,
            labels: metadata?.labels ? parseMap(metadata?.labels, this.props.filterLabels) : "",
        });
    };

    render() {
        return <SectionExpander name={this.props.name || "Labels & Annotations"}>
            <Label>Labels</Label>
            <TextArea resetable={this.props.mode !== "create"}
                      changed={this.state.labelsChanged}
                      initial={this.state.labelsInitial}
                      value={this.state.labels}
                      onChangedStatus={(changed) => this.setState({labelsChanged: changed})}
                      onChange={(e) => this.setState({labels: e.target.value})}
                      placeholder={`label1: value1
label2: value2`} />
            <Label>Annotations</Label>
            <TextArea resetable={this.props.mode !== "create"}
                      changed={this.state.annotationsChanged}
                      initial={this.state.annotationsInitial}
                      value={this.state.annotations}
                      onChangedStatus={(changed) => this.setState({annotationsChanged: changed})}
                      onChange={(e) => this.setState({annotations: e.target.value})}
                      placeholder={`annotation1: value1
annotation2: value2`} />
        </SectionExpander>
    }
}