import React from "react";
import {useRouteMatch} from "react-router-dom";
import {ResourceTable} from "../../../../components/ResourceTable/ResourceTable";
import {Resources} from "../../../../lib/resources";
import SpacesHeader from "../SpacesHeader/SpacesHeader";

export default function Secrets() {
    const match = useRouteMatch();
    const {cluster, space} = match.params as any;

    return <ResourceTable cluster={cluster}
                          namespace={space}
                          top={<SpacesHeader />}
                          resource={Resources.V1Secret} />
}