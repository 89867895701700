import React, {useRef, useState} from "react";
import ItemDrawer, {SectionProps} from "../../../../components/Drawer/ItemDrawer";
import {NewResource, Resources} from "../../../../lib/resources";
import ClientMessage from "../../../../lib/Message/ClientMessage";
import {Err, ErrorTypeNotFound, ResultError, Return} from "../../../../lib/result";
import client from "../../../../lib/client";
import {useDrawerDispatcher} from "../../../../contexts/drawer/DrawerContext";
import VClusterLocation from "./Sections/VClusterLocation";
import VClusterMetadata from "./Sections/VClusterMetadata";
import VClusterAdvancedOptions from "./Sections/VClusterAdvancedOptions";
import {StorageV1VirtualCluster} from "../../../../../gen/model/agentstorageV1VirtualCluster";
import {useUser} from "../../../../contexts/UserContext/UserContext";
import {ManagementV1User} from "../../../../../gen/model/managementV1User";
import {arr} from "../../../../lib/helpers/renderhelper";
import {ErrorMessage} from "../../../../components/ErrorMessage/ErrorMessage";
import VClusterAccess from "./Sections/VClusterAccess";
import {ProgressPopup} from "../../../../components/ProgressPopup/ProgressPopup";
import {ManagementV1VirtualClusterTemplate} from "../../../../../gen/model/managementV1VirtualClusterTemplate";
import {getDefaultSpaceTemplate} from "../../../Spaces/Spaces/SpaceDrawer/Sections/SpaceOwner";
import {ManagementV1App} from "../../../../../gen/model/managementV1App";
import {streamTask} from "../../../Spaces/Spaces/SpaceDrawer/SpaceDrawer";
import AppParametersDrawer, {
    AppWithNamespace,
    AppWithParameters
} from "../../../../components/Drawer/AppParametersDrawer/AppParametersDrawer";
import constants from "../../../../constants/constants";

export interface VClusterDrawerProps extends SectionProps {
    cluster?: string;

    vCluster?: StorageV1VirtualCluster;
    vClusters?: StorageV1VirtualCluster[];
    refetch: () => Promise<void>;
}

type ChangeFunctionProps = Omit<VClusterDrawerProps, "mode"> & {apps: AppWithParameters[] | undefined, template: ManagementV1VirtualClusterTemplate | undefined, progressPopupRef: ProgressPopup | null, vClusterAccessRef: VClusterAccess | null, userState: ManagementV1User, vClusterLocationRef: VClusterLocation | null, vClusterMetadataRef: VClusterMetadata | null, vClusterAdvancedOptionsRef: VClusterAdvancedOptions | null};

async function onCreate({apps, cluster, template, progressPopupRef, userState, vClusterAccessRef, vClusterLocationRef, vClusterMetadataRef, vClusterAdvancedOptionsRef}: ChangeFunctionProps) {
    if (!cluster) {
        return Return.Ok();
    }
    
    // make sure the terminal is cleared
    progressPopupRef?.clear();

    // make sure we have an object
    const vCluster = NewResource(Resources.StorageV1VirtualCluster);
    
    // apply cluster location
    let result = vClusterLocationRef?.create({vCluster, cluster});
    if (result?.err) {
        return result;
    }

    // apply metadata
    result = await vClusterMetadataRef?.create(vCluster);
    if (result?.err) {
        return result;
    }

    // apply access
    const createAccessResult = await vClusterAccessRef?.create(vCluster);
    if (createAccessResult?.err) {
        return createAccessResult;
    }

    // apply sleep mode
    result = await vClusterAdvancedOptionsRef?.create(vCluster);
    if (result?.err) {
        return result;
    }

    // create namespace if it does not exist
    const task = NewResource(Resources.ManagementV1Task);
    task.spec = {
        displayName: "Create Virtual Cluster "+vCluster.metadata?.name,
        target: {
            cluster: {
                cluster: cluster,
            }
        },
        task: {
            virtualClusterCreation: {
                metadata: vCluster.metadata,
                apps: arr(apps).map(app => {
                    return {
                        name: app.app?.metadata?.name,
                        namespace: app.namespace,
                        parameters: app.parameters,
                    }
                }),
                access: vCluster.spec?.access,
                helmRelease: vCluster.spec?.helmRelease,
            }
        },
    }
    const spaceResult = await client.cluster(cluster, Resources.ClusterV1Space).Get(vCluster.metadata?.namespace!)
    if (spaceResult?.err) {
        if (spaceResult.val.type !== ErrorTypeNotFound) {
            return spaceResult;
        }

        // check if admin
        const canIResult = await client.cluster(cluster, Resources.V1Namespace).CanI("create");
        if (canIResult.err) {
            return canIResult;
        }

        // if we are not admin we try to get a cluster access we can use to create a space
        const spaceToCreate = NewResource(Resources.ClusterV1Space, vCluster.metadata?.namespace!, {spec:{}})

        // get user & teams
        const clusterAccessResult = await client.management(Resources.ManagementV1ClusterMemberAccess).Get(cluster);
        if (clusterAccessResult.err) {
            return clusterAccessResult;
        }

        // set the space user to the user we can use
        if (arr(clusterAccessResult.val.users).length > 0) {
            spaceToCreate.spec!.user = clusterAccessResult.val?.users?.[0].info?.name;
        } else if (arr(clusterAccessResult.val.teams).length > 0) {
            spaceToCreate.spec!.team = clusterAccessResult.val?.teams?.[0].info?.name;
        } else if (!canIResult.val) {
            return Return.Failed("no user or team found to create virtual cluster space")
        }
        
        // try to find space template
        let spaceTemplate = template?.spec?.spaceTemplateRef?.name;
        if (!spaceTemplate) {
            const defaultSpaceTemplateResult = await getDefaultSpaceTemplate(cluster);
            if (defaultSpaceTemplateResult.err) {
                return defaultSpaceTemplateResult;
            } else if (defaultSpaceTemplateResult.val) {
                spaceTemplate = defaultSpaceTemplateResult.val;
            }
        }
        
        // get space template
        let spaceApps: Array<ManagementV1App> = [];
        if (spaceTemplate) {
            const spaceTemplateResult = await client.management(Resources.ManagementV1SpaceTemplate).Get(spaceTemplate);
            if (spaceTemplateResult.err) {
                return spaceTemplateResult;
            }
            
            const resolvedAppsResult = vClusterAdvancedOptionsRef?.resolveApps(arr(spaceTemplateResult.val?.spec?.template?.apps).map(app => app.name!));
            if (resolvedAppsResult?.err) {
                return Return.Failed("Error resolving space template apps: " + resolvedAppsResult.val.message);
            }
            
            spaceApps = arr(resolvedAppsResult?.val);
            
            // set annotations & labels
            if (!spaceToCreate.metadata) {
                spaceToCreate.metadata = {};
            }
            spaceToCreate.metadata.labels = spaceTemplateResult.val.metadata?.labels;
            spaceToCreate.metadata.annotations = spaceTemplateResult.val.metadata?.annotations;
            if (!spaceToCreate.metadata.annotations) {
                spaceToCreate.metadata.annotations = {}
            }
            spaceToCreate.metadata.annotations[constants.LoftSpaceTemplate] = spaceTemplateResult.val.metadata?.name + "";
        }
        
        // add timezone
        if (!spaceToCreate.metadata) {
            spaceToCreate.metadata = {}
        }
        if (!spaceToCreate.metadata.annotations) {
            spaceToCreate.metadata.annotations = {}
        }
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (timezone) {
            spaceToCreate.metadata.annotations["sleepmode.loft.sh/timezone"] = timezone;
        }
        if (!spaceToCreate.metadata.annotations[constants.LoftDescriptionAnnotation]) {
            spaceToCreate.metadata.annotations[constants.LoftDescriptionAnnotation] = `Space for Virtual Cluster [${vCluster.metadata?.name}](/vclusters#search=${vCluster.metadata?.name})`
        }
        spaceToCreate.metadata.annotations["loft.sh/vcluster-space"] = "true"
        
        if (spaceApps.length > 0 || arr(apps).length > 0) {
           task.spec.task!.virtualClusterCreation!.spaceCreation = {
               metadata: spaceToCreate.metadata,
               owner: {
                   user: spaceToCreate.spec?.user,
                   team: spaceToCreate.spec?.team
               },
               apps: arr(spaceApps).map(app => {
                   return {
                       name: app.metadata?.name,
                   }
               })
           }
        } else {
            const createSpaceResult = await client.cluster(cluster, Resources.ClusterV1Space).Create(spaceToCreate);
            if (createSpaceResult.err) {
                return createSpaceResult;
            }
        }
    }

    // create virtual cluster
    if (arr(apps).length > 0 || task.spec.task?.virtualClusterCreation?.spaceCreation) {
        const streamTaskResult = await streamTask(userState, task, progressPopupRef, true);
        if (streamTaskResult.err) {
            return streamTaskResult;
        }
    } else {
        const virtualClusterResult = await client.cluster(cluster, Resources.StorageV1VirtualCluster).Namespace(vCluster.metadata?.namespace).Create(vCluster);
        if (virtualClusterResult.err) {
            return virtualClusterResult;
        }
    }
    
    // create access
    let accessResult = await vClusterAccessRef?.createSpaceAccess(vCluster);
    if (accessResult?.err) {
        return accessResult;
    }

    return Return.Ok();
}

async function onUpdate({vCluster, cluster, vClusterAccessRef, vClusterMetadataRef, vClusterAdvancedOptionsRef}: ChangeFunctionProps) {
    if (!cluster || !vCluster) {
        return Return.Ok();
    }

    // we refresh the object here, otherwise it can be possible that we get a conflict error if the space has changed meanwhile
    const virtualClusterResult = await client.cluster(cluster, Resources.StorageV1VirtualCluster).Namespace(vCluster.metadata?.namespace).Get(vCluster.metadata?.name!);
    if (virtualClusterResult.err) {
        return virtualClusterResult;
    }
    vCluster = virtualClusterResult.val;

    // apply metadata
    let result = await vClusterMetadataRef!.update(vCluster);
    if (result?.err) {
        return result;
    }

    // apply advanced options
    result = await vClusterAdvancedOptionsRef!.update(vCluster);
    if (result?.err) {
        return result;
    }

    // update access
    const updateAccessResult = await vClusterAccessRef?.update(vCluster);
    if (updateAccessResult?.err) {
        return updateAccessResult;
    }

    // update virtual cluster
    const updateResult = await client.cluster(cluster, Resources.StorageV1VirtualCluster).Namespace(vCluster?.metadata?.namespace).Update(vCluster.metadata?.name!, vCluster);
    if (updateResult.err) {
        return updateResult;
    }

    // update access
    let accessResult = await vClusterAccessRef?.updateSpaceAccess(vCluster);
    if (accessResult?.err) {
        return accessResult;
    }

    return Return.Ok();
}

async function onBatch({vClusters, cluster, vClusterMetadataRef}: ChangeFunctionProps) {
    if (!cluster || !vClusters) {
        return Return.Ok();
    }

    // we refresh the objects here, otherwise it can be possible that we get a conflict error if the virtual cluster has changed meanwhile
    const vClustersResult = await client.cluster(cluster, Resources.StorageV1VirtualCluster).List();
    if (vClustersResult.err) {
        return vClustersResult;
    }

    // assign the new spaces
    vClusters = vClustersResult.val.items.filter(vCluster => vClusters!.find(oldVCluster => oldVCluster.metadata?.namespace === vCluster.metadata?.namespace && oldVCluster.metadata?.name === vCluster.metadata?.name));

    // apply metadata
    const result = vClusterMetadataRef!.batch(vClusters);
    if (result?.err) {
        return result;
    }

    // update virtual clusters
    for (let i = 0; i < vClusters.length; i++) {
        const vClustersResult = await client.cluster(cluster, Resources.StorageV1VirtualCluster).Namespace(vClusters[i].metadata?.namespace).Update(vClusters[i].metadata?.name!, vClusters[i]);
        if (vClustersResult.err) {
            return vClustersResult;
        }
    }

    return Return.Ok();
}

export default function VClusterDrawer(props: VClusterDrawerProps) {
    const [template, setTemplate] = useState<ManagementV1VirtualClusterTemplate | undefined>(undefined);
    const [cluster, setCluster] = useState<string | undefined>(props.cluster);
    const [error, setError] = useState<Err<any> | undefined>(undefined);
    const userState = useUser();
    const drawer = useDrawerDispatcher();
    const vClusterLocationRef = useRef<VClusterLocation>(null);
    const vClusterMetadataRef = useRef<VClusterMetadata>(null);
    const vClusterAdvancedOptionsRef = useRef<VClusterAdvancedOptions>(null);
    const vClusterAccessRef = useRef<VClusterAccess>(null);
    const progressPopupRef = useRef<ProgressPopup>(null);

    // apps
    const [apps, setApps] = useState<AppWithNamespace[]>([]);
    return <React.Fragment>
        {apps.length > 0 && <AppParametersDrawer drawerDispatcher={drawer}
                                                 apps={apps}
                                                 onCreate={async (apps) => {
                                                     const message = ClientMessage.Loading(cluster!);
                                                     const result = await onCreate({apps, template, progressPopupRef: progressPopupRef.current!, vClusterAccessRef: vClusterAccessRef.current!, vCluster: props.vCluster, cluster: cluster!, userState: userState!, refetch: props.refetch, vClusterLocationRef: vClusterLocationRef.current, vClusterMetadataRef: vClusterMetadataRef.current, vClusterAdvancedOptionsRef: vClusterAdvancedOptionsRef.current});
                                                     if (result?.err) {
                                                         message.ErrorCluster(result, cluster!);
                                                         return;
                                                     }

                                                     // refetch
                                                     await props.refetch();
                                                     message.DoneCluster(cluster!);

                                                     // close drawer
                                                     drawer({});
                                                 }}
                                                 onClose={() => {
                                                     setApps([]);
                                                     drawer({
                                                         title: "Create Virtual Cluster",
                                                         update: true,
                                                     })
                                                 }} />}
        <ItemDrawer okButtonText={props.mode === "create" ? "Create" : "Update"} onOkAsync={async () => {
            if (!cluster) {
                ClientMessage.Error(Return.Failed("no cluster selected"));
                return;
            }
            if (error) {
                ClientMessage.Error(error);
                return;
            }

            // get apps
            let apps: AppWithParameters[] = [];
            if (props.mode === "create") {
                const selectedAppsResult = vClusterAdvancedOptionsRef?.current?.getSelectedApps();
                if (selectedAppsResult?.err) {
                    ClientMessage.Error(selectedAppsResult);
                    return;
                } else if (!!selectedAppsResult?.val.find(app => arr(app.app?.spec?.parameters).length > 0)) {
                    setApps(selectedAppsResult?.val?.map(app => ({app: app.app, namespace: app.namespace})));
                    return;
                }
                
                apps = selectedAppsResult?.val.map(app => ({app: app.app, namespace: app.namespace, parameters: undefined})) || [];
            }
    
            const message = ClientMessage.Loading(cluster!);
    
            // execute the create / update / batch logic
            let result: ResultError | undefined = undefined;
            if (props.mode === "create") {
                result = await onCreate({apps, template, progressPopupRef: progressPopupRef.current!, vClusterAccessRef: vClusterAccessRef.current!, vCluster: props.vCluster, cluster: cluster!, userState: userState!, refetch: props.refetch, vClusterLocationRef: vClusterLocationRef.current, vClusterMetadataRef: vClusterMetadataRef.current, vClusterAdvancedOptionsRef: vClusterAdvancedOptionsRef.current});
            } else if (props.mode === "update") {
                result = await onUpdate({apps, template, progressPopupRef: progressPopupRef.current!, vClusterAccessRef: vClusterAccessRef.current!, vCluster: props.vCluster, cluster: cluster!, userState: userState!, refetch: props.refetch, vClusterLocationRef: vClusterLocationRef.current, vClusterMetadataRef: vClusterMetadataRef.current, vClusterAdvancedOptionsRef: vClusterAdvancedOptionsRef.current});
            } else if (props.mode === "batch") {
                result = await onBatch({apps, template, progressPopupRef: progressPopupRef.current!, vClusterAccessRef: vClusterAccessRef.current!, vClusters: props.vClusters, cluster: cluster!, userState: userState!, refetch: props.refetch, vClusterLocationRef: vClusterLocationRef.current, vClusterMetadataRef: vClusterMetadataRef.current, vClusterAdvancedOptionsRef: vClusterAdvancedOptionsRef.current});
            }
    
            // check if there was an error
            if (result?.err) {
                message.ErrorCluster(result, cluster!);
                return;
            }
    
            // refetch
            await props.refetch();
            message.DoneCluster(cluster!);
    
            // close drawer
            drawer({});
        }}>
            {props.mode === "create" && <ProgressPopup title={"Creating Virtual Cluster"} ref={progressPopupRef} />}
            <VClusterLocation mode={props.mode} 
                              showClusterSelect={!!props.cluster} 
                              cluster={cluster}
                              onError={err => {
                                  setError(err);
                              }}
                              onSelectVirtualClusterValues={(values, version) => {
                                  vClusterAdvancedOptionsRef.current?.selectValues(values, version);
                                  setTemplate(undefined);
                              }}
                              onSelectVirtualClusterTemplate={vClusterTemplate => {
                                  vClusterMetadataRef.current?.selectTemplate(vClusterTemplate);
                                  vClusterAdvancedOptionsRef.current?.selectTemplate(vClusterTemplate);
                                  vClusterAccessRef.current?.selectTemplate(vClusterTemplate);
                                  setTemplate(vClusterTemplate);
                              }} 
                              onSelectCluster={cluster => {
                                  setError(undefined);
                                  setCluster(cluster?.metadata?.name);
                              }} 
                              ref={vClusterLocationRef} />
            {error && <ErrorMessage error={error} />}
            <VClusterMetadata mode={props.mode} cluster={cluster} vCluster={props.vCluster} ref={vClusterMetadataRef} noMargin={props.mode !== "create"} />
            <VClusterAdvancedOptions mode={props.mode} cluster={cluster} vCluster={props.vCluster} ref={vClusterAdvancedOptionsRef} />
            <VClusterAccess mode={props.mode} vCluster={props.vCluster} cluster={cluster} ref={vClusterAccessRef} />
        </ItemDrawer>
    </React.Fragment>
}