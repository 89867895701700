import React from "react";
import {displayName, formatTime, readableTime, timeAgo} from "../../../../lib/helper";
import {Popover} from "../../../../components/Popover/Popover";
import AsyncButton from "../../../../components/AsyncButton/AsyncButton";
import Icon from "@ant-design/icons";
import WakeUpIcon from "../../../../images/wakeup-icon.svg";
import SleepingIcon from "../../../../images/sleeping-icon.svg";
import {wakeUpSpace} from "./SpaceTable";
import Query from "../../../../components/Query/Query";
import client from "../../../../lib/client";
import {Resources} from "../../../../lib/resources";
import {ClusterV1SleepModeConfig} from "../../../../../gen/model/clusterV1SleepModeConfig";
import styles from './SleepPopover.module.scss';
import {Tooltip} from "../../../../components/Tooltip/Tooltip"; 

interface SleepPopoverProps {
    sleepModeConfig: ClusterV1SleepModeConfig | undefined;
    cluster: string | undefined;
    spaceName: string | undefined;
    spacePhase?: string | undefined;
    
    refetch: () => Promise<void>;
}

function renderUser(sleepModeConfig: ClusterV1SleepModeConfig | undefined) {
    const status = sleepModeConfig?.status;
    if (status?.lastActivityInfo?.subject?.indexOf("loft:user:") === 0) {
        const user = status.lastActivityInfo.subject.substring("loft:user:".length);
        return <Query query={async () => await client.management(Resources.ManagementV1User).Get(user)}>
            {
                result => {
                    return <span style={{fontStyle: "italic"}}>User {displayName(result.data) || user}:</span>
                }
            }
        </Query>
    } else if (status?.lastActivityInfo?.subject?.indexOf("loft:team:") === 0) {
        const team = status.lastActivityInfo.subject.substring("loft:team:".length);
        return <Query query={async () => await client.management(Resources.ManagementV1Team).Get(team)}>
            {
                result => {
                    return <span style={{fontStyle: "italic"}}>Team {displayName(result.data) || team}:</span>
                }
            }
        </Query>
    }
    
    return status?.lastActivityInfo?.subject ? status?.lastActivityInfo?.subject + ":" : ""; 
}

function renderLastActivity(sleepModeConfig: ClusterV1SleepModeConfig | undefined) {
    const status = sleepModeConfig?.status;
    if (!status?.lastActivity) {
        return undefined;
    }
    
    const lastActivity = status.lastActivity * 1000;
    let lastActivityInfo: JSX.Element | undefined = undefined;
    if (status?.lastActivityInfo) {
        if (status.lastActivityInfo.host) {
            lastActivityInfo = <span>request to {status.lastActivityInfo.host}</span>;
        } else if (status.lastActivityInfo.apiGroup === "cluster.loft.sh" && status.lastActivityInfo.resource === "sleepmodeconfigs" && status.lastActivityInfo.verb === "create") {
            lastActivityInfo = <span>{renderUser(sleepModeConfig)} Wake Up Space</span>;
        } else if (status.lastActivityInfo.resource && status.lastActivityInfo.verb) {
            let lastActivityInfoString = "";
            if (status.lastActivityInfo.subresource) {
                lastActivityInfoString = status.lastActivityInfo.subresource + " " + status.lastActivityInfo.resource 
            } else {
                lastActivityInfoString = status.lastActivityInfo!.verb! + " " + status.lastActivityInfo.resource
            }
            
            if (status.lastActivityInfo.apiGroup) {
                lastActivityInfoString += "." + status.lastActivityInfo.apiGroup
            }
            if (status.lastActivityInfo.name) {
                lastActivityInfoString += " " + status.lastActivityInfo.name
            }
            if (status.lastActivityInfo.virtualCluster) {
                lastActivityInfoString += " in virtual cluster " + status.lastActivityInfo.virtualCluster
            }

            lastActivityInfo = <span>{renderUser(sleepModeConfig)} {lastActivityInfoString}</span>;
        }
    }
    
    return <div>
        <div>Last Activity</div>
        <div>
            <Tooltip title={formatTime(lastActivity)}>
                <a target="_blank" href="https://loft.sh/docs/getting-started/explore/sleep-mode#inactivity-detection">
                    <i>{timeAgo(lastActivity)}</i>
                </a>
            </Tooltip> 
            {lastActivityInfo ? <span>&nbsp;({lastActivityInfo})</span> : undefined}
        </div>
    </div>;
}

function renderSleepAfter(sleepModeConfig: ClusterV1SleepModeConfig | undefined) {
    if (!sleepModeConfig?.status?.lastActivity) {
        return undefined;
    }
    
    const lastActivity = sleepModeConfig?.status?.lastActivity * 1000;
    if (!sleepModeConfig?.spec?.sleepAfter) {
        return undefined;
    }

    let nextSleep: JSX.Element | undefined = undefined;
    if (sleepModeConfig.status.sleepingSince) {
        nextSleep = undefined;
    } else if (new Date().getTime() > lastActivity + (sleepModeConfig?.spec?.sleepAfter * 1000)) {
        nextSleep = <React.Fragment>(Starts sleeping any moment)</React.Fragment>;
    } else {
        const t = lastActivity + (sleepModeConfig?.spec?.sleepAfter * 1000);
        nextSleep = <React.Fragment>(Sleeps in <Tooltip title={formatTime(t)}><i>{readableTime(t)}</i></Tooltip>)</React.Fragment>
    }

    return <div>
        <div>Sleep After Inactivity</div>
        <div>After {sleepModeConfig?.spec?.sleepAfter / 60} Minutes {nextSleep}</div>
    </div>
}

function renderDeleteAfter(sleepModeConfig: ClusterV1SleepModeConfig | undefined) {
    if (!sleepModeConfig?.status?.lastActivity) {
        return undefined;
    }

    const lastActivity = sleepModeConfig?.status?.lastActivity * 1000;
    if (!sleepModeConfig?.spec?.deleteAfter) {
        return undefined;
    }

    let nextSleep: JSX.Element | undefined = undefined;
    if (new Date().getTime() > lastActivity + (sleepModeConfig?.spec?.deleteAfter * 1000)) {
        nextSleep = <React.Fragment>(Will be deleted any moment)</React.Fragment>;
    } else {
        const t = lastActivity + (sleepModeConfig?.spec?.deleteAfter * 1000);
        nextSleep = <React.Fragment>(Delete in <Tooltip title={formatTime(t)}><i>{readableTime(t)}</i></Tooltip>)</React.Fragment>
    }

    return <div>
        <div>Delete After Inactivity</div>
        <div>After {sleepModeConfig?.spec?.deleteAfter / 60} Minutes {nextSleep}</div>
    </div>
}

function renderTimezone(sleepModeConfig: ClusterV1SleepModeConfig | undefined) {
    const timezone = sleepModeConfig?.spec?.timezone;
    const splitted = timezone?.split("#");
    if (splitted?.length === 2) {
        return splitted[0];
    }
    
    return timezone || "UTC";
}

function renderScheduledWakeup(sleepModeConfig: ClusterV1SleepModeConfig | undefined) {
    const wakeupAt = sleepModeConfig?.status?.scheduledWakeup;
    if (!sleepModeConfig?.spec?.wakeupSchedule || !wakeupAt) {
        return undefined
    }

    let message: JSX.Element | undefined = undefined;
    if (!sleepModeConfig?.status?.sleepingSince) {
        message = undefined;
    } else if (new Date().getTime() > wakeupAt * 1000) {
        message = <React.Fragment>(Wakes up any moment)</React.Fragment>;
    } else {
        message = <React.Fragment>(Next Wakeup in <Tooltip title={<span>
            {formatTime(wakeupAt * 1000)}<br />
            Timezone: {renderTimezone(sleepModeConfig)}
        </span>}><i>{readableTime(wakeupAt * 1000)}</i></Tooltip>)</React.Fragment>;
    }

    return <div>
        <div>Wake Up Schedule</div>
        <div>{sleepModeConfig?.spec?.wakeupSchedule} {message}</div>
    </div>
}

function renderScheduledSleep(sleepModeConfig: ClusterV1SleepModeConfig | undefined) {
    const sleepAt = sleepModeConfig?.status?.scheduledSleep;
    if (!sleepModeConfig?.spec?.sleepSchedule || !sleepAt) {
        return undefined
    }

    let message: JSX.Element | undefined = undefined;
    if (sleepModeConfig?.status?.sleepingSince) {
        message = undefined;
    } else if (new Date().getTime() > sleepAt * 1000) {
        message = <React.Fragment>(Starts sleeping any moment)</React.Fragment>;
    } else {
        message = <React.Fragment>(Next scheduled sleep in <Tooltip title={<span>
            {formatTime(sleepAt * 1000)}<br />
            Timezone: {renderTimezone(sleepModeConfig)}
        </span>}><i>{readableTime(sleepAt * 1000)}</i></Tooltip>)</React.Fragment>;
    }

    return <div>
        <div>Sleep Schedule</div>
        <div>{sleepModeConfig?.spec?.sleepSchedule} {message}</div>
    </div>
}

function renderSleeping(sleepModeConfig: ClusterV1SleepModeConfig | undefined) {
    let message: JSX.Element | undefined = undefined;
    if (sleepModeConfig?.status?.sleepingSince) {
        message = <React.Fragment>Sleeping for {readableTime(sleepModeConfig.status.sleepingSince * 1000)}</React.Fragment>
        if (sleepModeConfig.status.sleepType === "inactivitySleep") {
            message = <React.Fragment>{message} (Reason: Inactivity)</React.Fragment>
        } else if (sleepModeConfig.status.sleepType === "scheduledSleep") {
            message = <React.Fragment>{message} (Reason: Scheduled)</React.Fragment>
        } else if (sleepModeConfig.status.sleepType === "forcedSleep" || sleepModeConfig.status.sleepType === "forcedDurationSleep") {
            message = <React.Fragment>{message} (Reason: Forced)</React.Fragment>
        }
    } else {
        message = <React.Fragment>Not Sleeping</React.Fragment>
    }
    
    return <div className={styles["status"]}>
        <div>Sleeping</div>
        <div>{message}</div>
    </div>
}

function renderPopoverContents(props: React.PropsWithChildren<SleepPopoverProps>, active: boolean) {
    if (props.children) {
        if (!active) {
            return <Popover title={<span><b>Sleep Mode Configuration</b></span>} content={<div className={styles["sleeping-popover-info"]}>
                Not configured.
            </div>}>
                <span>{props.children}</span>
            </Popover>
        }
        
        return <span>{props.children}</span>
    } else if (props.sleepModeConfig?.status?.sleepingSince) {
        return <span className={styles["sleeping"]}>
            <Icon component={SleepingIcon as any} />Sleeping
        </span>;
    } else if (active) {
        return <span className={"color-primary"}>
            {props.spacePhase as any || "Active"}
        </span>
    }
    
    return <span>
        {props.spacePhase}
    </span>
}

export function SleepPopover(props: React.PropsWithChildren<SleepPopoverProps>) {
    const sleepModeSpec = props.sleepModeConfig?.spec;
    const active = !!(props.sleepModeConfig?.status?.sleepingSince || sleepModeSpec?.sleepAfter || sleepModeSpec?.deleteAfter || sleepModeSpec?.sleepSchedule || sleepModeSpec?.wakeupSchedule);
    if (active) {
        return <Popover title={<span><b>Sleep Mode Configuration</b></span>} content={<div className={styles["sleeping-popover-info"]}>
            <div className={styles["popover-table"]}>
                <div className={styles["header"]}>
                    <div>Name</div>
                    <div>Value</div>
                </div>
                {renderSleepAfter(props.sleepModeConfig)}
                {renderDeleteAfter(props.sleepModeConfig)}
                {renderScheduledSleep(props.sleepModeConfig)}
                {renderScheduledWakeup(props.sleepModeConfig)}
                {renderLastActivity(props.sleepModeConfig)}
                {renderSleeping(props.sleepModeConfig)}
            </div>
            {
                props.sleepModeConfig?.status?.sleepingSince && <div className={styles["wake-up"]}>
                    <AsyncButton type={"primary"} onClickAsync={() => wakeUpSpace(props.sleepModeConfig!, props.cluster!, props.spaceName!, props.refetch)}>
                        <Icon component={WakeUpIcon as any} />Wake up
                    </AsyncButton>
                </div>
            }
        </div>}>
            {renderPopoverContents(props, active)}
        </Popover>;
    }

    return renderPopoverContents(props, active);
}
