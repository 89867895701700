import React, {useState} from "react";
import {Tooltip} from "../../../components/Tooltip/Tooltip";
import styles from "./ClusterResetPopup.module.scss";
import {Modal} from "../../../lib/Modal/Modal";
import client from "../../../lib/client";
import {NewResource, Resources} from "../../../lib/resources";
import ClientMessage from "../../../../src/lib/Message/ClientMessage";
import Checkbox from "../../../components/Checkbox/Checkbox";
import {ManagementV1Cluster} from "../../../../gen/model/managementV1Cluster";
import {ReloadOutlined} from "@ant-design/icons/lib";
import {Return} from "../../../lib/result";

interface Props {
    cluster: ManagementV1Cluster;
}

export default function ClusterResetPopup(props: Props) {
    const [visible, setVisible] = useState<boolean>(false);
    const [resetRBAC, setResetRBAC] = useState<boolean>(false);
    const [resetAgent, setResetAgent] = useState<boolean>(false);

    return <React.Fragment>
        <Tooltip title="reset">
            <ReloadOutlined className={styles["setting"]} onClick={() => setVisible(true)} />
        </Tooltip>
        <Modal title={`Reset Cluster: ${props.cluster.metadata?.name}`} onCancel={() => setVisible(false)} visible={visible} onOkAsync={async () => {
            const message = ClientMessage.Loading(props.cluster.metadata?.name);
            if (!resetAgent && !resetRBAC) {
                message.ErrorCluster(Return.Failed("Please select at least one option"), props.cluster.metadata?.name!);
                return;
            }

            const clusterReset = NewResource(Resources.ManagementV1ClusterReset, props.cluster.metadata?.name!);
            clusterReset.agent = resetAgent;
            clusterReset.rbac = resetRBAC;

            const createResult = await client.management(Resources.ManagementV1ClusterReset).Name(props.cluster.metadata?.name!).Create(clusterReset);
            message.Result(createResult);
            setVisible(false);
        }}>
            <div>
                <Checkbox onChange={e => setResetRBAC(e.target.checked)} checked={resetRBAC}>Reset loft default cluster roles</Checkbox>
            </div>
            <div>
                <Checkbox onChange={e => setResetAgent(e.target.checked)} checked={resetAgent}>Reset loft agent</Checkbox>
            </div>
        </Modal>
    </React.Fragment>
}