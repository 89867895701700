import React from "react";
import Label from "../../../../../components/Label/Label";
import Description from "../../../../../components/Description/Description";
import {Result, ResultError, Return} from "../../../../../lib/result";
import {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import Query from "../../../../../components/Query/Query";
import {ErrorMessage} from "../../../../../components/ErrorMessage/ErrorMessage";
import client from "../../../../../lib/client";
import {Resources} from "../../../../../lib/resources";
import Section from "../../../../../components/Drawer/Section/Section";
import {StorageV1VirtualCluster} from "../../../../../../gen/model/agentstorageV1VirtualCluster";
import YAMLEditor from "../../../../../components/YAMLEditor/YAMLEditor";
import Input from "../../../../../components/Input/Input";
import styles from "./VClusterAdvancedOptions.module.scss";
import SectionExpander from "../../../../../components/Drawer/SectionExpander/SectionExpander";
import Select from "../../../../../components/Select/Select";
import {arr, selectDefaultFilter} from "../../../../../lib/helpers/renderhelper";
import FixedText from "../../../../../components/FixedText/FixedText";
import Loading from "../../../../../components/Loading/Loading";
import {ManagementV1VirtualClusterTemplate} from "../../../../../../gen/model/managementV1VirtualClusterTemplate";
import {StorageV1VirtualClusterAppReference} from "../../../../../../gen/model/storageV1VirtualClusterAppReference";
import {ManagementV1App} from "../../../../../../gen/model/managementV1App";
import {displayName} from "../../../../../lib/helper";
import constants from "../../../../../constants/constants";
const { Option } = Select;

interface TargetNamespaceSwitcherProps {
    selectedApp: SelectedApp;
    displayName: string;

    onChange: (template: string, namespaces: string[]) => void;
}

export function TargetNamespaceSwitcher(props: TargetNamespaceSwitcherProps) {
    return <div className={styles["target-namespace-switcher"]}>
        <span className={styles["template"]}>
            <FixedText maxWidth={160} text={props.displayName} />
        </span>
        <Select mode="tags" tokenSeparators={[',', ' ']}
                className={styles["cluster-roles-select"]}
                value={props.selectedApp.targetNamespaces}
                onChange={value => props.onChange(props.selectedApp.app, value)}>
            {props.selectedApp.targetNamespaces.map(namespace => <Option key={namespace} value={namespace}>{namespace}</Option>)}
        </Select>
    </div>
}

interface VClusterAdvancedOptionsState {
    values?: string;
    version?: string;
    distro?: string;
    virtualClusterTemplate?: string;
    
    apps?: SelectedApp[];
}

export interface SelectedApp {
    app: string;
    targetNamespaces: string[];
}

interface VClusterAdvancedOptionsProps extends SectionProps {
    cluster?: string;
    vCluster?: StorageV1VirtualCluster;
}

export interface NamespacedApp {
    app?: ManagementV1App;
    namespace?: string;
}

export const reduceSelectedApps = (selectedApps: SelectedApp[] | undefined): string[] => {
    return arr(selectedApps).map(s => s.app);
}

export const updateSelectedApps = (newApps: string[], oldSelectedApps: SelectedApp[] | undefined): SelectedApp[] => {
    oldSelectedApps = [...arr(oldSelectedApps)];
    
    // delete templates that are not there anymore
    for (let i = arr(oldSelectedApps).length - 1; i >= 0; i--) {
        const found = arr(newApps).find(n => n === oldSelectedApps![i].app);
        if (!found) {
            oldSelectedApps?.splice(i, 1);
        }
    }
    
    // add templates that are not there yet
    const addItems: SelectedApp[] = [];
    for (let i = arr(newApps).length - 1; i >= 0; i--) {
        const found = arr(oldSelectedApps).find(n => n.app === newApps![i]);
        if (!found) {
            addItems.push({
                app: newApps![i],
                targetNamespaces: ["default"]
            })
        }
    }
    
    return [...oldSelectedApps, ...addItems];
}

export const getSelectedApps = (templateApps: Array<StorageV1VirtualClusterAppReference>): SelectedApp[] => {
    const newApps: SelectedApp[] = [];
    for (let i = 0; i < templateApps.length; i++) {
        const templateApp = templateApps[i];
        if (!templateApp.name) {
            continue;
        }

        const namespace = templateApp.namespace || "default";
        const app = newApps.find(a => a.app === templateApp.name);
        if (app) {
            if (!app.targetNamespaces.find(t => t === namespace)) {
                app.targetNamespaces.push(namespace);
            }
        } else {
            newApps.push({
                app: templateApp.name + "",
                targetNamespaces: [namespace]
            })
        }
    }
    
    return newApps;
}

export default class VClusterAdvancedOptions extends React.PureComponent<VClusterAdvancedOptionsProps, VClusterAdvancedOptionsState> {
    private availableApps: Array<ManagementV1App> = [];
    
    state: VClusterAdvancedOptionsState = {
        distro: this.props.vCluster?.spec?.helmRelease?.chart?.name || "vcluster",
        values: this.props.vCluster?.spec?.helmRelease?.values,
        version: this.props.vCluster?.spec?.helmRelease?.chart?.version,
        
        apps: [],
    };

    create = async (vCluster: StorageV1VirtualCluster): Promise<ResultError> => {
        if (!vCluster.metadata) {
            vCluster.metadata = {};
        }
        if (!vCluster.metadata.annotations) {
            vCluster.metadata.annotations = {};
        }
        if (this.state.virtualClusterTemplate) {
            vCluster.metadata.annotations[constants.LoftVirtualClusterTemplate] = this.state.virtualClusterTemplate
        }
        if (!vCluster.spec) {
            vCluster.spec = {};
        }
        if (!vCluster.spec.helmRelease) {
            vCluster.spec.helmRelease = {};
        }

        vCluster.spec.helmRelease.chart = {
            version: this.state.version,
            name: this.state.distro
        }
        vCluster.spec.helmRelease.values = this.state.values;
        vCluster.spec.kubeConfigRef = {
            secretName: "vc-" + vCluster.metadata?.name,
            secretNamespace: vCluster.metadata?.namespace,
        };
        vCluster.spec.pod = {
            podSelector: {
                matchLabels: {
                    "app":     "vcluster",
                    "release": vCluster.metadata?.name!,
                }
            }
        }

        return Return.Ok();
    };

    update = async (vCluster: StorageV1VirtualCluster): Promise<ResultError> => {
        return this.create(vCluster);
    };
    
    selectValues = (values: string | undefined, version: string | undefined) => {
        this.setState({
            values: values,
            version: version,
            distro: "vcluster",
            virtualClusterTemplate: undefined,
            apps: []
        })
    }

    selectTemplate = (vClusterTemplate: ManagementV1VirtualClusterTemplate | undefined) => {
        if (!vClusterTemplate) {
            this.setState({
                virtualClusterTemplate: undefined,
            });
            return;
        }

        this.setState({
            values: vClusterTemplate.spec?.template?.helmRelease?.values,
            distro: vClusterTemplate.spec?.template?.helmRelease?.chart?.name || "vcluster",
            version: vClusterTemplate.spec?.template?.helmRelease?.chart?.version,
            virtualClusterTemplate: vClusterTemplate.metadata?.name,
            apps: getSelectedApps(arr(vClusterTemplate.spec?.template?.apps))
        })
    }

    resolveApps(apps: string[]): Result<Array<ManagementV1App>> {
        const deployApps: Array<ManagementV1App> = [];
        for (let i = 0; i < apps.length; i++) {
            const app = this.availableApps.find(app => app.metadata?.name === apps[i]);
            if (!app) {
                return Return.Failed(`cannot find app ${apps[i]}, the user has either no access to this app or it does not exist`)
            }

            deployApps.push(app);
        }

        return Return.Value(deployApps);
    }
    
    getSelectedApps(): Result<Array<NamespacedApp>> {
        const deployApps: Array<NamespacedApp> = [];
        const apps = arr(this.state.apps);
        for (let i = 0; i < apps.length; i++) {
            const app = this.availableApps.find(app => app.metadata?.name === apps[i].app);
            if (!app) {
                return Return.Failed(`cannot find app ${apps[i]}, the user has either no access to this app or it does not exist`)
            }

            const namespaces = arr(apps[i].targetNamespaces);
            for (let j = 0; j < namespaces.length; j++) {
                deployApps.push({
                    app,
                    namespace: namespaces[j]
                });
            }
        }

        return Return.Value(deployApps);
    }
    
    renderVirtualClusterDistro() {
        if (this.props.mode === "update") {
            let distro = "k3s";
            if (this.state.distro === "vcluster-k0s") {
                distro = "k0s";
            } else if (this.state.distro === "vcluster-k8s") {
                distro = "k8s";
            }
            
            return <div>
                <Label>Virtual Kubernetes Distribution</Label>
                <Input
                    className={styles["select"]}
                    style={{ width: '100%' }}
                    value={distro}
                    readOnly={true} />
                <Description>The Kubernetes distribution to use for the virtual cluster.</Description>
            </div>
        }
        
        return <div>
            <Label>Virtual Kubernetes Distribution</Label>
            <Select
                className={styles["select"]}
                style={{ width: '100%' }}
                placeholder="Please select a Kubernetes distribution"
                value={this.state.distro}
                onChange={(value) => this.setState({distro: value})}
                showSearch
                optionFilterProp="children"
                filterOption={selectDefaultFilter}
            >
                <Option key={"k3s"} value={"vcluster"}>k3s</Option>
                <Option key={"k0s"} value={"vcluster-k0s"}>k0s</Option>
                <Option key={"k8s"} value={"vcluster-k8s"}>k8s</Option>
            </Select>
            <Description>The Kubernetes distribution to use for the virtual cluster.</Description>
        </div>
    }
    
    render() {
        if (this.props.mode === "batch" || !this.props.cluster) {
            return null;
        }

        return <Section title={"Advanced Options"} foldable={true} defaultFolded={true}>
            <Query query={async () => await client.management(Resources.ManagementV1App).List()}>
                {
                    result => {
                        if (result.loading) {
                            return <Loading />;
                        } else if (result.error) {
                            return <ErrorMessage error={result.error} />
                        }

                        this.availableApps = arr(result.data?.items);
                        return <React.Fragment>
                            <div className={"row"}>
                                <div>
                                    <Label>Virtual Cluster Version</Label>
                                    <Input resetable={this.props.mode !== "create"}
                                           placeholder={"Empty for default version"}
                                           value={this.state.version}
                                           onChange={(e) => this.setState({version: e.target.value})} />
                                    <Description>The chart version of the virtual cluster to deploy</Description>
                                </div>
                                {this.renderVirtualClusterDistro()}
                            </div>
                            <Label>Helm Values for Virtual Cluster Chart</Label>
                            <YAMLEditor value={this.state.values} 
                                        minLines={10}
                                        maxLines={100}
                                        onChange={val => this.setState({values: val})} />
                            <Description>For all available values, refer to the <a href={"https://vcluster.com/docs"} target={"_blank"}>vcluster documentation</a></Description>

                            {this.props.mode === "create" && <SectionExpander name={"Apps"}>
                                <Label>Which apps are deployed within the virtual cluster?</Label>
                                <Select
                                    className={styles["select"]}
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    placeholder="Please select apps that should be deployed in the virtual cluster"
                                    value={reduceSelectedApps(this.state.apps)}
                                    onChange={(value) => this.setState({apps: updateSelectedApps(value, this.state.apps)})}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={selectDefaultFilter}
                                >
                                    {arr(result.data?.items).map(app => <Option key={app.metadata?.name} value={app.metadata?.name!}>
                                        {displayName(app)}
                                    </Option>)}
                                </Select>
                                <Description>Each app will be deployed into the specified virtual cluster namespaces. If a namespace does not exist within the virtual cluster, it will be created before the app is deployed.</Description>
                                <SectionExpander name={"Edit Target Namespaces For Apps"}>
                                    {
                                        !arr(this.state.apps).length ? <Description>
                                            Please add a app via the input above.
                                        </Description> : <div>
                                            {
                                                this.state.apps?.map((selectedApp, index) => <TargetNamespaceSwitcher key={index}
                                                                                                                      selectedApp={selectedApp}
                                                                                                                      displayName={displayName(this.availableApps.find(app => selectedApp.app === app.metadata?.name))}
                                                                                                                      onChange={(app, namespaces) => {
                                                    const newApps = [...arr(this.state.apps)];
                                                    const oldNamespaces = [...newApps[index].targetNamespaces]

                                                    // delete namespaces that are not there anymore
                                                    for (let i = arr(oldNamespaces).length - 1; i >= 0; i--) {
                                                        const found = arr(namespaces).find(n => n === oldNamespaces![i]);
                                                        if (!found) {
                                                            oldNamespaces?.splice(i, 1);
                                                        }
                                                    }

                                                    // add namespaces that are not there yet
                                                    const addItems: string[] = [];
                                                    for (let i = arr(namespaces).length - 1; i >= 0; i--) {
                                                        const found = arr(oldNamespaces).find(n => n === namespaces![i]);
                                                        if (!found) {
                                                            addItems.push(namespaces![i]);
                                                        }
                                                    }

                                                    newApps[index] = {
                                                        app: app,
                                                        targetNamespaces: [...oldNamespaces, ...addItems],
                                                    }
                                                    this.setState({
                                                        apps: newApps
                                                    });
                                                }} />)
                                            }
                                        </div>
                                    }
                                </SectionExpander>
                            </SectionExpander>}
                        </React.Fragment>
                    }
                }
            </Query>
        </Section>
    }
}