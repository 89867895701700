import React from "react";
import Label from "../../../../../components/Label/Label";
import {ResultError, Return} from "../../../../../lib/result";
import {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import Input from "../../../../../components/Input/Input";
import {ManagementV1UserProfile} from "../../../../../../gen/model/managementV1UserProfile";
import Description from "../../../../../components/Description/Description";
import {Avatar} from "antd";
import {UserOutlined} from "@ant-design/icons/lib";

interface IconState {
    icon: string | undefined;
}

interface IconProps extends SectionProps {
    icon: string | undefined;
    show: boolean;
}

export default class Icon extends React.PureComponent<IconProps, IconState> {
    state: IconState = {
        icon: this.props.icon,
    };

    create = (obj: ManagementV1UserProfile): ResultError => {
        if (!this.props.show) {
            return Return.Ok();
        }

        obj.icon = this.state.icon;
        return Return.Ok();
    };

    render() {
        if (this.props.mode !== "create" || !this.props.show) {
            return null;
        }

        return <div>
            <Label>Picture Url</Label>
            <Input onChange={e => this.setState({icon: e.target.value})} placeholder={"http://path-to-my-image.com/my-image.png"} value={this.state.icon} />
            <Description>The url of your profile picture.</Description>
            <Label>Preview</Label>
            {
                this.state.icon ? <Avatar shape="square" size={42} src={this.state.icon} /> : <Avatar shape="square" size={42} icon={<UserOutlined />} />
            }
        </div>
    }
}