import React from "react";
import {ResultError, Return} from "../../../../../lib/result";
import {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import Section from "../../../../../components/Drawer/Section/Section";
import {ManagementV1Team} from "../../../../../../gen/model/managementV1Team";
import {arr, selectOwnerFilter} from "../../../../../lib/helpers/renderhelper";
import Query from "../../../../../components/Query/Query";
import client from "../../../../../lib/client";
import {Resources} from "../../../../../lib/resources";
import {ErrorMessage} from "../../../../../components/ErrorMessage/ErrorMessage";
import Loading from "../../../../../components/Loading/Loading";
import Label from "../../../../../components/Label/Label";
import Select from "../../../../../components/Select/Select";
import styles from "./TeamMembers.module.scss";
import Owner from "../../../../../components/Owner/Owner";
import {ManagementV1User} from "../../../../../../gen/model/managementV1User";
const { Option } = Select;

interface TeamMembersState {
    users: string[];
    usersChanged: boolean;

    groups: string[];
    groupsChanged: boolean;
}

interface TeamMembersProps extends SectionProps {
    team?: ManagementV1Team;
}

export default class TeamMembers extends React.PureComponent<TeamMembersProps, TeamMembersState> {
    state: TeamMembersState = {
        groups: this.props.team ? arr(this.props.team?.spec?.groups) : [],
        groupsChanged: false,

        users: this.props.team ? arr(this.props.team?.spec?.users) : [],
        usersChanged: false,
    };

    create = (team: ManagementV1Team): ResultError => {
        if (!team.spec) {
            team.spec = {};
        }

        team.spec.users = this.state.users;
        team.spec.groups = this.state.groups;
        return Return.Ok();
    };

    update = (team: ManagementV1Team): ResultError => {
        if (!team.spec) {
            team.spec = {};
        }

        if (this.state.usersChanged) {
            team.spec.users = this.state.users;
        }
        if (this.state.groupsChanged) {
            team.spec.groups = this.state.groups;
        }
        return Return.Ok();
    };

    batch = (teams: ManagementV1Team[]): ResultError => {
        for (let i = 0; i < teams.length; i++) {
            const result = this.update(teams[i]);
            if (result.err) {
                return result;
            }
        }

        return Return.Ok()
    };

    renderOptions(users?: ManagementV1User[]) {
        const options: JSX.Element[] = [];

        // add users
        users?.forEach(user => {
            options.push(<Option key={user.metadata?.name!} value={user?.metadata?.name!}>
                <Owner displayName={user.spec?.displayName} username={user.spec?.username} kubeName={user?.metadata?.name!} type={"small"} className={styles["option"]} />
            </Option>)
        });

        return options;
    }

    render() {
        return <Section title={`Team Members`}>
            <Query query={async () => await client.management(Resources.ManagementV1User).List()}>
                {
                    result => {
                        if (result.error) {
                            return <ErrorMessage error={result.error} />
                        } else if (result.loading) {
                            return <Loading />
                        }

                        return <div>
                            <Label>Users as Members</Label>
                            <Select
                                resetable={this.props.mode !== "create"}
                                onChangedStatus={changed => this.setState({usersChanged: changed})}
                                className={styles["select"]}
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Please select users that are part of this team"
                                value={this.state.users}
                                onChange={value => this.setState({users: value})}
                                showSearch
                                optionFilterProp="children"
                                filterOption={selectOwnerFilter}
                            >
                                {this.renderOptions(result.data?.items)}
                            </Select>
                            <Label>Kubernetes Groups as Members</Label>
                            <Select mode="tags" tokenSeparators={[',', ' ']} resetable={this.props.mode !== "create"} onChangedStatus={changed => this.setState({groupsChanged: changed})} placeholder={"my-group, my-group1"} value={this.state.groups} onChange={value => this.setState({groups: value})} />
                        </div>
                    }
                }
            </Query>
        </Section>
    }
}