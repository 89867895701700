import React from "react";
import {displayName, formatURIComponent} from "../../../lib/helper";
import {DrawerDispatch, useItemDrawer} from "../../../contexts/drawer/DrawerContext";
import * as History from "history";
import {ToggleColumn, ToggleColumnContent} from "../../../contexts/ToggleColumn/ToggleColumn";
import FixedText from "../../../components/FixedText/FixedText";
import {Link, useHistory} from "react-router-dom";
import useQuery from "../../../lib/Query/Query";
import client from "../../../lib/client";
import {Resources} from "../../../lib/resources";
import Table, {TableActions} from "../../../components/Table/Table";
import ClustersHeader from "../ClustersHeader/ClustersHeader";
import Button from "../../../components/Button/Button";
import {Tooltip} from "../../../components/Tooltip/Tooltip";
import {DeleteOutlined, SettingOutlined} from "@ant-design/icons";
import {deleteConfirm} from "../../../lib/Modal/Modal";
import ClientMessage from "../../../lib/Message/ClientMessage";
import {
    arr,
    Filter,
    getDisplayNameFromEntity,
    removeDuplicateFilters
} from "../../../lib/helpers/renderhelper";
import {creationTimestampSorter, displayNameSorter, numberSorter, stringSorter} from "../../../lib/helpers/sorthelper";
import DynamicTime from "../../../components/DynamicTime/DynamicTime";
import {MultiItem} from "../../../components/MultiItem/MultiItem";
import ShowYamlPopup from "../../../components/ShowYamlPopup/ShowYamlPopup";
import SpaceConstraintsDrawer from "./SpaceConstraintsDrawer/SpaceConstraintsDrawer";
import Description from "../../../components/Description/Description";
import Query from "../../../components/Query/Query";
import {ManagementV1SpaceConstraint} from "../../../../gen/model/managementV1SpaceConstraint";

interface Props {}

function getTableColumns(refetch: () => Promise<void>, drawerDispatcher: DrawerDispatch, history: History.History<any>, allSpaceConstraints: ManagementV1SpaceConstraint[]) {
    const editGlobalSpaceConstaints = (spaceConstraints: ManagementV1SpaceConstraint) => {
        drawerDispatcher({
            title: "Edit Space Constraints: " + displayName(spaceConstraints),
            content: <SpaceConstraintsDrawer mode={"update"} spaceConstraint={spaceConstraints} refetch={refetch} />
        })
    };

    return [
        {
            title: <ToggleColumn id={"displayname"} columns={['Display Name', 'Kubernetes Name (ID)']}/>,
            sorter: (a: ManagementV1SpaceConstraint, b: ManagementV1SpaceConstraint) => stringSorter(displayName(a), displayName(b)),
            render: (spaceConstraint: ManagementV1SpaceConstraint) => {
                return <ToggleColumnContent id={"displayname"} columns={[
                    () => {
                        return <FixedText className={"clickable-link"} onClick={() => editGlobalSpaceConstaints(spaceConstraint)} text={displayName(spaceConstraint)} />;
                    },
                    () => {
                        return <FixedText className={"clickable-link"} onClick={() => editGlobalSpaceConstaints(spaceConstraint)} text={spaceConstraint.metadata?.name} />;
                    },
                ]}/>
            }
        },
        {
            title: "Description",
            render: (spaceConstraint: ManagementV1SpaceConstraint) => {
                return <Description.Column>
                    {spaceConstraint.spec?.description || ""}
                </Description.Column>;
            }
        },
        {
            title: 'Clusters',
            filters: removeDuplicateFilters(arr(allSpaceConstraints).reduce((previous: Filter[], clusterAccess): Filter[] => {
                const clusters = clusterAccess.status?.clusters?.map(c => ({
                    text: getDisplayNameFromEntity(c),
                    value: getDisplayNameFromEntity(c) + ""
                }))
                return [...previous, ...arr(clusters)];
            }, [])),
            onFilter: (value: string | number | boolean, cluster: ManagementV1SpaceConstraint) => {
                return !!cluster.status?.clusters?.find(c => getDisplayNameFromEntity(c) === value);
            },
            render: (cluster: ManagementV1SpaceConstraint) => {
                const clusters = arr(cluster.status?.clusters);
                return <MultiItem items={clusters.map(c => ({key: c.name, children: getDisplayNameFromEntity(c)}))} maxItems={5} />;
            }
        },
        {
            title: 'Sync Changes',
            render: (cluster: ManagementV1SpaceConstraint) => {
                return <span>{!!cluster.spec?.localSpaceConstraintTemplate?.spec?.sync +""}</span>
            }
        },
        {
            title: 'Assigned Cluster Role',
            render: (cluster: ManagementV1SpaceConstraint) => {
                if (!cluster.status?.clusterRole?.name) {
                    return <span>None</span>;
                }
                
                return <Link to={`/clusters/clusterroles#search=${formatURIComponent(getDisplayNameFromEntity(cluster.status?.clusterRole))}`} className={"hover-link"}>{getDisplayNameFromEntity(cluster.status?.clusterRole)}</Link>
            }
        },
        {
            title: 'Created',
            sorter: (a: ManagementV1SpaceConstraint, b: ManagementV1SpaceConstraint) => creationTimestampSorter(a, b),
            render: (cluster: ManagementV1SpaceConstraint) => {
                return <DynamicTime timestamp={cluster.metadata?.creationTimestamp} useTooltip={true}/>
            }
        },
        {
            title: 'Actions',
            width: "210px",
            render: (cluster: ManagementV1SpaceConstraint) => {
                return <TableActions>
                    <Tooltip title="edit">
                        <SettingOutlined className={"blue-btn"} onClick={() => editGlobalSpaceConstaints(cluster)} />
                    </Tooltip>
                    <ShowYamlPopup className={"blue-btn"} object={cluster} resource={Resources.ManagementV1SpaceConstraint} name={cluster.metadata?.name!} refetch={refetch} />
                    <Tooltip title="delete">
                        <DeleteOutlined className={"actions-delete-btn"} onClick={() => {
                            deleteConfirm({
                                title: `Delete Space Constraints: ${cluster?.metadata?.name}`,
                                content: `Are you sure you want to delete the space constraints ${cluster?.metadata?.name}? This action CANNOT be reverted!`,
                                onOkAsync: async () => {
                                    const message = ClientMessage.Loading();
                                    const result = await client.management(Resources.ManagementV1SpaceConstraint).Delete(cluster?.metadata?.name as string);
                                    message.Result(result);
                                    await refetch();
                                },
                            });
                        }} />
                    </Tooltip>
                </TableActions>;
            }
        },
    ];
}

function filter(item: ManagementV1SpaceConstraint, value: string) {
    return item.metadata?.name?.includes(value) || item.spec?.displayName?.includes(value) || item.status?.clusterRole?.name?.includes(value) || item.status?.clusterRole?.displayName?.includes(value) || item.spec?.description?.includes(value) || !!item.status?.clusters?.find(cluster => cluster.name?.includes(value) || cluster.displayName?.includes(value));
}

function SpaceConstraints(props: Props) {
    const history = useHistory();
    const drawerDispatcher = useItemDrawer({width: 900});
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
    const {loading, error, data, refetch} = useQuery(async () => await client.management(Resources.ManagementV1SpaceConstraint).List());
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedKeys: any) => {
            setSelectedRowKeys(selectedKeys);
        },
    };

    const allGlobalSpaceConstraints = arr(data?.items).map(cluster => { return {...cluster, key: cluster.metadata!.name!}}).sort((a, b) => displayNameSorter(a, b));
    return <div>
        <Table showSorterTooltip={false} loading={loading} columns={getTableColumns(refetch, drawerDispatcher, history, allGlobalSpaceConstraints)} dataSource={arr(allGlobalSpaceConstraints)} error={error} rowSelection={rowSelection} filter={filter} refetch={refetch} header={{
            top: <ClustersHeader>
                <Description.Table>Space constraints define the space owners <Link to={"/clusters/clusterroles"}>cluster role</Link>, enforced space metadata or any other Kubernetes resource that should be deployed inside a space. Space constraints are useful to enforce certain security objects or metadata inside created namespaces. Space constraints can also be synced automatically, which allows you to keep those enforcements up-to-date even across multiple clusters.</Description.Table>
            </ClustersHeader>,
            right: <Query query={async () => await client.management(Resources.ManagementV1SpaceConstraint).CanI("create")}>
                {
                    result => {
                        if (result.loading || !result.data) {
                            return null;
                        }

                        return <Button type={"primary"} onClick={() => {
                            drawerDispatcher({
                                title: "Create Space Constraints",
                                content: <SpaceConstraintsDrawer mode={"create"} refetch={refetch} />
                            });
                        }}>Create Space Constraints</Button>
                    }
                }
            </Query>,
            selectedActions: <React.Fragment>
                <Tooltip title={"delete"}>
                    <DeleteOutlined className={"actions-delete-btn-batch"} onClick={() =>
                    {
                        deleteConfirm({
                            title: `Delete Space Constraints`,
                            content: `Are you sure you want to delete the space constraints ${selectedRowKeys.join(", ")}?`,
                            onOkAsync: async () => {
                                const message = ClientMessage.Loading();
                                for (let i = 0; i < selectedRowKeys.length; i++) {
                                    const clusterName = selectedRowKeys[i];
                                    const result = await client.management(Resources.ManagementV1SpaceConstraint).Delete(clusterName as string);
                                    if (result.err) {
                                        message.Error(result);
                                        return;
                                    }
                                }

                                message?.DoneManagement();
                                await refetch();
                                setSelectedRowKeys([]);
                            }
                        });
                    }} />
                </Tooltip>
            </React.Fragment>
        }} />
    </div>
}

export default SpaceConstraints;
