import React, {useRef} from "react";
import ItemDrawer, {SectionProps} from "../../../../components/Drawer/ItemDrawer";
import {useDrawerDispatcher} from "../../../../contexts/drawer/DrawerContext";
import ClientMessage from "../../../../lib/Message/ClientMessage";
import {ErrorTypeNotFound, ResultError, Return} from "../../../../lib/result";
import {NewResource, Resources} from "../../../../lib/resources";
import client from "../../../../lib/client";
import {ManagementV1User} from "../../../../../gen/model/managementV1User";
import EmailAddress from "./Sections/EmailAddress";
import Password from "./Sections/Password";
import Username from "./Sections/Username";
import Icon from "./Sections/Icon";

export interface ProfileDrawerProps extends SectionProps {
    user: ManagementV1User;
    type: "email"|"username"|"password"|"icon";

    refetch: () => Promise<void>;
}

type ChangeFunctionProps = Omit<ProfileDrawerProps, "mode"|"refetch"|"type"> & {emailAddressRef: EmailAddress, passwordRef: Password, usernameRef: Username, iconRef: Icon};

async function onCreate({user, emailAddressRef, passwordRef, usernameRef, iconRef}: ChangeFunctionProps): Promise<ResultError> {
    // make sure we have an object
    let profile = NewResource(Resources.ManagementV1UserProfile, undefined, {});

    let result = emailAddressRef.create(profile);
    if (result.err) {
        return result;
    }

    result = await passwordRef.create(profile);
    if (result.err) {
        return result;
    }

    result = usernameRef.create(profile);
    if (result.err) {
        return result;
    }

    result = iconRef.create(profile);
    if (result.err) {
        return result;
    }

    const createResult = await client.management(Resources.ManagementV1UserProfile).Name(user.metadata?.name!).Create(profile);
    if (createResult.err) {
        return createResult;
    }

    return Return.Ok();
}

export default function ProfileDrawer(props: ProfileDrawerProps) {
    const drawer = useDrawerDispatcher();
    const emailAddressRef = useRef<EmailAddress>(null);
    const passwordRef = useRef<Password>(null);
    const usernameRef = useRef<Username>(null);
    const iconRef = useRef<Icon>(null);

    return <ItemDrawer okButtonText={"Save"} onOkAsync={async () => {
        const message = ClientMessage.Loading();

        // execute the create / update / batch logic
        let result: ResultError | undefined = undefined;
        if (props.mode === "create") {
            result = await onCreate({user: props.user, emailAddressRef: emailAddressRef.current!, passwordRef: passwordRef.current!, usernameRef: usernameRef.current!, iconRef: iconRef.current!});
        }

        // check if there was an error
        if (result?.err) {
            message.ErrorManagement(result);
            return;
        }

        // refetch
        await props.refetch();

        message.DoneManagement();

        // close drawer
        drawer({});
    }}>
        <EmailAddress emailAddress={props.user.spec?.email} mode={props.mode} show={props.type === "email"} ref={emailAddressRef} />
        <Password mode={props.mode} show={props.type === "password"} username={props.user.spec?.username!} ref={passwordRef} />
        <Username username={props.user.spec?.username} displayName={props.user.spec?.displayName} mode={props.mode} show={props.type === "username"} ref={usernameRef} />
        <Icon icon={props.user.spec?.icon} mode={props.mode} show={props.type === "icon"} ref={iconRef} />
    </ItemDrawer>
}