import React from "react";
import {
    LockOutlined,
    SecurityScanOutlined
} from "@ant-design/icons/lib";
import { useRouteMatch } from "react-router-dom";
import Header from "../../../../components/Header/Header";
import {SolutionOutlined} from "@ant-design/icons";

interface UserDetailsHeaderProps {

}

export default function UserDetailsHeader(props: React.PropsWithChildren<UserDetailsHeaderProps>) {
    const routeMatch = useRouteMatch<{user: string}>();
    const user = routeMatch.params.user;
    const path = routeMatch.path.split("/");
    const selectedKey = path[path.length - 1] === ":user" ? "access-keys" : path[path.length - 1];

    return <Header title={user} selectedKey={selectedKey} items={[
        {
            key: "access-keys",
            url: `/users/details/${user}/access-keys`,
            content: <React.Fragment>
                <LockOutlined />Access Keys
            </React.Fragment>
        },
        {
            key: "activity",
            url: `/users/details/${user}/activity`,
            content: <React.Fragment>
                <SolutionOutlined />Activity
            </React.Fragment>
        },
        {
            key: "permissions",
            url: `/users/details/${user}/permissions`,
            content: <React.Fragment>
                <SecurityScanOutlined />Assigned Cluster Roles
            </React.Fragment>
        },
    ]}>
        {props.children}
    </Header>
}