import React from "react";
import {V1Beta1PodMetrics} from "../../../../lib/types";
import {creationTimestampSorter, nameSorter, numberSorter} from "../../../../lib/helpers/sorthelper";
import {arr} from "../../../../lib/helpers/renderhelper";
import {cpuParser, memoryParser} from "../../../../lib/helpers/resourcehelper";
import Table, {TableActions} from "../../../../components/Table/Table";
import {useRouteMatch} from "react-router-dom";
import useQuery from "../../../../lib/Query/Query";
import client from "../../../../lib/client";
import {Resources} from "../../../../lib/resources";
import {V1Pod} from "@kubernetes/client-node";
import {Return} from "../../../../lib/result";
import {TextPodLogsPopup} from "../Pods/PodLogsPopup";
import styles from "../SpaceTable/SpaceTable.module.scss";
import {Tooltip} from "../../../../components/Tooltip/Tooltip";
import {DeleteOutlined} from "@ant-design/icons";
import {deleteConfirm} from "../../../../lib/Modal/Modal";
import ShowYamlPopup from "../../../../components/ShowYamlPopup/ShowYamlPopup";
import ClientMessage from "../../../../lib/Message/ClientMessage";
import SpacesHeader from "../SpacesHeader/SpacesHeader";
import Warning from "../../../../components/Warning/Warning";
import DynamicTime from "../../../../components/DynamicTime/DynamicTime";

interface PodMetrics extends V1Pod {
    key: string;
    metrics?: V1Beta1PodMetrics;
}

function filterPopupTable(item: PodMetrics, value: string) {
    return !!item.metadata?.name?.includes(value)
}

function aggregateCoreLimits(metric: PodMetrics): number {
    let aggregated = 0;
    for (let j = 0; j < arr(metric.spec?.containers).length; j++) {
        const container = metric.spec?.containers?.[j];
        if (container?.resources?.limits?.["cpu"]) {
            aggregated += cpuParser((container?.resources?.limits?.["cpu"] || 0) + "")
        }
    }

    return aggregated;
}

function aggregateCoreRequests(metric: PodMetrics): number {
    let aggregated = 0;
    for (let j = 0; j < arr(metric.spec?.containers).length; j++) {
        const container = metric.spec?.containers?.[j];
        if (container?.resources?.requests?.["cpu"]) {
            aggregated += cpuParser((container?.resources?.requests?.["cpu"] || 0) + "")
        }
    }

    return aggregated;
}

function aggregateMemoryLimits(metric: PodMetrics): number {
    let aggregated = 0;
    for (let j = 0; j < arr(metric.spec?.containers).length; j++) {
        const container = metric.spec?.containers?.[j];
        if (container?.resources?.limits?.["memory"]) {
            aggregated += memoryParser((container?.resources?.limits?.["memory"] || 0) + "")
        }
    }

    return aggregated;
}

function aggregateMemoryRequests(metric: PodMetrics): number {
    let aggregated = 0;
    for (let j = 0; j < arr(metric.spec?.containers).length; j++) {
        const container = metric.spec?.containers?.[j];
        if (container?.resources?.requests?.["memory"]) {
            aggregated += memoryParser((container?.resources?.requests?.["memory"] || 0) + "")
        }
    }

    return aggregated;
}

function aggregateCores(metric: PodMetrics): number {
    let aggregated = 0;
    for (let j = 0; j < arr(metric.metrics?.containers).length; j++) {
        const container = metric.metrics?.containers?.[j];
        aggregated += cpuParser((container?.usage?.cpu || 0) + "")
    }

    return aggregated;
}

function aggregateMemory(metric: PodMetrics): number {
    let aggregated = 0;
    for (let j = 0; j < arr(metric.metrics?.containers).length; j++) {
        const container = metric.metrics?.containers?.[j];
        aggregated += memoryParser((container?.usage?.memory || 0) + "")
    }

    return aggregated;
}

function getColumns(cluster: string, refetch: () => Promise<void>) {
    return [
        {
            title: 'Pod',
            sorter: (a: PodMetrics, b: PodMetrics) => {
                return nameSorter(a, b);
            },
            render: (metric: PodMetrics) => {
                return <TextPodLogsPopup cluster={cluster} pod={metric.metadata?.name as string} namespace={metric.metadata?.namespace!} />
            }
        },
        {
            title: 'CPU Requests',
            sorter: (a: PodMetrics, b: PodMetrics) => {
                return numberSorter(aggregateCoreRequests(a), aggregateCoreRequests(b));
            },
            render: (metric: PodMetrics) => {
                const cores = aggregateCoreRequests(metric);
                return <span>{cores.toFixed(2)} Cores</span>
            }
        },
        {
            title: 'CPU Usage',
            sorter: (a: PodMetrics, b: PodMetrics) => {
                return numberSorter(aggregateCores(a), aggregateCores(b));
            },
            render: (metric: PodMetrics) => {
                const coresRequests = aggregateCoreRequests(metric);
                const coresUsed = aggregateCores(metric);
                const coresLimits = aggregateCoreLimits(metric);
                
                if (coresRequests > 0 && coresUsed > 0) {
                    if (coresRequests - coresUsed > 0.25 && coresUsed / coresRequests < 0.5) {
                        return <Warning tooltip={"CPU is underutilized compared to requested"} text={coresUsed.toFixed(2) + " Cores"} />
                    }
                }
                if (coresLimits > 0 && coresUsed > 0) {
                    if (coresLimits - coresUsed < 0.5 && coresUsed / coresLimits > 0.75) {
                        return <Warning tooltip={"CPU is very close to limit"} text={coresUsed.toFixed(2) + " Cores"} />
                    }
                }
                if (coresUsed > 1) {
                    return <Warning tooltip={"High CPU Usage"} text={coresUsed.toFixed(2) + " Cores"} />
                }
                
                return <span>{coresUsed.toFixed(2)} Cores</span>
            }
        },
        {
            title: 'CPU Limits',
            sorter: (a: PodMetrics, b: PodMetrics) => {
                return numberSorter(aggregateCoreLimits(a), aggregateCoreLimits(b));
            },
            render: (metric: PodMetrics) => {
                const cores = aggregateCoreLimits(metric);
                return <span>{cores.toFixed(2)} Cores</span>
            }
        },
        {
            title: 'Memory Requests',
            sorter: (a: PodMetrics, b: PodMetrics) => {
                return numberSorter(aggregateMemoryRequests(a), aggregateMemoryRequests(b));
            },
            render: (metric: PodMetrics) => {
                const memory = aggregateMemoryRequests(metric) / 1024 / 1024 / 1024;
                return <span>{memory.toFixed(1)} GB</span>
            }
        },
        {
            title: 'Memory Usage',
            sorter: (a: PodMetrics, b: PodMetrics) => {
                return numberSorter(aggregateMemory(a), aggregateMemory(b));
            },
            render: (metric: PodMetrics) => {
                const memoryRequests = aggregateMemoryRequests(metric) / 1024 / 1024 / 1024;
                const memoryUsed = aggregateMemory(metric) / 1024 / 1024 / 1024;
                const memoryLimits = aggregateMemoryLimits(metric) / 1024 / 1024 / 1024;

                if (memoryRequests > 0 && memoryUsed > 0) {
                    if (memoryRequests - memoryUsed > 0.25 && memoryUsed / memoryRequests < 0.5) {
                        return <Warning tooltip={"Memory is underutilized"} text={memoryUsed.toFixed(1) + " GB"} />
                    }
                }
                if (memoryLimits > 0 && memoryUsed > 0) {
                    if (memoryLimits - memoryUsed < 0.5 && memoryUsed / memoryLimits > 0.75) {
                        return <Warning tooltip={"Memory Usage is very close to limit"} text={memoryUsed.toFixed(1) + " GB"} />
                    }
                }
                if (memoryUsed > 1.25) {
                    return <Warning tooltip={"High Memory Usage"} text={memoryUsed.toFixed(1) + " GB"} />
                }

                return <span>{memoryUsed.toFixed(1)} GB</span>
            }
        },
        {
            title: 'Memory Limits',
            sorter: (a: PodMetrics, b: PodMetrics) => {
                return numberSorter(aggregateMemoryLimits(a), aggregateMemoryLimits(b));
            },
            render: (metric: PodMetrics) => {
                const memory = aggregateMemoryLimits(metric) / 1024 / 1024 / 1024;
                return <span>{memory.toFixed(1)} GB</span>
            }
        },
        {
            title: 'Created',
            width: "180px",
            sorter: (a: PodMetrics, b: PodMetrics) => creationTimestampSorter(a, b),
            render: (podMetrics: PodMetrics) => {
                return <DynamicTime timestamp={podMetrics.metadata?.creationTimestamp} useTooltip={true}/>
            }
        },
        {
            title: 'Actions',
            width: "90px",
            render: (metric: PodMetrics) => {
                return <TableActions className={styles["actions"]}>
                    {metric.metrics && <ShowYamlPopup className={styles["setting"]} object={metric.metrics} canUpdate={false} resource={Resources.V1Beta1PodMetrics} />}
                    <Tooltip title="delete">
                        <DeleteOutlined className={styles["delete"]} onClick={() => {
                            deleteConfirm({
                                title: `Delete Pod: ${metric.metadata?.name}`,
                                content: `Are you sure you want to delete the pod ${metric.metadata?.name}?`,
                                onOkAsync: async () => {
                                    const message = ClientMessage.Loading(cluster);
                                    const result = await client.cluster(cluster!, Resources.V1Pod).Namespace(metric.metadata?.namespace).Delete(metric.metadata?.name!);
                                    message.Result(result);
                                    await refetch();
                                },
                            });
                        }} />
                    </Tooltip>
                </TableActions>;
            }
        },
    ];
}

export function Metrics() {
    const match = useRouteMatch();
    const {cluster, space} = match.params as any;
    const {error, loading, data, refetch} = useQuery(async () => {
        const podsResult = await client.cluster(cluster!, Resources.V1Pod).Namespace(space).List();
        if (podsResult.err) {
            return podsResult;
        }
        
        const podsMetricsResult = await client.cluster(cluster!, Resources.V1Beta1PodMetrics).Namespace(space).List();
        if (podsMetricsResult.err) {
            return Return.Failed("Metrics API not available or cannot be retrieved (Error: " + podsMetricsResult.val.message + "). Please make sure you have installed the metrics server into your Kubernetes cluster.");
        }
        
        return Return.Value<PodMetrics[]>(arr(podsResult.val.items).map(pod => {
            return {...pod, key: pod.metadata?.name!, metrics: arr(podsMetricsResult.val.items).find(metric => metric.metadata?.name === pod.metadata?.name)};
        }));
    });
    
    return <Table loading={loading} header={{top:<SpacesHeader />}} columns={getColumns(cluster, refetch)} error={error} dataSource={data} filter={filterPopupTable as any} refetch={refetch} />
}