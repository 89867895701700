import React, {useEffect} from "react";
import client from "../../../../lib/client";
import {Resources} from "../../../../lib/resources";
import useQuery from "../../../../lib/Query/Query";
import {useRouteMatch} from "react-router-dom";
import {SpaceTable} from "../../../Spaces/Spaces/SpaceTable/SpaceTable";
import ClusterHeader from "../ClusterHeader/ClusterHeader";
import {ClusterV1Space} from "../../../../../gen/model/clusterV1Space";
import {ClusterObject} from "../../../Spaces/Spaces/Spaces";

function buildClusterSpaces(cluster: string, spaces: Array<ClusterV1Space>): Array<ClusterObject<ClusterV1Space>> {
    return spaces.map(space => {
        return {
            cluster,
            object: space,
        }
    });
}

export default function Spaces() {
    const match = useRouteMatch();
    const {cluster} = match.params as any;
    const {refetch, error, loading, data} = useQuery(async () => await client.cluster(cluster, Resources.ClusterV1Space).List());
    useEffect(() => {
        const timeout = window.setTimeout(() => refetch(), 3000);
        return () => {
            window.clearTimeout(timeout);
        }
    }, [refetch]);
    return <SpaceTable cluster={cluster}
                       top={<ClusterHeader />}
                       error={error}
                       loading={loading}
                       spaces={data ? buildClusterSpaces(cluster, data.items) : undefined}
                       refetch={refetch} />
}