import React, {useRef} from "react";
import ItemDrawer, {SectionProps} from "../../../../components/Drawer/ItemDrawer";
import {useDrawerDispatcher} from "../../../../contexts/drawer/DrawerContext";
import TeamMetadata from "./Sections/TeamMetadata";
import {ManagementV1Team} from "../../../../../gen/model/managementV1Team";
import TeamMembers from "./Sections/TeamMembers";
import ClientMessage from "../../../../lib/Message/ClientMessage";
import {NewResource, Resources} from "../../../../lib/resources";
import client from "../../../../lib/client";
import {ResultError, Return} from "../../../../lib/result";
import ClusterAccountTemplates from "../../UserDrawer/Sections/ClusterAccountTemplates";
import Access from "../../../../components/Drawer/Sections/Access/Access";
import {useUser} from "../../../../contexts/UserContext/UserContext";
import {defaultVerbMappingUser} from "../../UserDrawer/UserDrawer";
import UserClusterRoles from "../../UserDrawer/Sections/UserClusterRoles";

export interface TeamDrawerProps extends SectionProps {
    team?: ManagementV1Team;
    teams?: ManagementV1Team[];
    refetch: () => Promise<void>;
}

type ChangeFunctionProps = Omit<TeamDrawerProps, "mode"|"refetch"> & {
    accessRef: Access | null, 
    teamMetadataRef: TeamMetadata | null, 
    teamMembersRef: TeamMembers | null, 
    teamClusterRoles?: UserClusterRoles | null, 
    clusterAccountTemplates?: ClusterAccountTemplates | null, 
};

async function onCreate({accessRef, teamMetadataRef, teamMembersRef, teamClusterRoles, clusterAccountTemplates}: ChangeFunctionProps) {
    // make sure we have a team object
    let team = NewResource(Resources.ManagementV1Team, undefined, {spec: {}});

    // apply team metadata
    const result = await teamMetadataRef?.create(team);
    if (result?.err) {
        return result;
    }

    // apply team members
    const authResult = await teamMembersRef?.create(team);
    if (authResult?.err) {
        return authResult;
    }

    // apply cluster account templates
    const clusterAccountResults = await clusterAccountTemplates?.create(team.spec!);
    if (clusterAccountResults?.err) {
        return clusterAccountResults;
    }

    // apply access
    const accessResult = await accessRef?.create(team);
    if (accessResult?.err) {
        return accessResult;
    }

    // create cluster roles
    const clusterRoleResult = teamClusterRoles?.create(team.spec!);
    if (clusterRoleResult?.err) {
        return clusterRoleResult;
    }

    // create team
    const teamResult = await client.management(Resources.ManagementV1Team).Create(team);
    if (teamResult.err) {
        return teamResult;
    }

    return Return.Ok();
}


async function onUpdate({team, accessRef, teamMetadataRef, teamMembersRef, teamClusterRoles, clusterAccountTemplates}: ChangeFunctionProps) {
    if (!team) {
        return Return.Ok();
    }

    // make sure the team object is up to date
    const teamGetResult = await client.management(Resources.ManagementV1Team).Get(team.metadata?.name!);
    if (teamGetResult.err) {
        return teamGetResult;
    }

    team = teamGetResult.val;

    // apply team metadata
    const result = await teamMetadataRef?.update(team);
    if (result?.err) {
        return result;
    }

    // apply team members
    const authResult = await teamMembersRef?.update(team);
    if (authResult?.err) {
        return authResult;
    }

    // apply cluster account templates
    const clusterAccountResults = await clusterAccountTemplates?.update(team.spec!);
    if (clusterAccountResults?.err) {
        return clusterAccountResults;
    }

    // apply access
    const accessResult = await accessRef?.update(team);
    if (accessResult?.err) {
        return accessResult;
    }

    // create cluster roles
    const clusterRoleResult = teamClusterRoles?.update(team.spec!);
    if (clusterRoleResult?.err) {
        return clusterRoleResult;
    }

    // update team
    const teamResult = await client.management(Resources.ManagementV1Team).Update(team.metadata?.name!, team);
    if (teamResult.err) {
        return teamResult;
    }

    return Return.Ok();
}

async function onBatch({teams, accessRef, teamMetadataRef, teamMembersRef, teamClusterRoles, clusterAccountTemplates}: ChangeFunctionProps) {
    if (!teams) {
        return Return.Ok();
    }

    // we refresh the objects here, otherwise it can be possible that we get a conflict error if the team has changed meanwhile
    const teamsResult = await client.management(Resources.ManagementV1Team).List();
    if (teamsResult.err) {
        return teamsResult;
    }

    // assign the new spaces
    teams = teamsResult.val.items.filter(team => teams!.find(oldTeam => oldTeam.metadata?.name === team.metadata?.name));

    // update metadata
    const metadataError = teamMetadataRef!.batch(teams);
    if (metadataError.err) {
        return metadataError;
    }

    // update members
    const groupsError = teamMembersRef!.batch(teams);
    if (groupsError.err) {
        return groupsError;
    }

    // apply cluster account templates
    const clusterAccountResults = await clusterAccountTemplates?.batch(teams.map(team => team.spec!));
    if (clusterAccountResults?.err) {
        return clusterAccountResults;
    }

    // apply access
    const accessResult = await accessRef?.batch(teams);
    if (accessResult?.err) {
        return accessResult;
    }

    // update cluster roles
    const clusterRoleResult = teamClusterRoles?.batch(teams.map(team => team.spec!));
    if (clusterRoleResult?.err) {
        return clusterRoleResult;
    }

    // update teams
    for (let i = 0; i < teams.length; i++) {
        const teamResult = await client.management(Resources.ManagementV1Team).Update(teams[i].metadata?.name!, teams[i]);
        if (teamResult.err) {
            return teamResult;
        }
    }

    return Return.Ok();
}

export default function TeamDrawer(props: TeamDrawerProps) {
    const drawer = useDrawerDispatcher();
    const user = useUser();
    const teamMetadataRef = useRef<TeamMetadata>(null);
    const clusterAccountTemplates = useRef<ClusterAccountTemplates>(null);
    const teamMembersRef = useRef<TeamMembers>(null);
    const teamClusterRoles = useRef<UserClusterRoles>(null);
    const accessRef = useRef<Access>(null);

    return <ItemDrawer okButtonText={props.mode === "create" ? "Create" : "Update"} onOkAsync={async () => {
        const message = ClientMessage.Loading();

        // execute the create / update / batch logic
        let result: ResultError | undefined = undefined;
        if (props.mode === "create") {
            result = await onCreate({accessRef: accessRef.current!, teamMetadataRef: teamMetadataRef.current, teamMembersRef: teamMembersRef.current, teamClusterRoles: teamClusterRoles?.current, clusterAccountTemplates: clusterAccountTemplates.current});
        } else if (props.mode === "update") {
            result = await onUpdate({accessRef: accessRef.current!, team: props.team, teamMetadataRef: teamMetadataRef.current, teamMembersRef: teamMembersRef.current, teamClusterRoles: teamClusterRoles?.current, clusterAccountTemplates: clusterAccountTemplates.current});
        } else if (props.mode === "batch") {
            result = await onBatch({accessRef: accessRef.current!, teams: props.teams, teamMetadataRef: teamMetadataRef.current, teamMembersRef: teamMembersRef.current, teamClusterRoles: teamClusterRoles?.current, clusterAccountTemplates: clusterAccountTemplates.current});
        }

        // check if there was an error
        if (result?.err) {
            message.ErrorManagement(result);
            return;
        }

        // refetch
        await props.refetch();

        message.DoneManagement();

        // close drawer
        drawer({});
    }}>
        <TeamMetadata mode={props.mode} team={props.team} ref={teamMetadataRef}  />
        <ClusterAccountTemplates mode={props.mode} clusterAccountTemplatesSpec={props.team?.spec} clusterAccountTemplatesSpecs={props.teams ? props.teams.map(team => team?.spec!) : undefined} ref={clusterAccountTemplates} />
        <TeamMembers mode={props.mode} team={props.team} ref={teamMembersRef} />
        <UserClusterRoles mode={props.mode} 
                          clusterRoles={props.team?.spec?.clusterRoles} 
                          imagePullSecrets={props.team?.spec?.imagePullSecrets}
                          groups={props.team?.spec?.groups}
                          title={"Advanced Options"}
                          hideGroups={true}
                          label={"Cluster Roles for Team"} 
                          description={"Cluster roles allow the team to do certain things in loft"} 
                          ref={teamClusterRoles} />
        <Access mode={props.mode} kind={"Team"} user={user} allowedVerbs={defaultVerbMappingUser} access={props.team} ref={accessRef} />
    </ItemDrawer>
}