import React, {RefObject} from "react";
import {SectionProps} from "../../../../components/Drawer/ItemDrawer";
import Select from "../../../../components/Select/Select";
import {
    parseMap
} from "../../../../components/Drawer/Sections/Metadata/LabelsAnnotationsSection";
import Section from "../../../../components/Drawer/Section/Section";
import {arr, getDisplayName, selectOwnerFilter} from "../../../../lib/helpers/renderhelper";
import Label from "../../../../components/Label/Label";
import TextArea from "../../../../components/TextArea/TextArea";
import {StorageV1ClusterQuota} from "../../../../../gen/model/agentstorageV1ClusterQuota";
const { Option } = Select;

interface QuotaStatusState {
    namespace: string | undefined;
}

interface QuotaStatusProps extends SectionProps {
    quota: StorageV1ClusterQuota;
    noMargin: boolean;
}

export default class QuotaStatus extends React.PureComponent<QuotaStatusProps, QuotaStatusState> {
    state: QuotaStatusState = {
        namespace: undefined
    };

    render() {
        if (this.props.mode !== "update") {
            return null;
        }

        let used = "";
        if (this.state.namespace) {
            const namespace = arr(this.props.quota.status?.namespaces).find(namespace => namespace.namespace === this.state.namespace);
            if (namespace) {
                used = parseMap(namespace.status?.used);
            }
        } else {
            used = parseMap(this.props.quota.status?.total?.used);
        }

        return <Section title={"Quota Status"} noMargin={this.props.noMargin}>
            <div style={{marginBottom: "10px"}}>
                <Label>Select a space to see quota details for a single space</Label>
                <Select
                    showSearch
                    allowClear
                    placeholder="Aggregated quota for all spaces"
                    style={{ width: '100%' }}
                    value={this.state.namespace}
                    onChange={value => this.setState({namespace: value})}
                    optionFilterProp="children"
                    filterOption={selectOwnerFilter}
                >
                    {arr(this.props.quota.status?.namespaces).map(namespace => {
                        return <Option key={namespace.namespace!} value={namespace.namespace!}>
                            {namespace.namespace!}
                        </Option>
                    })}
                </Select>
            </div>
            <div>
                <TextArea readOnly
                          value={used}
                          style={{minHeight: "98px"}}
                          placeholder={``}
                />
            </div>
        </Section>
    }
}