import React from "react";
import {SectionProps} from "../../../../../../components/Drawer/ItemDrawer";
import {Result, ResultError, Return} from "../../../../../../lib/result";
import constants from "../../../../../../constants/constants";
import client from "../../../../../../lib/client";
import {Resources} from "../../../../../../lib/resources";
import Query from "../../../../../../components/Query/Query";
import {arr, selectOwnerFilter} from "../../../../../../lib/helpers/renderhelper";
import Label from "../../../../../../components/Label/Label";
import Description from "../../../../../../components/Description/Description";
import Loading from "../../../../../../components/Loading/Loading";
import * as OwnerComponent from "../../../../../../components/Owner/Owner";
import {ErrorMessage} from "../../../../../../components/ErrorMessage/ErrorMessage";
import Select from "../../../../../../components/Select/Select";
import {ConfigV1alpha1Account} from "../../../../../../../gen/model/configV1alpha1Account";
import {ClusterV1EntityInfo} from "../../../../../../../gen/model/clusterV1EntityInfo";
const { Option } = Select;

export interface Owner {
    isTeam: boolean;
    info: ClusterV1EntityInfo;
}

interface AccountOwnerState {
    selectedOwner: Owner | undefined;
    selectedOwnerChanged: boolean;
}

interface AccountOwnerProps extends SectionProps {
    // The cluster the account is in
    cluster: string;

    // If the account is modified this will be non empty
    owner?: Owner;
}

export default class AccountOwner extends React.PureComponent<AccountOwnerProps, AccountOwnerState> {
    state: AccountOwnerState = {
        selectedOwner: this.props.owner ? this.props.owner : undefined,
        selectedOwnerChanged: false,
    };

    create = async (obj: ConfigV1alpha1Account): Promise<ResultError> => {
        if (!this.state.selectedOwner) {
            return Return.Failed("Please select an owner for the account");
        }

        if (!obj.metadata) {
            obj.metadata = {}
        }
        if (!obj.metadata.labels) {
            obj.metadata.labels = {}
        }

        // check if the new owner already has an account
        const result = await client.cluster(this.props.cluster, Resources.ConfigV1alpha1Account).List({labelSelector:  `${this.state.selectedOwner.isTeam ? constants.TeamLabel : constants.UserLabel}=${this.state.selectedOwner.info.name}`});
        if (result.err) {
            return result;
        } else if (result.ok && result.val.items.length > 0) {
            return Return.Failed(`${this.state.selectedOwner.isTeam ? "Team" : "User"} ${this.state.selectedOwner.info.name} already has an account in cluster ${this.props.cluster}`);
        }

        if (this.state.selectedOwner.isTeam) {
            obj.metadata.labels[constants.TeamLabel] = this.state.selectedOwner.info.name!;
        } else {
            obj.metadata.labels[constants.UserLabel] = this.state.selectedOwner.info.name!;
        }

        return Return.Ok();
    };

    update = async (obj: ConfigV1alpha1Account): Promise<ResultError> => {
        if (!this.state.selectedOwnerChanged) {
            return Return.Ok();
        }

        return this.create(obj);
    };

    renderSelectOwner(owners: Owner[]) {
        return <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Select an owner"
            optionFilterProp="children"
            value={this.state.selectedOwner ? (this.state.selectedOwner.isTeam ? "team:" : "user:") + this.state.selectedOwner.info.name : undefined}
            resetable={this.props.mode !== "create"}
            onChangedStatus={(changed) => this.setState({selectedOwnerChanged: changed})}
            onChange={(value) => {
                const name = value.split(":");
                if (name[0] === "team") {
                    this.setState({
                        selectedOwner: owners.find(owner => owner.isTeam && owner.info.name === name[1])
                    });
                } else {
                    this.setState({
                        selectedOwner: owners.find(owner => !owner.isTeam && owner.info.name === name[1])
                    });
                }
            }}
            filterOption={selectOwnerFilter}
        >
            {owners.map(owner => <Option key={(owner.isTeam ? "team:" : "user:") + owner.info.name} value={(owner.isTeam ? "team:" : "user:") + owner.info.name} >
                <OwnerComponent.default displayName={owner.info.displayName} username={owner.info.username} kubeName={owner.info.name} isTeam={owner.isTeam} type={"small"} />
            </Option>)}
        </Select>;
    }

    render() {
        if (this.props.mode === "batch") {
            return null;
        }

        return <div>
            {
                this.props.cluster && <Query query={async (): Promise<Result<Owner[]>> => {
                    const usersResult = await client.management(Resources.ManagementV1User).List();
                    if (usersResult.err) {
                        return usersResult;
                    }

                    const teamsResult = await client.management(Resources.ManagementV1Team).List();
                    if (teamsResult.err) {
                        return teamsResult;
                    }

                    return Return.Value([
                        ...arr(usersResult.val.items).map(user => { return {isTeam: false, info: {displayName: user.spec?.displayName, username: user.spec?.username, name: user.metadata?.name}}}),
                        ...arr(teamsResult.val.items).map(team => { return {isTeam: true, info: {displayName: team.spec?.displayName, username: team.spec?.username, name: team.metadata?.name}}}),
                    ]);
                }}>
                    {
                        result => {
                            if (result.error) {
                                return <ErrorMessage error={result.error} />
                            }
                            if (!result.data) {
                                return <Loading />
                            }

                            return <React.Fragment>
                                <Label>Owner</Label>
                                {this.renderSelectOwner(result.data!)}
                                <Description>Choose the owner for this account.</Description>
                            </React.Fragment>
                        }
                    }
                </Query>
            }
        </div>
    }
}