import React from "react";
import Label from "../../../../../../components/Label/Label";
import Description from "../../../../../../components/Description/Description";
import {arr, selectDefaultFilter} from "../../../../../../lib/helpers/renderhelper";
import {SectionProps} from "../../../../../../components/Drawer/ItemDrawer";
import Select from "../../../../../../components/Select/Select";
import {LinkOutlined} from "@ant-design/icons/lib";
import styles from "./AppSelector.module.scss";
import {StorageV1HelmChart} from "../../../../../../../gen/model/storageV1HelmChart";
import {ManagementV1App} from "../../../../../../../gen/model/managementV1App";
import {chartName} from "../../../Overview/AppsList/AppsList";
import {displayNameSorter} from "../../../../../../lib/helpers/sorthelper";
import {Link} from "react-router-dom";
const { Option, OptGroup } = Select;

interface AppSelectorState {
    selectedOption: string | undefined;
}

interface AppSelectorProps extends SectionProps {
    selectedOption: string | undefined;
    
    setSelectedChart: (chart: StorageV1HelmChart | undefined) => void;
    setSelectedApp: (app: ManagementV1App | undefined) => void;
    setCustomManifest: (manifests: boolean | undefined) => void;
    setCustomHelmChart: (helmChart: boolean | undefined) => void;
    
    apps: Array<ManagementV1App>;
    charts: Array<StorageV1HelmChart>;
}

export default class AppSelector extends React.PureComponent<AppSelectorProps, AppSelectorState> {
    state: AppSelectorState = {
        selectedOption: this.props.selectedOption || "helm",
    };
    
    selectOption(newOption: string | undefined) {
        if (!newOption) {
            return;
        }
        
        const splitted = arr(newOption?.split(":"));
        if (newOption === "helm") {
            this.props.setCustomHelmChart(true);
        } else if (newOption === "manifests") {
            this.props.setCustomManifest(true)
        } else if (splitted[0] === "app") {
            this.props.setSelectedApp(this.props.apps.find(app => app.metadata?.name === splitted[1]));
        } else if (splitted[0] === "chart") {
            this.props.setSelectedChart(this.props.charts.find(chart => chartName(chart) === splitted[1]));
        }
        
        this.setState({
            selectedOption: newOption
        });
    }

    render() {
        if (this.props.mode !== "create") {
            return null;
        } 

        return <div>
            <Label className={styles["chart-select-label"]}>
                <span className={arr(this.props.charts).length === 0 ? "color-warning" : ""}>{arr(this.props.charts).length === 0 ? "Helm Chart (loft is still fetching all helm repositories, please come back in a minute)" : "Select an App"}</span>
            </Label>
            <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select App"
                optionFilterProp="children"
                value={this.state.selectedOption}
                onChange={value => this.selectOption(value)}
                className={styles["chart-select"]}
                filterOption={selectDefaultFilter}
            >
                <OptGroup label="Custom">
                    <Option key={"helm"} value={"helm"}>
                        Deploy Helm Chart
                    </Option>
                    <Option key={"manifests"} value={"manifests"}>
                        Deploy Kubernetes Manifests
                    </Option>
                </OptGroup>
                {arr(this.props.apps).length > 0 && <OptGroup label="Loft Apps">
                    {this.props.apps.sort((a, b) => displayNameSorter(a, b)).map(app => {
                        const name = app.metadata?.name;
                        return <Option key={"app:"+name} value={"app:"+name!}>
                            {app.spec?.displayName || name}
                        </Option>;
                    })}
                </OptGroup>}
                {arr(this.props.charts).length > 0 && <OptGroup label="Helm Charts">
                    {this.props.charts.map(chart => {
                        const name = chart.repository?.name + "/" + chart.metadata?.name;
                        return <Option key={"chart:"+name} value={"chart:"+name}>
                            {name}
                            <a href={`https://artifacthub.io/packages/helm/${chart.repository?.name}/${chart.metadata?.name}`} target={"_blank"} className={styles["link-wrapper"]} onClick={e => {
                                e.stopPropagation();
                            }}>
                                <LinkOutlined className={styles["link"]} />
                            </a>
                        </Option>;
                    }).sort((a: any, b: any) => {
                        return a.key < b.key ? -1 : a.key > b.key ? 1 : 0;
                    })}
                </OptGroup>}
            </Select>
            <Description>Search your defined <Link to={"/apps"}>apps</Link> and other helm repositories connected to <a href={"https://artifacthub.io/"} target={"_blank"}>Artifact Hub</a></Description>
        </div>
    }
}