export default {
    "LoftIgnoreAnnotation": "loft.sh/ignore",
    "LoftDefaultSpaceConstraints": "loft.sh/default-space-constraints",
    "LoftSpaceTemplate": "loft.sh/space-template",
    "DisableSpaceCreationAnnotation": "loft.sh/disable-space-creation",

    "LoftVirtualClusterTemplate": "loft.sh/virtual-cluster-template",
    "LoftDisplayNameAnnotation": "loft.sh/display-name",
    "LoftDescriptionAnnotation": "loft.sh/description",
    "LoftClusterRoleCluster": "loft.sh/cluster-role-cluster",
    "LoftClusterRoleManagement": "loft.sh/cluster-role-management",
    "LoftClusterGlobalTemplate": "loft.sh/global-template",
    "LoftRequireIngressAuthentication": "loft.sh/require-ingress-authentication",
    
    "VirtualClusterDefaultTemplateAnnotation": "virtualcluster.loft.sh/default-template",
    
    // Shared backend constants
    "LoftUserCustomDataAnnotation": "loft.sh/custom-data",
    "LoftUserLastActivityAnnotation": "loft.sh/last-activity",
    "LoftCreatedBySingleSignOnAnnotation": "loft.sh/single-sign-on",
    "LoftUserCreateAccountAnnotation": "loft.sh/create-account",

    "LoftManagementDefaultRoleLabel": "loft.sh/management-default-role",
    "LoftClusterAccountClusterRole": "loft.sh/account-cluster-role",
    "LoftClusterDefaultTemplateLabel": "loft.sh/default-template",
    "LoftManagementDefaultClusterAccountTemplateLabel": "loft.sh/default-template",

    "UserAccessKeyMain": "loft-main",
    // UserLabel is the label that references an account to a user
    "UserLabel": "loft.sh/user",
    // TeamLabel is the label that references an account to a team
    "TeamLabel": "loft.sh/team",
    // LoftHelmReleaseAppLabel indicates if the helm release was deployed via the loft app store
    "LoftHelmReleaseAppLabel": "loft.sh/app",
    // LoftHelmReleaseClusterAppLabel indicates if the helm release should be shown on the cluster overview level
    "LoftHelmReleaseClusterAppLabel": "loft.sh/cluster-app",
    // LoftHelmReleaseAppNameAnnotation indicates if the helm release was deployed via the loft app store
    "LoftHelmReleaseAppNameAnnotation": "loft.sh/app-name",
    // LoftHelmReleaseAppGenerationAnnotation indicates the generation of the loft app
    "LoftHelmReleaseAppGenerationAnnotation": "loft.sh/app-generation",
    // LoftHelmReleaseSystemAppLabel indicates if the helm release was deployed via the loft app store
    "LoftHelmReleaseSystemAppLabel": "loft.sh/system-app",

    // The latest vcluster version annotation
    "LoftVClusterLatestVersion": "virtualcluster.loft.sh/latest-version",
    "LoftDefaultSpaceTemplate": "space.loft.sh/default-template",
    "LoftDefaultVirtualClusterTemplate": "virtualcluster.loft.sh/default-template",
    // LoftClusterSpaceClusterRole indicates if the cluster cluster role should be displayed in the UI
    "LoftClusterSpaceClusterRole": "loft.sh/space-cluster-role",

    // UI Constants
    "Content": "base-layout-content",
    "ResetPasswordKey": "reset-password",
    "LoftDataNamespace": "loft-data",

    "ConnectLocalClusterFeature": "connect-local-cluster",
    "SleepModeFeature": "sleep-mode",

    // LoftClusterDomainAnnotation holds the currently claimed domain
    LoftClusterDomainAnnotation: "loft.sh/cluster-domain",

    // LoftClusterDomainTargetAnnotation holds the domain target for the claimed subdomain
    LoftClusterDomainTargetAnnotation: "loft.sh/cluster-domain-target",
}