import React, {useRef} from "react";
import ItemDrawer, {SectionProps} from "../../../../components/Drawer/ItemDrawer";
import {useDrawerDispatcher} from "../../../../contexts/drawer/DrawerContext";
import ClientMessage from "../../../../lib/Message/ClientMessage";
import {Resources} from "../../../../lib/resources";
import {ResultError, Return} from "../../../../lib/result";
import Access from "../../../../components/Drawer/Sections/Access/Access";
import {useUser} from "../../../../contexts/UserContext/UserContext";
import Metadata from "../../../../components/Drawer/Sections/Metadata/Metadata";
import Clusters from "../../../../components/Drawer/Sections/Clusters/Clusters";
import {genericOnBatch, genericOnCreate, genericOnUpdate} from "../../../../lib/helpers/drawerhelper";
import {arr} from "../../../../lib/helpers/renderhelper";
import SpaceConstraintConfig from "./Sections/SpaceConstraintConfig";
import SpaceConstraintObjects from "./Sections/SpaceConstraintObjects";
import {ManagementV1SpaceConstraint} from "../../../../../gen/model/managementV1SpaceConstraint";

const defaultVerbMappingRoles = [
    {
        key: "*",
        text: "All"
    },
    {
        key: "create",
        text: "create"
    },
    {
        key: "get",
        text: "view"
    },
    {
        key: "update",
        text: "update"
    },
    {
        key: "delete",
        text: "delete"
    },
    {
        key: "bind",
        text: "bind"
    },
];

export interface GlobalSpaceConstraintsDrawerProps extends SectionProps {
    spaceConstraint?: ManagementV1SpaceConstraint;
    spaceConstraints?: ManagementV1SpaceConstraint[];

    refetch: () => Promise<void>;
}

export default function SpaceConstraintsDrawer(props: GlobalSpaceConstraintsDrawerProps) {
    const drawer = useDrawerDispatcher();
    const user = useUser();
    const metadataRef = useRef<Metadata>(null);
    const accessRef = useRef<Access>(null);
    const clustersRef = useRef<Clusters>(null);
    const spaceConstraintConfigRef = useRef<SpaceConstraintConfig>(null);
    const spaceConstraintObjectsRef = useRef<SpaceConstraintObjects>(null);

    return <ItemDrawer okButtonText={props.mode === "create" ? "Create" : "Update"} onOkAsync={async () => {
        const message = ClientMessage.Loading();

        // execute the create / update / batch logic
        let result: ResultError | undefined = undefined;
        if (props.mode === "create") {
            result = await genericOnCreate({
                resource: Resources.ManagementV1SpaceConstraint,
                sections: [
                    metadataRef.current!.create,
                    accessRef!.current!.create,
                    clustersRef!.current!.create,
                    spaceConstraintConfigRef!.current!.create,
                    spaceConstraintObjectsRef!.current!.create,
                ],
                defaultData: {
                    spec: {
                        localSpaceConstraintTemplate: {}
                    }
                }
            });
        } else if (props.mode === "update") {
            result = await genericOnUpdate({
                existing: props.spaceConstraint!,
                resource: Resources.ManagementV1SpaceConstraint,
                sections: [
                    metadataRef.current!.update,
                    accessRef!.current!.update,
                    clustersRef!.current!.update,
                    spaceConstraintConfigRef!.current!.update,
                    spaceConstraintObjectsRef!.current!.update,
                ],
            });
        } else if (props.mode === "batch") {
            result = await genericOnBatch({
                existings: arr(props.spaceConstraints),
                resource: Resources.ManagementV1SpaceConstraint,
                sections: [
                    metadataRef.current!.batch,
                    accessRef!.current!.batch,
                ],
            });
        }

        // check if there was an error
        if (result?.err) {
            message.ErrorManagement(result);
            return;
        }

        // refetch
        await props.refetch();
        message.DoneManagement();

        // close drawer
        drawer({});
    }}>
        <Metadata mode={props.mode} type={"Space Constraints"} obj={props.spaceConstraint} showDisplayName={true} showDescription={true} noMargin ref={metadataRef} />
        <SpaceConstraintObjects mode={props.mode} spaceConstraint={props.spaceConstraint} ref={spaceConstraintObjectsRef} />
        <SpaceConstraintConfig mode={props.mode} spaceConstraint={props.spaceConstraint} ref={spaceConstraintConfigRef} />
        <Clusters description={"The target clusters where these space constraints should be available in."} obj={props.spaceConstraint} mode={props.mode} ref={clustersRef} foldable={true} defaultFolded={true} />
        <Access mode={props.mode} kind={"Global Space Constraints"} user={user} allowedVerbs={defaultVerbMappingRoles} access={props.spaceConstraint} ref={accessRef} />
    </ItemDrawer>
}