import {CodeOutlined, DownOutlined, FileOutlined, ReloadOutlined, UpOutlined} from '@ant-design/icons';
import {Space, Tabs, Tooltip} from 'antd';
import React, {createRef, useCallback, useContext, useRef, useState} from 'react';
import { SearchAddon } from 'xterm-addon-search';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import Loading from '../Loading/Loading';
import Query from '../Query/Query';
import { Search } from '../Search/Search';
import Select from '../Select/Select';
import { ExecAddon } from '../Terminal/ExecAddon';
import { Terminal } from '../Terminal/Terminal';
import client, { RequestOptionsVCluster } from '../../lib/client';
import { arr, selectDefaultFilter } from '../../lib/helpers/renderhelper';
import useQuery from '../../lib/Query/Query';
import { Resources } from '../../lib/resources';
import { Return } from '../../lib/result';
import styles from './PodExecDockTab.module.scss';
import {DockContext, DockTabActions, DockTabKind} from "./DockContext";
const Option = Select.Option;

interface PodExecDockTabProps {
    className?: string;
    onClose?: () => void;
    container?: string;

    vCluster?: RequestOptionsVCluster;
    cluster?: string;

    text?: string;

    task?: string;
    pod?: string;
    namespace?: string;
    
    dockHeight?: number;
}

export function calculateTerminalSize(dockHeight: number | undefined): number | undefined {
    return dockHeight ? dockHeight - 32 - 31 - 25 : undefined;
}

export function PodExecDockTab(props: PodExecDockTabProps) {
    const [containers, setContainers] = useState<string[]>([]);
    const { dispatch } = useContext(DockContext);
    const [selectedContainer, setSelectedContainer] = useState<string>(props.container || "");
    const { error, loading } = useQuery(async () => {
        const podRequest = await client.auto(props.cluster, props.vCluster, Resources.V1Pod).Namespace(props.namespace).Get(props.pod!);
        if (podRequest.err) {
            return podRequest;
        }

        const pod = podRequest.val;
        const containers: string[] = [];
        arr(pod.spec?.initContainers).forEach(container => containers.push(container.name));
        arr(pod.spec?.containers).forEach(container => containers.push(container.name));
        if (containers.length === 0) {
            return Return.Failed("Pod has no containers");
        }

        setContainers(containers);
        setSelectedContainer(props.container || containers[0]);
        return Return.Ok();
    });

    const [searchAddon] = useState(new SearchAddon());
    const [searchTerm, setSearchTerm] = useState<string>();
    const onSearchNext = useCallback(() => {
        if (searchTerm) {
            searchAddon.findNext(searchTerm);
        }
    }, [searchAddon, searchTerm]);
    const onSearchPrev = useCallback(() => {
        if (searchTerm) {
            searchAddon.findPrevious(searchTerm);
        }
    }, [searchAddon, searchTerm]);

    if (error) {
        return <ErrorMessage error={error} />;
    } else if (loading) {
        return null;
    }

    return <Query
        refetch={[selectedContainer]}
        query={
            async () => {
                return await client
                    .autoNonResource(props.cluster, props.vCluster)
                    .Exec(
                        props.namespace!,
                        props.pod!,
                        {
                            container: selectedContainer,
                            command: ['sh', '-c', `command -v bash >/dev/null 2>&1 && exec bash || exec sh`],
                            stdout: true,
                            stdin: true,
                            tty: true
                        }
                    )
            }
        }>
        {
            result => {
                if (result.error) {
                    return <ErrorMessage error={result.error} />;
                }
                
                return <div>
                    <div className={styles["header"]}>
                        <div className={styles["select-wrapper"]}>
                            <React.Fragment>
                                <span>Container:</span>
                                <Select showSearch
                                    placeholder={"Select a container"}
                                    dropdownClassName={styles["dropdown"]}
                                    optionFilterProp="children"
                                    style={{ width: "200px", marginRight: "5px" }}
                                    filterOption={selectDefaultFilter}
                                    value={selectedContainer}
                                    onChange={value => setSelectedContainer(value)}>
                                    {arr(containers).map(container => {
                                        return <Option key={container} value={container}>{container}</Option>
                                    })}
                                </Select>
                                <Tooltip title={"logs"}>
                                    <FileOutlined className={styles["setting"]} onClick={() => {
                                        dispatch({
                                            type: DockTabActions.OPEN,
                                            payload: {
                                                kind: DockTabKind.LOGS,
                                                cluster: props.cluster,
                                                vCluster: props.vCluster,
                                                namespace: props.namespace,
                                                pod: props.pod,
                                                container: selectedContainer,
                                            }
                                        })
                                    }} />
                                </Tooltip>
                            </React.Fragment>
                        </div>
                        <Space direction="horizontal" className={styles["right-wrapper"]}>
                            <Search className={styles["search"]}
                                placeholder={"Search"}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                onPressEnter={onSearchNext} />
                            <Tooltip title={"previous"}>
                                <UpOutlined className={styles["setting"]} onClick={onSearchPrev} />
                            </Tooltip>
                            <Tooltip title={"next"}>
                                <DownOutlined className={styles["setting"]} onClick={onSearchNext} />
                            </Tooltip>
                            <div>
                                <Tooltip title="refresh">
                                    <ReloadOutlined className={styles["setting"]} onClick={() => result.refetch()} />
                                </Tooltip>
                            </div>
                        </Space>
                    </div>
                    <div>
                        {result.loading
                            ? <div>
                                <Loading />
                              </div>
                            : <Terminal className={styles["terminal"]}
                                        cursorBlink={true}
                                        disableStdin={false}
                                        height={calculateTerminalSize(props.dockHeight)}
                                        addons={[
                                            searchAddon,
                                            new ExecAddon(result.data!, { onSuccess: props.onClose }),
                                        ]} />
                        }
                    </div>
                </div>
            }
        }
    </Query>
}