import React from "react";
import client from "../../../../lib/client";
import {Resources} from "../../../../lib/resources";
import {DrawerDispatch, useItemDrawer} from "../../../../contexts/drawer/DrawerContext";
import DynamicTime from "../../../../components/DynamicTime/DynamicTime";
import Table, {TableActions} from "../../../../components/Table/Table";
import {Tooltip} from "../../../../components/Tooltip/Tooltip";
import {SettingOutlined, WarningOutlined} from "@ant-design/icons/lib";
import useQuery from "../../../../lib/Query/Query";
import styles from "./TemplateInstancesTable.module.scss";
import {Button} from "antd";
import {ConfigV1alpha1TemplateInstance} from "../../../../../gen/model/configV1alpha1TemplateInstance";
import {arr} from "../../../../lib/helpers/renderhelper";
import TemplateInstanceDrawer from "./TemplateInstanceDrawer/TemplateInstanceDrawer";
import {creationTimestampSorter, nameSorter} from "../../../../lib/helpers/sorthelper";
import ShowYamlPopup from "../../../../components/ShowYamlPopup/ShowYamlPopup";
import TemplateInstanceDeletePopup from "./TemplateInstanceDeletePopup";
import TemplateDrawer from "../Templates/TemplateDrawer/TemplateDrawer";
import ClientMessage from "../../../../lib/Message/ClientMessage";
import {alert} from "../../../../lib/Modal/Modal";
import FixedText from "../../../../components/FixedText/FixedText";

function getSelectedTemplateInstances(allTemplateInstances: Array<ConfigV1alpha1TemplateInstance> | undefined, selectedRowKeys: React.ReactText[]): Array<ConfigV1alpha1TemplateInstance> {
    return allTemplateInstances!.filter(ti => !!selectedRowKeys.find(selectedRowKey => {
        const templateInstanceName = selectedRowKey.toString().split("/");
        return ti.metadata?.namespace === templateInstanceName[0] && ti.metadata?.name === templateInstanceName[1];
    }));
}

function getTableColumns(refetch: () => Promise<void>, drawerDispatcher: DrawerDispatch, cluster: string, space: string | undefined) {
    const editTemplateInstance = (templateInstance: ConfigV1alpha1TemplateInstance) => {
        drawerDispatcher({
            title: "Edit Template Instance: " + templateInstance.metadata?.name!,
            content: <TemplateInstanceDrawer mode={"update"} cluster={cluster} templateInstance={templateInstance} refetch={refetch} />
        })
    };

    const columns = [
        {
            title: "Template Instance Name",
            sorter: (a: ConfigV1alpha1TemplateInstance, b: ConfigV1alpha1TemplateInstance) => nameSorter(a, b),
            render: (templateInstance: ConfigV1alpha1TemplateInstance) => {
                return <FixedText className={styles["clickable"]} onClick={() => editTemplateInstance(templateInstance)} text={templateInstance.metadata?.name!} />
            },
        },
        {
            title: "Namespace",
            sorter: (a: ConfigV1alpha1TemplateInstance, b: ConfigV1alpha1TemplateInstance) => nameSorter(a, b),
            render: (templateInstance: ConfigV1alpha1TemplateInstance) => {
              return <FixedText text={templateInstance.metadata?.namespace!} />;
            },
        },
        {
            title: "Status",
            sorter: (a: ConfigV1alpha1TemplateInstance, b: ConfigV1alpha1TemplateInstance) => nameSorter(a, b),
            render: (templateInstance: ConfigV1alpha1TemplateInstance) => {
                if (templateInstance.metadata?.deletionTimestamp) {
                    return <span className={styles["pending"]}>Deleting</span>
                }
                
                const status = templateInstance.status?.status;
                if (!status){
                    return <span className={styles["pending"]}>Pending</span>
                } else if (status === "Deployed"){
                    return status;
                }
                
                return <span className={styles["error-status"]} style={{"cursor": "pointer"}} onClick={() => alert({
                        title: status,
                        content: <div>
                            {templateInstance.status?.message}
                        </div>
                    })}>
                    <WarningOutlined/>{status}
                </span>;
            },
        },
        {
            title: "Template",
            sorter: (a: ConfigV1alpha1TemplateInstance, b: ConfigV1alpha1TemplateInstance) => nameSorter(a, b),
            render: (templateInstance: ConfigV1alpha1TemplateInstance) => {
              return <FixedText className={styles["clickable"]} onClick={async () => {
                  const result = await client.cluster(cluster, Resources.ConfigV1alpha1Template).Get(templateInstance.spec?.template!);
                  if (result.err) {
                      ClientMessage.Error(result);
                      return;
                  }

                  const listResult = await client.cluster(cluster, Resources.ConfigV1alpha1Template).List();
                  if (listResult.err) {
                      ClientMessage.Error(listResult);
                      return;
                  }
                  
                  const template = result.val;
                  drawerDispatcher({
                      title: "Edit Template: " + template.metadata?.name!,
                      content: <TemplateDrawer mode={"update"} cluster={cluster} template={template} allTemplates={arr(listResult.val.items)} refetch={refetch} />
                  })
              }} text={templateInstance.spec?.template!} />;
            },
        },
        {
            title: "Sync",
            sorter: (a: ConfigV1alpha1TemplateInstance, b: ConfigV1alpha1TemplateInstance) => nameSorter(a, b),
            render: (templateInstance: ConfigV1alpha1TemplateInstance) => {
              if (!templateInstance.spec?.sync){
                return "Disabled";
              }
              return "Enabled";
            },
        },
        {
            title: 'Created',
            width: "180px",
            sorter: (a: ConfigV1alpha1TemplateInstance, b: ConfigV1alpha1TemplateInstance) => creationTimestampSorter(a, b),
            render: (templateInstance: ConfigV1alpha1TemplateInstance) => {
                return <DynamicTime timestamp={templateInstance.metadata?.creationTimestamp} useTooltip={true}/>
            }
        },
        {
            title: 'Actions',
            width: "180px",
            render: (templateInstance: ConfigV1alpha1TemplateInstance) => {
                return <TableActions className={styles["actions"]}>
                    <Tooltip title="edit">
                        <SettingOutlined className={styles["setting"]} onClick={() => editTemplateInstance(templateInstance)} />
                    </Tooltip>
                    <ShowYamlPopup className={styles["setting"]} object={templateInstance} cluster={cluster} resource={Resources.ConfigV1alpha1TemplateInstance} namespace={templateInstance.metadata?.namespace} name={templateInstance.metadata?.name!} refetch={refetch} />
                    <TemplateInstanceDeletePopup templateinstances={[templateInstance]} cluster={cluster} refetch={refetch} />
                </TableActions>;
            }
        },
    ];
    
    // if we have a space delete the namespace column
    if (space) {
        columns.splice(1, 1);
    }
    
    return columns;
}

function filter(item: ConfigV1alpha1TemplateInstance, value: string) {
    return !!(item.metadata?.name?.includes(value) || item.metadata?.namespace?.includes(value))
}

interface TemplatesInstancesTableProps {
    cluster: string;
    space?: string;
    
    top: React.ReactNode;
}

export default function TemplatesInstancesTable(props: TemplatesInstancesTableProps) {
    const drawerDispatcher = useItemDrawer();
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedKeys: any) => {
            setSelectedRowKeys(selectedKeys);
        },
    };

    // get template instances
    const {loading, error, data, refetch} = useQuery(async () => await client.cluster(props.cluster, Resources.ConfigV1alpha1TemplateInstance).Namespace(props.space).List());
    return <div>
        <Table loading={loading} columns={getTableColumns(refetch, drawerDispatcher, props.cluster, props.space)} dataSource={data ? arr(data.items).map(templateinstance => { return {...templateinstance, key: templateinstance.metadata!.namespace!+"/"+templateinstance.metadata!.name!}}) : undefined} error={error} rowSelection={rowSelection} filter={filter} refetch={refetch} header={{
            top: props.top,
            right: <Button type={"primary"} onClick={() => {
                drawerDispatcher({
                    title: "Create Template Instance",
                    content: <TemplateInstanceDrawer mode={"create"} cluster={props.cluster} namespace={props.space} refetch={refetch} />
                })
            }}>Create Template Instance</Button>, 
            selectedActions: <React.Fragment>
                <TemplateInstanceDeletePopup className={styles["delete-batch"]} templateinstances={getSelectedTemplateInstances(arr(data?.items), selectedRowKeys)} cluster={props.cluster} refetch={() => {
                  setSelectedRowKeys([]);
                  return refetch();
                }} />
            </React.Fragment> 
        }} />
    </div>
};