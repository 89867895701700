import React, {useState} from "react";
import * as Ant from "antd";
import * as AntTable from "antd/lib/table/Table";
import styles from "./Table.module.scss";
import {ResultError} from "../../lib/result";
import {ErrorMessage} from "../ErrorMessage/ErrorMessage";
import {ReloadOutlined} from "@ant-design/icons/lib";
import {ToggleColumnProvider} from "../../contexts/ToggleColumn/ToggleColumn";
import ActionsIcon from "../../images/actions-icon.svg";
import Icon from "@ant-design/icons";
import {without} from "../../lib/helpers/renderhelper";
import {Popover} from "../Popover/Popover";
import {Tooltip} from "../Tooltip/Tooltip";
import {Search} from "../Search/Search";

export interface TableActionsProps {
    className?: string;
}

export function TableActions(props: React.PropsWithChildren<TableActionsProps>) {
    const classNames = [styles["actions"]];
    if (props.className) {
        classNames.push(props.className);
    }

    return <div className={classNames.join(" ")}>
        <div className={styles["actions-icon"]}>
            <Icon component={ActionsIcon as any} />
        </div>
        <div className={styles["actions-content"]}>
            {props.children}
        </div>
    </div>
}

interface TableHeaderProps extends TableHeaderExtraProps {
    filterValue?: string;
    setFilterValue: (val: string) => void;

    total: number;
    displayed: number;
    refetch?: () => void;

    popoverVisible: boolean;
    hideSearch?: boolean;
}

function TableHeader(props: TableHeaderProps) {
    return <div className={styles["header-wrapper"]}>
        {props.top}
        <div className={styles["header"]}>
            <Popover visible={props.popoverVisible} overlayClassName={styles["popover"]} content={props.selectedActions}>
                <div className={styles["popover-marker"]} />
            </Popover>
            <div className={styles["left"]}>
                {props.left}
                {!props.hideSearch && <Search className={styles["search"]} fromUrl={true} placeholder={"Search"} value={props.filterValue} onChange={(e) => props.setFilterValue(e.target.value)} />}
                {props.refetch && <Tooltip title={"refresh"}>
                    <ReloadOutlined className={styles["reload"]} onClick={props.refetch} />
                </Tooltip>}
                <span className={styles["total"]}>
                        Showing <span className={styles["bold"]}>{props.displayed}</span> of <span className={styles["bold"]}>{props.total}</span> rows
                    </span>
                {props.middleLeft}
            </div>
            <div>
                {props.right}
            </div>
        </div>
    </div>
}

export interface TableHeaderExtraProps {
    left?: React.ReactNode;
    middleLeft?: React.ReactNode;
    right?: React.ReactNode;
    top?: React.ReactNode;
    hideSearch?: boolean;
    selectedActions?: React.ReactNode;
}

export interface TableProps<T> extends AntTable.TableProps<T> {
    error?: ResultError;

    refetch?: () => void;
    filter?: (item: T, value: string) => boolean;

    header?: TableHeaderExtraProps;
}

function Table<T extends object = any>(props: TableProps<T>) {
    const [searchValue, setSearchValue] = useState<string>("");

    const classNames = [styles["table"]];
    if (props.className) {
        classNames.push(props.className);
    }

    const hasError = props.error && props.error.err;
    let items: T[] | undefined = undefined;
    if (props && props.dataSource) {
        items = [...props.dataSource];
        if (props.filter && searchValue) {
            items = items.filter((item) => props.filter!(item, searchValue))
        }
    }

    return <ToggleColumnProvider>
        <Ant.Table {...without(props, ["error", "refetch"])} className={classNames.join(" ")}
                   locale={hasError ? {
                        emptyText: <ErrorMessage error={props.error!} />
                    } : props.locale}
                   dataSource={hasError ? undefined : items}
                   title={
                       () => <TableHeader {...props.header}
                                             popoverVisible={!!(props.rowSelection && props.rowSelection.selectedRowKeys && props.rowSelection.selectedRowKeys.length > 0)}
                                             filterValue={searchValue}
                                             setFilterValue={setSearchValue}
                                             refetch={props.refetch}
                                             total={props.dataSource ? props.dataSource.length : 0}
                                             displayed={items ? items.length : 0} />
                   }
                   pagination={{
                     defaultPageSize: window.innerHeight > 1000 ? 15 : 10,
                     hideOnSinglePage: true,
                   }}
                   showSorterTooltip={false}
        />
    </ToggleColumnProvider>
}

export default Table;