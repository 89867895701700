import React from "react";
import client from "../../lib/client";
import {Resources} from "../../lib/resources";
import {Button, Space} from "antd";
import {ManagementV1Cluster} from "../../../gen/model/managementV1Cluster";
import {DrawerDispatch, useDrawerDispatcher, useItemDrawer} from "../../contexts/drawer/DrawerContext";
import ClusterConnect from "./ClusterConnect/ClusterConnect";
import DynamicTime from "../../components/DynamicTime/DynamicTime";
import Table, {TableActions} from "../../components/Table/Table";
import {Tooltip} from "../../components/Tooltip/Tooltip";
import {ApiOutlined, DeleteOutlined, SettingOutlined, WarningOutlined} from "@ant-design/icons/lib";
import {alert, deleteConfirm} from "../../lib/Modal/Modal";
import ClientMessage from "../../lib/Message/ClientMessage";
import {ToggleColumn, ToggleColumnContent} from "../../contexts/ToggleColumn/ToggleColumn";
import Progress from "../../components/Progress/Progress";
import useQuery from "../../lib/Query/Query";
import {arr} from "../../lib/helpers/renderhelper";
import styles from "./Clusters.module.scss";
import Query from "../../components/Query/Query";
import {useHistory} from "react-router-dom";
import * as History from "history";
import {creationTimestampSorter, displayNameSorter, nameSorter, stringSorter} from "../../lib/helpers/sorthelper";
import Loading from "../../components/Loading/Loading";
import {ErrorMessage} from "../../components/ErrorMessage/ErrorMessage";
import EmptySvg from "../../images/empty.svg";
import {useFeatures} from "../../contexts/LicenseContext/LicenseContext";
import {ManagementV1Feature} from "../../../gen/model/managementV1Feature";
import {wrapLicenseLink} from "../Spaces/Spaces/SpaceTable/SpaceTable";
import ShowYamlPopup from "../../components/ShowYamlPopup/ShowYamlPopup";
import Code from "../../components/Code/Code";
import copy from "copy-to-clipboard";
import ClusterResetPopup from "./ClusterResetPopup/ClusterResetPopup";
import ClusterDeletePopup from "./ClusterDeletePopup/ClusterDeletePopup";
import FixedText from "../../components/FixedText/FixedText";
import ClusterDrawer from "./ClusterDrawer/ClusterDrawer";
import ClustersHeader from "./ClustersHeader/ClustersHeader";
import {displayName} from "../../lib/helper";
import Description from "../../components/Description/Description";

interface Props {}

function getTableColumns(refetch: () => Promise<void>, drawerDispatcher: DrawerDispatch, history: History.History<any>, features: Array<ManagementV1Feature>) {
    const editCluster = (cluster: ManagementV1Cluster) => {
        drawerDispatcher({
            title: "Edit Cluster: " + cluster.metadata?.name!,
            content: <ClusterDrawer mode={"update"} cluster={cluster} refetch={refetch} />
        })
    };
    
    return [
        {
            title: <ToggleColumn id={"displayname"} columns={['Display Name', 'Kubernetes Name (ID)']}/>,
            sorter: (a: ManagementV1Cluster, b: ManagementV1Cluster) => stringSorter(displayName(a), displayName(b)),
            render: (cluster: ManagementV1Cluster) => {
                return <Query query={async () => await client.cluster(cluster.metadata?.name!, Resources.V1ClusterRole).CanI("create")}>
                    {
                        result => {
                            return <ToggleColumnContent id={"displayname"} columns={[
                                () => {
                                    if (result.error && result.error.err) {
                                        console.error(result.error.val.message);
                                        return <FixedText text={displayName(cluster)} />;
                                    } else if (result.loading || !result.data) {
                                        return <FixedText text={displayName(cluster)} />;
                                    }

                                    return <FixedText className={styles["cluster"]} onClick={() => history.push(`/clusters/details/${cluster.metadata?.name!}`)} text={displayName(cluster)} />;
                                },
                                () => {
                                    if (result.error && result.error.err) {
                                        console.error(result.error.val.message);
                                        return <FixedText text={cluster.metadata?.name} />;
                                    } else if (result.loading || !result.data) {
                                        return <FixedText text={cluster.metadata?.name} />;
                                    }

                                    return <FixedText className={styles["cluster"]} onClick={() => history.push(`/clusters/details/${cluster.metadata?.name!}`)} text={cluster.metadata?.name} />;
                                },
                            ]}/>
                        }
                    }
                </Query>;
            }
        },
        {
            title: 'Status',
            render: (cluster: ManagementV1Cluster) => {
                if (cluster.status?.phase === "Initialized") {
                    return "Ready";
                } else if (!cluster.status?.phase) {
                    return <span className={"color-warning"}>Initializing</span>
                }

                return <Tooltip title={"Error initializing: " + cluster.status?.message}>
                    <span className={styles["cluster-error"]}><WarningOutlined />Error</span>
                </Tooltip>
            }
        },
        {
            title: "Description",
            render: (cluster: ManagementV1Cluster) => {
                return <Description.Column>
                    {cluster.spec?.description || ""}
                </Description.Column>;
            }
        },
        {
            title: 'Version',
            render: (cluster: ManagementV1Cluster) => {
                return <Query query={async () => await client.clusterNonResource<any>(cluster.metadata?.name!).Version()}>
                    {
                        result => {
                            if (result.error && result.error.err) {
                                console.error(result.error.val.message);
                                return <Tooltip title={result.error.val.message}><span>Unknown</span></Tooltip>;
                            } else if (result.loading) {
                                return null;
                            }

                            const version = result.data;
                            if (!version) {
                                return <span>Unknown</span>;
                            }

                            const versionString = version.gitVersion ? version.gitVersion : ("v" + version.major + "." + version.minor);
                            return <span>{versionString}</span>;
                        }
                    }
                </Query>;
            }
        },
        {
            title: 'Connected',
            sorter: (a: ManagementV1Cluster, b: ManagementV1Cluster) => creationTimestampSorter(a, b),
            render: (cluster: ManagementV1Cluster) => {
                return <DynamicTime timestamp={cluster.metadata?.creationTimestamp} useTooltip={true}/>
            }
        },
        {
            title: <ToggleColumn id={"time-sleeping"} columns={['Time Sleeping (last 30 days)', 'Time Sleeping (last 7 days)']}/>,
            width: 240,
            render: (cluster: ManagementV1Cluster) => {
                return <ToggleColumnContent id={"time-sleeping"} columns={[
                    () => {
                        return <Query query={async () => await client.cluster(cluster.metadata?.name!, Resources.ClusterV1SleepModeConfig).List()}>
                            {
                                result => {
                                    if (result.error && result.error.err) {
                                        return <Tooltip title={result.error.val.message}><span>Unknown</span></Tooltip>;
                                    } else if (result.loading) {
                                        return null;
                                    } else if (!result.data || !result.data.items || !result.data.items.length) {
                                        return wrapLicenseLink(<Progress className={styles["progress"]} percent={0} />, features);
                                    }

                                    let totalSleeping = 0;
                                    for (let i = 0; i < result.data.items.length; i++) {
                                        totalSleeping += result.data.items[i].status?.sleptLastThirtyDays || 0;
                                    }

                                    return wrapLicenseLink(<Progress className={styles["progress"]} percent={Math.round((totalSleeping / result.data.items.length) * 100)} />, features);
                                }
                            }
                        </Query>;
                    },
                    () => {
                        return <Query query={async () => await client.cluster(cluster.metadata?.name!, Resources.ClusterV1SleepModeConfig).List()}>
                            {
                                result => {
                                    if (result.error && result.error.err) {
                                        return <Tooltip title={result.error.val.message}><span>Unknown</span></Tooltip>;
                                    } else if (result.loading) {
                                        return null;
                                    } else if (!result.data || !result.data.items || !result.data.items.length) {
                                        return wrapLicenseLink(<Progress percent={0} />, features);
                                    }

                                    let totalSleeping = 0;
                                    for (let i = 0; i < result.data.items.length; i++) {
                                        totalSleeping += result.data.items[i].status?.sleptLastSevenDays || 0;
                                    }

                                    return wrapLicenseLink(<Progress percent={Math.round((totalSleeping / result.data.items.length) * 100)} />, features);
                                }
                            }
                        </Query>;
                    },
                ]} />
            }
        },
        {
            title: 'Actions',
            width: "210px",
            render: (cluster: ManagementV1Cluster) => {
                return <TableActions className={styles["actions"]}>
                    <Tooltip title={"connect"}>
                        <ApiOutlined className={styles["setting"]} onClick={() => {
                            const copyText = `loft use cluster ${cluster.metadata?.name!}`;
                            alert({
                                title: `Access the cluster via kubectl`,
                                okText: "Copy & Close",
                                onOkAsync: async () => copy(copyText),
                                content: <Space direction={"vertical"} size={10}>
                                    <div>
                                        Make sure you have <a className={"text-bold color-primary"} href={"https://loft.sh/install-cli"} target={"_blank"}>loft CLI</a> installed and copy the following command to configure your local kubernetes context to use this cluster:
                                    </div>
                                    <Code text={copyText} />
                                </Space>
                            })
                        }} />
                    </Tooltip>
                    <Tooltip title="edit">
                        <SettingOutlined className={"blue-btn"} onClick={() => editCluster(cluster)} />
                    </Tooltip>
                    <ClusterResetPopup cluster={cluster} />
                    <ShowYamlPopup className={styles["setting"]} object={cluster} resource={Resources.ManagementV1Cluster} name={cluster.metadata?.name!} refetch={refetch} />
                    <ClusterDeletePopup cluster={cluster?.metadata?.name!} refetch={refetch}/>
                </TableActions>;
            }
        },
    ];
}

function filter(item: ManagementV1Cluster, value: string) {
    return !!(item.metadata?.name?.includes(value) || item.spec?.displayName?.includes(value));
}

function Clusters(props: Props) {
    const features = useFeatures();
    const history = useHistory();
    const itemDispatcher = useItemDrawer();
    const drawerDispatcher = useDrawerDispatcher();
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedKeys: any) => {
            setSelectedRowKeys(selectedKeys);
        },
    };

    const {loading, error, data, refetch} = useQuery(async () => await client.management(Resources.ManagementV1Cluster).List());
    if (loading) {
        return <Loading />;
    } else if (error) {
        return <ErrorMessage error={error} />;
    } else if (!data?.items?.length) {
        return <Query query={async () => await client.management(Resources.ManagementV1Cluster).CanI("create")}>
            {
                result => {
                    if (result.error && result.error.err) {
                        return <span className={"color-error"}>{result.error.val.message}</span>
                    } else if (result.loading) {
                        return null;
                    } else if (!result.data) {
                        return <div className={styles["empty-wrapper"]}>
                            <ClustersHeader />
                            <div className={styles["empty"]}>
                                <EmptySvg />
                                <h4>There are no clusters you have access to.</h4>
                            </div>
                        </div>
                    }

                    return <div className={styles["empty-wrapper"]}>
                        <ClustersHeader />
                        <div className={styles["empty"]}>
                            <EmptySvg />
                            <h4>You don't have any clusters.</h4>
                            <div className={"text-large"}>You must connect a cluster to create spaces.</div>
                            <Button type={"primary"} className={styles["connect-cluster"]} onClick={() => {
                                drawerDispatcher({
                                    title: "Connect Cluster",
                                    content: <ClusterConnect refetch={refetch} />
                                });
                            }}>Connect Cluster</Button>
                            <div>
                                If you only have one cluster, you can also
                            </div>
                            <div>
                                connect the cluster that loft is running in.
                            </div>
                        </div>
                    </div>
                }
            }
        </Query>;
    }

    const clusters = arr(data?.items).sort((a, b) => displayNameSorter(a, b))
    return <div>
        <Table className={styles["table"]} loading={loading} columns={getTableColumns(refetch, itemDispatcher, history, features)} dataSource={clusters.map(cluster => { return {...cluster, key: cluster.metadata!.name!}})} error={error} rowSelection={rowSelection} filter={filter} refetch={refetch} header={{
            top: <ClustersHeader>
                <Description.Table>Connected Kubernetes clusters that can be managed through Loft. You can allow users and teams to access those clusters and they can create new spaces and virtual clusters inside them.</Description.Table>
            </ClustersHeader>,
            right: <Query query={async () => await client.management(Resources.ManagementV1Cluster).CanI("create")}>
                {
                    result => {
                        if (result.error && result.error.err) {
                            return <span className={"color-error"}>{result.error.val.message}</span>
                        } else if (result.loading || !result.data) {
                            return null;
                        }

                        return <Button type={"primary"} onClick={() => {
                            drawerDispatcher({
                                title: "Connect Cluster",
                                content: <ClusterConnect refetch={refetch} />
                            });
                        }}>Connect Cluster</Button>
                    }
                }
            </Query>,
            selectedActions: <React.Fragment>
            <Tooltip title={"delete"}>
                <DeleteOutlined className={styles["delete-batch"]} onClick={() =>
                {
                    deleteConfirm({
                        title: `Delete Clusters`,
                        content: `Are you sure you want to delete the clusters ${selectedRowKeys.join(", ")}?`,
                        onOkAsync: async () => {
                            const message = ClientMessage.Loading();
                            for (let i = 0; i < selectedRowKeys.length; i++) {
                                const clusterName = selectedRowKeys[i];
                                const result = await client.management(Resources.ManagementV1Cluster).Delete(clusterName as string);
                                if (result.err) {
                                    message.Error(result);
                                    return;
                                }
                            }

                            message?.DoneManagement();
                            await refetch();
                            setSelectedRowKeys([]);
                        }
                    });
                }} />
            </Tooltip>
        </React.Fragment>
        }} />
    </div>
}

export default Clusters;
