import React from "react";
import {DrawerDispatch, useItemDrawer} from "../../../contexts/drawer/DrawerContext";
import {creationTimestampSorter, displayNameSorter, nameSorter, stringSorter} from "../../../lib/helpers/sorthelper";
import FixedText from "../../../components/FixedText/FixedText";
import {MultiItem} from "../../../components/MultiItem/MultiItem";
import {arr, getDisplayNameFromEntity} from "../../../lib/helpers/renderhelper";
import DynamicTime from "../../../components/DynamicTime/DynamicTime";
import Table, { TableActions } from "../../../components/Table/Table";
import {Tooltip} from "../../../components/Tooltip/Tooltip";
import {Resources} from "../../../lib/resources";
import ShowYamlPopup from "../../../components/ShowYamlPopup/ShowYamlPopup";
import {ApiOutlined, DeleteOutlined, SettingOutlined} from "@ant-design/icons";
import {alert, deleteConfirm} from "../../../lib/Modal/Modal";
import ClientMessage from "../../../lib/Message/ClientMessage";
import client from "../../../lib/client";
import useQuery from "../../../lib/Query/Query";
import Button from "../../../components/Button/Button";
import {ManagementV1VirtualClusterTemplate} from "../../../../gen/model/managementV1VirtualClusterTemplate";
import VClusterTemplateDrawer from "./VClusterTemplateDrawer/VClusterTemplateDrawer";
import VClustersHeader from "../VClustersHeader/VClustersHeader";
import styles from "../../Spaces/Spaces/SpaceTable/SpaceTable.module.scss";
import copy from "copy-to-clipboard";
import {Space} from "antd";
import Code from "../../../components/Code/Code";
import {ToggleColumn, ToggleColumnContent} from "../../../contexts/ToggleColumn/ToggleColumn";
import {displayName} from "../../../lib/helper";
import Description from "../../../components/Description/Description";

function getTableColumns(refetch: () => Promise<void>, drawerDispatcher: DrawerDispatch, allVirtualClusterTemplates: ManagementV1VirtualClusterTemplate[]) {
    const editVirtualClusterTemplate = (virtualClusterTemplate: ManagementV1VirtualClusterTemplate) => {
        drawerDispatcher({
            title: "Edit Virtual Cluster Template: " + virtualClusterTemplate.metadata?.name!,
            content: <VClusterTemplateDrawer mode={"update"} virtualClusterTemplate={virtualClusterTemplate} allVirtualClusterTemplates={allVirtualClusterTemplates} refetch={refetch} />
        })
    };

    return [
        {
            title: <ToggleColumn id={"displayname"} columns={['Display Name', 'Kubernetes Name (ID)']}/>,
            sorter: (a: ManagementV1VirtualClusterTemplate, b: ManagementV1VirtualClusterTemplate) => stringSorter(displayName(a), displayName(b)),
            render: (virtualClusterTemplate: ManagementV1VirtualClusterTemplate) => {
                return <ToggleColumnContent id={"displayname"} columns={[
                    () => {
                        return <FixedText className={"clickable-link"} onClick={() => editVirtualClusterTemplate(virtualClusterTemplate)} text={displayName(virtualClusterTemplate)} />
                    },
                    () => {
                        return <FixedText className={"clickable-link"} onClick={() => editVirtualClusterTemplate(virtualClusterTemplate)} text={virtualClusterTemplate.metadata?.name} />
                    },
                ]}/>
            }
        },
        {
            title: "Description",
            render: (virtualClusterTemplate: ManagementV1VirtualClusterTemplate) => {
                return <Description.Column>
                    {virtualClusterTemplate.spec?.description || ""}
                </Description.Column>;
            }
        },
        {
            title: 'Apps',
            render: (virtualClusterTemplate: ManagementV1VirtualClusterTemplate) => {
                return <MultiItem items={arr(virtualClusterTemplate.status?.apps).map(app => ({key: app.name, children: getDisplayNameFromEntity(app)}))} />
            }
        },
        {
            title: 'Created',
            width: "180px",
            sorter: (a: ManagementV1VirtualClusterTemplate, b: ManagementV1VirtualClusterTemplate) => creationTimestampSorter(a, b),
            render: (virtualClusterTemplate: ManagementV1VirtualClusterTemplate) => {
                return <DynamicTime timestamp={virtualClusterTemplate.metadata?.creationTimestamp} useTooltip={true}/>
            }
        },
        {
            title: 'Actions',
            width: "230px",
            render: (virtualClusterTemplate: ManagementV1VirtualClusterTemplate) => {
                return <TableActions>
                    <Tooltip title={"use"}>
                        <ApiOutlined className={styles["wakeup"]} onClick={() => {
                            const copyText = `loft create vcluster my-vcluster --template ${virtualClusterTemplate.metadata?.name}`;
                            alert({
                                title: `Create a new virtual cluster with this template`,
                                okText: "Copy & Close",
                                onOkAsync: async () => copy(copyText),
                                content: <Space direction={"vertical"} size={10}>
                                    <div>
                                        Make sure you have <a className={"text-bold color-primary"} href={"https://loft.sh/install-cli"} target={"_blank"}>loft CLI</a> installed and copy the following command to create a new virtual cluster using this template:
                                    </div>
                                    <Code text={copyText} />
                                </Space>
                            })
                        }} />
                    </Tooltip>
                    <Tooltip title="edit">
                        <SettingOutlined className={"blue-btn"} onClick={() => editVirtualClusterTemplate(virtualClusterTemplate)} />
                    </Tooltip>
                    <ShowYamlPopup className={"blue-btn"} object={virtualClusterTemplate} resource={Resources.ManagementV1VirtualClusterTemplate} name={virtualClusterTemplate.metadata?.name!} refetch={refetch} />
                    <Tooltip title="delete">
                        <DeleteOutlined className={"actions-delete-btn"} onClick={() => {
                            deleteConfirm({
                                title: `Delete Virtual Cluster Template: ${virtualClusterTemplate?.metadata?.name}`,
                                content: `Are you sure you want to delete the virtual cluster template ${virtualClusterTemplate?.metadata?.name}? This action CANNOT be reverted! Already deployed virtual clusters will stay unaffected.`,
                                onOkAsync: async () => {
                                    const message = ClientMessage.Loading();
                                    const result = await client.management(Resources.ManagementV1VirtualClusterTemplate).Delete(virtualClusterTemplate.metadata?.name!);
                                    message.Result(result);
                                    await refetch();
                                },
                            });
                        }} />
                    </Tooltip>
                </TableActions>;
            }
        },
    ];
}

function filter(item: ManagementV1VirtualClusterTemplate, value: string) {
    return !!(item.metadata?.name?.includes(value) || item.spec?.template?.apps?.find(app => app.name?.includes(value)) || item.spec?.displayName?.includes(value));
}

export default function VClusterTemplates() {
    const drawerDispatcher = useItemDrawer();
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
    const {loading, error, data, refetch} = useQuery(async () => await client.management(Resources.ManagementV1VirtualClusterTemplate).List());
    const {loading: permissionLoading, error: permissionError, data: permissionData} = useQuery(async () => await client.management(Resources.ManagementV1VirtualClusterTemplate).CanI("create"));
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedKeys: any) => {
            setSelectedRowKeys(selectedKeys);
        },
    };

    const templates = arr(data?.items).sort((a, b) => displayNameSorter(a, b))
    return <div>
        <Table loading={permissionLoading || loading} columns={getTableColumns(refetch, drawerDispatcher, templates)} dataSource={templates.map(user => { return {...user, key: user.metadata!.name!}})} error={error || permissionError} rowSelection={rowSelection} filter={filter} refetch={refetch} header={{
            top: <VClustersHeader>
                <Description.Table>Virtual Cluster templates can be used to create virtual clusters that have already certain applications deployed inside them or other predefined configuration applied. They are a powerful tool to create new predefined environments on demand.</Description.Table>
            </VClustersHeader>,
            right: permissionData ? <Button type={"primary"} onClick={() => {
                drawerDispatcher({
                    title: "Add Virtual Cluster Template",
                    content: <VClusterTemplateDrawer mode={"create"} allVirtualClusterTemplates={templates} refetch={refetch} />
                });
            }}>Add Virtual Cluster Template</Button> : undefined,
            selectedActions: <React.Fragment>
                <Tooltip title={"delete"}>
                    <DeleteOutlined className={"actions-delete-btn-batch"} onClick={() =>
                    {
                        deleteConfirm({
                            title: `Delete Virtual Cluster Templates`,
                            content: `Are you sure you want to delete the virtual cluster templates ${selectedRowKeys.join(", ")}? This action CANNOT be reverted! Already deployed virtual clusters will not be deleted.`,
                            onOkAsync: async () => {
                                const message = ClientMessage.Loading();
                                for (let i = 0; i < selectedRowKeys.length; i++) {
                                    const virtualClusterTemplate = selectedRowKeys[i];
                                    const result = await client.management(Resources.ManagementV1VirtualClusterTemplate).Delete(virtualClusterTemplate as string);
                                    if (result.err) {
                                        message.Error(result);
                                        return;
                                    }
                                }

                                message?.DoneManagement();
                                await refetch();
                                setSelectedRowKeys([]);
                            }
                        });
                    }} />
                </Tooltip>
            </React.Fragment>
        }} />
    </div>
}