import React, {useEffect, useState} from "react";
import {Err, ResultError, Return} from "../../../../../lib/result";
import {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import {StorageV1VirtualCluster} from "../../../../../../gen/model/agentstorageV1VirtualCluster";
import {SelectCluster} from "../../../../Spaces/Spaces/SpaceDrawer/Sections/SpaceOwner";
import useQuery from "../../../../../lib/Query/Query";
import client from "../../../../../lib/client";
import {Resources} from "../../../../../lib/resources";
import {arr, selectDefaultFilter} from "../../../../../lib/helpers/renderhelper";
import {ErrorMessage} from "../../../../../components/ErrorMessage/ErrorMessage";
import Label from "../../../../../components/Label/Label";
import Select from "../../../../../components/Select/Select";
import Description from "../../../../../components/Description/Description";
import {ManagementV1Cluster} from "../../../../../../gen/model/managementV1Cluster";
import {ManagementV1VirtualClusterTemplate} from "../../../../../../gen/model/managementV1VirtualClusterTemplate";
import Loading from "../../../../../components/Loading/Loading";
import {displayName} from "../../../../../lib/helper";
import SectionExpander from "../../../../../components/Drawer/SectionExpander/SectionExpander";
const Option = Select.Option;

interface SelectTemplateProps {
    cluster?: string;

    onError: (err: Err<any>) => void;
    onChangeTemplate: (template: ManagementV1VirtualClusterTemplate | undefined) => void;
    onChangeValues: (values: string | undefined, version: string | undefined) => void;
}

interface SelectTemplateState {
    selectedTemplate?: string;
    selectedCluster?: string;
}

export function SelectTemplate(props: SelectTemplateProps) {
    const [state, setState] = useState<SelectTemplateState>({});
    const {loading, error, data} = useQuery(async () => client.management(Resources.ManagementV1VirtualClusterTemplate).List());
    useEffect( () => {
        (async () => {
            if (props.cluster && props.cluster !== state.selectedCluster) {
                const result = await client.management(Resources.ManagementV1ClusterVirtualClusterDefaults).Get(props.cluster);
                if (result.err) {
                    props.onError(Return.Failed(`Error retrieving default values for cluster ${props.cluster}: ${result.val.message}`));
                    return;
                }
                
                const defaultTemplate = result.val.defaultTemplate;
                setState({
                    selectedCluster: props.cluster,
                    selectedTemplate: defaultTemplate?.metadata?.name
                });
                if (defaultTemplate) {
                    if (defaultTemplate.metadata?.name !== state.selectedTemplate) {
                        props.onChangeTemplate(defaultTemplate);
                    }
                } else if (result.val.values) {
                    props.onChangeValues(result.val.values, result.val.latestVersion);
                }
            }
        })();
    }, [props.cluster]);
    if (error && error.err) {
        return <ErrorMessage error={error} />
    } else if (loading) {
        return <Loading />;
    } else if (!arr(data?.items).length) {
        return null;
    }

    return <SectionExpander name={"Create From Template"}>
        <Label>Virtual Cluster Template</Label>
        <Select
            showSearch
            allowClear={true}
            loading={loading}
            style={{ width: "100%" }}
            placeholder="Select a template"
            value={state.selectedTemplate}
            onChange={(v) => {
                props.onChangeTemplate(arr(data?.items).find(t => t.metadata?.name === v));
                setState({...state, selectedTemplate: v});
            }}
            optionFilterProp="children"
            filterOption={selectDefaultFilter}
        >
            {data ? arr(data.items).map(template => <Option key={template?.metadata?.name!} value={template?.metadata?.name!}>{displayName(template)}</Option>) : undefined}
        </Select>
        <Description>Select a template to create the virtual cluster from</Description>
    </SectionExpander>
}

interface VClusterLocationState {}

interface VClusterLocationProps extends SectionProps {
    // The cluster the virtual cluster is in
    cluster?: string;
    
    // If cluster select should be shown
    showClusterSelect?: boolean;
    
    // A function to set an error
    onError: (err: Err<any>) => void;

    // A function to set the cluster
    onSelectCluster: (cluster: ManagementV1Cluster | undefined, isOnlyOne?: boolean) => void;
    
    // A function to set the virtual cluster template
    onSelectVirtualClusterTemplate: (template: ManagementV1VirtualClusterTemplate | undefined) => void;
    
    // A function to set the virtual cluster default values and version
    onSelectVirtualClusterValues: (version: string | undefined, values: string | undefined) => void;
}

export default class VClusterLocation extends React.PureComponent<VClusterLocationProps, VClusterLocationState> {
    state: VClusterLocationState = {};

    create = (obj: {vCluster: StorageV1VirtualCluster, cluster: string}): ResultError => {
        if (!this.props.cluster) {
            return Return.Failed("Please select a cluster to create the virtual cluster in");
        }

        obj.cluster = this.props.cluster;
        return Return.Ok();
    };

    render() {
        if (this.props.mode !== "create") {
            return null;
        }

        return <div>
            {!this.props.showClusterSelect && <SelectCluster cluster={this.props.cluster} onChange={(value, isOnlyOne) => {
                this.props.onSelectCluster(value, isOnlyOne);
            }} />}
            <SelectTemplate onError={err => this.props.onError(err)} 
                            onChangeValues={(values, version) => this.props.onSelectVirtualClusterValues(values, version)} 
                            onChangeTemplate={(template) => this.props.onSelectVirtualClusterTemplate(template)} 
                            cluster={this.props.cluster} />
        </div>
    }
}