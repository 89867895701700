import React, {useEffect, useState} from "react";
import useQuery from "../../../../../lib/Query/Query";
import {Resources} from "../../../../../lib/resources";
import client from "../../../../../lib/client";
import {ErrorMessage} from "../../../../../components/ErrorMessage/ErrorMessage";
import Label from "../../../../../components/Label/Label";
import Description from "../../../../../components/Description/Description";
import {useUser} from "../../../../../contexts/UserContext/UserContext";
import {ManagementV1ClusterMembers} from "../../../../../../gen/model/managementV1ClusterMembers";
import {selectOwnerFilter} from "../../../../../lib/helpers/renderhelper";
import {Err, Result, ResultError, Return} from "../../../../../lib/result";
import {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import Query from "../../../../../components/Query/Query";
import Loading from "../../../../../components/Loading/Loading";
import Select from "../../../../../components/Select/Select";
import {arr, selectDefaultFilter} from "../../../../../lib/helpers/renderhelper";
import Owner from "../../../../../components/Owner/Owner";
import {deepCopy, displayName} from "../../../../../lib/helper";
import {ManagementV1Cluster} from "../../../../../../gen/model/managementV1Cluster";
import {ManagementV1SpaceTemplate} from "../../../../../../gen/model/managementV1SpaceTemplate";
import constants from "../../../../../constants/constants";
import {ClusterV1Space} from "../../../../../../gen/model/clusterV1Space";
import {GetKey, GetUserTeamFromKey} from "./SpaceAccess";
import SectionExpander from "../../../../../components/Drawer/SectionExpander/SectionExpander";
const { Option } = Select;

interface SelectTemplateProps {
    cluster: string | undefined;

    onError: (err: Err<any>) => void;
    onChangeTemplate: (template: ManagementV1SpaceTemplate | undefined) => void;
}

interface SelectTemplateState {
    selectedTemplate?: string;
    selectedCluster?: string;
}

export async function getDefaultSpaceTemplate(cluster: string) {
    const result = await client.management(Resources.ManagementV1Cluster).Get(cluster);
    if (result.err) {
        return result;
    }

    return Return.Value(result.val.metadata?.annotations?.[constants.LoftDefaultSpaceTemplate]);
}

export function SelectTemplate(props: SelectTemplateProps) {
    const [state, setState] = useState<SelectTemplateState>({});
    const {loading, error, data} = useQuery(async () => client.management(Resources.ManagementV1SpaceTemplate).List());
    useEffect( () => {
        (async () => {
            if (data && props.cluster && props.cluster !== state.selectedCluster) {
                const defaultTemplateResult = await getDefaultSpaceTemplate(props.cluster);
                if (defaultTemplateResult.err) {
                    props.onError(defaultTemplateResult);
                    return;
                }

                const defaultTemplate = defaultTemplateResult.val;
                setState({
                    selectedCluster: props.cluster,
                    selectedTemplate: defaultTemplate
                });
                if (defaultTemplate) {
                    if (defaultTemplate !== state.selectedTemplate) {
                        const template = data.items.find(t => t.metadata?.name === defaultTemplate);
                        if (!template) {
                            props.onError(Return.Failed("cannot find default template " + defaultTemplate));
                            return;
                        }
                        
                        props.onChangeTemplate(template);
                    }
                }
            }
        })();
    }, [props.cluster, data]);
    if (error && error.err) {
        return <ErrorMessage error={error} />
    } else if (loading) {
        return <Loading />
    } else if (!arr(data?.items).length) {
        return null;
    }

    return <React.Fragment>
        <Label>Space Template</Label>
        <Select
            showSearch
            allowClear={true}
            loading={loading}
            style={{ width: "100%" }}
            placeholder="Select a template"
            value={state.selectedTemplate}
            onChange={(v) => {
                props.onChangeTemplate(arr(data?.items).find(t => t.metadata?.name === v));
                setState({...state, selectedTemplate: v});
            }}
            optionFilterProp="children"
            filterOption={selectDefaultFilter}
        >
            {data ? arr(data.items).map(template => <Option key={template?.metadata?.name!} value={template?.metadata?.name!}>{displayName(template)}</Option>) : undefined}
        </Select>
        <Description>Select a template to create the space from</Description>
    </React.Fragment>
}

interface SelectClusterProps {
    onChange: (value: ManagementV1Cluster | undefined, isOnlyOne?: boolean) => void;
    cluster: string | undefined;
    
    text?: string;
}

export function SelectCluster(props: SelectClusterProps) {
    const user = useUser();
    const [cluster, setCluster] = useState<string | undefined>(props.cluster);
    const {loading, error, data} = useQuery(async () => client.management(Resources.ManagementV1UserClusters).Get(user?.metadata?.name!));
    useEffect(() => {
        if (!props.cluster && !cluster && data && data.clusters && data.clusters.length > 0) {
            props.onChange(data.clusters?.[0].cluster, arr(data.clusters).length === 1);
            setCluster(data.clusters?.[0].cluster?.metadata?.name);
        }
    });

    if (error && error.err) {
        return <ErrorMessage error={error} />
    } else if (data && (!data.clusters || !data.clusters.length)) {
        return <span>There is no cluster you have access to</span>
    } else if (data?.clusters?.length === 1) {
        return null;
    }

    return <React.Fragment>
        <Label>Cluster</Label>
        <Select
            showSearch
            loading={loading}
            style={{ width: "100%" }}
            placeholder="Select a cluster"
            value={cluster}
            onChange={(v) => {
                setCluster(v);
                props.onChange(data?.clusters?.find(c => c.cluster?.metadata?.name === v)?.cluster);
            }}
            optionFilterProp="children"
            filterOption={selectDefaultFilter}
        >
            {data ? data.clusters?.map(cluster => <Option key={cluster.cluster?.metadata?.name!} value={cluster.cluster?.metadata?.name!}>{displayName(cluster.cluster)}</Option>) : undefined}
        </Select>
        <Description>{props.text || "Select a cluster to create the space in."}</Description>
    </React.Fragment>
}

interface SpaceOwnerState {
    selectedOwner: string | undefined;
    selectedOwnerChanged: boolean;
}

interface SpaceOwnerProps extends SectionProps {
    clusterMembers: ManagementV1ClusterMembers | undefined;

    showClusterSelect?: boolean;

    // The cluster the space is in
    cluster?: string;

    // A function to set the cluster
    onClusterSelect: (cluster: string) => void;
    
    // A function to set the space template
    onTemplateSelect: (template: ManagementV1SpaceTemplate | undefined) => void;
    
    // A function to set an error
    onError: (err: Err<any>) => void;

    // If the space is modified this will be non empty
    space?: ClusterV1Space;
}

export default class SpaceOwner extends React.PureComponent<SpaceOwnerProps, SpaceOwnerState> {
    state: SpaceOwnerState = {
        selectedOwner: GetKey(this.props.space?.spec?.user, this.props.space?.spec?.team),
        selectedOwnerChanged: false,
    };

    create = (obj: {space: ClusterV1Space, cluster: string}): ResultError => {
        if (!this.props.cluster) {
            return Return.Failed("Please select a cluster to create the space in");
        } else if (!this.props.clusterMembers) {
            return Return.Failed("Please select a cluster to create the space in");
        }

        if (!obj.space.spec) {
            obj.space.spec = {}
        }

        if (this.state.selectedOwner) {
            const userTeam = GetUserTeamFromKey(this.state.selectedOwner);
            obj.space!.spec!.user = userTeam.user;
            obj.space!.spec!.team = userTeam.team;
            
            obj.cluster = this.props.cluster;
        }

        return Return.Ok();
    };

    update = (space: ClusterV1Space): ResultError => {
        // early return if nothing changed
        if (!this.state.selectedOwnerChanged) {
            return Return.Ok();
        } else if (!this.props.clusterMembers) {
            return Return.Failed("Please select a cluster to create the space in");
        }

        if (!space.spec) {
            space.spec = {}
        }

        // delete old owners
        space.metadata!.ownerReferences = [];
        if (this.state.selectedOwner) {
            const userTeam = GetUserTeamFromKey(this.state.selectedOwner);
            space!.spec!.user = userTeam.user;
            space!.spec!.team = userTeam.team;
        } else {
            space.spec!.user = undefined;
            space.spec!.team = undefined;
        }

        return Return.Ok();
    };

    renderOptions = (data: ManagementV1ClusterMembers) => {
        const options: JSX.Element[] = [];

        // add other users
        data.users?.forEach(user => {
            options.push(<Option key={"user:"+user?.info?.name} value={"user:"+user?.info?.name}>
                <Owner displayName={user.info?.displayName} username={user.info?.username} kubeName={user.info?.name} type={"small"} />
            </Option>)
        });

        // add teams
        data.teams?.forEach(team => {
            options.push(<Option key={"team:"+team?.info?.name} value={"team:"+team?.info?.name}>
                <Owner isTeam={true} displayName={team.info?.displayName} username={team.info?.username} kubeName={team.info?.name} type={"small"} />
            </Option>)
        });

        return options;
    };

    renderSelectOwner(clusterMembers: ManagementV1ClusterMembers) {
        return <Select
            allowClear
            showSearch
            style={{ width: "100%" }}
            placeholder="Select an owner"
            optionFilterProp="children"
            value={this.state.selectedOwner}
            resetable={this.props.mode !== "create"}
            onChangedStatus={(changed) => this.setState({selectedOwnerChanged: changed})}
            onChange={(value) => this.setState({selectedOwner: value})}
            filterOption={selectOwnerFilter}
        >
            {this.renderOptions(clusterMembers)}
        </Select>;
    }
    
    renderOwnerAndTemplates() {
        let ownerSelect = undefined;
        if (this.props.cluster && this.props.clusterMembers) {
            ownerSelect = <Query refetch={[this.props.cluster]} query={async (): Promise<Result<ManagementV1ClusterMembers>> => {
                // Okay we have to get the users & teams the user has access to with the correct owner names here,
                // so we do this by retrieving all members first and then retrieve the accounts the user can list
                // and filter the members by this list
                const clusterMembers = deepCopy(this.props.clusterMembers!);

                // filter the users and teams on create
                if (this.props.mode === "create") {
                    const allowedResult = await client.management(Resources.ManagementV1ClusterMemberAccess).Get(this.props.cluster!);
                    if (allowedResult.err) {
                        return allowedResult;
                    }

                    // filter users
                    if (clusterMembers.users) {
                        clusterMembers.users = clusterMembers.users.filter(user => !!arr(allowedResult.val.users)?.find(allowedUser => allowedUser.info?.name === user.info?.name));
                    }

                    // filter teams
                    if (clusterMembers.teams) {
                        clusterMembers.teams = clusterMembers.teams.filter(team => !!arr(allowedResult.val.teams)?.find(allowedTeam => allowedTeam.info?.name === team.info?.name));
                    }

                    // default select the user
                    if (arr(clusterMembers.users).length > 0) {
                        this.setState({
                            selectedOwner: "user:"+arr(clusterMembers.users)[0].info?.name
                        });
                    } else if (arr(clusterMembers.teams).length > 0) {
                        this.setState({
                            selectedOwner: "team:"+arr(clusterMembers.teams)[0].info?.name
                        });
                    }
                }

                return Return.Value(clusterMembers);
            }}>
                {
                    result => {
                        if (result.error) {
                            return <ErrorMessage error={result.error} />
                        } else if (!result.data) {
                            return <Loading />
                        }

                        return <React.Fragment>
                            <Label>Space Owner</Label>
                            {this.renderSelectOwner(result.data!)}
                            <Description>Choose the user or team that will own this space.</Description>
                        </React.Fragment>
                    }
                }
            </Query>;
        }
        
        let templateSelect = undefined;
        if (this.props.mode === "create") {
            templateSelect = <SelectTemplate onError={err => this.props.onError(err)}
                                             onChangeTemplate={(template) => this.props.onTemplateSelect(template)}
                                             cluster={this.props.cluster} />
        }
        
        if (!ownerSelect && !templateSelect) {
            return undefined;
        } else if (ownerSelect && !templateSelect) {
            return <SectionExpander name={"Owner"}>
                {ownerSelect}
            </SectionExpander>
        }
        
        return <SectionExpander name={"Owner & Template"}>
            {ownerSelect}
            {templateSelect}
        </SectionExpander>
    }

    render() {
        if (this.props.mode === "batch") {
            return null;
        }

        return <div>
            {
                this.props.showClusterSelect && <SelectCluster cluster={this.props.cluster} onChange={(value) => {
                    if (value?.metadata?.name) {
                        this.props.onClusterSelect(value.metadata.name);
                    }
                }} />
            }
            {this.renderOwnerAndTemplates()}
        </div>
    }
}