import React, {useEffect} from "react";
import useQuery from "../../../../../lib/Query/Query";
import client, {RequestOptionsVCluster} from "../../../../../lib/client";
import {Resources} from "../../../../../lib/resources";
import Table, {TableActions} from "../../../../../components/Table/Table";
import {arr, removeDuplicateFilters} from "../../../../../lib/helpers/renderhelper";
import {CoreV1Event, V1Pod} from "@kubernetes/client-node";
import {nameSorter, numberSorter, stringSorter, timeSorter} from "../../../../../lib/helpers/sorthelper";
import DynamicTime from "../../../../../components/DynamicTime/DynamicTime";
import ShowYamlPopup from "../../../../../components/ShowYamlPopup/ShowYamlPopup";
import styles from "./PodsTable.module.scss";
import {IconPodLogsPopup, TextPodLogsPopup} from "../PodLogsPopup";
import {Tooltip} from "../../../../../components/Tooltip/Tooltip";
import {getPodStatus} from "../../../../../lib/helpers/kubehelper";
import {IconPodExecPopup} from "../PodExecPopup";
import {DeleteOutlined, WarningOutlined} from "@ant-design/icons";
import {Button} from "antd";
import ResourcesDrawer from "../../../../Clusters/Details/ResourcesDrawer/ResourcesDrawer";
import {useItemDrawer} from "../../../../../contexts/drawer/DrawerContext";
import {Return} from "../../../../../lib/result";
import {Popover} from "../../../../../components/Popover/Popover";
import {deleteConfirm} from "../../../../../lib/Modal/Modal";
import ClientMessage from "../../../../../lib/Message/ClientMessage";
import {getEventLastOccurrence} from "../../Events/EventsTable/EventsTable";

interface EventsPopoverProps {
    events: Array<CoreV1Event>;
}

function EventsPopover(props: EventsPopoverProps) {
    const items = props.events.map(event => {
        return <div key={event.metadata.namespace + "/" + event.metadata.name}>
            <div>
                <DynamicTime timestamp={getEventLastOccurrence(event)} useTooltip={false}/>
            </div>
            <div>
                {event.type === "Warning" ? <span className={styles["warning-column"]}>
                        <WarningOutlined style={{paddingRight: "5px"}} />{event.message} ({event.reason})
                </span> : <span>{event.message} ({event.reason})</span>}
            </div>
        </div>
    })

    return <div className={styles["events-popover"]}>
        <div className={styles["event-header"]}>
            <div>Last Seen</div>
            <div>Message</div>
        </div>
        <div className={styles["event-items"]}>
            {items}
        </div>
    </div>
}


function getRestarts(pod: V1Pod) {
    let restarts = 0;
    for (let i = 0; i < arr(pod.status?.containerStatuses).length; i++) {
        const containerStatus = pod.status!.containerStatuses![i];
        restarts += containerStatus.restartCount;
    }

    return restarts;
}

// CriticalStatus container status
const CriticalStatus: {[key: string]: boolean} = {
    Error: true,
    Pending: true,
    Unknown: true,
    ImagePullBackOff: true,
    CrashLoopBackOff: true,
    RunContainerError: true,
    ErrImagePull: true,
    CreateContainerConfigError: true,
    InvalidImageName: true,
};

function getTableColumns(
    refetch: () => Promise<void>,
    pods: V1Pod[],
    events: CoreV1Event[],
    cluster: string | undefined,
    namespace: string | undefined,
    vCluster: RequestOptionsVCluster | undefined,
) {
    let columns = [
        {
            title: 'Name',
            sorter: (a: V1Pod, b: V1Pod) => nameSorter(a, b),
            render: (pod: V1Pod) => {
                return <Tooltip title="logs">
                    <TextPodLogsPopup cluster={cluster} vCluster={vCluster} pod={pod.metadata?.name} namespace={pod.metadata?.namespace} />
                </Tooltip>
            }
        },
        {
            title: 'Ready',
            render: (pod: V1Pod) => {
                let allContainers = 0
                let readyContainers = 0
                for (let i = 0; i < arr(pod.spec?.containers).length; i++) {
                    allContainers++
                    const container = pod.spec!.containers![i];
                    const containerStatus = pod.status?.containerStatuses?.find(status => container.name === status.name);
                    if (containerStatus?.ready && !!containerStatus.state?.running?.startedAt) {
                        readyContainers++
                    }
                }

                return readyContainers + "/" + allContainers
            }
        },
        {
            title: 'Status',
            sorter: (a: V1Pod, b: V1Pod) => stringSorter(getPodStatus(a), getPodStatus(b)),
            filters: removeDuplicateFilters(arr(pods).map(obj => {
                const status = getPodStatus(obj);
                return {
                    text: status!,
                    value: status + ""
                }
            })),
            onFilter: (value: string, record: V1Pod) => getPodStatus(record) === value,
            render: (pod: V1Pod) => {
                const status = getPodStatus(pod);
                if (CriticalStatus[status]) {
                    // get the first message
                    let message = status;
                    for (let i = 0; i < arr(pod.status?.containerStatuses).length; i++) {
                        const containerStatus = pod.status!.containerStatuses![i];
                        if (containerStatus.state?.waiting) {
                            message = containerStatus.state.waiting.message + "(" + containerStatus.state.waiting.reason + ")"
                            break;
                        } else if (containerStatus.state?.terminated) {
                            message = containerStatus.state.terminated.message + "(" + containerStatus.state.terminated.reason + ")"
                            break;
                        }
                    }

                    return <Tooltip title={message}>
                        <span className={"color-warning"}>
                            <WarningOutlined style={{paddingRight: "5px"}} />{status}
                        </span>
                    </Tooltip>
                }

                return status;
            }
        },
        {
            title: 'Events',
            render: (pod: V1Pod) => {
                // find pod events
                const podEvents = events.filter(event => event.involvedObject.uid === pod.metadata?.uid && event.involvedObject.kind === "Pod" && event.involvedObject.name === pod.metadata?.name && event.involvedObject.namespace === pod.metadata?.namespace).sort((a, b) => -timeSorter(getEventLastOccurrence(a), getEventLastOccurrence(b)));
                const hasWarning = podEvents.find(event => event.type === "Warning");
                return <Popover title={<span><b>Events</b></span>} content={<EventsPopover events={podEvents} />}>
                    {hasWarning ? <span className={"color-warning"}>
                            <WarningOutlined style={{paddingRight: "5px"}} />{podEvents.length}
                        </span> : <span>
                        {podEvents.length}
                    </span>}
                </Popover>;
            }
        },
        {
            title: 'Restarts',
            sorter: (a: V1Pod, b: V1Pod) => numberSorter(getRestarts(a), getRestarts(b)),
            render: (pod: V1Pod) => {
                return getRestarts(pod);
            }
        },
        {
            title: 'Node',
            sorter: (a: V1Pod, b: V1Pod) => stringSorter(a.spec?.nodeName, b.spec?.nodeName),
            render: (pod: V1Pod) => {
                return pod.spec?.nodeName ;
            }
        },
        {
            title: 'Created',
            width: "180px",
            sorter: (a: V1Pod, b: V1Pod) => timeSorter(a.metadata?.creationTimestamp, b.metadata?.creationTimestamp),
            render: (pod: V1Pod) => {
                return <DynamicTime timestamp={pod.metadata?.creationTimestamp} useTooltip={true}/>
            }
        },
        {
            title: 'Actions',
            width: "180px",
            render: (pod: V1Pod) => {
                return <TableActions>
                    <IconPodLogsPopup cluster={cluster} vCluster={vCluster} className={"blue-btn"} pod={pod.metadata?.name!} namespace={pod.metadata?.namespace!} />
                    <IconPodExecPopup cluster={cluster} vCluster={vCluster} className={"blue-btn"} pod={pod.metadata?.name!} namespace={pod.metadata?.namespace!} />
                    <ShowYamlPopup className={"blue-btn"} cluster={cluster} namespace={pod.metadata?.namespace} vCluster={vCluster} object={pod} resource={Resources.V1Pod} name={pod.metadata?.name!} refetch={refetch} />
                    <Tooltip title="delete">
                        <DeleteOutlined className={"actions-delete-btn"} onClick={() => {
                            deleteConfirm({
                                title: `Delete ${pod.metadata?.name}`,
                                content: `Are you sure you want to delete the Pod ${pod.metadata?.name}?`,
                                onOkAsync: async () => {
                                    const message = ClientMessage.Loading(cluster, vCluster);
                                    const result = await client.auto(cluster, vCluster, Resources.V1Pod).Namespace(pod.metadata?.namespace).Delete(pod.metadata?.name!);
                                    message.ResultAuto(result, cluster, vCluster);
                                    await refetch();
                                },
                            });
                        }} />
                    </Tooltip>
                </TableActions>;
            }
        },
    ];
    if (!namespace) {
        columns = [{
            title: "Namespace",
            sorter: (a: V1Pod, b: V1Pod) => stringSorter(a.metadata?.namespace, b.metadata?.namespace),
            filters: removeDuplicateFilters(arr(pods).map(obj => {
                return {
                    text: obj.metadata?.namespace + "",
                    value: obj.metadata?.namespace + ""
                }
            })),
            onFilter: (value: string, record: V1Pod) => record.metadata?.namespace! === value,
            render: (pod: V1Pod) => {
                return pod.metadata?.namespace!;
            }

        }, ...columns]
    }

    return columns;
}

function filter(item: V1Pod, value: string) {
    return !!(item.metadata?.namespace?.includes(value) || item.metadata?.name?.includes(value) || getPodStatus(item).includes(value));
}

interface PodsTableProps {
    cluster?: string;
    namespace?: string;
    vCluster?: RequestOptionsVCluster;

    top?: React.ReactNode;
}

function PodsTable(props: PodsTableProps) {
    const drawerDispatcher = useItemDrawer();
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
    const {loading, error, data: unsortedData, refetch} = useQuery(async () => {
        const podResult = await client.auto(props.cluster, props.vCluster, Resources.V1Pod).Namespace(props.namespace).List();
        if (podResult.err) {
            return podResult;
        }

        const eventResult = await client.auto(props.cluster, props.vCluster, Resources.V1Event).Namespace(props.namespace).List();
        if (eventResult.err) {
            return eventResult;
        }

        return Return.Value({
            pods: podResult.val.items,
            events: eventResult.val.items
        })
    });
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedKeys: any) => {
            setSelectedRowKeys(selectedKeys);
        },
    };
    useEffect(() => {
        const timeout = window.setTimeout(() => refetch(), 4000);
        return () => {
            window.clearTimeout(timeout);
        }
    }, [refetch]);

    const data = arr(unsortedData?.pods).sort((a, b) => stringSorter(a.metadata?.name, b.metadata?.name))
    return <div>
        <Table loading={!unsortedData && loading}
               columns={getTableColumns(refetch, data, arr(unsortedData?.events), props.cluster, props.namespace, props.vCluster) as any}
               dataSource={data ? arr(data).map(pod => { return {...pod, key: pod.metadata?.namespace + "/" + pod.metadata?.name}}) : undefined}
               error={error}
               filter={filter}
               rowSelection={rowSelection}
               refetch={refetch}
               header={{
                   top: props.top,
                   right: <Button type={"primary"} onClick={() => {
                       drawerDispatcher({
                           title: "Create a new Pod",
                           content: <ResourcesDrawer selectedResource={Resources.V1Pod} cluster={props.cluster} vCluster={props.vCluster} placeholder={`apiVersion: v1
kind: Pod
metadata:
  name: example
spec:
  containers: 
...`} namespace={props.namespace} mode={"create"} refetch={refetch} />
                       })
                   }}>Create Pod</Button>,
                   selectedActions: <React.Fragment>
                       <Tooltip title={"delete"}>
                           <DeleteOutlined className={"actions-delete-btn-batch"} onClick={() =>
                           {
                               deleteConfirm({
                                   title: `Delete Pods`,
                                   content: `Are you sure you want to delete the pods ${selectedRowKeys.join(", ")}?`,
                                   onOkAsync: async () => {
                                       const message = ClientMessage.Loading(props.cluster, props.vCluster);
                                       for (let i = 0; i < selectedRowKeys.length; i++) {
                                           const splitted = (selectedRowKeys[i] + "").split("/");
                                           const row = data?.find(row => row.metadata?.namespace === splitted[0] && row.metadata?.name === splitted[1]);
                                           if (row) {
                                               const result = await client.auto(props.cluster, props.vCluster, Resources.V1Pod).Namespace(row.metadata?.namespace).Delete(row.metadata?.name!);
                                               if (result.err) {
                                                   message.ErrorAuto(result, props.cluster, props.vCluster);
                                                   return;
                                               }
                                           }
                                       }

                                       message?.DoneAuto(props.cluster, props.vCluster);
                                       await refetch();
                                       setSelectedRowKeys([]);
                                   }
                               });
                           }} />
                       </Tooltip>
                   </React.Fragment>
               }} />
    </div>
}

export default PodsTable;