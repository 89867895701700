import React, {useRef} from "react";
import ItemDrawer, {SectionProps} from "../../../components/Drawer/ItemDrawer";
import {useDrawerDispatcher} from "../../../contexts/drawer/DrawerContext";
import {ResultError, Return} from "../../../lib/result";
import ClientMessage from "../../../lib/Message/ClientMessage";
import {NewResource, Resources} from "../../../lib/resources";
import Manifests from "./Sections/Manifests";
import TargetSelector from "./Sections/TargetSelector";
import {streamTask} from "../../../views/Spaces/Spaces/SpaceDrawer/SpaceDrawer";
import {ManagementV1User} from "../../../../gen/model/managementV1User";
import {ProgressPopup} from "../../ProgressPopup/ProgressPopup";
import {useUser} from "../../../contexts/UserContext/UserContext";
import {RequestOptionsVCluster} from "../../../lib/client";

export interface ApplyManifestsDrawerProps extends SectionProps {
    
}

type ChangeFunctionProps = Omit<ApplyManifestsDrawerProps, "mode"|"refetch"> & {
    user: ManagementV1User,
    manifestsRef: Manifests,
    targetSelectorRef: TargetSelector,
    progressPopupRef: ProgressPopup | null,
};

async function onCreate({user, targetSelectorRef, manifestsRef, progressPopupRef}: ChangeFunctionProps) {
    // make sure we have a task object
    let task = NewResource(Resources.ManagementV1Task, undefined, {spec: {
        displayName: "Apply Manifests"
    }});

    let result = targetSelectorRef?.create(task);
    if (result?.err) {
        return result;
    }
    
    result = manifestsRef?.create(task);
    if (result?.err) {
        return result;
    }

    const streamTaskResult = await streamTask(user, task, progressPopupRef, false);
    if (streamTaskResult.err) {
        return streamTaskResult;
    }

    return Return.Ok();
}

export default function ApplyManifestsDrawer(props: ApplyManifestsDrawerProps) {
    const user = useUser();
    const drawer = useDrawerDispatcher();
    const targetSelectorRef = useRef<TargetSelector>(null);
    const manifestsRef = useRef<Manifests>(null);
    const progressPopupRef = useRef<ProgressPopup>(null);

    return <ItemDrawer okButtonText={props.mode === "create" ? "Create" : "Update"} onOkAsync={async () => {
        const target = targetSelectorRef.current!.state.selectedTarget;
        const vCluster: RequestOptionsVCluster | undefined = target?.virtualCluster ? {
            cluster: target.virtualCluster.cluster!,
            namespace: target.virtualCluster.namespace!,
            name: target.virtualCluster.name!,
        } : undefined;
        
        const message = ClientMessage.Loading(target?.cluster?.cluster, vCluster);

        // execute the create / update / batch logic
        let result: ResultError | undefined = undefined;
        if (props.mode === "create") {
            result = await onCreate({
                user: user!,
                manifestsRef: manifestsRef.current!,
                targetSelectorRef: targetSelectorRef.current!,
                progressPopupRef: progressPopupRef.current!,
            });
        }

        // check if there was an error
        if (result?.err) {
            message.ErrorAuto(result, target?.cluster?.cluster, vCluster);
            return;
        }

        message.DoneAuto(target?.cluster?.cluster, vCluster);

        // close drawer
        drawer({});
    }}>
        <TargetSelector mode={props.mode} ref={targetSelectorRef} />
        <Manifests mode={props.mode} ref={manifestsRef} />
        <ProgressPopup title={"Applying Manifests"} ref={progressPopupRef} />
    </ItemDrawer>
}