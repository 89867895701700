import React, {useEffect} from "react";
import useQuery, {QueryResult} from "../../lib/Query/Query";
import client from "../../lib/client";
import {Result} from "../../lib/result";

type State = {[key: string]: Promise<Result<NodeStatsType>>}

interface NodeStatsContextType {
    state: State,
    dispatch: React.Dispatch<Action>,
}

interface Action {
    refresh?: boolean;
    cluster?: string;
    node?: string;
}

export const NodeStatsContext = React.createContext<NodeStatsContextType | undefined>(undefined);

function reducer(state: State, action: Action) {
    if (action.refresh) {
        return {};
    }
    
    const key = action.cluster + "/" + action.node;
    if (state[key]) {
        return state;
    }
    
    const promise = client.clusterNonResource(action.cluster!).Path(`api/v1/nodes/${action.node!}/proxy/stats/summary`) as Promise<Result<NodeStatsType>>;
    return {...state, [key]: promise};
}

export function NodeStatsProvider(props: React.PropsWithChildren<{}>) {
    const [state, dispatch] = React.useReducer(reducer, {});
    return (
        <NodeStatsContext.Provider value={{state, dispatch}}>
            {props.children}
        </NodeStatsContext.Provider>
    )
}

export function useNodeStatsProvider() {
    let context = React.useContext(NodeStatsContext);
    if (!context) {
        throw new Error("useNodeStatsProvider has to be used below a NodeStatsProvider component");
    }

    return context;
}

export interface NodeStatsType {
    node?: {
        cpu?: {
            usageNanoCores?: number;
        };
        memory?: {
            usageBytes?: number;
            availableBytes?: number;
        };
        fs?: {
            usedBytes?: number;
            capacityBytes?: number;
        };
    };
    
    pods?: {
        podRef?: {
            name?: string;
            namespace?: string;
            uid?: string;
        }
    }[];
} 

interface NodeStatsProps {
    cluster: string;
    node: string;

    children: (result: QueryResult<NodeStatsType>) => JSX.Element | null;
}

export function NodeStats(props: NodeStatsProps) {
    const key = props.cluster + "/" + props.node;
    const state = useNodeStatsProvider();
    const queryResult = useQuery(async () => await state.state[key], {skip: !state.state[key], refetch: [state.state[key]]});
    useEffect(() => {
        state.dispatch({
            cluster: props.cluster,
            node: props.node
        })
    }, [state.state[key]]);

    return props.children(queryResult);
}
