import React, {RefObject, useEffect, useState} from "react";
import {Err, Result, ResultError, Return} from "../../../../../lib/result";
import {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import client from "../../../../../lib/client";
import {Resources} from "../../../../../lib/resources";
import {ErrorMessage} from "../../../../../components/ErrorMessage/ErrorMessage";
import Select from "../../../../../components/Select/Select";
import SpaceAccess from "../../../../Spaces/Spaces/SpaceDrawer/Sections/SpaceAccess";
import Loading from "../../../../../components/Loading/Loading";
import Query from "../../../../../components/Query/Query";
import {StorageV1VirtualCluster} from "../../../../../../gen/model/agentstorageV1VirtualCluster";
import Section from "../../../../../components/Drawer/Section/Section";
import SectionExpander from "../../../../../components/Drawer/SectionExpander/SectionExpander";
import {arr} from "../../../../../lib/helpers/renderhelper";
import VClusterAccessRules from "./VClusterAccessRules";
import {ManagementV1VirtualClusterTemplate} from "../../../../../../gen/model/managementV1VirtualClusterTemplate";
const Option = Select.Option;

interface VClusterAccessState {
}

interface VClusterAccessProps extends SectionProps {
    cluster?: string;

    vCluster: StorageV1VirtualCluster | undefined;
}

export default class VClusterAccess extends React.PureComponent<VClusterAccessProps, VClusterAccessState> {
    spaceAccessRef?: RefObject<SpaceAccess>;
    vClusterAccessRulesRef?: RefObject<VClusterAccessRules>;
    
    state: VClusterAccessState = {
        defaultClusterRole: this.props.vCluster?.spec?.access?.defaultClusterRole,
    };

    constructor(props: VClusterAccessProps) {
        super(props);

        this.spaceAccessRef = React.createRef<SpaceAccess>();
        this.vClusterAccessRulesRef = React.createRef<VClusterAccessRules>();
    }

    createSpaceAccess = async (vCluster: StorageV1VirtualCluster): Promise<ResultError> => {
        if (this.spaceAccessRef?.current) {
            return this.spaceAccessRef.current.create(vCluster.metadata?.namespace!);
        }

        return Return.Ok();
    };

    updateSpaceAccess = async (vCluster: StorageV1VirtualCluster): Promise<ResultError> => {
        if (this.spaceAccessRef?.current) {
            return this.spaceAccessRef.current.update(vCluster.metadata?.namespace!);
        }

        return Return.Ok();
    };

    create = async (vCluster: StorageV1VirtualCluster): Promise<ResultError> => {
        if (!vCluster.spec) {
            vCluster.spec = {};
        }

        const result = await this.vClusterAccessRulesRef?.current?.create(vCluster.spec);
        if (result?.err) {
            return result;
        }

        return Return.Ok();
    };

    update = async (vCluster: StorageV1VirtualCluster): Promise<ResultError> => {
        if (!vCluster.spec) {
            vCluster.spec = {};
        }

        const result = await this.vClusterAccessRulesRef?.current?.update(vCluster.spec);
        if (result?.err) {
            return result;
        }

        return Return.Ok();
    };

    selectTemplate = (vClusterTemplate: ManagementV1VirtualClusterTemplate | undefined) => {
        this.vClusterAccessRulesRef?.current?.selectTemplate(vClusterTemplate)
    }

    render() {
        if (this.props.mode === "batch" || !this.props.cluster) {
            return null;
        }

        return <Query query={async () => {
            const clusterMembers = await client.management(Resources.ManagementV1ClusterMembers).Get(this.props.cluster!);
            if (clusterMembers.err) {
                return clusterMembers;
            }

            return Return.Value({
                clusterMembers: clusterMembers.val
            })
        }} refetch={[this.props.cluster]}>
            {
                result => {
                    if (result.error) {
                        return <ErrorMessage error={result.error}/>;
                    } else if (result.loading) {
                        return <Loading/>;
                    }

                    return <Section title={"Virtual Cluster Access"} defaultFolded={true} foldable={true}>
                        <VClusterAccessRules vCluster={this.props.vCluster?.spec}
                                             users={arr(result.data?.clusterMembers?.users).map(t => t.info!)}
                                             teams={arr(result.data?.clusterMembers?.teams).map(t => t.info!)}
                                             ref={this.vClusterAccessRulesRef} />
                        <SectionExpander name={"Access To Virtual Cluster Space"}>
                            <SpaceAccess mode={this.props.mode}
                                         kind={"Virtual Cluster Space"}
                                         cluster={this.props.cluster!}
                                         clusterMembers={result.data?.clusterMembers!}
                                         defaultClusterRole={"loft-cluster-vcluster-admin"}
                                         space={this.props.vCluster?.metadata?.namespace}
                                         hideSection={true}
                                         ref={this.spaceAccessRef} />
                        </SectionExpander>
                    </Section>
                }
            }
        </Query>
    }
}