import React, {useRef} from "react";
import ItemDrawer, {SectionProps} from "../../../../components/Drawer/ItemDrawer";
import {useDrawerDispatcher} from "../../../../contexts/drawer/DrawerContext";
import ClientMessage from "../../../../lib/Message/ClientMessage";
import {NewResource, Resources} from "../../../../lib/resources";
import client from "../../../../lib/client";
import {ResultError, Return} from "../../../../lib/result";
import Access, {VerbMapping} from "../../../../components/Drawer/Sections/Access/Access";
import {useUser} from "../../../../contexts/UserContext/UserContext";
import {ManagementV1ClusterRoleTemplate} from "../../../../../gen/model/managementV1ClusterRoleTemplate";
import Metadata from "../../../../components/Drawer/Sections/Metadata/Metadata";
import RolesConfig from "./Sections/RolesConfig";
import Clusters from "../../../../components/Drawer/Sections/Clusters/Clusters";

export interface RolesDrawerProps extends SectionProps {
    clusterRole?: ManagementV1ClusterRoleTemplate;
    clusterRoles?: ManagementV1ClusterRoleTemplate[];
    
    management: boolean;
    refetch: () => Promise<void>;
}

type ChangeFunctionProps = Omit<RolesDrawerProps, "mode"|"refetch"> & {clustersRef: Clusters | null, accessRef: Access | null, metadataRef: Metadata, configRef: RolesConfig};


async function onCreate({accessRef, metadataRef, clustersRef, configRef}: ChangeFunctionProps) {
    // make sure we have a team object
    let clusterRole = NewResource(Resources.ManagementV1ClusterRoleTemplate, undefined, {spec: {}});

    // apply team metadata
    const result = await metadataRef?.create(clusterRole);
    if (result?.err) {
        return result;
    }

    // apply config
    const configResult = await configRef?.create(clusterRole);
    if (configResult?.err) {
        return configResult;
    }
    
    // apply access
    const accessResult = await accessRef?.create(clusterRole);
    if (accessResult?.err) {
        return accessResult;
    }
    
    // apply clusters
    if (clustersRef) {
        const clustersResult = await clustersRef?.create(clusterRole);
        if (clustersResult?.err) {
            return clustersResult;
        }
    } else {
        clusterRole.spec!.management = true;
    }

    // create 
    const createResult = await client.management(Resources.ManagementV1ClusterRoleTemplate).Create(clusterRole);
    if (createResult.err) {
        return createResult;
    }

    return Return.Ok();
}


async function onUpdate({clusterRole, accessRef, metadataRef, clustersRef, configRef}: ChangeFunctionProps) {
    if (!clusterRole) {
        return Return.Ok();
    }

    // make sure the team object is up to date
    const getResult = await client.management(Resources.ManagementV1ClusterRoleTemplate).Get(clusterRole.metadata?.name!);
    if (getResult.err) {
        return getResult;
    }

    clusterRole = getResult.val;

    // apply team metadata
    const result = await metadataRef?.update(clusterRole);
    if (result?.err) {
        return result;
    }

    // apply config
    const configResult = await configRef?.update(clusterRole);
    if (configResult?.err) {
        return configResult;
    }

    // apply access
    const accessResult = await accessRef?.update(clusterRole);
    if (accessResult?.err) {
        return accessResult;
    }

    // apply clusters
    if (clustersRef) {
        const clustersResult = await clustersRef?.update(clusterRole);
        if (clustersResult?.err) {
            return clustersResult;
        }
    } else {
        clusterRole.spec!.management = true;
    }

    // update team
    const updateResult = await client.management(Resources.ManagementV1ClusterRoleTemplate).Update(clusterRole.metadata?.name!, clusterRole);
    if (updateResult.err) {
        return updateResult;
    }

    return Return.Ok();
}

async function onBatch({clusterRoles, accessRef, metadataRef}: ChangeFunctionProps) {
    if (!clusterRoles) {
        return Return.Ok();
    }

    // we refresh the objects here, otherwise it can be possible that we get a conflict error if the team has changed meanwhile
    const listResult = await client.management(Resources.ManagementV1ClusterRoleTemplate).List();
    if (listResult.err) {
        return listResult;
    }

    // assign the new spaces
    clusterRoles = listResult.val.items.filter(clusterRole => clusterRoles!.find(oldClusterRole => oldClusterRole.metadata?.name === clusterRole.metadata?.name));

    // update metadata
    const metadataError = metadataRef!.batch(clusterRoles);
    if (metadataError.err) {
        return metadataError;
    }

    // apply access
    const accessResult = await accessRef?.batch(clusterRoles);
    if (accessResult?.err) {
        return accessResult;
    }

    // update teams
    for (let i = 0; i < clusterRoles.length; i++) {
        const updateResult = await client.management(Resources.ManagementV1ClusterRoleTemplate).Update(clusterRoles[i].metadata?.name!, clusterRoles[i]);
        if (updateResult.err) {
            return updateResult;
        }
    }

    return Return.Ok();
}

export default function RolesDrawer(props: RolesDrawerProps) {
    const drawer = useDrawerDispatcher();
    const user = useUser();
    const metadataRef = useRef<Metadata>(null);
    const configRef = useRef<RolesConfig>(null);
    const accessRef = useRef<Access>(null);
    const clustersRef = useRef<Clusters>(null);

    return <ItemDrawer okButtonText={props.mode === "create" ? "Create" : "Update"} onOkAsync={async () => {
        const message = ClientMessage.Loading();

        // execute the create / update / batch logic
        let result: ResultError | undefined = undefined;
        if (props.mode === "create") {
            result = await onCreate({management: props.management, clustersRef: clustersRef.current!, accessRef: accessRef.current!, configRef: configRef.current!, metadataRef: metadataRef.current!});
        } else if (props.mode === "update") {
            result = await onUpdate({management: props.management, clustersRef: clustersRef.current!, accessRef: accessRef.current!, clusterRole: props.clusterRole, configRef: configRef.current!, metadataRef: metadataRef.current!});
        } else if (props.mode === "batch") {
            result = await onBatch({management: props.management, clustersRef: clustersRef.current!, accessRef: accessRef.current!, clusterRoles: props.clusterRoles, configRef: configRef.current!, metadataRef: metadataRef.current!});
        }

        // check if there was an error
        if (result?.err) {
            message.ErrorManagement(result);
            return;
        }

        // refetch
        await props.refetch();

        message.DoneManagement();

        // close drawer
        drawer({});
    }}>
        <Metadata mode={props.mode} type={"Cluster Role"} obj={props.clusterRole} showDisplayName={true} showDescription={true} noMargin ref={metadataRef} />
        <RolesConfig mode={props.mode} clusterRole={props.clusterRole} ref={configRef} />
        {!props.management && <Clusters description={"The target clusters where this cluster role should be created in."} obj={props.clusterRole} mode={props.mode} defaultFolded={true} foldable={true} ref={clustersRef} />}
        <Access mode={props.mode} kind={"Cluster Role"} user={user} allowedVerbs={[
            {
                key: "*",
                text: "All"
            },
            {
                key: "create",
                text: "create"
            },
            {
                key: "get",
                text: "view"
            },
            {
                key: "update",
                text: "update"
            },
            {
                key: "delete",
                text: "delete"
            },
            {
                key: props.management ? "bind" : "bindcluster",
                text: "bind"
            },
        ]} access={props.clusterRole} ref={accessRef} />
    </ItemDrawer>
}