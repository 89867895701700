import React, {RefObject} from "react";
import Tabs from "../../../../../components/Tabs/Tabs";
import {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import {ManagementV1ClusterRoleTemplate} from "../../../../../../gen/model/managementV1ClusterRoleTemplate";
import {Result, ResultError, Return} from "../../../../../lib/result";
import Label from "../../../../../components/Label/Label";
import {arr} from "../../../../../lib/helpers/renderhelper";
import jsyaml from "js-yaml";
import Section from "../../../../../components/Drawer/Section/Section";
import Description from "../../../../../components/Description/Description";
import LabelsAnnotationsSection from "../../../../../components/Drawer/Sections/Metadata/LabelsAnnotationsSection";
import YAMLEditor from "../../../../../components/YAMLEditor/YAMLEditor";
const { TabPane } = Tabs;

type YamlArray = Array<any>;

export function parseArray(a?: YamlArray) {
    if (!a) {
        return "";
    }

    return arr(a).length > 0 ? jsyaml.dump(a) : "";
}

export function convertToArray(str?: string): Result<YamlArray> {
    if (!str) {
        return Return.Value([]);
    }

    try {
        const a: YamlArray = jsyaml.load(str);
        return Return.Value(a);
    } catch(err) {
        return Return.Failed(err + "")
    }
}

export type YamlObject = {
    [key: string]: any;
};

export function parseMap(a?: YamlObject) {
    if (!a) {
        return "";
    }

    return Object.keys(a).length > 0 ? jsyaml.dump(a) : "";
}

export function convertToMap(str?: string): Result<YamlObject> {
    if (!str) {
        return Return.Value({});
    }

    try {
        const a: YamlObject = jsyaml.load(str);
        return Return.Value(a);
    } catch(err) {
        return Return.Failed(err + "")
    }
}

interface RolesConfigState {
    rules: string;
    aggregatedRules: string;

    activeKey: string;
}

interface RolesConfigProps extends SectionProps {
    clusterRole?: ManagementV1ClusterRoleTemplate;
}

export default class RolesConfig extends React.PureComponent<RolesConfigProps, RolesConfigState> {
    labelsSectionRef?: RefObject<LabelsAnnotationsSection>;
    state: RolesConfigState = {
        rules: this.props.clusterRole?.spec?.localClusterRoleTemplate?.spec?.clusterRoleTemplate?.rules ? parseArray(this.props.clusterRole?.spec?.localClusterRoleTemplate?.spec?.clusterRoleTemplate?.rules) : "",
        aggregatedRules: this.props.clusterRole?.spec?.localClusterRoleTemplate?.spec?.clusterRoleTemplate?.aggregationRule ? parseMap(this.props.clusterRole?.spec?.localClusterRoleTemplate?.spec?.clusterRoleTemplate?.aggregationRule) : "",
        
        activeKey: this.props.clusterRole?.spec?.localClusterRoleTemplate?.spec?.clusterRoleTemplate?.aggregationRule ? "2" : "1"
    };

    constructor(props: RolesConfigProps) {
        super(props);

        this.labelsSectionRef = React.createRef<LabelsAnnotationsSection>();
    }

    create = async (clusterRole: ManagementV1ClusterRoleTemplate): Promise<ResultError> => {
        return this.update(clusterRole);
    };

    update = async (clusterRole: ManagementV1ClusterRoleTemplate): Promise<ResultError> => {
        if (!clusterRole.spec) {
            clusterRole.spec = {};
        }
        if (!clusterRole.spec.localClusterRoleTemplate) {
            clusterRole.spec.localClusterRoleTemplate = {};
        }
        if (!clusterRole.spec.localClusterRoleTemplate.spec) {
            clusterRole.spec.localClusterRoleTemplate.spec = {};
        }
        if (!clusterRole.spec.localClusterRoleTemplate.spec.clusterRoleTemplate) {
            clusterRole.spec.localClusterRoleTemplate.spec.clusterRoleTemplate = {};
        }
        if (!clusterRole.spec.localClusterRoleTemplate.spec.clusterRoleTemplate.metadata) {
            clusterRole.spec.localClusterRoleTemplate.spec.clusterRoleTemplate.metadata = {};
        }

        // set annotations & labels
        const annotationsResult = this.labelsSectionRef!.current!.create(clusterRole.spec.localClusterRoleTemplate.spec.clusterRoleTemplate.metadata);
        if (annotationsResult.err) {
            return annotationsResult;
        }

        if (this.state.activeKey === "1") {
            if (this.state.rules) {
                const parsed = convertToArray(this.state.rules);
                if (parsed.err) {
                    return parsed;
                }

                clusterRole.spec.localClusterRoleTemplate.spec.clusterRoleTemplate.rules = parsed.val;
            }
            
            return Return.Ok();
        }

        if (this.state.aggregatedRules) {
            const parsed = convertToMap(this.state.aggregatedRules);
            if (parsed.err) {
                return parsed;
            }

            clusterRole.spec.localClusterRoleTemplate.spec.clusterRoleTemplate.aggregationRule = parsed.val;
        }
        
        return Return.Ok();
    };

    render() {
        if (this.props.mode === "batch") {
            return null;
        }

        return <Section title={`ClusterRole Template`}>
            <Tabs onChange={(activeKey) => this.setState({activeKey: activeKey})} defaultActiveKey={this.state.activeKey} type="card" size={"small"}>
                <TabPane tab="RBAC Rules" key="1">
                    <Label>RBAC Rules</Label>
                    <YAMLEditor value={this.state.rules}
                                height={"300px"}
                                minLines={10}
                                maxLines={100}
                                onChange={(val) => this.setState({rules: val})}
                                placeholder={`- apiGroups: [""] # "" indicates the core API group
  resources: ["pods"]
  verbs: ["get", "watch", "list"]`} />
                    <Description>Rules represent a set of permissions. Permissions are purely additive (there are no "deny" rules). For more information, see the <a href={"https://kubernetes.io/docs/reference/access-authn-authz/rbac/"} target={"_blank"}>Kubernetes documentation</a></Description>
                </TabPane>
                <TabPane tab="Aggregation" key="2">
                    <Label>Aggregation Rule</Label>
                    <YAMLEditor value={this.state.aggregatedRules}
                                minLines={10}
                                maxLines={60}
                                onChange={(val) => this.setState({aggregatedRules: val})}
                                placeholder={`clusterRoleSelectors:
- matchLabels:
    rbac.example.com/aggregate-to-monitoring: "true"`} />
                    <Description>The aggregationRule defines a label selector that the controller uses to match other ClusterRole objects that should be combined into the rules field of this one. For more information, see the <a href={"https://kubernetes.io/docs/reference/access-authn-authz/rbac/"} target={"_blank"}>Kubernetes documentation</a></Description>
                </TabPane>
            </Tabs>
            <LabelsAnnotationsSection {...this.props} name={"Labels & Annotations For ClusterRole"} metadata={this.props.clusterRole?.spec?.localClusterRoleTemplate?.spec?.clusterRoleTemplate?.metadata} ref={this.labelsSectionRef} />
        </Section>
    }
}