import React, {useState} from "react";
import {ClusterV1HelmRelease} from "../../../../../../gen/model/clusterV1HelmRelease";
import {StorageV1HelmChart} from "../../../../../../gen/model/storageV1HelmChart";
import TextArea from "../../../../../components/TextArea/TextArea";
import {displayName, timeAgo} from "../../../../../lib/helper";
import {DrawerDispatch, useItemDrawer} from "../../../../../contexts/drawer/DrawerContext";
import * as History from "history";
import AppsDrawer from "../AppsDrawer/AppsDrawer";
import {stringSorter, timeSorter} from "../../../../../lib/helpers/sorthelper";
import {isApp, isOutdated, isSystemApp} from "../../Overview/AppsList/AppsList";
import styles from "./AppsTable.module.scss";
import {Popover} from "../../../../../components/Popover/Popover";
import DynamicTime from "../../../../../components/DynamicTime/DynamicTime";
import Table, {TableActions} from "../../../../../components/Table/Table";
import {Tooltip} from "../../../../../components/Tooltip/Tooltip";
import {SettingOutlined} from "@ant-design/icons";
import ShowYamlPopup from "../../../../../components/ShowYamlPopup/ShowYamlPopup";
import {Resources} from "../../../../../lib/resources";
import client, {RequestOptionsVCluster} from "../../../../../lib/client";
import useQuery from "../../../../../lib/Query/Query";
import constants from "../../../../../constants/constants";
import Button from "../../../../../components/Button/Button";
import {Link, useHistory} from "react-router-dom";
import {arr} from "../../../../../lib/helpers/renderhelper";
import {alert} from "../../../../../lib/Modal/Modal";
import FixedText from "../../../../../components/FixedText/FixedText";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
import {ManagementV1App} from "../../../../../../gen/model/managementV1App";
import {ResultError, Return} from "../../../../../lib/result";
import AppDeletePopup from "./AppDeletePopup";
import AppRollbackPopup from "./AppRollbackPopup";
import drawerStyles from "../AppsDrawer/AppsDrawer.module.scss";
import {ToggleColumn, ToggleColumnContent} from "../../../../../contexts/ToggleColumn/ToggleColumn";

export interface HelmRelease {
    key: string;
    release: ClusterV1HelmRelease;
    app?: ManagementV1App;
    chart?: StorageV1HelmChart;
}

export function releaseStatusAlert(release: HelmRelease) {
    alert({
        title: <span>Helm Release {release.release.metadata?.name}</span>,
        content: <TextArea readOnly
                           style={{minHeight: "500px"}}
                           value={`NAME: ${release.release.metadata?.name}
LAST DEPLOYED: ${timeAgo(release.release.status?.info?.last_deployed)}
NAMESPACE: ${release.release.metadata?.namespace}
STATUS: deployed
REVISION: ${release.release.metadata?.labels?.["version"]}
NOTES:
${release.release.status?.info?.notes}
`} />,
        width: "900px",
        okText: "Close"
    });
}

function getTableColumns(refetch: () => Promise<void>, drawerDispatcher: DrawerDispatch, cluster: string, history: History.History<any>, namespace?: string, vCluster?: RequestOptionsVCluster) {
    const editApp = (release: HelmRelease) => {
        drawerDispatcher({
            title: "Edit App: " + release.release.metadata?.name,
            content: <AppsDrawer mode={"update"} cluster={cluster} vCluster={vCluster} release={release} namespace={namespace} clusterApp={false} refetch={refetch} />
        })
    };

    const columns = [
        {
            title: <ToggleColumn id={"displayname"} columns={['App', 'Release Name (ID)']}/>,
            sorter: (a: HelmRelease, b: HelmRelease) => stringSorter(displayName(a.app!) || a.release?.metadata?.name, displayName(b.app!) || b.release?.metadata?.name),
            render: (release: HelmRelease) => {
                if (isSystemApp(release.release)) {
                    return <ToggleColumnContent id={"displayname"} columns={[
                        () => {
                            return <span>{release.release.metadata?.name}</span>;
                        },
                        () => {
                            return <span>{release.release.metadata?.name}</span>;
                        },
                    ]}/>
                }
                if (release.app) {
                    return <ToggleColumnContent id={"displayname"} columns={[
                        () => {
                            return <FixedText className={styles["clickable"]} onClick={() => editApp(release)} text={displayName(release.app!)} />
                        },
                        () => {
                            return <FixedText className={styles["clickable"]} onClick={() => editApp(release)} text={release.release.metadata?.name!} />
                        },
                    ]}/>
                }

                return <ToggleColumnContent id={"displayname"} columns={[
                    () => {
                        return <FixedText className={styles["clickable"]} onClick={() => editApp(release)} text={release.release.metadata?.name!} />
                    },
                    () => {
                        return <FixedText className={styles["clickable"]} onClick={() => editApp(release)} text={release.release.metadata?.name!} />
                    },
                ]}/>
            },
        },
        {
            title: 'Status',
            render: (release: HelmRelease) => {
                switch (release.release.status?.info?.status) {
                    case "deployed":
                        if (isOutdated(release)) {
                            return <Popover content={<span>
                                There is a newer app version available.
                            </span>}>
                                <span className={styles["outdated"]} onClick={() => editApp(release)}>Outdated</span>
                            </Popover>;
                        }

                        return <span className={styles["hover-clickable"]} onClick={() => {
                            releaseStatusAlert(release);
                        }}>Active</span>;
                    case "failed":
                        return <span className={styles["failed"]} onClick={() => {
                            alert({
                                title: <span>Failed Helm Release {release.release.metadata?.name}</span>,
                                content: <TextArea readOnly
                                                   value={release.release.status?.info?.description} />,
                                okText: "Close"
                            });
                        }}>Failed</span>;
                    default:
                        return <span className={"color-warning"}>Unknown</span>;
                }
            }
        },
        {
            title: "Type",
            render: (release: HelmRelease) => {
                return <FixedText text={isSystemApp(release.release) ? "System App" : isApp(release.release) ? "App" : "Helm Release"} />;
            }
        },
        {
            title: 'Namespace',
            sorter: (a: HelmRelease, b: HelmRelease) => stringSorter(a.release.metadata?.namespace, b.release.metadata?.namespace),
            render: (release: HelmRelease) => {
                if (vCluster) {
                    return <FixedText text={release.release.metadata?.namespace} />;
                }
                
                return <Link to={`/spaces/${cluster}/${release.release.metadata?.namespace}`} className={styles["link"]}><FixedText className={styles["hover-clickable"]} text={release.release.metadata?.namespace} /></Link>;
            }
        },
        {
            title: "Revision",
            render: (release: HelmRelease) => {
                return release.release.status?.version;
            }
        },
        {
            title: 'Chart',
            render: (release: HelmRelease) => {
                if (release.release.spec?.manifests) {
                    return "None";
                }
                
                return <FixedText text={release.chart ? release.chart.repository?.name + "/" + release.release.status?.metadata?.name : release.release.status?.metadata?.name} />;
            }
        },
        {
            title: 'Chart Version',
            render: (release: HelmRelease) => {
                if (release.release.spec?.manifests) {
                    return "None";
                }
                
                return <FixedText text={release.release.status?.metadata?.version} />;
            }
        },
        {
            title: 'Updated',
            width: "180px",
            sorter: (a: HelmRelease, b: HelmRelease) => timeSorter(a.release.status?.info?.last_deployed, b.release.status?.info?.last_deployed),
            render: (release: HelmRelease) => {
                return <DynamicTime timestamp={release.release.status?.info?.last_deployed} useTooltip={true}/>
            }
        },
        {
            title: 'Actions',
            width: "180px",
            render: (release: HelmRelease) => {
                const resource = vCluster ? Resources.VirtualclusterV1HelmRelease : Resources.ClusterV1HelmRelease;
                if (isSystemApp(release.release)) {
                    return <TableActions className={styles["actions"]}>
                        <ShowYamlPopup canUpdate={false} className={styles["setting"]} object={release.release} cluster={cluster} vCluster={vCluster} resource={resource} name={release.release.metadata?.name!} namespace={release.release.metadata?.namespace!} refetch={refetch} />
                    </TableActions>;
                }
                
                return <TableActions className={styles["actions"]}>
                    <Tooltip title="edit">
                        <SettingOutlined className={styles["setting"]} onClick={() => editApp(release)} />
                    </Tooltip>
                    {(release.release.status?.version || 0) > 1 && <AppRollbackPopup className={styles["setting"]}
                                      cluster={cluster}
                                      vCluster={vCluster}
                                      release={release}
                                      refetch={refetch} />}
                    <ShowYamlPopup canUpdate={false} className={styles["setting"]} object={release.release} cluster={cluster} vCluster={vCluster} resource={resource} name={release.release.metadata?.name!} namespace={release.release.metadata?.namespace!} refetch={refetch} />
                    <AppDeletePopup className={styles["delete"]}
                                    cluster={cluster}
                                    vCluster={vCluster}
                                    release={release}
                                    refetch={refetch} />
                </TableActions>;
            }
        },
    ];
    if (namespace && !vCluster) {
        columns.splice(3, 1);
    }
    return columns;
}

function filter(item: HelmRelease, value: string) {
    return !!(item.release.metadata?.name?.includes(value) || item.release.metadata?.namespace?.includes(value))
}

function buildReleases(releases: Array<ClusterV1HelmRelease>, charts: Array<StorageV1HelmChart>, apps: Array<ManagementV1App>): Array<HelmRelease> {
    return releases.map(release => {
        const chart = charts.find(chart => chart.metadata?.name === release.spec?.chart?.name && chart.repository?.url === release.spec?.chart?.repoURL);
        const app = apps.find(app => app.metadata?.name === release.spec?.annotations?.[constants.LoftHelmReleaseAppNameAnnotation])
        return {
            key: release.metadata?.namespace + "/" + release.metadata?.name,
            release,
            app,
            chart
        }
    });
}

export interface UseAppsResult {
    charts: Array<StorageV1HelmChart>;
    busy: boolean;
    apps: Array<ManagementV1App> | undefined;
    releases: Array<HelmRelease> | undefined;
    loading: boolean;
    error: ResultError | undefined;
    showAll: boolean;
    setShowAll: (show: boolean) => void;
    refetch: () => Promise<void>;
}

export function useApps(cluster: string, namespace?: string, vCluster?: RequestOptionsVCluster): UseAppsResult {
    const [showAll, setShowAll] = useState(false);
    const {loading, error, data, refetch} = useQuery(async () => await client.auto(cluster, vCluster, vCluster ? Resources.VirtualclusterV1HelmRelease : Resources.ClusterV1HelmRelease).Namespace(!vCluster ? namespace : undefined).List({
        labelSelector: !showAll ? `${constants.LoftHelmReleaseAppLabel}=true` : undefined
    }), {refetch: [showAll]});
    const {loading: appsLoading, error: appsError, data: appsData} = useQuery(async () => await client.management(Resources.ManagementV1App).List());
    const {loading: chartsLoading, error: chartsError, data: chartsData} = useQuery(async () => await client.management(Resources.ManagementV1ClusterCharts).Get(cluster));
    const releases = data && chartsData && appsData ? buildReleases(arr(data.items), arr(chartsData.charts), arr(appsData.items)) : undefined;
    return {
        charts: arr(chartsData?.charts),
        busy: !!chartsData?.busy,
        apps: appsData?.items,
        releases,
        loading: loading || chartsLoading || appsLoading,
        error: error || chartsError || appsError,
        showAll,
        setShowAll,
        refetch
    }
}

interface AppsTableProps {
    cluster: string;
    namespace?: string;
    vCluster?: RequestOptionsVCluster;
    
    query: UseAppsResult;
    header: JSX.Element;
}

export default function AppsTable(props: AppsTableProps) {
    const history = useHistory();
    const drawerDispatcher = useItemDrawer({width: 600, className: drawerStyles["apps-drawer"]});

    // get releases
    return <div>
        <Table className={styles["table"]} loading={props.query.loading} columns={getTableColumns(props.query.refetch, drawerDispatcher, props.cluster, history, props.namespace, props.vCluster)} dataSource={props.query.releases} error={props.query.error} filter={filter} refetch={props.query.refetch} header={{
            top: props.header,
            middleLeft: <span style={{marginLeft: "15px"}}><Checkbox onChange={e => props.query.setShowAll(e.target.checked)} checked={props.query.showAll}>Show All Helm Releases</Checkbox></span>,
            right: <Button type={"primary"} onClick={() => {
                drawerDispatcher({
                    title: "Install App",
                    content: <AppsDrawer mode={"create"} 
                                         cluster={props.cluster} 
                                         clusterApp={false} 
                                         namespace={props.namespace} 
                                         vCluster={props.vCluster} 
                                         busy={props.query.busy} 
                                         apps={props.query.apps} 
                                         charts={props.query.charts} 
                                         refetch={props.query.refetch} />
                })
            }}>Install App</Button>,
        }} />
    </div>
};