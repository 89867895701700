import React from "react";
import {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import Label from "../../../../../components/Label/Label";
import Description from "../../../../../components/Description/Description";
import {NewResource, Resources} from "../../../../../lib/resources";
import {arr} from "../../../../../lib/helpers/renderhelper";
import Select from "../../../../../components/Select/Select";
import {ResultError, Return} from "../../../../../lib/result";
import {ManagementV1ClusterAccountTemplate} from "../../../../../../gen/model/managementV1ClusterAccountTemplate";
import SectionExpander from "../../../../../components/Drawer/SectionExpander/SectionExpander";

interface ClusterRoleBindingsProps extends SectionProps {
    clusterAccountTemplate?: ManagementV1ClusterAccountTemplate;
}

interface ClusterRoleBindingsState {
    clusterRoles: string[] | undefined;
    clusterRolesChanged: boolean;
}

export function isClusterRoleBinding(resource?: any): boolean {
    return resource &&
        resource["apiVersion"] === (Resources.V1ClusterRoleBinding.group + "/" + Resources.V1ClusterRoleBinding.version) &&
        resource["kind"] === Resources.V1ClusterRoleBinding.kind &&
        !arr(resource["subjects"]).length &&
        resource["roleRef"]?.["kind"] === "ClusterRole" &&
        resource["roleRef"]?.["name"];
}

function getClusterRoles(clusterAccountTemplate: ManagementV1ClusterAccountTemplate | undefined): string[] | undefined {
    if (!clusterAccountTemplate) {
        return undefined;
    }

    const clusterRoles: string[] = [];
    const resources = arr(clusterAccountTemplate.spec?.owns);
    for (let i = 0; i < resources.length; i++) {
        const resource = resources[i] as any;
        if (isClusterRoleBinding(resource)) {
            clusterRoles.push(resource["roleRef"]?.["name"]);
        }
    }

    return clusterRoles;
}

function setClusterRoles(clusterAccountTemplate: ManagementV1ClusterAccountTemplate | undefined, clusterRoles: string[] | undefined) {
    if (!clusterAccountTemplate) {
        return;
    }

    clusterRoles = arr(clusterRoles);
    const resources = arr(clusterAccountTemplate.spec?.owns);
    if (!resources.length && !clusterRoles.length) {
        return;
    }

    // remove the ones that are not there anymore
    for (let i = 0; i < resources.length; i++) {
        const resource = resources[i] as any;
        if (isClusterRoleBinding(resource)) {
            const found = clusterRoles.find(clusterRole => clusterRole === resource["roleRef"]?.["name"]);
            if (!found) {
                resources.splice(i, 1);
                i--;
            }
        }
    }

    // add the ones that are not there
    for (let i = 0; i < clusterRoles.length; i++) {
        const found = resources.find((clusterRole: any) => isClusterRoleBinding(clusterRole) && clusterRole["roleRef"]["name"] === arr(clusterRoles)[i]);
        if (!found) {
            resources.push(NewResource(Resources.V1ClusterRoleBinding, undefined, {
                roleRef: {
                    apiGroup: Resources.V1ClusterRole.group,
                    kind: Resources.V1ClusterRole.kind,
                    name: clusterRoles[i].trim(),
                }
            }))
        }
    }

    if (!clusterAccountTemplate.spec) {
        clusterAccountTemplate.spec = {}
    }
    clusterAccountTemplate.spec.owns = resources;
}

export default class ClusterRoleBindings extends React.PureComponent<ClusterRoleBindingsProps, ClusterRoleBindingsState> {
    state: ClusterRoleBindingsState = {
        clusterRoles: this.props.clusterAccountTemplate ? getClusterRoles(this.props.clusterAccountTemplate) : undefined,
        clusterRolesChanged: false,
    };

    create(clusterAccountTemplate: ManagementV1ClusterAccountTemplate): ResultError {
        setClusterRoles(clusterAccountTemplate, this.state.clusterRoles);
        return Return.Ok();
    }

    update(clusterAccountTemplate: ManagementV1ClusterAccountTemplate): ResultError {
        if (this.state.clusterRolesChanged) {
            setClusterRoles(clusterAccountTemplate, this.state.clusterRoles);
        }

        return Return.Ok();
    }

    render() {
        if (this.props.mode === "batch") {
            return null;
        }

        return <SectionExpander name={"Cluster Role Bindings"}>
            <Label>Specify cluster roles the template should create a cluster role binding for</Label>
            <Select mode="tags" tokenSeparators={[',', ' ']}
                    resetable={this.props.mode !== "create"}
                    onChangedStatus={(changed) => this.setState({clusterRolesChanged: changed})}
                    value={this.state.clusterRoles}
                    placeholder={"cluster-admin, edit, view..."}
                    onChange={value => this.setState({clusterRoles: value})} />
            <Description>Cluster roles allow the account to do certain things in the cluster.</Description>
        </SectionExpander>
    }
}